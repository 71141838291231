// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
//@ts-ignore
import _ from "lodash";

export const sideMenuValue = ["Post", "Itinerary"];

const useStyles = makeStyles((theme) => ({
  PostCreates: {
    padding: "40px 20px 20px",
    backgroundColor: "#FAFBFF",
    minHeight: "100%",
    boxSizing: "border-box",
    "& .post-container": {
      margin: "unset !important",
      padding: "0 !important",
    },
    "& .mainHeader": {
      fontFamily: "Helvetica-Bold",
      fontSize: "22px",
      fontWeight: "400",
      marginBottom: "40px",
      lineHeight: "27px",
    },
    "& .white-box-container": {
      borderRadius: "20px",
      border: "0.5px solid rgba(105,134,216,0.1)",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
      padding: "29px 20px",
      marginBottom: 20,
      position: "relative",
      cursor: "pointer",
    },
    "& .box-text": {
      height: "24px",
      fontFamily: "Helvetica",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    "& .white-font": {
      color: "#818181",
    },
    "& .active::after": {
      right: "0",
      top: "20px",
      content: "''",
      position: "absolute",
      width: "6px",
      height: "40px",
      borderRadius: "20px",
      background: "#25aae1",
      backgroundBlendMode: "normal",
    },
    "& .black-font": {
      color: "#000000",
    },
    "& .light-font": {
      color: "#818181",
    },
    "& .right-side-main-container": {
      borderRadius: "20px",
      border: "0.5px solid rgba(105,134,216,0.1)",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
      padding: 40,
      marginBottom: 20,
    },
  },
}));

interface Props {
  children: any;
  navigation: any;
  title: string;
}

const PostOrItineraryContainer: React.FC<Props> = ({
  children,
  navigation,
  title,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const path: any = window.location.pathname;
    if(path){
      //@ts-ignore
      const parts = path.match(/\/([^\/-]+)/)[1];
      setActiveMenu(_.capitalize(parts));
    }
  }, [window.location.pathname]);

  const [activeSideMenu, setActiveMenu] = useState("Post");

  return (
    <Box className={classes?.PostCreates}>
      <Typography variant="h5" className="mainHeader">
        {title}
      </Typography>
      <Container className="post-container" maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item md={4} sm={4}>
            {sideMenuValue.map((item) => {
              return (
                <Box
                  key={item}
                  onClick={() => {
                    setActiveMenu(item);
                    navigation.history.push(`${item.toLowerCase()}-create`);
                  }}
                  className={`white-box-container  ${
                    activeSideMenu === item ? "black-font" : "white-font"
                  }`}
                >
                  <Typography
                    variant="body1"
                    className={`box-text ${activeSideMenu === item &&
                      "active"}`}
                  >
                    {item}
                  </Typography>
                </Box>
              );
            })}
          </Grid>
          <Grid item md={8} sm={8}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PostOrItineraryContainer;
// Customizable Area End
