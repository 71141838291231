// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import DataContext from "../../../components/src/DataContext";
import { dashboard, Screen2, Screen3 } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  otp: string;
  validOtp: string; // a sample OTP to validate against
  showErrorMessage: boolean;
  accessToken: string | null;
  email: string | null;
  typezz: string | null;
  number: string;
  errorMsg: string;
  error: boolean;
  oneMinTimer: number;
  otps: string;
  isLoading: boolean;
  images: {id:number; img: ''; data: string;}[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class RegistrationOTPControllerBlock extends BlockComponent<
  Props,
  S,
  SS
> {
  otpVerifyId: string = "";
  otpResendId: string = "";
  interval: any;
  static contextType: React.Context<string> = DataContext;

  async componentDidMount() {
    super.componentDidMount();
    const Token = await getStorageData('token');
    this.setState({accessToken: Token})
    if (this.state.oneMinTimer > 0) {
      this.interval = setInterval(
        () =>
          this.setState((prevState) => ({
            oneMinTimer: prevState.oneMinTimer - 1,
          })),
        1000
      );
    }
    this.setState({
      email: this.context.data.email,
      number: this.context.data.number,
    });
  }

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      otp: "",
      validOtp: "1234", // a sample OTP to validate against
      showErrorMessage: false,
      accessToken: '',
      email: "",
      typezz: localStorage.getItem("path"),
      number: "",
      error: false,
      errorMsg: "",
      oneMinTimer: 60,
      otps: "",
      isLoading: false,
      images: [
        {
          data:'head1title',
          id: 1,
          img: dashboard,
        },
        {
          img: Screen2,
          id: 2,
          data: 'head1title'
        },
        {
          id: 3,
          data: 'head1title',
          img: Screen3,
        },
      ],
      // Customizable Area End
    };
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.otpVerifyId) {
        if (responseJson.data) {
          this.saveLoggedInUserData(responseJson);
          this.props.navigation.navigate("MoreSteps");
        } else {
          this.setState({ error: true, errorMsg: responseJson.errors[0].pin });
        }
      }
      if (apiRequestCallId === this.otpResendId) {
        this.saveLoggedInUserData(responseJson);
        if (responseJson) {
          this.setState({ oneMinTimer: 60 });
        } else {
          this.setState({ error: true, errorMsg: responseJson.errors[0].pin });
        }
      }
      this.setState({
        isLoading: false,
      });
    }
  }
  
  handleChangesss = (otps: any) => {
    this.setState({ otps });
  };

  saveLoggedInUserData = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );
      this.send(msg);
    }
  };

  otpVerify = (data: { data: { attributes: { pin: string } } }) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.accessToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpVerifyId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.typezz === "email_account"
        ? configJSON.verifyEmailOtp
        : configJSON.verifysmsOtp
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  OTPSignUp = () => {
    this.setState({
      isLoading: true,
    });
    const data = {
      data: {
        attributes: {
          pin: this.state.otps,
        },
      },
    };
    this.otpVerify(data);
  };

  resendOtp = () => {
    this.setState({
      isLoading: true,
    });
    let data = {};
    if (this.state.typezz === "email_account") {
      data = {
        data: {
          attributes: {
            email: this.state.email,
          },
        },
      };
    } else {
      data = {
        data: {
          attributes: {
            full_phone_number: this.state.number,
          },
        },
      };
    }
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpResendId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpResendOtp
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
