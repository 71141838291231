// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../../components/src/utils.web";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes?: any;
}

interface S {
    isLoading: boolean;
    itinenary: { id: string; owner_id: number; title?: string; travelers: { profile_photo: string; account_id: number; full_name: string; }[]; itinerary_locations: { title: string; days: number; id: number }[] };
    isUsersExpand: boolean;
    ModelTitle: string;
    ModelOpen: boolean;
    anchorEl: any;
    deleteModal: boolean;
    userDetail: { id?: string }
    Activitys: { name: string; location: string; photos: string[]; description: string }[];
    EditableUsers: string[];
}

interface SS {
    id: any;
}

export default class ViewItineraryController extends BlockComponent<
    Props,
    S,
    SS
> {
    getItinenaryApiID = "";
    getItinenaryActivityApiID = "";
    DeleteItinenaryApiID = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            isLoading: false,
            itinenary: { owner_id: 0, id: "", title: "", travelers: [], itinerary_locations: [] },
            isUsersExpand: false,
            ModelTitle: "",
            ModelOpen: false,
            anchorEl: null,
            deleteModal: false,
            userDetail: {},
            Activitys: [],
            EditableUsers: []
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson.errors) {
                this.handleError(responseJson.errors);
                return;
            }

            switch (apiRequestCallId) {
                case this.getItinenaryApiID:
                    this.setState({
                        itinenary: responseJson,
                        isLoading: false,
                        EditableUsers: [responseJson.owner_id.toString(), ...responseJson.travelers.map((user: { account_id: number }) => {
                            return user.account_id.toString();
                        })],
                    });
                    break;
                case this.getItinenaryActivityApiID:
                    this.handleActivitys(responseJson);
                    break;
                case this.DeleteItinenaryApiID:
                    if (responseJson.message === configJSON.deleteItinerarySuccessTitle) {
                        toast.success(responseJson.message);
                        this.props.navigation.history.push(`/profile/${this.state.userDetail.id}`, { isItineraryCreated: true });
                    }
                    break;
            }
        }
    }

    async componentDidMount() {
        const data = localStorage.getItem("user_details")
        if (data) {
            this.setState({
                userDetail: JSON.parse(data)
            })

        }
        const id = this.props.navigation.getParam("id");
        this.getItinenaryData(id);
    }

    handleActivitys = (responseJson: []) => {
        this.setState({
            Activitys: responseJson,
            isLoading: false,
            ModelOpen: true,
        })
    }

    handleUsers = (value: boolean) => {
        if (this.state.itinenary.travelers.length > 5) {
            this.setState({
                isUsersExpand: value
            })
        }
    }
    modalclose = () => {
        this.setState({
            ModelOpen: false
        })
    }
    handleActivity = async (DayId: number, name: string, index: number) => {
        this.setState({
            ModelTitle: name,
            isLoading: true,
        })
        this.getItinenaryActivityApiID = await apiCall({
            endPoint: configJSON.getItinenaryActivityApiEndPoint + this.state.itinenary.id + `&location_id=${DayId}&day_rank=${index + 1}`,
            method: "GET",
            contentType: configJSON.exampleApiContentType,
        });
    }
    handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleDeleteItinerary = () => {
        this.setState({
            deleteModal: true
        })
    }

    handleBackButton = (isUserID:boolean) =>{
        const history = this.props.navigation.history
        if(isUserID){
            history.push(`/profile/${history.location.state.userId}`,{ isItineraryCreated: true })
        }else{
            history.goBack()
        }
    }
    handleDelete = async () => {
        this.handleModalClose();
        this.DeleteItinenaryApiID = await apiCall({
            endPoint: configJSON.deleteItinenaryApiEndPoint,
            method: "DELETE",
            contentType: configJSON.exampleApiContentType,
            body: JSON.stringify({
                "itinerary_id": this.state.itinenary.id,
            })
        });
    }

    handleModalClose = () => {
        this.setState({
            deleteModal: false
        })
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    }

    getItinenaryData = async (id: number) => {
        this.setState({
            isLoading: true,
        })
        this.getItinenaryApiID = await apiCall({
            endPoint: configJSON.getItineraryApiEndPoint + id,
            method: "GET",
            contentType: configJSON.exampleApiContentType,
        });
    }

    handleDays = (day: number) => {
        const plans = [];

        for (let i = 0; i < day; i++) {
            const dayName = `Day ${i + 1}`;
            const plan = { name: dayName };
            plans.push(plan);
        }

        return plans;
    };


    handleError = (errors: { token: string }[]) => {
        if (Array.isArray(errors)) {
            if (
                errors[0].token === "Invalid token" ||
                errors[0].token === "Token has Expired"
            ) {
                toast.error(`${errors[0].token}${configJSON.errormassage2}`);
                window.localStorage.removeItem("authToken");
                window.localStorage.removeItem("user_details");
                this.props.navigation.navigate("EmailAccountLoginBlock");
            } else {
                toast.error(configJSON.errormassage);
            }
        }
    }

}

// Customizable Area End
