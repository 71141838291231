import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    index: number,
    item: any,
    userDetails: any,
    handleLikeForumAction: any,
}

interface S {
    // Customizable Area Start
    temp: {
        [keys: number]: string | number | any;
    };
    txtInputValue: string;
    like: boolean;
    commentId: number;
    anchorEle: any;
    filterPopUp: boolean;
    AllForums: { id: number; created_at: string; location: string; description: string; images_and_videos: { type: string, url: string }[]; likes: number | string; comments: number | string; uploader_name: string; liked_by_user: string; uploader_image: string; account_id: number; admin_user_id: number }[];
    comments: { data: { id: number; content: string; created_at: string; account_full_name: string; account_image: string; commentable_id: number; comment_by_id: number; upvote: number; downvote: number; current_user_upvoted: string; current_user_downvoted: string }[], total_page: 0 };
    commentText: string;
    token: string;
    showEmojis: boolean;
    confirmModel: boolean;
    myCommentId: number;
    tempMenuId: number;
    showMore: number;
    // postMenu: any;
    // postMenuId: number;
    // deleteForumModel: boolean
    // shareModalId: number;
    // shareModal: boolean;
    currentForumId: number;
    commentPage: number;
    commentCount: number;
    editCommentData: {
        [keys: string]: string | number;
    },
    hasMoreComment: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ForumCardController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    AllForumId: string = "";
    handleDeleteCommentId: string = "";
    ForumCommentId: string = "";
    handleForumCommentId: string = "";
    likeForumId: string = "";
    handleUpdateCommentId: string = "";
    // handleDeleteForumId: string = "";
    activitiesId: string = "";
    searchActivityApiId: string = "";
    searchApiId: string = "";
    handleVoteCallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            txtInputValue: "",
            like: false,
            commentId: NaN,
            anchorEle: false,
            filterPopUp: false,
            AllForums: [],
            token: localStorage.getItem("authToken") || "",
            comments: { data: [], total_page: 0 },
            myCommentId: NaN,
            commentText: "",
            showEmojis: false,
            confirmModel: false,
            temp: {},
            // postMenuId: NaN,
            tempMenuId: NaN,
            showMore: NaN,
            // postMenu: "",
            // deleteForumModel: false,
            // shareModal: false,
            currentForumId: NaN,
            commentCount: 0,
            // shareModalId: NaN,
            editCommentData: {},
            commentPage: 1,
            hasMoreComment: false
            // Customizable Area End 
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.handleForumLike = this.handleForumLike.bind(this)
        this.receive = this.receive.bind(this);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.likeForumId) {
                if (responseJson.message === 'Unlike Successfully') {
                    this.props.item.likes = --this.props.item.likes;
                    this.props.item.liked_by_user = "false"
                } else {
                    this.props.item.likes = ++this.props.item.likes;
                    this.props.item.liked_by_user = "true"
                }
                this.props.handleLikeForumAction(this.props.item, this.props.index);
            } else if (apiRequestCallId === this.handleUpdateCommentId) {
                const commentData = this.state.comments.data;
                this.state.editCommentData['content'] = this.state.commentText
                const index = commentData?.findIndex((item) => item.id === this.state.editCommentData?.id);
                this.state.comments.data[index] = this.state.editCommentData as any;
                this.setState({ comments: this.state.comments, editCommentData: {}, commentText: "" })
            } else if (apiRequestCallId === this.ForumCommentId) {
                if (responseJson.data.length) {
                    const commentData = responseJson.data.map((i: { attributes: {} }) => i.attributes);
                    this.setState({
                        comments: { data: [...commentData, ...this.state.comments.data], total_page: responseJson?.meta?.total_pages }
                    })
                }
            } else if (apiRequestCallId === this.handleDeleteCommentId) {
                const commentData = this.state.comments.data
                const index = commentData.findIndex(item => item.id == this.state.commentId)
                const tempData = commentData.splice(index, 1);
                this.setState({
                    comments: { data: tempData, total_page: this.state.comments.total_page },
                    commentCount: this.state.commentCount - 1
                })
            } else if (apiRequestCallId === this.handleForumCommentId) {
                this.state.comments.data.unshift(responseJson.data.attributes)
                this.setState({ comments: { ...this.state.comments }, commentCount: this.state.commentCount + 1, commentText: "" })
            } else if (apiRequestCallId === this.handleVoteCallID) {
                const commentData = this.state.comments.data
                const index = commentData.findIndex((item: any) => item.id == this.state.myCommentId);
                commentData[index]=responseJson?.data?.attributes;
                this.setState({ comments: { data: commentData, total_page: this.state.comments.total_page } })

            }
        }

        // Customizable Area Start
        // Customizable Area End
    }

    // web events
    // Customizable Area Start

    componentDidMount(): any {
        this.setState({
            commentCount: this.props.item.comments || 0
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (this.props.item.comments !== prevProps.item.comments) {
            this.setState({
                commentCount: this.props.item.comments || 0
            })
        }
    }

    handleForumLike = () => {
        const id = this.props.item.id
        const body = JSON.stringify({
            data: {
                attributes: {
                    likeable_id: id,
                    likeable_type: configJSON.blockType
                }
            }
        })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.likeForumId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.likeUnlikeForumEndpoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    };

    handleCommentMenuEdit = (id: number) => {
        const comment = this.state.comments?.data?.filter((item) => item.id === id);
        this.handleCommentMenuClose();
        this.setState({
            editCommentData: comment[0],
            commentText: comment[0].content
        })
    }

    getForumComment = (id: number, page: number) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };
        this.setState({
            currentForumId: id
        })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ForumCommentId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllCommentForumEndpoint + id + `?page=${page}&per_page=10`
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    onHandleConfirmation = (commentId: number, myCommentId: number) => {
        const body = JSON.stringify({
            commentable_id: commentId,
            commentable_type: configJSON.blockType
        })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.handleDeleteCommentId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.commentForumEndpoint + `/${myCommentId}`
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
        this.setState({
            commentId
        })
    }
    onHandleUpWardAndDownWard = (id: any, vote_type: string) => {
        const data = {
            "attributes": {
                "votable_id": id,
                "votable_type": "BxBlockComment::Comment",
                "vote_type": vote_type
            }
        }
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.handleVoteCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodPOST
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.voteCommentEndpoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({ data })
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ myCommentId: id })
    }


    handleForumComment = () => {
        const id = this.props.item.id;
        const value = this.state.commentText
        if (value) {
            this.setState({
                commentId: id
            })
            const body = JSON.stringify({
                commentable_id: id,
                commentable_type: "BxBlockForum::Forum",
                content: value
            })
            const headers = {
                "Content-Type": configJSON.validationApiContentType,
                "token": this.state.token
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            if (this.state.editCommentData?.id) {
                this.handleUpdateCommentId = requestMessage.messageId;
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    "PUT"
                )
                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    configJSON.commentForumEndpoint + `/${this.state.editCommentData.id}`
                )

            } else {
                this.handleForumCommentId = requestMessage.messageId;
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    "POST"
                )
                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    configJSON.commentForumEndpoint
                )
            }

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                headers
            )
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            )
            runEngine.sendMessage(requestMessage.id, requestMessage)
        }
    }
    handleComment = () => {
        const id = this.props.item.id
        if (this.state.commentId !== id) {
            this.setState({ commentId: id });
            this.getForumComment(id, this.state.commentPage)
        } else {
            this.setState({ commentId: NaN,comments:{data:[],total_page:0} });
        }
    };

    handleEnter = (event: any) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            this.setState({
                showEmojis: false,
            })
            this.handleForumComment();
        }
    };
    handleEmoji = () => {
        this.setState({
            showEmojis: !this.state.showEmojis
        })
    }
    handleCommentText = (event: any) => {
        this.setState({
            commentText: event.target.value
        })
    }
    onCommentEmojiClick = (emojiObject: any) => {
        let emoji = emojiObject.emoji;
        let commentText = this.state.commentText + emoji;
        this.setState({ commentText, showEmojis: false });
    }
    handleCommentMenuClose = () => {
        this.setState({ anchorEle: false })
    }
    handleCommentMenuClick = (event: any, id: number) => {
        this.setState({ anchorEle: event.currentTarget, tempMenuId: id })
    }
    handleCloseModal = () => {
        this.setState({
            confirmModel: false,
            tempMenuId: NaN
        })
    }
    handleCommentMenuRemove = (commentId: number) => {
        this.setState({
            confirmModel: true,
            myCommentId: commentId,

        })
        this.handleCommentMenuClose();
    }

    handleClickAway = () => {
        this.setState({ commentId: NaN ,comments:{data:[],total_page:0}});
    }
    showMore = (number: number) => {
        this.setState({ showMore: this.state.showMore >= 0 ? NaN : number })
    }
    handleNextCommentPage = async () => {
        await this.setState({ commentPage: this.state.commentPage + 1 })
        await this.getForumComment(this.state.currentForumId, this.state.commentPage)
    }

    // Customizable Area End
}
