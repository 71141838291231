import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../../components/src/utils.web";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  temp: {
    [keys: number]: string | number | any;
  };
  txtInputValue: string;
  commentId: number;
  filterPopUp: boolean;
  comments: { id: number; content: string; created_at: string; account_full_name: string; account_image: string; commentable_id: number; comment_by_id: number; }[];
  token: string;
  filterRef: any;
  filterActivities: { id: string; name: string; data: { id: string; name: string }[] }[];
  filterActivitiesId: {
    [keys: string]: string[]
  };
  userDetail: any;
  userPost: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      description: string;
      location: string;
      state: string;
      account_id: number;
      admin_user_id: null | string;
      created_at: string;
      updated_at: string;
      model_name: string;
      uploader_name: string;
      uploader_image: string;
      images_and_videos: {
        id: number;
        filename: string;
        type: string;
        url: string;
      }[];
      tags: string[];
      is_current_user: string;
      likes: number;
      share_count: number;
      comments: number | null;
      liked_by_user: string;
      interests: {
        data: {
          id: string;
          type: string;
          attributes: {
            name: string;
            activities: {
              data: {
                id: string;
                type: string;
                attributes: {
                  name: string;
                };
              }[];
            };
          };
        }[];
      };
    };
  }[];
  isLoading: boolean;
  currentPage: number;
  hasMoreData: boolean;
  singlePostID: string;
  reloadChatList: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FanWallControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  AllPostId: string = "";
  likePostId: string = "";
  PostCommentId: string = "";
  handlePostCommentId: string = "";
  handleDeleteCommentId: string = "";
  handleDeletePostId: string = "";
  activitiesId: string = "";
  searchApiId: string = "";
  searchActivityApiId: string = "";
  AllPostPageId: string = "";
  singlePostDetails: string = "";
  ItineraryData= '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      commentId: NaN,
      filterPopUp: false,
      temp: {},
      comments: [],
      token: localStorage.getItem("authToken") || "",
      filterRef: false,
      filterActivities: [],
      filterActivitiesId: {},
      userDetail: {},
      userPost: [],
      isLoading: false,
      currentPage: 1,
      hasMoreData: false,
      singlePostID: "",
      reloadChatList: false,
      // Customizable Area End 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handlePostLike = this.handlePostLike.bind(this)
    this.handlePostFilter = this.handlePostFilter.bind(this)
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    const itinerary = await getStorageData('ItineraryData', true);
    if(itinerary && Object.keys(itinerary)?.length){
      this.props.navigation.history.push('itinerary-create');
    }
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.setState(
        {
          singlePostID: id,
        },
        () => {
          this.getSinglePostDetails();
        }
      );
    } else {
      this.getAllPost();
    }
    const data = localStorage.getItem("user_details")
    if (data) {
      this.setState({
        userDetail: JSON.parse(data)
      })

    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson.errors) {
        this.setState({isLoading: false});
        this.handleError(responseJson.errors);
        return;
      }

      switch (apiRequestCallId) {
        case this.AllPostId:
        case this.searchApiId:
        case this.searchActivityApiId:
          this.setState({
            userPost: responseJson.data,
            isLoading: false,
            hasMoreData: true,
            currentPage: 1
          });
          break;

        case this.likePostId:
          this.getAllPost();
          break;

        case this.handlePostCommentId:
        case this.handleDeleteCommentId:
          this.getPostComment(this.state.commentId);
          this.getAllPost();
          break;

        case this.PostCommentId:
          if (responseJson.data.length) {
            this.setState({
              comments: responseJson.data.map((i: { attributes: {} }) => i.attributes)
            });
          }
          break;

        case this.handleDeletePostId:
          this.getAllPost();
          break;

        case this.activitiesId:
          const data = responseJson.data.map((item: any) => {
            return {
              id: item.id,
              name: item?.attributes.name,
              data: item?.attributes.activities.data.map((data: any) => {
                return {
                  id: data.id,
                  name: data.attributes.name
                };
              })
            };
          });
          this.setState({
            filterActivities: data
          });
          break;

        case this.AllPostPageId:
          if (responseJson.data?.length) {
            this.setState({
              userPost: [...this.state.userPost, ...responseJson.data]
            });
          } else {
            this.setState({
              hasMoreData: false
            });
          }
          break;
        case this.singlePostDetails:
          this.setState({
            userPost: [responseJson.data],
            hasMoreData: false,
          });
      }
      this.setState({
        isLoading: false,
      })

    }

    // Customizable Area Start
    // Customizable Area End
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  // Customizable Area Start

  handleError = (errors: any) => {
    if (Array.isArray(errors)) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}${configJSON.errormassage2}`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
      } else if(errors[0].Post === "Not found"){
        toast.error(configJSON.noPostFoundTitle);
      } else {
        toast.error(configJSON.errormassage);
      }
    }
  }

  getAllPost = () => {
    this.setState({
      userPost: [],
      isLoading: true
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.AllPostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allPost + `?page=${this.state.currentPage}&per_page=10`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getSinglePostDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.singlePostDetails = await apiCall({
      endPoint: `bx_block_posts/posts/${this.state.singlePostID}`,
      method: "GET",
    });
  };

  handleLikePostAction = (attr: any, index: number) => {
    const data = this.state.userPost[index];
    data.attributes = attr
    this.state.userPost[index] = data;
    this.setState({ userPost: this.state.userPost })
  }
  handleDeletePostLocal = (index: number) => {
    const data = this.state.userPost
    data.splice(index, 1);
    this.setState({ userPost: data })
  }

  handlePostLike = (id: number) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const body = JSON.stringify({
      data: {
        attributes: {
          likeable_id: id,
          likeable_type: configJSON.blockType
        }
      }
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likePostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likePost
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  };

  getPostComment = (id: number) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPostComments + id
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  onHandleConfirmation = (commentId: number, myCommentId: number) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const body = JSON.stringify({
      commentable_id: commentId,
      commentable_type: configJSON.blockType
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.handleDeleteCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postComment + `/${myCommentId}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    this.setState({
      commentId
    })
  }

  onHandleConfirmationPostDelete = (postId: number) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.handleDeletePostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allPost + `/${postId}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handlePost = () => {
    this.setState({
      currentPage: this.state.currentPage + 1
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.AllPostPageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    if (Object.keys(this.state.filterActivitiesId)?.length) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applyActivityFilter + JSON.stringify(this.state.filterActivitiesId) + `&page=${this.state.currentPage}&per_page=10`
      )
    } else if (this.state.txtInputValue) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applyFilter + this.state.txtInputValue + `&page=${this.state.currentPage}&per_page=10`
      )
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allPost + `?page=${this.state.currentPage}&per_page=10`
      )
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handlePostComment = (id: number, value: string, isEdit: { [keys: string]: string | number; }) => {
    if (value) {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token
      };
      this.setState({
        commentId: id
      })
      const body = JSON.stringify({
        commentable_id: id,
        commentable_type: "BxBlockPosts::Post",
        content: value
      })
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.handlePostCommentId = requestMessage.messageId;
      if (isEdit?.id) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        )
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.postComment + `/${isEdit?.id}`
        )
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        )
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.postComment
        )
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )
      runEngine.sendMessage(requestMessage.id, requestMessage)
    }
  }

  handleApplyFilter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.setState({
        userPost: [],
        isLoading: true
      })
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.searchApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applyFilter + this.state.txtInputValue + `&page=1&per_page=10`
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      )
      runEngine.sendMessage(requestMessage.id, requestMessage)
    }
  }

  handlePostFilter = (event: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activitiesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activities
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    this.setState({ filterPopUp: true, filterRef: event.currentTarget })
  }

  handleActivityFilter = (activityId: string, subActivityId: string, isSelected: boolean) => {
    if (isSelected) {
      const filterActivity = this.state.filterActivitiesId[activityId];
      const activityIndex = this.state.filterActivitiesId[activityId].indexOf(subActivityId);
      filterActivity.splice(activityIndex, 1);
      if (filterActivity.length) {
        this.setState({
          filterActivitiesId: {
            ...this.state.filterActivitiesId,
            [activityId]: filterActivity
          }
        })
      } else {
        const filterActivitiesId = this.state.filterActivitiesId;
        delete filterActivitiesId[activityId];
        this.setState({
          filterActivitiesId
        })
      }

    } else {
      const activity = this.state.filterActivitiesId?.[activityId] || []
      this.setState({
        filterActivitiesId: {
          ...this.state.filterActivitiesId,
          [activityId]: [...activity, subActivityId]
        }
      })
    }

  }

  handleApplyActivityFilter = () => {
    this.setState({
      userPost: [],
      isLoading: true
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchActivityApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.applyActivityFilter + JSON.stringify(this.state.filterActivitiesId) + `&page=1&per_page=10`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    this.handleCloseFilter();
  }

  handleCloseFilter = () => {
    this.setState({ filterPopUp: false, filterRef: null })
  }

  chatListAgainApiCall = () => {
    this.setState({
      reloadChatList: !this.state.reloadChatList,
    });
  };

  updateShareCount = (postId: number, share_count: number) => {
    const userPost = this.state.userPost;
    const postIndex = userPost.findIndex((data) => Number(data.id) === postId);
    if (postIndex !== -1) {
      const oneData = userPost[postIndex];
      oneData.attributes.share_count =
        oneData.attributes.share_count + share_count;
      userPost.splice(postIndex, 1, oneData);
      this.setState({
        userPost,
      });
    }
  };
  // Customizable Area End
}
