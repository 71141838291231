// Customizable Area Start

export const EditProfileStyles = {
  Profile: {
    backgroundColor: "#FAFBFF",
    padding: "40px 20px 20px",
    minHeight: "100%",
    boxSizing: "border-box",
    "& .title": {
      fontFamily: "Helvetica-Bold",
      fontSize: "22px",
      fontWeight: "500",
      lineHeight: "27px",
      margin: "40px 0 20px",
    },
    "& .white-box": {
      borderRadius: "20px",
      border: "0.5px solid rgba(105,134,216,0.1)",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
      padding: "25px 0",
    },
    "& .text-area-field::placeholder": {
      color: "#d5d5d5",
    },
    "& .text-area-field": {
      borderRadius: "16px",
      border: "0.5px solid rgba(105,134,216,0.1)",
      backgroundBlendMode: "normal",
      outline: "none",
      background: "#ffffff",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
      width: "100%",
      color: "#353249",
      padding: "25.32px 14px",
      resize: "none",
    },
    "& .left-part": {
      borderRight: "1px solid #979797",
      padding: "0 25px",
    },
    "& .right-part": {
      padding: "0 25px",
    },
    "& .button-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "30px 0",
    },
    "& .right-align-items": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: 20,
    },
    "& .image-side-text": {
      color: "#8f92a1",
      fontFamily: "Helvetica-Bold",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "22px",
    },
    "& .chip-item-container": {
      display: "flex",
      flexWrap: "wrap",
      gap: "15px",
    },
    "& .image-main-container": {
      display: "flex",
      alignItems: "center",
      gap: 20,
    },
    "& .image-container": {
      position: "relative",
      borderRadius: "50%",
      cursor: "pointer",
    },
    "& .image-container img": {
      height: "128px",
      width: "128px",
      border: "1px solid rgb(33, 150, 243)",
      borderRadius: "50%",
      padding: "3px",
      boxSizing: "border-box",
    },
    "& .text-field-outside-box": {
      padding: "15px 14px",
      border: "0.5px solid rgba(105,134,216,0.1)",
      borderRadius: "16px",
      cursor: "pointer",
    },
    "& .place-holder": {
      color: "#d5d5d5",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
      margin: "9px 0",
    },
    "& .chip-item": {
      borderRadius: "12px",
      border: "1px solid rgba(151,151,151,0.21)",
      background: "rgba(37,170,225,0.2)",
      backgroundBlendMode: "normal",
      color: "#25aae1",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
      letterSpacing: "-0.45px",
      lineHeight: "22px",
      padding: "5.5px 15px",
    },
    "& .verify-label": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#25aae1",
      textDecoration: "underline",
      cursor: "pointer",
    },
    "& .not-found-text": {
      width: "100%",
      fontSize: "16px",
      fontFamily: "Helvetica",
      lineHeight: "20px",
      textAlign: "center",
      color: "#000000",
    },
    "& .form-inner-label": {
      fontFamily: "Helvetica-Bold",
      fontSize: "22px",
      fontWeight: "500",
      lineHeight: "27px",
    },
    "& .published-button": {
      padding: "15px",
      borderRadius: "4px",
      background: "#25aae1",
      backgroundBlendMode: "normal",
      width: "50%",
      marginBottom: "20px",
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "Helvetica",
        fontSize: "24px",
        fontWeight: "400",
        textTransform: "capitalize",
      },
    },
    "& .selected-chip": {
      borderRadius: "20px",
      border: "1px solid rgba(37,170,225,0.24)",
      background: "#25aae1",
      backgroundBlendMode: "normal",
      height: "39px",
      cursor: "pointer",
      "& .MuiChip-label": {
        color: "#ffffff",
        fontFamily: "Helvetica",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "19px",
        padding: "0 16px",
      },
    },
    "& .un-selected-chip": {
      backgroundBlendMode: "normal",
      borderRadius: "20px",
      border: "1px solid rgba(37,170,225,0.24)",
      height: "39px",
      cursor: "pointer",
      background: "#fafbff",
      "& .MuiChip-label": {
        color: "#000000",
        lineHeight: "19px",
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "Helvetica",
        padding: "0 16px",
      },
    },
    "& .add-Tags": {
      height: "fit-content",
      padding: "0 25px",
      width: "100%",
      "& .back-button": {
        width: "80px",
        borderRadius: "8px",
        backgroundColor: "#a9d9ed",
        boxShadow: "none",
        padding:"8px 0",
        "& .MuiButton-label": {
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: "400",
          color: "#25aae1",
          "& .back-button-image": {
            fill: "#25aae1",
            height: "15px",
          },
        },
      },
      "& .main-heading": {
        fontFamily: "Helvetica",
        marginBottom: "20px",
        fontWeight: "400",
        color: "#0a083a",
        fontSize: "24px",
        lineHeight: "29px",
        marginTop: "30px",
      },
      "& .tag-heading": {
        lineHeight: "24px",
        color: "#0a083a",
        fontSize: "20px",
        fontWeight: "400",
        marginBottom: "20px",
        fontFamily: "Helvetica",
        marginTop: "20px",
      },
      "& .tag-items": {
        overflowY: "auto",
        height: "100%",
        marginBottom: "20px",
        maxHeight: "565px",
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "rgb(0 4 5 / 16%)",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: " #f1f1f1",
        },
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(23,89,117,0.16)",
          borderRadius: "4px",
        },
      },
    },
  },
};
// Customizable Area End
