import React from "react";

import {
  Box,
  Typography,

  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
// Customizable Area End

import SettingsController, { Props } from "../SettingsController.web";

export default class ContactUs extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.fetchContactUs();
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { contact_us } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainWrapper}>
        <Typography style={webStyle.heading} variant="h6">
          Contact Us
        </Typography>
        <Grid container style={{ flexWrap: "nowrap", flexDirection: "column" }}>
          <Grid lg={6} style={webStyle.notif_row}>
            <PhoneIcon style={{ color: "#25AAE1" }} />
            <p style={webStyle.item_style}>{contact_us?.full_phone_number}</p>
          </Grid>
          <Grid lg={6} style={webStyle.notif_row}>
            <EmailIcon style={{ color: "#25AAE1" }} />
            <p style={webStyle.item_style}>{contact_us?.email}</p>
          </Grid>
          <Grid lg={6} style={webStyle.notif_row}>
            <LocationIcon style={{ color: "#25AAE1" }} />
            <p style={webStyle.item_style}>{contact_us?.address}</p>
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    height: "90%",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "20px",
  },
  heading: {
    fontSize: "22px",
    color: "#000000",
    fontFamility: "HelveticaNeue",
    fontStyle: "italic",
    margin: "0 0 30px 0",
  },
  item_style: {
    fontSize: "18px",
    margin: "0 0 0 20px",
    color: "#818181",
    fontFamility: "HelveticaNeue",
    lineHeight: "26px",
  },
  notif_row: {
    display: "flex",
    margin: "0 0 20px 0",
  },
};

// Customizable Area End
