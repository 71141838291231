Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings";
exports.labelBodyText = "Settings5 Body";

exports.btnExampleTitle = "CLICK ME";

exports.valii = /[A-Z][a-zA-Z]*/;

exports.postContentType = "application/json";
exports.privacyURL = "privacy";
exports.GET = "GET";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.POST = "POST";
exports.termsAndConditionUrl = "terms_and_condition";
exports.aboutUsUrl = "about_us";
exports.contactUsUrl = "contact_us";
exports.changePasswordUrl = "change_password";
exports.deleteAccURl = "account_block/accounts/";
exports.deactivateUrl = "/account_block/accounts/deactivate";
exports.removeDeviceIdUrl = "/account_block/accounts/remove_device_id";
exports.bookmarkTitle = "My Bookmarks";
exports.getAllBookmarksEndPoint = "bx_block_content_management/bookmarks";
exports.loadingTitle = "Loading...";
exports.PostType = "BxBlockPosts::Post";
exports.ExploreLocationType = "BxBlockExplore::ExploreLocation";
exports.ExploreItemType = "BxBlockExplore::ExploreItem";
exports.deleteBookmarkApiEndPoint = "bx_block_content_management/bookmarks/";
exports.noDataFoundError = "Bookmark not found";
exports.deleteBookmarkTitle = "Bookmark deleted succesfully!";
exports.pushNotificationUrl =
  "bx_block_push_notifications/push_notifications/disable_push_notification";
exports.inAppNotificationUrl =
  "bx_block_push_notifications/push_notifications/in_app_push_notification";
exports.blocktitle = "Unblock";
exports.blockedUsersEndpoint = "bx_block_profile/profiles/blocked_user_list";
exports.unblockUserEndpoint = "bx_block_followers/unblock_user";
exports.noBlockedUserFoundError = "Blocked users not found";
// Customizable Area End
