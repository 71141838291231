// Customizable Area Start
export const BookMarkStyles = {
  BookMark: {
    minHeight: "100%",
    padding: "20px 20px 20px",
    backgroundColor: "#FAFBFF",
    boxSizing: "border-box",
    "& .not-found-text": {
      color: "#000000",
      lineHeight: "20px",
      fontSize: "16px",
      width: "100%",
      textAlign: "center",
      fontFamily: "Helvetica",
    },
    "& .detail-container": {
      padding: "0 !important",
      margin: "unset !important",
    },
    "& .white-box-container": {
      padding: "20px",
      backgroundBlendMode: "normal",
      boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
      background: "#ffffff",
      borderRadius: "12px",
      border: "0.5px solid rgba(105,134,216,0.1)",
    },
    "& .page-title": {
      margin: "15px 0 20px",
      fontFamily: "Helvetica-Bold",
      lineHeight: "27px",
      fontWeight: "600",
      fontSize: "22px",
      color: "#000000",
    },
    "& .text-area-field": {
      border: "0.5px solid rgba(105,134,216,0.1)",
      fontFamily: "Helvetica",
      backgroundBlendMode: "normal",
      outline: "none",
      background: "#ffffff",
      padding: "25.32px 14px",
      resize: "none",
      fontWeight: "400",
      width: "100%",
      borderRadius: "16px",
      fontSize: "18px",
      color: "#353249",
    },
    "& .text-area-field::placeholder": {
      color: "#d5d5d5",
    },
    "& .bookmark-button": {
      background: "#25aae1",
      height: "72px",
      backgroundBlendMode: "normal",
      borderRadius: "4px",
      "& .MuiButton-label": {
        fontFamily: "Helvetica",
        fontSize: "24px",
        textTransform: "capitalize",
        fontWeight: "400",
        color: "#ffffff",
      },
    },
  },
};
// Customizable Area End
