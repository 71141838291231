import React, { CSSProperties } from "react";

import {
    Box, Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import UserProfileController, { Props, configJSON } from "../UserProfileController.web";
import Loader from "../../../../components/src/Loader";
import PostCard from "../../../FanWall/src/Post.web";
// Customizable Area End

export default class UserProfile extends UserProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.fetchPlaceVisited(this.props.id);
    }

    onClickPlaceVisited = (id: string) => {
        this.fetchPlaceVisitedPost(id)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Loader loading={this.state.isLoader} />
                {!this.state.visiblePlaceVisitedPost ? <Box style={webStyle.container}>
                    {this.state.placeVisitedList.length ? this.state.placeVisitedList?.map((item: any, index: number) => (
                        <ImageCard
                            key={index}
                            imageSrc={item?.attributes?.folder_image}
                            title={item?.attributes?.visited_state}
                            postCount={item?.attributes?.posts_count}
                            handleClick={this.onClickPlaceVisited}
                            id={item?.id}
                        />)):<p>{configJSON.placeErrortitle}</p>}
                </Box> :
                    // Customizable Area Start
                    <Box style={webStyle.container}>
                        <Typography
                            style={webStyle.heading}>
                            {this.state.placeVisitedPostList?.visited_state}
                        </Typography>

                        {this.state.placeVisitedPostList?.posts?.data?.map((post: any, index: number) => <PostCard
                            index={index}
                            item={post?.attributes}
                            navigation={this.props.navigation} id={""}
                            userDetails={this.props.userDetails}
                            handleLikePostAction={undefined}
                            handleDeletePostLocal={undefined}
                            updateShareCount={this.updateSharePostVisitedCount}                   // handleLikePostAction={this.handleLikePostAction}
                        // handleDeletePostLocal={this.handleDeletePostLocal}             // onHandleConfirmationPostDelete={postObject.onHandleConfirmationPostDelete}
                        />
                        )}
                    </Box>
                    // <PlaceVisitedPost navigation={this.props.navigation} id={this.props.navigation.getParam("id")} userDetails={this.props.userDetails} postList={this.state.placeVisitedPostList}/>
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    container: {
        overflowX: 'hidden',
        overflowY: 'auto',
        margin: "20px",
        height: "90%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        boxSizing: "border-box",
    } as CSSProperties,
    placeHeading: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '0 20px',
        background: "transparent",
        position: "absolute",
        top: 0,
        right: 0,
        left: 0
    } as CSSProperties,
    iterDesc: {
        fontSize: "16px",
        fontFamily: "HelveticaNeue",
        color: "white",
    } as CSSProperties,
    imgContainer: {
        borderRadius: '12px',
        position: "relative",
        cursor: "pointer"
    } as CSSProperties,
    imgStyle: {
        width: "100%",
        height: '224px',
        objectFit: 'cover',
        borderRadius: "12px"
    } as CSSProperties,
    heading: {
        fontSize: "22px",
        color: '#000000',
        fontFamily: "HelveticaNeue",

    } as CSSProperties,
};






export const ImageCard = ({ id, title, postCount, handleClick, imageSrc }: { id: string, title: string, postCount: number, handleClick: any, imageSrc: any }) => (

    <Box onClick={() => handleClick(id)} style={webStyle.imgContainer}>
        <img src={imageSrc} style={webStyle.imgStyle} />
        <Box style={webStyle.placeHeading}>
            <p style={webStyle.iterDesc}>{title}</p>
            <p style={webStyle.iterDesc}>{postCount}</p>
        </Box>

    </Box>)
// Customizable Area End
