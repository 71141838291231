// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { debounce } from "lodash";
import { apiCall } from "../../../components/src/utils.web";

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation?: any;
    history?: any;
    id?: string;

    children: { props: { history: any } };

    // Customizable Area End
}
// Customizable Area End
export interface S {
    // Customizable Area Start
    // navItems: any
    navItems: ['Home', 'Explore', 'Feeds'],
    flag: string
    activeFolg: boolean
    searchLocation: string;
    Termsandconditions: {
        content?: string;
        created_at?: string;
    };
    privacy: {
        content?: string;
        created_at?: string;
    };
    AboutUS: {
        content?: string;
        created_at?: string;
    };
    contact_us: {
        address: string, email: string, full_phone_number: string
    };
    isLoading: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start
export default class LandingPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    timerId: NodeJS.Timeout | null = null;
    getTOCID= '';
    getPPID = '';
    getAboutID= '';
    apiGetContactUsCallID: string = "";
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationLandingHomeMessage),
            getName(MessageEnum.AccoutLoginSuccess)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            // Customizable Area Start 
            navItems: ['Home', 'Explore', 'Feeds'],
            flag: "Home",
            activeFolg: false,
            searchLocation: "",
            contact_us: { address: "",  email: "", full_phone_number: "" },
            Termsandconditions: {

            },
            privacy:{
                
            },
            AboutUS:{

            },
            isLoading: false,
            // Customizable Area End
        };
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
           
            if (responseJson && responseJson.errors) {
                this.handleErrorResponse(responseJson.errors);
            } else if (this.apiGetContactUsCallID == apiRequestCallId){
                this.setState({
                    contact_us: responseJson?.contact_us,
                });
            }else if(apiRequestCallId === this.getTOCID){
                this.setState({
                    Termsandconditions: {...responseJson?.terms_and_condition,
                        created_at: this.formatDate(responseJson?.terms_and_condition.updated_at)
                    }
                })
            }else if(apiRequestCallId === this.getPPID){
                this.setState({
                    privacy: {...responseJson?.privacy,
                        created_at: this.formatDate(responseJson?.privacy.updated_at)
                    }
                })
            }else if(apiRequestCallId === this.getAboutID){
                this.setState({
                    AboutUS: {...responseJson?.about_us,
                        created_at: this.formatDate(responseJson?.about_us.updated_at)
                    }
                })
            }
            this.setState({isLoading: false});
        }
        // Customizable Area End
    }

    formatDate = (dateString: string): string => {
        const monthNames = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
      
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const monthName = monthNames[date.getMonth()];
        const year = date.getFullYear();
      
        return `${day} ${monthName} ${year}`;
    }

    getAboutUS = async () => {
        this.setState({isLoading: true});
        this.getAboutID = await apiCall({
            endPoint: configJSON.about_us,
            method: configJSON.validationApiMethodType,
        })
    }

    getTOC = async () => {
        this.setState({isLoading: true});
        this.getTOCID = await apiCall({
            endPoint: configJSON.terms_and_condition,
            method: configJSON.validationApiMethodType,
        })
    }

    getPP = async () => {
        this.setState({isLoading: true});
        this.getPPID = await apiCall({
            endPoint: configJSON.privicy_endPoint,
            method: configJSON.validationApiMethodType,
        })
    }

    goToNavigation = (path: string) => {
        const { children } = this.props;
        children?.props?.history.push(path)
    }
    goToFooterNavigation = (path: string) => {
        const { navigation } = this.props;
        if(navigation?.history?.location?.pathname=="/"+path){
            this.scrollToTop();
        }else{
            navigation.navigate(path)
        }
        
    }
    handleSearch = () => {
        const { children } = this.props;
        children?.props?.history.push("Places", { search: this.state.searchLocation })
    }
    handleDebouncedSearch = async (e: any) => {
        await this.setState({ searchLocation: e.target.value })
        await debounce(this.handleSearch, 2000)();
    };

    menuButton = () => {
        this.setState({ activeFolg: !this.state.activeFolg })
    }
    openSocialLink = (url: string) => {
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    }
    scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    fetchContactUs = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetContactUsCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.contactUsUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };
    handleErrorResponse = (errors: any) => {
        this.parseApiErrorResponse(errors);
        this.parseApiCatchErrorResponse(errors);
    };

}
// Customizable Area End