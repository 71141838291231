export const imgBell = require("../../global_assets/bell.png");
export const imgic_password_invisible = require("../../global_assets/ic_password_invisible.png");
export const imgic_password_visible = require("../../global_assets/ic_password_visible.png");
export const fanwallImg =require("../assets/fanwallimg.png")
export const dummyProfile = require("../assets/profile.png");
export const boltIcon = require("../assets/bolt.png");
export const emailUpdateIcon = require("../assets/emailUpdateIcon.png")
export const hotelIcon = require("../assets/hotel.png")
export const newFeatureIcon = require("../assets/newFeature.png")
export const passwordupdateIcon = require("../assets/passwordupdateIcon.png")
export const phoneUpdateIcon = require("../assets/phoneUpdateIcon.png")
export const profileUpdateIcon = require("../assets/profileUpdateIcon.png")
export const threadIcon = require("../assets/thread.png")
export const searchIcon = require("../assets/searchicon.png");
