export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const searchIcon = require("../assets/searchicon.png")
export const filterIcon = require("../assets/filtericon.png")
export const menuIcon = require("../assets/menuicon.png")
export const fanwallImg = require("../assets/fanwallimg.png")
export const like = require("../assets/likeicon.png")
export const disLike = require("../assets/unlikeicon.png")
export const comment = require("../assets/commeticon.png")
export const share = require("../assets/shareicon.png")
export const bookmark = require("../assets/bookmark.png")
export const leftScroll = require("../assets/scrolllefticon.png")
export const rightScroll = require("../assets/scrollrighticon.png")
export const sendBtn = require("../assets/send-btn.png")
export const emoji = require("../assets/emoji.png")
export const dummyProfile = require("../assets/profile.png")