// Customizable Area Start
import React from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { Box, Typography, Button } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ConfirmationModal from "./ConfirmationModal.web";

const more = require("./assets/menuicon.png");

const useStyles = makeStyles((theme) => ({
  BookMark: {
    "& .bookmarked-details": {
      borderRadius: "16px",
      border: "1px solid rgba(143, 146, 161,0.088890440762043)",
      background: "rgba(226,226,226,0.088890440762043)",
    },
    "& .bookmarked-text-container": {
      padding: "20px",
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
      alignItems: "center",
    },
    "& .plus-image-container": {
      width: "26px",
      height: "26px",
      color: "#fff",
      background: "#26A5E0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      position: "absolute",
      top: 5,
      right: 5,
      cursor: "pointer",
      "& .plus-image": {
        height: "20px",
        width: "20px",
      },
    },
    "& .bookmarked-main-text": {
      color: "#818181",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "500",
    },
    "& .under-bookmarked-details": {
      borderRadius: "16px",
      border: "2px solid rgba(143, 146, 161, 0.09)",
      background: "#FFF",
      padding: "32px",
      display: "flex",
      gap: "15px",
    },
    "& .bookmarked-image-container": {
      position: "relative",
    },
    "& .bookmarked-image": {
      height: "132px",
      width: "132px",
      borderRadius: "12px",
    },
    "& .text-space-between": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "10px",
    },
    "& .bookmarked-rating": {
      display: "flex",
      gap: "5px",
      alignItems: "center",
      color: "#1E1F20",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
    },
    "& .bookmarked-location": {
      display: "flex",
      gap: "5px",
      alignItems: "center",
      color: "#818181",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
    },
    "& .bookmarked-name": {
      color: "#1E1F20",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "1",
      wordBreak: "break-all",
    },
    "& .bookmarked-rating-icon": {
      width: "14px",
      height: "14px",
      fill: "orange",
    },
    "& .bookmarked-location-icon": {
      width: "14px",
      height: "14px",
      fill: "#25aae1",
    },
    "& .bookmarked-detailed-container": {
      width: "100%",
    },
    "& .bookmarked-description": {
      color: "#818181",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "300",
      lineHeight: "20px",
      marginTop: "14px",
    },
    "& .text-ellipsis": {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "1",
      wordBreak: "break-all",
    },
    "& .bookmarked-review-total": {
      color: "#25AAE1",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
    },
    "& .more-button-icon": {
      fill: "#919092",
      height: "16px",
      width: "4px",
      cursor: "pointer",
    },
    "& .more-button": {
      minWidth: "16px",
      padding: 0,
    },
    "& .MuiList-root": {
      borderRadius: "10px",
      background: "#FAFBFF",
      boxShadow: "0px 0px 32px 0px rgba(14, 70, 93, 0.16)",
      padding: "20px 0",
      minWidth: "127px",
    },
    "& .MuiMenuItem-root": {
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Helvetica",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderBottom: "1px solid #d5d5d5",
    },
    "& .MuiMenuItem-root:last-child": {
      borderBottom: "none",
    },
    "& .bookmarked-total-review": {
      color: "#25AAE1",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
      whiteSpace: "nowrap",
      cursor: 'pointer'
    },
  },
}));

interface Props {
  descriptionBookmark: string;
  exploreItemsName?: string;
  location?: string;
  totalReviews?: number;
  ratings?: number;
  descriptionExploreItems: string;
  imageSrc: string;
  city_name?: string;
  onDeleteItem: (id: string) => void;
  onEditItem: (bookmarkable_id: number, id: string) => void;
  id: string;
  bookmarkable_id: number;
  addLocationItem: (cityName: string) => void;
  isDataAddable: boolean;
  onRedirectRating: (attributes: any) => void;
}

const ItineraryBookmarkDetails: React.FC<Props> = ({
  descriptionBookmark,
  descriptionExploreItems,
  exploreItemsName,
  imageSrc,
  location,
  ratings,
  totalReviews,
  city_name,
  onDeleteItem,
  onEditItem,
  id,
  bookmarkable_id,
  addLocationItem,
  isDataAddable,
  onRedirectRating
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [confirmationModal, setConfirmationModal] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const classes = useStyles();
  return (
    <Box className={classes?.BookMark}>
      <Box className="bookmarked-details">
        <Box className="bookmarked-text-container">
          <Typography className="bookmarked-main-text">
            {descriptionBookmark}
          </Typography>
          <Box>
            <Button
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              className="more-button"
            >
              <img src={more} className="more-button-icon" alt="menu" />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              placement="bottom-end"
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          onClick={(e) => {
                            handleClose(e);
                            onEditItem(bookmarkable_id, id);
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            handleClose(e);
                            setConfirmationModal(true);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Box>
        <Box className="under-bookmarked-details">
          <Box className="bookmarked-image-container">
            <img className="bookmarked-image" src={imageSrc} />
            {isDataAddable ? <Box
              className="plus-image-container"
              onClick={() =>
                addLocationItem(
                  location ? location : city_name ? city_name : ""
                )
              }
            >
              <AddIcon className="plus-image" />
            </Box> : null}
          </Box>
          <Box className="bookmarked-detailed-container">
            {exploreItemsName && (
              <>
                <Box className="text-space-between">
                  <Typography className="bookmarked-name">
                    {exploreItemsName}
                  </Typography>
                  <Typography className="bookmarked-rating">
                    <StarIcon className="bookmarked-rating-icon" /> {ratings}/5
                  </Typography>
                </Box>
                <Box className="text-space-between mt-10">
                  <Typography className="bookmarked-location">
                    <LocationOnIcon className="bookmarked-location-icon" />{" "}
                    <span className="text-ellipsis">{location}</span>
                  </Typography>
                  <Typography onClick={onRedirectRating} className="bookmarked-total-review">
                    ({totalReviews} Reviews)
                  </Typography>
                </Box>
              </>
            )}
            {city_name && (
              <Typography className="bookmarked-name mt-10">
                {city_name}
              </Typography>
            )}
            <Typography className="bookmarked-description">
              <div dangerouslySetInnerHTML={{ __html: descriptionExploreItems }}></div>
            </Typography>
          </Box>
        </Box>
      </Box>
      <ConfirmationModal
        open={confirmationModal}
        handleCloseModal={() => setConfirmationModal(false)}
        onHandleConfirmation={() => {
          onDeleteItem(id);
          setConfirmationModal(false);
        }}
        title="Bookmarked"
        description="Are you sure want to delete bookmarked item?"
      />
    </Box>
  );
};

export default ItineraryBookmarkDetails;
// Customizable Area End
