// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine as runEngineForChat } from "../../../framework/src/RunEngine";
import {
  apiCall,
  setupOneToOneWebSocket,
} from "../../../components/src/utils.web";
const { baseURL } = require("../../../framework/src/config");

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  chatId: number;
  resetChatId: () => void;
  userDetails: {
    email: string;
    id: number;
    name: string;
    offline: boolean;
    profile_photo: string;
    unread_messages: number;
  };
}

export interface S {
  isLoader: boolean;
  chatId: any;
  chatListData: any;
  message: string;
  media: any[];
  messageId: string;
  scrollToBottom: boolean;
  haveMoreChat: boolean;
  currentPage: number;
  limit: number;
  openDeleteConfirmationModal: boolean;
  other_user_id: number;
  attachmentId: number;
}

interface SS {
  id: any;
}

export default class ChatDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getChatListApiCallId: string = "";
  sendMessageApiCallId: string = "";
  deleteMessageApiCallId: string = "";
  editMessageApiCAllId: string = "";
  deleteChatApiCallId: string = "";
  webSocket: any = null;
  readMessageApiCallId: string = "";
  deleteAttachmentApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      isLoader: false,
      chatId: 0,
      chatListData: [],
      message: "",
      media: [],
      messageId: "",
      scrollToBottom: false,
      haveMoreChat: false,
      currentPage: 1,
      limit: 15,
      openDeleteConfirmationModal: false,
      other_user_id: 0,
      attachmentId: NaN
    };
    runEngineForChat.attachBuildingBlock(
      this as IBlock,
      this.subScribedMessages
    );
  }

  async componentDidMount() {
    this.setState(
      {
        chatId: Number(this.props.chatId),
        other_user_id: this.props.userDetails.id,
      },
      () => {
        this.getChatList();
        this.webSocketConnection();
      }
    );
  }

  getMoreApiChatList = async () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      () => {
        this.getChatList();
      }
    );
  };

  webSocketConnection = () => {
    const URL = baseURL.replace(/^https?:\/\//i, "");
    const token = localStorage.getItem("authToken");
    this.webSocket = setupOneToOneWebSocket(
      URL,
      token,
      this.state.chatId,
      this.toHandleWebSocketMessage
    );
  };

  async componentWillUnmount() {
    this.webSocket.send(
      JSON.stringify({
        command: "unsubscribe",
        identifier: `{"channel":"ChatChannel","id":${this.state.chatId},"tag":"general_chat_unsubscribe"}`,
      })
    );
    this.webSocket.close();
  }

  toHandleWebSocketMessage = (item: any) => {
    if (item.messages === "new_post_created") {
      let { chatListData } = this.state;
      let userData: any = localStorage.getItem("user_details");
      if (userData) {
        userData = JSON.parse(userData);
        if (userData.id) {
          userData = userData.id;
        }
      }
      const messages = {
        data: { ...item.data },
      };
      chatListData = [messages, ...chatListData];
      const isCurrentUser = userData == item.data.attributes.account_id;
      this.checkCurrentUser(isCurrentUser, chatListData);
    } else if (item.messages === "message_deleted") {
      let { chatListData } = this.state;
      const { id } = item;
      this.setState({
        chatListData: chatListData.filter((item: any) => item.data.id != id),
      });
    } else if (item.messages === "message_edited_successfully") {
      const index = this.state.chatListData.findIndex(
        (data: any) => data.data.id === item.id
      );
      if (index != -1) {
        const { chatListData } = this.state;
        const oneData = this.state.chatListData[index];
        oneData.data.attributes.message = item.data.message;
        oneData.data.attributes.updated_at = item.data.updated_at;
        chatListData.splice(index, 1, oneData);
        this.setState({
          chatListData,
        });
      }
    } else if (item.messages === 'attachment_deleted') {
      const index = this.state.chatListData.findIndex(
        (data: any) => data.data.id === item.id
      );
      if (index != -1) {
        const { chatListData } = this.state;
        const oneData = this.state.chatListData[index];
        oneData.data.attributes.attachments = item.data.attachments;
        oneData.data.attributes.updated_at = item.data.updated_at;
        chatListData.splice(index, 1, oneData);
        this.setState({
          chatListData,
        });
      }
    }
  };
  checkCurrentUser = (isCurrentUser: boolean, chatListData: any) => {
    if (isCurrentUser) {
      this.setState({
        chatListData,
        scrollToBottom: isCurrentUser,
      });
    } else {
      this.setState(
        {
          chatListData,
        },
        () => {
          this.readAllChatMessage();
        }
      );
    }
  }

  readAllChatMessage = async () => {
    this.readMessageApiCallId = await apiCall({
      endPoint: `bx_block_chat/chats/${this.state.chatId}/read_messages`,
      method: "PUT",
    });
  };

  getChatList = async () => {
    this.getChatListApiCallId = await apiCall({
      endPoint: `bx_block_chat/chats/${this.state.chatId}/chat_messages?page=${this.state.currentPage}&per_page=${this.state.limit}`,
      method: "GET",
      contentType: "application/json",
    });
  };

  deleteChat = async () => {
    this.deleteChatApiCallId = await apiCall({
      endPoint: `bx_block_chat/chats/delete_chat`,
      method: "DELETE",
      contentType: "application/json",
      body: JSON.stringify({
        chat_id: this.state.chatId,
      }),
    });
  };

  editChatMessage = async (message: string, messageId: string) => {
    this.setState({
      messageId,
    });
    this.editMessageApiCAllId = await apiCall({
      endPoint: "bx_block_chat/chats/edit_message_in_chat",
      method: "PUT",
      contentType: "application/json",
      body: JSON.stringify({
        chat: {
          chat_id: this.state.chatId,
          message: {
            message_id: Number(messageId),
            message_text: message,
            message_type: "text",
          },
        },
      }),
    });
  };

  sendMessageChat = async () => {
    const formData = new FormData();
    const ImgMsg = this.state.media.length > 0 ? configJSON.imgMassage : configJSON.nodatamsg
    formData.append("chat[message][message_text]", this.state.message || ImgMsg);
    let message_type: any;
    if (this.state.message.length > 0 && this.state.media.length > 0) {
      message_type = "text";
    } else if (this.state.media.length > 0) {
      message_type = "image";
    } else {
      message_type = "text";
    }
    if (this.state.media.length) {
      this.state.media.forEach((image: any) => {
        formData.append("photos[]", image);
      });
    }
    formData.append("chat[message][message_type]", message_type);
    formData.append("chat[chat_id]", this.state.chatId);
    formData.append("chat[other_user_Id]", String(this.state.other_user_id)); //Need to add other user id
    this.sendMessageApiCallId = await apiCall({
      endPoint: `bx_block_chat/private_chats/send_message_to_private_chat`,
      method: "POST",
      body: formData,
    });
  };

  deleteChatMessage = async (deleteMessageId: string) => {
    this.setState({
      messageId: deleteMessageId,
    });
    this.deleteMessageApiCallId = await apiCall({
      endPoint: `bx_block_chat/chats/remove_message_in_chat`,
      method: "DELETE",
      contentType: "application/json",
      body: JSON.stringify({
        chat: {
          chat_id: this.state.chatId,
          message: {
            message_id: deleteMessageId,
          },
        },
      }),
    });
  };
  deleteAttachmentMessage = async (deleteMessageId: string, deleteAttachmentId: number) => {
    this.setState({
      messageId: deleteMessageId,
      attachmentId: deleteAttachmentId
    });
    const index = this.state.chatListData.findIndex((item: any) => item.data.id == deleteMessageId)
    const attachments = this.state.chatListData[index].data.attributes.attachments;
    const message=this.state.chatListData[index].data.attributes.message;
    if((message==='Attachment' || message==='N/A') && attachments.length==1){
      this.deleteChatMessage(deleteMessageId)
    }else{
      this.deleteAttachmentApiCallId = await apiCall({
        endPoint: `bx_block_chat/chats/remove_message_in_chat`,
        method: "DELETE",
        contentType: "application/json",
        body: JSON.stringify({
          chat: {
            chat_id: this.state.chatId,
            message: {
              message_id: deleteMessageId,
              attachment_id: deleteAttachmentId
            },
          },
        }),
      });
    }
    
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.handleResponseInChat(apiRequestCallId, responseJson);
      }
      this.setState({ isLoader: false });
    }
  }

  submitMessageOrMedia = (message: any, media: any[]) => {
    this.setState({ media, message }, () => {
      this.sendMessageChat();
    });
  };

  handleResponseInChat = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getChatListApiCallId:
        this.handleChatListResponse(responseJson);
        break;
      case this.deleteMessageApiCallId:
        this.handleDeleteMessageResponse(responseJson);
        break;
      case this.editMessageApiCAllId:
        this.handleEditMessageResponse(responseJson);
        break;
      case this.sendMessageApiCallId:
        this.handleSendMessageResponse(responseJson);
        break;
      case this.deleteChatApiCallId:
        this.handleDeleteChatResponse(responseJson);
      case this.deleteAttachmentApiCallId:
        this.handleDeleteAttachmentResponse(responseJson);
        break;
        break;
    }
  };

  handleDeleteChatResponse = (responseJson: any) => {
    if (responseJson) {
      this.setState(
        {
          openDeleteConfirmationModal: false,
        },
        () => {
          this.props.resetChatId();
        }
      );
    }
  };

  handleDeleteMessageResponse = (responseJSon: any) => {
    if (responseJSon.message) {
      const data = JSON.stringify({
        command: "message",
        identifier: `{"channel":"ChatChannel","id":${this.state.chatId}}`,
        data: JSON.stringify({
          action: "speak",
          message: JSON.stringify({
            messages: "message_deleted",
            id: this.state.messageId,
          }),
          tag: "general_chat_message",
        }),
      });
      this.webSocket.send(data);
    }
  };
  handleDeleteAttachmentResponse = (responseJSon: any) => {
    if (responseJSon.data.id === this.state.messageId) {
      const data = JSON.stringify({
        command: "message",
        identifier: `{"channel":"ChatChannel","id":${this.state.chatId}}`,
        data: JSON.stringify({
          action: "speak",
          message: JSON.stringify({
            messages: "attachment_deleted",
            id: this.state.messageId,
            data: responseJSon.data.attributes
          }),
          tag: "general_chat_message",
        }),
      });
      this.webSocket.send(data);
    }
  }

  handleEditMessageResponse = (responseJSon: any) => {
    if (
      responseJSon.status &&
      responseJSon.message &&
      responseJSon.chat_message
    ) {
      const data = JSON.stringify({
        command: "message",
        identifier: `{"channel":"ChatChannel","id":${this.state.chatId}}`,
        data: JSON.stringify({
          action: "speak",
          message: JSON.stringify({
            messages: "message_edited_successfully",
            id: this.state.messageId,
            data: responseJSon.chat_message,
          }),
          tag: "general_chat_message",
        }),
      });
      this.webSocket.send(data);
    }
  };

  handleChatListResponse = (responseJson: any) => {
    if (responseJson.message && responseJson.messages) {
      let data = responseJson.messages;
      const haveMoreChat =
        responseJson.pagination.current_page <
          responseJson.pagination.total_pages
          ? true
          : false;
      this.setState(
        {
          chatListData: [...this.state.chatListData, ...data],
          scrollToBottom:
            responseJson.pagination.current_page == 1 ? true : false,
          haveMoreChat,
        },
        () => {
          this.props.userDetails.unread_messages != 0 &&
            this.readAllChatMessage();
        }
      );
    }
  };

  handleSendMessageResponse = (responseJson: any) => {
    if (responseJson.message && responseJson.chat_message.data) {
      const data = JSON.stringify({
        command: "message",
        identifier: `{"channel":"ChatChannel","id":${this.state.chatId}}`,
        data: JSON.stringify({
          action: "speak",
          message: JSON.stringify({
            messages: "new_post_created",
            data: responseJson.chat_message.data,
          }),
          tag: "general_chat_message",
        }),
      });
      this.webSocket.send(data);
      this.setState({ message: "", media: [], scrollToBottom: true });
    }
  };
}
// Customizable Area End
