import React from "react";

import {
  Box,
  Typography,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import SettingsController, { Props } from "../SettingsController.web";

export default class PrivacyPolicy extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.fetchPrivacyPolicy();
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainWrapper}>
        <Typography style={webStyle.heading} variant="h6">
          Privacy Policy
        </Typography>
        <div
          id="scrollableDiv"
          style={{ ...webStyle.desc, overflowX: "auto" }}
          dangerouslySetInnerHTML={{ __html: this.state?.privacy_policy }}
        />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    height: "90%",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "20px",
    position: "relative",
  },
  heading: {
    fontSize: "22px",
    color: "#000000",
    fontFamility: "HelveticaNeue",
  },
  desc: {
    fontSize: "16px",
    color: "#818181",
    fontFamility: "HelveticaNeue",
    lineHeight: "24px",
    height: "100%",
  },
};
// Customizable Area End
