import React, { CSSProperties } from "react";

import {
    Box
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../../components/src/Loader";
// import PostCard from '../../../FanWall/src/Post.web'
// Customizable Area End

import UserProfileController, { Props, configJSON } from "../UserProfileController.web";
import PostCard from "../../../FanWall/src/Post.web";
// import FanWallControllerWeb from "../../../FanWall/src/FanWallController.web";

export default class UserPost extends UserProfileController {
    [x: string]: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.fetchUserPost(this.props.id);
    }
  
    handleLikePostAction = (attr: any, index: number) => {
        const data = this.state.userPost[index];
        data.attributes = attr;
        this.state.userPost[index] = data;
        this.setState({ userPost: this.state.userPost });
    };
    handleDeletePostLocal = (index: number) => {
        this.state.userPost.splice(index, 1);
        this.setState({ userPost: this.state.userPost });
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={webStyle.container} id="scrollableDiv">
                <Loader loading={this.state.isLoader} />
                {this.state.userPost.length ?this.state.userPost?.map((post: any, index: number) => <PostCard
                    index={index}
                    item={post?.attributes} id={index.toString()}
                    navigation={this.props.navigation}
                    userDetails={this.props.userDetails}
                    handleLikePostAction={this.handleLikePostAction}
                    handleDeletePostLocal={this.handleDeletePostLocal}
                    updateShareCount={this.updateShareCount}
                    />
                ): <p>{configJSON.PostErrortitle}</p>}
                
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    container: {
        margin: "20px",
        overflowX: "hidden",
        overflowY: "auto",
        height: "90%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        boxSizing: "border-box",
    } as CSSProperties
};

// Customizable Area End
