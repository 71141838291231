import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Grid, TextField } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Formik } from "formik";
import RegistrationOTPControllerBlock, {
  Props, configJSON,
} from "./RegistrationOTPControllerBlock.web";
import ErrorIcon from "@material-ui/icons/Error";
//@ts-ignore
import OtpInput from "react-otp-input";
import Loader from "../../../components/src/Loader.web";
import Carousel from "react-material-ui-carousel";
import {Line} from './assets';
// Customizable Area End
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const MuiDiv = styled("div")({
  position: 'absolute',
  width: '100%',
  height: '300px',
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
  "& h3, & h1" : {
    color: '#ffffff',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '56px',
    letterSpacing: '1.8px',
    marginLeft: '70px',
    marginBottom: '0',
    fontFamily: 'Helvetica',
  },
  "& h1": {
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  "& .text-color":{
    color: "#ffffff",
    display: 'block',
  },
});

const MuiContainers = styled("div")({
  position: 'relative',
  "& .Carousel-box": { flex: 1, overflow: "hidden", height: "100%" },
  "& .main-img": {
    height: "100%",
    width: "100%",
    objectFit: 'cover'
  }
});
// Customizable Area End
export default class OTPSignUpBlock extends RegistrationOTPControllerBlock {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  renderInput = (props: any) => {
    const { value, ...rest } = props;
    return (
      <input
        {...rest}
        type="password"
        value={value}
        autoComplete="off"
        style={{
          width: "50px",
          height: "50px",
          margin: "5px",
          fontSize: "24px",
          borderRadius: "5px",
          border: "1px solid gray",
          textAlign: "center",
          // Hide the input text and show stars instead
          color: "transparent",
          textShadow: "0 0 0 #000",
          backgroundColor: "#fff",
        }}
      />
    );
  };
  render() {
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <ThemeProvider theme={theme}>
          <Grid container style={{ overflowX: "hidden", height: "100%" }}>
            <Grid item xs={6}>
            <MuiContainers>
              <Carousel
                animation="slide"
                swipe={true}
                indicators={false}
                autoPlay
              >
                {this.state.images.map((item ,i) => {
                  return (
                    <Box
                      className="Carousel-box"
                      key={i}
                    >
                      <MuiDiv>
                        <h3>{configJSON.maintitle}</h3>
                        <img src={Line} alt="line-object" width='100%' />
                        <h1>
                          {configJSON[item.data+'11']}
                          <br/>
                          <span className="text-color">
                            {configJSON[item.data+'22']}
                          </span>
                        </h1>
                      </MuiDiv>
                      <img
                        className="main-img"
                        src={item.img}
                      />
                    </Box>
                  );
                })}
              </Carousel>
                <Box>
                  <Button
                    data-test-id={"btnForLogInScreen"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="explore-button"
                  >
                    Explore
                  </Button>
                </Box>
              </MuiContainers>
            </Grid>
            <Grid item xs={6} style={{ padding: "80px" }} className="background-color">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 0px",
                }}
              >
                <div
                  test-goBack="go_back"
                  className="go-back-button"
                  onClick={() => this.props.navigation.goBack()}
                >
                  <ArrowBackIosIcon className="go-back-button-icon" />
                </div>
                <Typography
                  variant="h4"
                  component="h2"
                  align="left"
                  style={{ fontWeight: "bold", marginBottom: "20px" }}
                >
                  OTP Verification
                </Typography>
                {this.state.typezz === "email_account" ? (
                  <Typography variant="subtitle1" component="div" align="left">
                    Enter the 4 digits OTP sent via Email on your phone <br />
                    {this.state.email}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" component="div" align="left">
                    Enter the 4 digits OTP sent via SMS on your phone
                    <br />
                    {this.state.number}
                  </Typography>
                )}
                <Box sx={{ width: "100%", paddingTop: "30px" }}>
                  <Formik
                    initialValues={{ one: "", two: "", three: "", four: "" }}
                    onSubmit={(values) => {
                      this.OTPSignUp();
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <>
                          <Box
                            style={{
                              display: "flex",
                              gap: "30px",
                              marginBottom: "25px",
                            }}
                          >
                            <OtpInput
                              //@ts-ignore
                              value={this.state.otps}
                              onChange={this.handleChangesss}
                              numInputs={4}
                              renderSeparator={<span> </span>}
                              renderInput={this.renderInput}
                              inputStyle={{
                                height: "60px",
                                width: "60px",
                                marginRight: "20px",
                                textAlign: "center",
                                marginBottom: "30px",
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: "4px",
                                boxShadow: "0px 10px 25px -3px rgba(0,0,0,0.21",
                              }}
                            />
                          </Box>
                          {this.state.error && (
                            <Typography
                              data-testid="errorset"
                              variant="subtitle1"
                              component="div"
                              align="left"
                              style={{
                                color: "red",
                                display: "flex",
                                paddingBottom: "15px",
                              }}
                            >
                              <ErrorIcon style={{ marginRight: "8px" }} />{" "}
                              {this.state.errorMsg}
                            </Typography>
                          )}
                          {this.state.oneMinTimer > 0 ? (
                            <Typography
                              data-testid="onemintimer"
                              variant="subtitle1"
                              component="div"
                              align="left"
                              style={{ color: "red", paddingBottom: "40px" }}
                            >
                              {this.state.oneMinTimer < 10
                                ? `00:0${this.state.oneMinTimer}`
                                : `00:${this.state.oneMinTimer}`}
                            </Typography>
                          ) : (
                            <Box>
                              <Button
                                test-id="resend_test"
                                onClick={this.resendOtp}
                                style={{ color: "rgb(33, 150, 243" }}
                              >
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    textTransform: "capitalize",
                                    fontWeight: 600,
                                  }}
                                >
                                  Resend OTP
                                </span>{" "}
                                <RefreshIcon />
                              </Button>
                            </Box>
                          )}
                          <Button
                            test-handleSubmit="test-handleSubmit"
                            style={{
                              background: "rgb(33, 150, 243)",
                              margin: "30px 0",
                              padding: "18px",
                              textTransform: "capitalize",
                              fontWeight: 600,
                              color: "#fff",
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            disabled={this.state.otps.length < 4}
                          >
                            Verify
                          </Button>
                        </>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
    );
  }
  // Customizable Area End
}

export const OTPSignUP = ({
  values,
  handleChange,
  resendOtp,
  error,
  errorMsg,
  oneMinTimer,
}: any) => {
  return (
    <>
      <Box style={{ display: "flex", gap: "30px", marginBottom: "25px" }}>
        <TextField
          variant="outlined"
          data-test-id="test_one"
          fullWidth
          id="one"
          name="one"
          value={values.one}
          onChange={handleChange}
          required
          inputProps={{ maxLength: 1 }}
          style={{
            width: "60px",
            textAlign: "center",
            marginBottom: "30px",
            boxShadow: "0px 10px 25px -3px rgba(0,0,0,0.21",
          }}
        />
        <TextField
          variant="outlined"
          data-test-id="test_one"
          fullWidth
          id="two"
          name="two"
          value={values.two}
          onChange={handleChange}
          required
          inputProps={{ maxLength: 1 }}
          style={{
            width: "60px",
            textAlign: "center",
            marginBottom: "30px",
            boxShadow: "0px 10px 25px -3px rgba(0,0,0,0.21",
          }}
        />
        <TextField
          variant="outlined"
          data-test-id="test_one"
          fullWidth
          id="three"
          name="three"
          value={values.three}
          onChange={handleChange}
          required
          inputProps={{ maxLength: 1 }}
          style={{
            width: "60px",
            textAlign: "center",
            marginBottom: "30px",
            boxShadow: "0px 10px 25px -3px rgba(0,0,0,0.21",
          }}
        />
        <TextField
          variant="outlined"
          data-test-id="test_one"
          fullWidth
          id="four"
          name="four"
          value={values.four}
          onChange={handleChange}
          required
          inputProps={{ maxLength: 1 }}
          style={{
            width: "60px",
            textAlign: "center",
            marginBottom: "30px",
            boxShadow: "0px 10px 25px -3px rgba(0,0,0,0.21",
          }}
        />
      </Box>
      {error && (
        <Typography
          variant="subtitle1"
          component="div"
          align="left"
          style={{ color: "red", display: "flex", paddingBottom: "15px" }}
        >
          <ErrorIcon style={{ marginRight: "8px" }} /> {errorMsg}
        </Typography>
      )}
      {oneMinTimer > 0 ? (
        <Typography
          variant="subtitle1"
          component="div"
          align="left"
          style={{ color: "red", paddingBottom: "40px" }}
        >
          {oneMinTimer < 10 ? `00:0${oneMinTimer}` : `00:${oneMinTimer}`}
        </Typography>
      ) : (
        <Box>
          <Button
            test-id="resend_test"
            onClick={resendOtp}
            style={{ color: "rgb(33, 150, 243" }}
          >
            <span style={{ textDecoration: "underline" }}>Resent OTP</span>{" "}
            <RefreshIcon />
          </Button>
        </Box>
      )}
      <Button
        test-handleSubmit="test-handleSubmit"
        style={{
          background: "rgb(33, 150, 243)",
          marginBottom: "30px",
          padding: "18px",
        }}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        Verify
      </Button>
    </>
  );
};
