import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import DataContext from "../../../components/src/DataContext";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { removeStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setNotificationData: (list: any) => {};
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeIndex: number;
  change_password_error: boolean;
  passwordMsg: string;
  password: string;
  confirmPassword: string;
  checkPassword: boolean;
  checkConfrimPassword: boolean;
  passwordNotmatch: boolean;

  openDeleteModal: boolean;
  openDeactivateModal: boolean;

  terms_and_condition: string;
  privacy_policy: string;
  about_us: string;
  contact_us: any;

  openLogoutModal: boolean;
  openDeleteAlert: boolean;
  openDeactivateAlert: boolean;
  passwordChangeAlert: {
    visible: boolean;
    message: string;
    success: boolean;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetPrivacyCallID: string = "";
  apiGetTermsConditionCallID: string = "";
  apiGetAboutUsCallID: string = "";
  apiGetContactUsCallID: string = "";
  apiPutUpdatePasswordCallID: string = "";
  apiDelAccountCallID: string = "";
  apiDeactivateAccountCallID: string = "";
  apiRemoveDeviceIdCallID: string = "";
  static contextType: React.Context<string> = DataContext;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeIndex: 0,
      change_password_error: false,
      passwordMsg: "",
      password: "",
      confirmPassword: "",
      checkPassword: false,
      checkConfrimPassword: false,
      passwordNotmatch: false,

      openDeleteModal: false,
      openDeactivateModal: false,
      terms_and_condition: "",
      privacy_policy: "",
      about_us: "",
      contact_us: {
        id: "",
        full_phone_number: "",
        email: "",
        address: "",
        created_at: "",
        updated_at: "",
      },
      openLogoutModal: false,
      openDeleteAlert: false,
      openDeactivateAlert: false,
      passwordChangeAlert: {
        visible: false,
        message: "",
        success: false,
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.handleErrorResponse(responseJson.errors);
      } else if (responseJson) {
        this.checkApiResponse(responseJson, apiRequestCallId);
      } else {
        this.handleErrorResponse(errorReponse);
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start
  async componentDidMount() {
    let authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
    if (this.context?.data?.isMyBookmark) {
      this.setState({
        activeIndex: 2,
      });
      this.context.setData({
        isMyBookmark: false,
      });
    }
  }
  onHandleActive = (value: number) => {
    this.setState({ activeIndex: value });
  };
  handleClickShowPassword = () => {
    this.setState({ checkPassword: !this.state.checkPassword });
  };
  handleClickShowConfirmPassword = () => {
    this.setState({ checkConfrimPassword: !this.state.checkConfrimPassword });
  };

  handleVisibleDeleteModal = () => {
    this.setState({ openDeleteModal: !this.state.openDeleteModal });
  };
  handleVisibleDeactivateModal = () => {
    this.setState({ openDeactivateModal: !this.state.openDeactivateModal });
  };
  handleVisibleLogoutModal = () => {
    this.setState({ openLogoutModal: !this.state.openLogoutModal });
  };
  checkApiResponse = (responseJson: any, apiRequestCallId: any) => {
    switch (apiRequestCallId) {
      case this.apiGetPrivacyCallID:
        this.setState({ privacy_policy: responseJson?.privacy?.content });
        break;
      case this.apiGetTermsConditionCallID:
        this.setState({
          terms_and_condition: responseJson?.terms_and_condition?.content,
        });
        break;
      case this.apiGetAboutUsCallID:
        this.setState({
          about_us: responseJson?.about_us?.content,
        });
        break;
      case this.apiGetContactUsCallID:
        this.setState({
          contact_us: responseJson?.contact_us,
        });
        break;
      case this.apiPutUpdatePasswordCallID:
        this.setState({
          passwordChangeAlert: {
            success: true,
            visible: true,
            message: responseJson?.meta?.message,
          },
        });
        break;
      case this.apiDelAccountCallID:
        this.setState({ openDeleteAlert: true, openDeleteModal: false });
        break;
      case this.apiDeactivateAccountCallID:
        this.setState({
          openDeactivateAlert: true,
          openDeactivateModal: false,
        });
        break;
      case this.apiRemoveDeviceIdCallID:
        this.handleVisibleLogoutModal();
        if (responseJson.message === "device id remove successfully") {
          window.localStorage.removeItem("authToken");
          window.localStorage.removeItem("user_details");
          this.props.navigation.navigate("LandingHomePage");
          this.props.setNotificationData({
            data: [],
            meta: { total_pages: 0, unread_notifications: 0 },
          });
        }

        break;
      default:
        break;
    }
  };

  fetchPrivacyPolicy = () => {
    const header = {
      "Content-Type": configJSON.postContentType,
      //   token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPrivacyCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  fetchTermsCondition = () => {
    const header = {
      "Content-Type": configJSON.postContentType,
      //   token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTermsConditionCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndConditionUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  fetchAboutUs = () => {
    const header = {
      "Content-Type": configJSON.postContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAboutUsCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.aboutUsUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  fetchContactUs = () => {
    const header = {
      "Content-Type": configJSON.postContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetContactUsCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  updatePassword = (values: any) => {
    let token = window.localStorage.getItem("authToken");
    const attrs = {
      data: {
        new_password: values?.password,
      },
    };
    const header = {
      "Content-Type": configJSON.postContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPutUpdatePasswordCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  onDeleteAccount = () => {
    let userDetails: any = window.localStorage.getItem("user_details");
    const id: any = JSON.parse(userDetails)?.id;
    let token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.postContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDelAccountCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccURl + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  onDeactivateAccount = () => {
    // eslint-disable-next-line no-undef
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.postContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeactivateAccountCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deactivateUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  onRemoveDeviceId = () => {
    // eslint-disable-next-line no-undef
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.postContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRemoveDeviceIdCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeDeviceIdUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleLogout = () => {
    this.onRemoveDeviceId();
  };
  handlePasswordAlert = () => {
    this.setState({
      passwordChangeAlert: {
        success: false,
        visible: false,
        message: "",
      },
    });
  };
  onHandleDeleteAlert = () => {
    this.setState({ openDeleteAlert: false });
    this.props.navigation.navigate("OnboardingScreenBlock");
    removeStorageData('authToken');
  };
  onHandleDeactivateAlert = () => {
    this.setState({ openDeactivateAlert: false });
    this.props.navigation.navigate("OnboardingScreenBlock");
    removeStorageData('authToken');
  };

  handleErrorResponse = (errors: any) => {
    this.parseApiErrorResponse(errors);
    this.handleError(errors);
    this.parseApiCatchErrorResponse(errors);
  };

  handleError = (error: any) => {
    if (Array.isArray(error)) {
      const token = error[0];
      if (
        token === "Invalid token" ||
        token === "Token has Expired"
      ) {
        toast.error(`${token}, Please login again.`);
        window.localStorage.removeItem("authToken");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        window.localStorage.removeItem("user_details");
        return;
      } else if (error[0]) {

        this.props.navigation.goBack();

        toast.error(error[0]);

        return;
      }
    }
    toast.error("Something went to wrong please retry");
  };

  // Customizable Area End
}
