// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { apiCall } from "../../../components/src/utils.web";
import { ChangeEvent } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  isLoading: boolean;
  first_name: string;
  travel_bio: string;
  email: string;
  mobile_number: string;
  instagram: string;
  facebook: string;
  user_id: number;
  photo: string;
  userTags: {
    id: number;
    attributes: { name: string };
    parentId: string;
  }[];
  emailVerificationModal: boolean;
  emailOtpVerificationModal: boolean;
  emailSuccessFullyVerifiedModal: boolean;
  phoneVerificationModal: boolean;
  phoneOtpVerificationModal: boolean;
  phoneSuccessFullyVerifiedModal: boolean;
  updateEmail: string;
  updatedPhoneNumber: string;
  token: string;
  errorMessage: string;
  tags: {
    id: string;
    attributes: {
      name: string;
      activities: {
        data: { id: number; attributes: { name: string } }[];
      };
    };
  }[];
  user_name: string;
  openTagPage: boolean;
  isEmailPrivate: boolean;
  isPhoneNumberPrivate: boolean;
  isExistUser: string;
  username: string
}

interface SS {
  id: any;
}

export default class EditUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  upload: any;
  tagApiCallId: string = "";
  inputFileRef: any;
  userDetailsApiCallId: string = "";
  generateEmailOptApiCallId: string = "";
  emailVerifyApiCallId: string = "";
  generatePhoneOptApiCallId: string = "";
  phoneVerifyApiCallId: string = "";
  resendEmailOtpApiCallId: string = "";
  resendPhoneOtpApiCallId: string = "";
  getTagDetailsApiCallId: string = "";
  isUsernameExistId: string = "";
  editUserDetailApiCallId: string = "";
  timerId: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
      email: "",
      facebook: "",
      first_name: "",
      instagram: "",
      mobile_number: "",
      travel_bio: "",
      user_id: 0,
      photo: "",
      userTags: [],
      emailVerificationModal: false,
      emailOtpVerificationModal: false,
      updateEmail: "",
      emailSuccessFullyVerifiedModal: false,
      phoneVerificationModal: false,
      phoneOtpVerificationModal: false,
      phoneSuccessFullyVerifiedModal: false,
      updatedPhoneNumber: "",
      token: "",
      errorMessage: "",
      tags: [],
      openTagPage: false,
      isEmailPrivate: false,
      isPhoneNumberPrivate: false,
      user_name: "",
      isExistUser: "",
      username: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await this.getUserDetails();
    await this.getTags();
  }

  validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .trim()
      .required("Full name is required"),
    user_name: Yup.string()
      .trim()
      .required(configJSON.usernameValidationMessage),
    photo: Yup.mixed().required("Profile picture is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address")
      .test("verify-email", "Please verify email", function (value) {
        return this.parent.verifiedEmail === value;
      })
      .trim(),
    mobile_number: Yup.string()
      .required("Number is required")
      .min(10, "Phone number is required minimum 10 digits")
      .max(10, "Phone number is required maximum 10 digits")
      .test("verify-password", "Please verify password", function (value) {
        return this.parent.verifiedPassword === value;
      })
      .trim(),
    isEmailPrivate: Yup.boolean().default(false),
    isPhoneNumberPrivate: Yup.boolean().default(false),
    tags: Yup.array().when("userTags", {
      is: (userTags) => userTags.length === 0,
      then: Yup.array().min(1, "One interest is required"),
    }),
  });

  validationTagsFormSchema = Yup.object().shape({
    tags: Yup.array().min(1, "One tag is required"),
  });

  isUserNameExist = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, values: {}) => {
    if (event.target.value !== this.state.username) {
      this.setState({ ...values });
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.setState({ user_name: event.target.value });
      this.timerId = setTimeout(this.handleunserNameExist, 2000) as unknown as NodeJS.Timeout;
    } else {
      this.setState({ isExistUser: "" })
    }
  }

  handleunserNameExist = async () => {
    this.isUsernameExistId = await apiCall({
      endPoint: configJSON.uniqueUsernameExist,
      method: configJSON.POST,
      contentType: configJSON.contentTypeApiGetUserProfile,
      body: JSON.stringify({ "username": this.state.user_name })
    });
  }

  userDetailsUpdate = async (data: {
    full_name: string;
    travel_bio: string;
    facebook: string;
    instagram: string;
    photo: any;
    tags: {
      id: number;
      attributes: { name: string };
      parentId: string;
    }[];
    isEmailPrivate: boolean;
    isPhoneNumberPrivate: boolean;
    user_name: string;
  }) => {
    if (!this.state.isExistUser) {
      const tag = data.tags.reduce((acc: any, { id, parentId }) => {
        if (!acc[parentId]) {
          acc[parentId] = [];
        }
        acc[parentId].push(String(id));
        return acc;
      }, {});
      const formData = new FormData();
      if (typeof data.photo !== "string") {
        formData.append("[data][photo]", data.photo);
      }
      formData.append("[data][username]", data.user_name);
      formData.append("[data][full_name]", data.full_name);
      formData.append("[data][travel_bio]", data.travel_bio);
      formData.append("[data][facebook]", data.facebook);
      formData.append("[data][instagram]", data.instagram);
      formData.append("[data][activities_data]", JSON.stringify(tag));
      //@ts-ignore
      formData.append("[data][email_private]", data.isEmailPrivate);
      //@ts-ignore
      formData.append("[data][phone_private]", data.isPhoneNumberPrivate);
      this.setState({
        isLoading: true,
      });
      this.editUserDetailApiCallId = await apiCall({
        endPoint: `bx_block_profile/profiles/${this.state.user_id}`,
        method: "PUT",
        body: formData,
      });
    }
  };

  getUserDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.userDetailsApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: "bx_block_profile/profiles/my_profile",
    });
  };

  handleImageClick = () => {
    this.inputFileRef.click();
  };

  getTags = async () => {
    this.setState({
      isLoading: true,
    });
    this.getTagDetailsApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: "bx_block_activities/user_activities/get_all_activities",
    });
  };

  verifyEmail = async (email: string) => {
    this.setState({
      updateEmail: email,
      isLoading: true,
    });
    this.generateEmailOptApiCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "bx_block_update_user_data/send_email_update_otp",
      body: JSON.stringify({ email }),
    });
  };

  verifyEmailOtp = async (otp: string) => {
    this.setState({
      isLoading: true,
    });
    this.emailVerifyApiCallId = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: "bx_block_update_user_data/update_email_with_otp",
      body: JSON.stringify({ otp }),
      token: this.state.token,
    });
  };

  resendEmailOtp = async () => {
    this.setState({
      isLoading: true,
    });
    this.resendEmailOtpApiCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "account_block/accounts/resend_otp",
      body: JSON.stringify({
        data: {
          attributes: {
            email: this.state.updateEmail,
          },
        },
      }),
    });
  };

  openEmailVerificationModal = () => {
    this.setState({
      emailVerificationModal: true,
      errorMessage: "",
    });
  };

  closeEmailVerificationModal = () => {
    this.setState(
      {
        emailVerificationModal: false,
      },
      () => {
        this.openEmailOtpVerificationModal();
      }
    );
  };

  openEmailOtpVerificationModal = () => {
    this.setState({
      emailOtpVerificationModal: true,
    });
  };

  closeEmailOtpVerificationModal = () => {
    this.setState({
      emailOtpVerificationModal: false,
    });
  };

  openEmailSuccessFullyVerifiedModal = () => {
    this.setState({
      emailSuccessFullyVerifiedModal: true,
    });
  };

  closeEmailSuccessFullyVerifiedModal = () => {
    this.setState({
      emailSuccessFullyVerifiedModal: false,
    });
  };

  verifyPhone = async (phone: string) => {
    this.setState({
      updatedPhoneNumber: phone,
      isLoading: true,
    });
    this.generatePhoneOptApiCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "bx_block_update_user_data/send_mobile_update_otp",
      body: JSON.stringify({ full_phone_number: phone }),
    });
  };

  resendPhoneOtp = async () => {
    this.setState({
      isLoading: true,
    });
    this.resendPhoneOtpApiCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "account_block/accounts/resend_otp",
      body: JSON.stringify({
        data: {
          attributes: {
            full_phone_number: this.state.updatedPhoneNumber,
          },
        },
      }),
    });
  };

  verifyPhoneOtp = async (otp: string) => {
    this.setState({
      isLoading: true,
    });
    this.phoneVerifyApiCallId = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: "bx_block_update_user_data/update_mobile_with_otp",
      body: JSON.stringify({ otp }),
      token: this.state.token,
    });
  };

  openPhoneVerificationModal = () => {
    this.setState({
      phoneVerificationModal: true,
      errorMessage: "",
    });
  };

  closePhoneVerificationModal = () => {
    this.setState(
      {
        phoneVerificationModal: false,
      },
      () => {
        this.openPhoneOtpVerificationModal();
      }
    );
  };

  openPhoneOtpVerificationModal = () => {
    this.setState({
      phoneOtpVerificationModal: true,
    });
  };

  closePhoneOtpVerificationModal = () => {
    this.setState({
      phoneOtpVerificationModal: false,
    });
  };

  openPhoneSuccessFullyVerifiedModal = () => {
    this.setState({
      phoneSuccessFullyVerifiedModal: true,
    });
  };

  closePhoneSuccessFullyVerifiedModal = () => {
    this.setState({
      phoneSuccessFullyVerifiedModal: false,
    });
  };

  handleError = (errors: any) => {
    if (Array.isArray(errors)) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}, Please login again.`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      } else if (errors[0].pin) {
        this.setState({ errorMessage: errors[0].pin });
        return false;
      } else if (errors[0].account) {
        this.setState({ isExistUser: errors[0].account });
        return false;
      }
    }
    this.setState({
      isLoading: false,
    });
    toast.error("Something went to wrong please retry");
  };

  userDetailsHandleResponse = (responseJson: any) => {
    if (responseJson.profile.data) {
      const tags = responseJson.profile.data.attributes.interests.data.flatMap(
        (item: any) =>
          item.attributes.activities.data.map((activity: any) => ({
            parentId: item.id,
            id: activity.id,
            attributes: {
              name: activity.attributes.name,
            },
          }))
      );
      const data = responseJson.profile.data.attributes;
      this.setState({
        first_name: data.name,
        travel_bio: data.travel_bio,
        user_name: data.username,
        username: data.username,
        email: data.email,
        mobile_number: data.full_phone_number,
        instagram: data.instagram,
        facebook: data.facebook,
        userTags: tags,
        user_id: data.account_id,
        photo: data.photo,
        isEmailPrivate: data.email_private,
        isPhoneNumberPrivate: data.phone_private,
      });
    }
  };

  generateEmailOtpHandleResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState(
        {
          token: responseJson.meta.token,
        },
        () => {
          this.openEmailVerificationModal();
        }
      );
    }
  };

  resendEmailOtpHandleResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        token: responseJson.meta.token,
        errorMessage: "",
      });
    }
  };

  verifyEmailOtpHandleResponse = (responseJson: any) => {
    if (responseJson.message) {
      this.closeEmailOtpVerificationModal();
      this.openEmailSuccessFullyVerifiedModal();
      this.setState({
        email: this.state.updateEmail,
        errorMessage: "",
      });
    } else {
      this.setState({ errorMessage: responseJson.errors[0].pin });
    }
  };

  generatePhoneOtpHandleResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState(
        {
          token: responseJson.meta.token,
        },
        () => {
          this.openPhoneVerificationModal();
        }
      );
    }
  };

  resendPhoneOtpHandleResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        token: responseJson.meta.token,
        errorMessage: "",
      });
    }
  };

  verifyPhoneOtpHandleResponse = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        mobile_number: this.state.updatedPhoneNumber,
        errorMessage: "",
      });
      this.closePhoneOtpVerificationModal();
      this.openPhoneSuccessFullyVerifiedModal();
    } else {
      this.setState({ errorMessage: responseJson.errors[0].pin });
    }
  };

  getTagDetailsHandleResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        tags: responseJson.data,
      });
    } else {
      this.setState({
        tags: [],
      });
    }
  };

  updateUserDetailsHandleResponse = async(responseJson: any) => {
    if (responseJson.profile.data) {
      const response=responseJson;
      let data=await getStorageData('user_details',true).then(res=>res);
      response.profile.data={...response.profile.data,id:data.id,attributes:{...response.profile.data.attributes,id:data.id}};
      setStorageData("user_details",JSON.stringify(response.profile.data))
      toast.success("User details updated successfully");
      this.props.navigation.navigate("FanWall");
    } else {
      toast.error("Some thing went to wrong please try again");
    }
  };

  handleResponse = async (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.userDetailsApiCallId:
        this.userDetailsHandleResponse(responseJson);
        break;
      case this.generateEmailOptApiCallId:
        this.generateEmailOtpHandleResponse(responseJson);
        break;
      case this.emailVerifyApiCallId:
        this.verifyEmailOtpHandleResponse(responseJson);
        break;
      case this.generatePhoneOptApiCallId:
        this.generatePhoneOtpHandleResponse(responseJson);
        break;
      case this.phoneVerifyApiCallId:
        this.verifyPhoneOtpHandleResponse(responseJson);
        break;
      case this.resendEmailOtpApiCallId:
        this.resendEmailOtpHandleResponse(responseJson);
        break;
      case this.resendPhoneOtpApiCallId:
        this.resendPhoneOtpHandleResponse(responseJson);
        break;
      case this.getTagDetailsApiCallId:
        this.getTagDetailsHandleResponse(responseJson);
        break;
      case this.editUserDetailApiCallId:
        this.updateUserDetailsHandleResponse(responseJson);
        break;
      case this.isUsernameExistId:
        if (responseJson.message === configJSON.isUserNameValid) {
          this.setState({ isExistUser: "" })
        }
        break;
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson && responseJson.errors) {
        this.handleError(responseJson.errors);
      }
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        await this.handleResponse(apiRequestCallId, responseJson);
      } else if (!responseJson) {
        this.setState({ isExistUser: "" })
      }
      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
