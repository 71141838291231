// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getNotficationPermission } from '../../../web/src/firebase'
import { dashboard, Screen2, Screen3 } from "./assets";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}
// Customizable Area End
export interface S {
  // Customizable Area Start
  userName: string;
  password: string;
  error: boolean;
  checkPassword: boolean;
  index: number;
  imgData: any;
  isLoading: boolean;
  isRemember: boolean;
  fcmToken:string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export default class EmailAccountLoginControllerBlock extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.state = {
      // Customizable Area Start
      userName: "",
      password: "",
      error: false,
      checkPassword: false,
      index: 0,
      imgData: [
        {
          id: 1,
          img: dashboard,
          data:'head1title'
        },
        {
          id: 2,
          img: Screen2,
          data: 'head2title'
        },
        {
          id: 3,
          img: Screen3,
          data: 'head3title'
        },
      ],
      isLoading: false,
      isRemember: true,
      fcmToken: ""
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    let details: any = localStorage.getItem("authDetails");
    if (details) {
      details = JSON.parse(details);
      if (details.userName && details.password) {
        this.setState({
          password: details.password,
          userName: details.userName,
          isRemember: true,
        });
      }
    }
    getNotficationPermission().then((fcmToken: any) => this.setState({fcmToken}))
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.loginId) {
        if (responseJson.data) {
          this.setState({ error: false });
          this.saveLoggedInUserData(responseJson);
          localStorage.setItem(
            "user_details",
            JSON.stringify(responseJson.data)
          );
          this.props.navigation.navigate("FanWall");
        } else {
          this.setState({ error: true });
        }
      }
      this.setState({
        isLoading: false,
      });
    }
  }
  handleClickShowPassword() {
    this.setState({ checkPassword: !this.state.checkPassword });
  }
  navigationFunctuon() {
    this.props.navigation.goBack();
  }

  navigationNavigate(data: string, type: string) {
    localStorage.setItem("path", type);
    this.props.navigation.navigate(data);
  }

  Loginfunction(): any {
    this.setState({
      isLoading: true,
    });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    let attrs = {};
    if (isNaN(Number(this.state.userName))) {
      attrs = {
        email: this.state.userName,
        password: this.state.password,
        device_id: this.state.fcmToken
      };
    } else {
      attrs = {
        full_phone_number: this.state.userName,
        password: this.state.password,
        device_id: this.state.fcmToken
      };
    }
    const data = {
      data: {
        attributes: attrs,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.create_login
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  usserLogin(value: any) {
    this.setState({
      userName: value.userName,
      password: value.password,
      isRemember: value.isRemember,
    });
    if (value.isRemember) {
      localStorage.setItem(
        "authDetails",
        JSON.stringify({ userName: value.userName, password: value.password })
      );
    } else {
      localStorage.removeItem("authDetails");
    }
    this.Loginfunction();
  }
  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  // Customizable Area End
}
