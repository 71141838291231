import React from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import FanWallHomeController, {
  Props,
  configJSON,
} from "./FanWallHomeController.web";
import Carousel from "react-material-ui-carousel";
import ImageLabel from "../../../components/src/ImageLabel.web";
import PostCard from "./Post.web";
import Notification from "../../notifications/src/ChatWithNotification.web";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../../../components/src/Loader.web";
import InfiniteScroll from "react-infinite-scroll-component";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// Customizable Area End

export default class FanWallHome extends FanWallHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const data =
      this.state.total_page != 1
        ? 20 * this.state.total_page + this.state.subcategorys
        : this.state.exploreList.length;
    return (
      // Customizable Area Start
      <WrapperContainer>
        <Loader loading={this.state.isLoading} />
        <Grid
          container
          component={Box}
          className="main-wrapper"
          bgcolor="#f4f5fa"
        >
          <Grid
            id="scrollableDiv"
            item
            xs={9}
            sm={9}
            md={9}
            lg={9}
            xl={9}
            className="left-side-container"
          >
            <div className="search-bar">
              {this.state.LocationsSearch && this.state.isDataLoaded ? (
                <Button
                  variant="contained"
                  className="back-btn"
                  data-test-id="back-btn"
                  onClick={this.backToPost}
                >
                  <ArrowBackIosIcon className="back-button-image" /> Back
                </Button>
              ) : null}
              <OutlinedInput
                className="search-box"
                id="outlined-adornment-amount"
                placeholder={configJSON.searchbtnExampleTitle}
                data-test-id="search-locations"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                }
                onChange={this.handleLocation}
                value={this.state.LocationsSearch}
              />
            </div>
            <div className="text-head">
              {this.state.LocationsSearch && this.state.isDataLoaded ? (
                <>
                  <Typography className="main-heading">
                    {configJSON.resTitle + ` '${this.state.LocationsSearch}'`}
                  </Typography>
                  <Typography className="main-heading-2">
                    {this.state.exploreList.length +
                      configJSON.ofTiltle +
                      data +
                      configJSON.ResultTiltle}
                  </Typography>
                </>
              ) : (
                <Typography className="main-heading">
                  {configJSON.ExploreTiltle}
                </Typography>
              )}
            </div>
            <div
              className={`fanwall-main-container ${
                this.state.LocationsSearch && "fanwall-main-container1"
              }`}
              id="scrollableDiv"
            >
              {this.state.LocationsSearch && this.state.isDataLoaded ? (
                <>
                  {this.state.exploreList.length > 0 ? (
                    <InfiniteScroll
                      dataLength={this.state.exploreList.length}
                      next={this.fetchMoreData}
                      scrollableTarget="scrollableDiv"
                      hasMore={this.state.hasMore}
                      loader={<h4>{configJSON.LoadingTilte}</h4>}
                      className="infiniteScroll"
                    >
                      <Grid container spacing={3}>
                        {this.state.exploreList.map(
                          (item: {
                            id: number;
                            attributes: {
                              id: number;
                              location_cover_image: string;
                              city_name: string;
                            };
                          }) => (
                            <Grid item md={4} sm={6} xs={12} key={item.id}>
                              <ImageLabel
                                imageClick={this.redirectToExploreList.bind(
                                  this,
                                  item.attributes.id
                                )}
                                imageSrc={item.attributes.location_cover_image}
                                name={item.attributes.city_name}
                              />
                            </Grid>
                          )
                        )}
                      </Grid>
                    </InfiniteScroll>
                  ) : (
                    <p className="not-found-text">{configJSON.NoExploreData}</p>
                  )}
                </>
              ) : (
                <>
                  <Carousel
                    animation="slide"
                    swipe={true}
                    indicators={true}
                    autoPlay={true}
                    interval={3000}
                    activeIndicatorIconButtonProps={{
                      style: { color: "rgb(37, 170, 225)" },
                    }}
                  >
                    {this.state.carousel_screen?.map(
                      ({ id, attributes }: any, index: number) => (
                        <Box key={id} className="banner">
                          <img src={attributes.image} alt="baner" />
                          <h2>{attributes?.content}</h2>
                          <Button
                            onClick={this.goToExperience.bind(
                              this,
                              attributes?.screen_type
                            )}
                            data-test-id={attributes?.screen_type}
                            className="exp-btn"
                          >
                            {this.renderCarouselBtnTitle(
                              attributes?.screen_type
                            )}
                          </Button>
                        </Box>
                      )
                    )}
                  </Carousel>
                  <Box className="section">
                    <Box className="sec-header">
                      <h1>Top Locations</h1>
                      <Button
                        data-test-id="share-exp"
                        onClick={this.goToExperience.bind(this, "explore")}
                        className="see-btn"
                      >
                        See All
                      </Button>
                    </Box>
                    <Grid container spacing={3}>
                      {this.state.exploreList.map((item: any) => (
                        <Grid item md={4} sm={4} lg={4} xs={12} key={item.id}>
                          <ImageLabel
                            imageClick={this.redirectToExploreList.bind(
                              this,
                              item.attributes.id
                            )}
                            imageSrc={item.attributes.location_cover_image}
                            name={item.attributes.city_name}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>

                  <Box className="section">
                    <Box className="sec-header">
                      <h1>Trending Posts</h1>
                      <Button
                        data-test-id="see-all-post_list"
                        onClick={this.goToExperience.bind(this, "post_list")}
                        className="see-btn"
                      >
                        See All
                      </Button>
                    </Box>
                    {this.state.userPost.map((post: any, index: number) => {
                      return (
                        <PostCard
                          index={index}
                          item={post?.attributes}
                          id={index.toString()}
                          chatListAgainApiCall={this.chatListAgainApiCall}
                          navigation={this.props.navigation}
                          userDetails={this.state.userDetail}
                          handleLikePostAction={this.handleLikePostAction}
                          handleDeletePostLocal={this.handleDeletePostLocal}
                          updateShareCount={this.updateShareCount}
                        />
                      );
                    })}
                  </Box>
                  <Box className="footer-section">
                    <Grid item md={8} xs={12} lg={8} xl={8}>
                      <h1>
                        Got a plan? Collaborate with friends & make it possible.
                      </h1>
                    </Grid>
                    <Grid
                      className="btn-wrapper"
                      item
                      md={4}
                      xs={12}
                      lg={4}
                      xl={4}
                    >
                      <Button
                        onClick={this.goToExperience.bind(
                          this,
                          "itinerary_creation"
                        )}
                        data-test-id="go-btn"
                        className="go-btn"
                      >
                        Let’s Go!
                      </Button>
                    </Grid>
                  </Box>
                </>
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            style={{
              backgroundColor: "transparent",
              position: "relative",
              height: "100%",
              paddingLeft: "5px",
            }}
          >
            <Notification
              setNotificationData={this.setNotificationData}
              navigation={this.props.navigation}
              id={""}
              userDetail={this.state.userDetail}
              notificationData={{
                data: [],
                meta: {
                  unread_notifications: 0,
                  total_pages: 0,
                },
              }}
              reloadChatList={this.state.reloadChatList}
            />
          </Grid>
        </Grid>
      </WrapperContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const WrapperContainer = styled(Grid)({
  height: "100vh",
  padding: "0px",
  "& button.MuiButtonBase-root.MuiIconButton-root.Carousel-indicator-6.Carousel-active-8.MuiIconButton-sizeSmall":
    {
      color: "#25AAE1",
    },
  "& .main-wrapper": {
    display: "flex",
    height: "100%",
    position: "relative",
    "& .left-side-container": {
      height: "100vh",
      overflowX: "auto",
      padding: "0 10px",
      "& .fanwall-main-container": {
        height: "calc(100vh - 116px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      "& .fanwall-main-container1": {
        height: "calc(100vh - 146px)",
      },
      "& .search-bar": {
        display: "flex",
        alignItems: "center",
      },
      "& .search-box .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .search-box": {
        borderRadius: "15px",
        width: "100%",
        margin: "20px 0",
        background: "#FFFF",
      },
      "& .back-btn": {
        height: "55px",
        width: "100px",
        boxShadow: "none",
        borderRadius: "8px",
        backgroundColor: "#a9d9ed !important",
        marginRight: "15px",
        "& .MuiButton-label": {
          color: "#25aae1",
          fontSize: "14px",
          textTransform: "capitalize",
          fontWeight: "600",
          "& .back-button-image": {
            height: "15px",
            fill: "#25aae1",
          },
        },
      },
      "& .infiniteScroll": {
        height: "unset !important",
        overflow: "unset !important",
      },
      "& .text-head": {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
      },
      "& .main-heading-2": {
        color: "#94959B",
      },
      "& .main-heading": {
        fontFamily: "Helvetica-Bold",
        fontSize: "20px",
        fontWeight: "600",
        color: "#222222",
        margin: "10px 0 10px 0",
      },
      "& .banner": {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "10px",
        minHeight: "300px",
        "& img": {
          position: "absolute",
          objectFit: "cover",
          width: "100%",
          height: "93%",
          top: 0,
          left: 0,
          borderRadius: "10px",
        },
        "& h2": {
          fontFamily: "HelveticaNeue",
          fontSize: "40px",
          fontWeight: "600",
          color: "white",
          zIndex: 9,
        },
        "& .exp-btn": {
          backgroundColor: "rgb(37, 170, 225)",
          color: "#fff",
          padding: "10px 30px",
          borderRadius: "6px",
          width: "180px",
          textTransform: "capitalize",
          "@media (max-width:900px)": {
            margin: "0 0 20px 0",
          },
        },
      },
      "& .section": {
        padding: "0 40px 40px 40px",
        boxShadow: "0px 10px 16px 0px #d3d3d3",
        background: "white",
        borderRadius: "15px",
        margin: "40px 0px",
        "& .sec-header": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 0",
          "& h1": {
            fontSize: "24px",
            fontWeight: 600,
            color: "#222222",
            fontFamily: "HelveticaNeue",
          },
          "& .see-btn": {
            fontSize: "14px",
            fontWeight: 400,
            color: "#25AAE1",
            cursor: "pointer",
            textTransform: "capitalize",
            fontFamily: "HelveticaNeue",
          },
        },
      },

      "& .footer-section": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "50px 0",
        "@media (max-width:900px)": {
          display: "block",
        },
        "& h1": {
          fontFamily: "HelveticaNeue",
          fontSize: "36px",
          fontWeight: 500,
          color: "#222222",
          "@media (max-width:900px)": {
            fontSize: "22px",
          },
        },
        "& .btn-wrapper": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .go-btn": {
            backgroundColor: "rgb(37, 170, 225)",
            color: "#fff",
            padding: "20px 40px",
            marginRight: "20px",
            borderRadius: "6px",
            fontWeight: 600,
            fontSize: "18px",
            textTransform: "capitalize",
            "@media (max-width:900px)": {
              margin: "0 0 20px 0",
            },
          },
        },
      },
    },
  },
});
// Customizable Area End
