import React from "react";
// Customizable Area Start
import {
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Box,
  Grid,
  Typography,
  Chip,
  Button
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { searchIcon, noPostFound, noUserFound } from "./assets"
const configJSON = require("./config");
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AdvancedSearchController, { Props } from "./AdvancedSearchController.web";
import UserCard from "../../../components/src/userCard.web";
import PostCard from "../../FanWall/src/Post.web";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#25AAE1",
      contrastText: "#fff"
    }
  }
});
function TabPanel(props: {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
// Customizable Area End

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderActivity = () => {
    return (
      <FilterWrapper>
        <Box className="filtermodel">
          <LocationWrapper>
            <Box className="filterheader">
              <Typography variant="body1" className="activetitle">
                {configJSON.sideBarHeader}
              </Typography>
            </Box>
            <Box className="location-filter">
              <Box className="serch-location">
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  onChange={this.handleLocationData}
                  value={this.state.locationValue}
                  InputProps={{
                    endAdornment: <InputAdornment position="end"><LocationOnOutlinedIcon className="lcn-icon" /></InputAdornment>
                  }}
                  placeholder="Choose Location"
                />
              </Box>
            </Box>
            <Box className="filterheader">
              <Typography variant="body2" className="activetitle">
                {configJSON.sideBarSubHeader}
              </Typography>
            </Box>
          </LocationWrapper>
          <Box className="tag-scroll scrollableDiv">
            {this.state.filterActivities.length ? this.state.filterActivities.map((item, index) => {
              return <AdventurWrapper key={index + 1}>
                <Box className="adv-title">
                  <Typography className="adv-title">{item.name}</Typography>
                  <Box>
                    {item.data.map((items, i) => <Chip data-test-id="activity-button" key={i} onClick={() => this.handleActivityFilter(item.id, items.id, this.state.filterActivitiesId?.[item.id]?.includes(items.id))}
                      label={items.name} variant="outlined" className={`adv-tags ${this.state.filterActivitiesId?.[item.id]?.includes(items.id) && "select-tag"}`} />)}
                  </Box>
                </Box>
              </AdventurWrapper>
            }) : null}

          </Box>
          <Box display="flex" justifyContent="end" component="div" sx={{ padding: "10px" }}>
            <Button className="apply-btn" onClick={() => this.applyActivityFilter()} data-test-id="apply-filter-button" variant="contained" size="large" color="primary">
              {configJSON.filterBtn}
            </Button>
          </Box>
        </Box>
      </FilterWrapper>
    )
  }

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <SerachPageWrapper id="scrollableDiv">
          <TextField className="search-post" placeholder={configJSON.searchBarPlaceholder} id="search-bhomio" data-test-id="search-user-post"
            onKeyDown={(e) => this.handleApplyFilter(e)}
            onChange={(e) => this.setSearchInputValue(e.target.value)}
            value={this.state.txtInputValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} alt="searchIcon" />
                </InputAdornment>
              )
            }} />
          <Loader loading={this.state.isLoading} />
          <Tabs TabIndicatorProps={{ children: <span /> }}
            className="main-tab"
            value={this.state.tabValue}
            indicatorColor="primary"
            onChange={this.handleTabChange}
            textColor="primary"
          >
            <Tab label="People" />
            <Tab label="Post" />
          </Tabs>
          <MuiTab value={this.state.tabValue} index={0}>
            {this.state.peoples?.length ?
              <InfiniteScroll
                next={this.handleUser} scrollableTarget="scrollableDiv" loader={<>{configJSON.loadingMassage}</>} hasMore={this.state.hasMoreUserData} dataLength={this.state.peoples.length}>
                <UserCardWapper>
                  {this.state.peoples?.map((item, index) => {
                    return <div className="card-wapper" key={index + 1}>
                      <UserCard item={item} onFollowClick={this.handleFollower} navigation={this.props.navigation} />
                    </div>
                  })}
                </UserCardWapper>
              </InfiniteScroll>
              :
              <EmptyDataPageWrapper>
                <div>
                  <img src={noUserFound} alt="no-user-found-image" />
                  <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "20px" }}>{configJSON.noUserFoundTitle}</Typography>
                </div>
              </EmptyDataPageWrapper>
            }
          </MuiTab>
          <MuiTab value={this.state.tabValue} index={1}>
            {this.state.userPost.length ? <Grid container style={{ padding: "0 40px", width: "100%", margin: "0" }} spacing={3}>
              <Grid className="topbar-activity" item xs={12}>
                {this.renderActivity()}
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                <InfiniteScroll
                  next={this.handlePost} scrollableTarget="scrollableDiv" style={{ display: "flex", flexDirection: "column", gap: "20px" }} loader={<>{configJSON.loadingMassage}</>} hasMore={this.state.hasMoreData} dataLength={this.state.userPost.length}>
                  {this.state.userPost.length > 0 ? this.state.userPost.map((post, index) => {
                    return <PostCard
                      index={index + 1}
                      item={post?.attributes} id={index.toString()}
                      navigation={this.props.navigation}
                      userDetails={this.state.userDetail}
                      handleLikePostAction={this.handleLikePost}
                      handleDeletePostLocal={this.handleDeletePost}
                      updateShareCount={this.updateShareCount}
                    />
                  }) : null}
                </InfiniteScroll>
              </Grid>
              <Grid className="sidebar-activity" item xs={12} sm={12} md={4} lg={3} xl={3}>
                {this.renderActivity()}
              </Grid>
            </Grid> :
              <EmptyDataPageWrapper>
                <div>
                  <img src={noPostFound} alt="no-user-found-image" />
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>{configJSON.noPostFoundTitle}</Typography>
                </div>
              </EmptyDataPageWrapper>
            }
          </MuiTab>
        </SerachPageWrapper>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const SerachPageWrapper = styled('section')({

  height: "100vh",
  overflowY: "scroll",
  background: "#fafbff",
  "& .search-post": {
    width: "100%",
    boxSizing: "border-box",
    padding: "40px 30px 0 40px",
    "& .MuiInput-underline": {
      padding: "10px 20px",
      background: "#ffffff"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    }
  },
  "& .main-tab": {
    background: "#fafbff",
    minHeight: "0",
    padding: "20px 40px",
    "& .MuiTab-root": {
      minWidth: "60px",
      padding: "0 12px",
      minHeight: "35px",
    },
    "& .MuiTabs-indicator": {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      height: "5px",
      '& > span': {
        maxWidth: 5,
        width: '100%',
        height: "5px",
        borderRadius: "50%",
        backgroundColor: '#25AAE1',
      },
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize"
    },
    "& .MuiButton-root": {
      textTransform: "capitalize"
    }
  }
})
const UserCardWapper = styled('div')({
  display: "flex",
  flexWrap: "wrap",
  padding: "0 20px",
  background: "#fafbff",
  justifyContent: "space-around",
  "& .card-wapper": {
    width: "18%",
    padding: "30px 0",
    margin: "20px 0",
    background: "#ffffff",
    "@media (max-width:1100px)": {
      width: "24%",
    },
    "@media (max-width:900px)": {
      width: "32.33%",
    },
    "@media (max-width:600px)": {
      width: "48%",
    },
  }
})
const FilterWrapper = styled("section")({
  display: "flex",
  justifyContent: "flex-end",
  position: "sticky",
  top: "30px",
  "& .filtermodel": {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "12px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px",
  },
  "& .tag-scroll": {
    overflowY: "auto",
    maxHeight: "555px",
    "@media (max-width:959px)": {
      maxHeight: "200px",
    },
  }
});
const LocationWrapper = styled("section")({
  "& .serch-location": {
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "10px",
    width: "92%"
  },
  "& .activetitle": {
    fontSize: "20px",
    fontWeight: "bold"
  },
  "& .filterheader": {
    padding: "10px 15px 10px 15px",
  },
  "& .location-filter": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .lcn-icon": {
    marginTop: "3px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent"
  }
})
const AdventurWrapper = styled("div")({
  "& .adv-title": {
    padding: "10px 15px 5px 15px",
  },
  "& .adv-tags": {
    margin: "5px 5px 5px 5px",
    cursor: "pointer"
  },
  "& .select-tag": {
    backgroundColor: "#25aae1 !important",
    color: "white !important",
    transition: "all 0.3s",
    "&:hover": {
      color: "#000000"
    }
  }

});
const EmptyDataPageWrapper = styled("div")({
  height: "75%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& div": {
    textAlign: "center",
  }
})
const MuiTab = styled(TabPanel)({
  "& .sidebar-activity": {
    "@media (max-width:959px)": {
      display: "none"
    },
  },
  "& .topbar-activity": {
    display: "none",
    "@media (max-width:959px)": {
      display: "block"
    },
  }
})
// Customizable Area End
