import React, { CSSProperties } from "react";

import {
    Box,
    Typography,
    // Customizable Area Start
    Grid,
    Button,
    Chip,
    makeStyles,
    Tabs,
    Tab,
    styled,
    Modal,

    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from 'react-router-dom'
import InstaIcon from '@material-ui/icons/Instagram'
import FbIcon from '@material-ui/icons/Facebook'
import ArrowRightIcon from '@material-ui/icons/ChevronRight'
import { NavLink } from "react-router-dom";

import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import Followers from "./tabsComponents/Followers.web";
import Followings from "./tabsComponents/Followings.web";
// import UserPost from './tabsComponents/UserPost.web';
import Itinerary from './tabsComponents/Itinerary.web';

// Customizable Area End

import UserProfileController, {
    Props,
    S
} from "./UserProfileController.web";
import BackButton from "../../../components/src/BackButton.web";
import { dummyProfile, active_info, notificationIcon, profileTabIcon_1, profileTabIcon_2, profileTabIcon_3, profileTabIcon_4, profileTabIcon_5 } from "./assets";
import PlaceVisited from './tabsComponents/PlaceVisited.web';
import UserPost from "./tabsComponents/UserPost.web";
import Loader from "../../../components/src/Loader.web";
export const useStyles = makeStyles({
    selectedChip: {
        borderRadius: "20px",
        border: "1px solid rgba(37,170,225,0.24)",
        background: "#25aae1",
        backgroundBlendMode: "normal",
        height: "24px",
        margin: "0 10px 10px 0",
        cursor: "pointer",
        "& .MuiChip-label": {
            color: "#ffffff",
            fontFamily: "Helvetica",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "19px",
            padding: "0 16px",
        },
    },
    modalBox: {
        backgroundColor: "white",
        border: "2px solid red",
    },

    contentHead: {
        fontSize: "32px",
        textAlign: "left",
    },
    content: {
        fontSize: "18px",
        padding: "10px 0",
        textAlign: "left",
        color: "#818181"
    },
    contentSpan: {
        color: "#BE9E44",
    },

});

export default class UserProfile extends UserProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {


        const userId = this.props.navigation.getParam("id")
        this.fetchUserProfile(userId)
        const userDetail = window.localStorage.getItem('user_details') as string
        const parsedUser = userDetail ? JSON.parse(userDetail) : null;
        this.setState({ ownerData: parsedUser })
        if (this.props.navigation.history.location.state?.isItineraryCreated) {
            this.setState({
                tabValue: 1
            })
            this.props.navigation.history.replace({ state: {} })
        }
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
        const userId = prevProps.navigation.getParam("id")
        const userIdnew = this.props.navigation.getParam("id");
        this.checkUserId(userId, userIdnew)

    }
    checkUserId = (userId: number, userIdnew: number) => {
        if (userId !== userIdnew) {
            this.fetchUserProfile(userIdnew);
            this.setState({
                tabValue: 0
            })
            this.props.navigation.history.replace({ state: {} })
        }
    }
    openSocialLink = (url: string, type: string) => {
        if (url !== "") {
            if (type === 'instagram') {
                const win = window.open("https://www.instagram.com/" + url, '_blank') as typeof window;
                this.onFocus(win)
            }
            else {
                const win = window.open("https://www.facebook.com/" + url, '_blank') as typeof window;
                this.onFocus(win)
            }
        }

    }
    onFocus=(win:{focus:()=>void})=>{
        win?.focus()
    }
    renderAboutText = (str: string) => {
        const newStr = str?.split(" ");
        let res = "";
        for (let i = 0; i < newStr?.length; i++) {
            const el = newStr[i];
            if (el?.startsWith("#")) {
                res = res + "<span style=color:#25AAE1>" + el + "</span> "
            } else {
                res = res + el + " "
            }

        }
        return `<p style=font-size:14px;font-family:HelveticaNeue;text-align:left;color:#8F92A1;margin-bottom:10px;>${res}</p>`
    }

    renderTabPannel = (value: number) => {
        switch (value) {
            case 0:
                return <UserPost userDetails={this.state.ownerData} navigation={this.props.navigation} id={this.props.navigation.getParam("id")} />;
            case 1:
                return <Itinerary navigation={this.props.navigation} id={this.props.navigation.getParam("id")} userDetails={this.state.ownerData} />
            case 2:
                return <PlaceVisited navigation={this.props.navigation} id={this.props.navigation.getParam("id")} userDetails={this.state.ownerData} />
            case 3:
                return <Followers navigation={this.props.navigation} id={this.props.navigation.getParam("id")} userDetails={this.state.ownerData} />;
            case 4:
                return <Followings navigation={this.props.navigation} id={this.props.navigation.getParam("id")} userDetails={this.state.ownerData} />;
            default:
                break;
        }
    }
    handleChange = (event: any, newValue: number) => {
        this.setState({ tabValue: newValue })
    };
    handleFollow = () => {
        const { profileData } = this.state;
        if (profileData?.attributes?.is_following) {
            this.removeFollow(profileData?.attributes?.account_id)
        } else {
            this.createFollow(profileData?.attributes?.account_id)
        }
    }
     handleInsta=()=>{
        const { profileData } = this.state;
        this.openSocialLink(profileData?.attributes.instagram, 'instagram')
    }
     handleFacebook=()=>{
        const { profileData } = this.state;
        this.openSocialLink(profileData?.attributes.facebook, 'facebook')
    }


    // Customizable Area End

    render() {
        // Customizable Area Start
        const tabList = [{ title: "Post", icon: profileTabIcon_1 }, { title: "Itinerary", icon: profileTabIcon_2 }, { title: "Place's visited", icon: profileTabIcon_3 }, { title: "Followers", icon: profileTabIcon_4 }, { title: "Following", icon: profileTabIcon_5 }]
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box sx={webStyle.mainWrapper}>
                {this.state.isLoader ? <Loader loading={this.state.isLoader} /> : <Grid container style={{ flexWrap: "nowrap" }}>
                    <Grid item md={5} lg={5} xl={5} sm={12} xs={12} style={webStyle.leftSection}>
                        <BackButton navigation={this.props.navigation} />
                        <Typography
                            style={{
                                fontSize: "22px",
                                fontFamily: "HelveticaNeue",
                                lineHeight: "28px",
                                marginBottom: "10px"
                            }}
                            variant="h6"
                        >
                            Profile
                        </Typography>
                        <ProfileCard
                            ownerData={this.state.ownerData}
                            renderAboutText={this.renderAboutText}
                            profile={this.state.profileData}
                            openContactModal={this.handleContactModal}
                            handleFollow={this.handleFollow}
                            handleInsta={this.handleInsta}
                            handleFacebook={this.handleFacebook}

                        />
                        <Box
                            onClick={this.goToSettings}
                            sx={webStyle.settingBtn}
                        >
                            <p
                                style={{
                                    color: "#000000",
                                    fontSize: "20px",
                                    fontFamily: "HelveticaNeue",
                                }}
                            >
                                Settings
                            </p>
                            <span style={webStyle.list_icon}>
                                <ArrowRightIcon style={webStyle.imgIcon} />
                            </span>
                        </Box>
                    </Grid>
                    <Grid item md={7} lg={7} xl={7} sm={12} xs={12} style={webStyle.rightSection}>
                        <Box style={{
                            background: "white", height: "100%",
                            borderRadius: '20px 0 0 20px',
                            width: '100%'
                        }}>
                            <Box style={{ padding: 0, display: "flex", justifyContent: "flexStart", width: "100%", alignItems: "center" }}>
                                <StyledTabs test-id="tabs-bar" value={this.state.tabValue} onChange={this.handleChange}>
                                    {tabList?.map((tabItem: any, index: number) => <Tab icon={<img style={{ width: "24px", height: "24px" }} src={tabItem.icon} />} key={index} value={index} label={tabItem.title} />)}
                                </StyledTabs>

                                {/* <span style={{ paddingRight: "20px", position: 'relative' }}>
                                    <span style={webStyle.badge} />
                                    <img src={notificationIcon} style={{ width: "18px", height: "18px", objectFit: "contain", cursor: "pointer" }} />
                                </span> */}
                            </Box>
                            {this.renderTabPannel(this.state.tabValue)}


                        </Box>

                    </Grid>
                </Grid>}
                <ContactModal profileData={this.state.profileData?.attributes} open={this.state.openContactModal} handleContactModal={this.handleContactModal} />
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "HelveticaNeue",
        flexDirection: "column",
        paddingBottom: "20px",
        background: "#F2F2F2",
        margin: '0px',
        height: "100%",
        boxSizing: "border-box",
        overflow: "auto",
    },
    leftSection: {
        padding: "30px",
        margin: "0 10px",
    },
    rightSection: {
        margin: "0px 10px",
        borderRadius: "20px",
        height: "100%",
        maxHeight: "972px",
        overflow: "hidden",
    },
    settingBtn: {
        display: "flex",
        boxShadow: '0px 10px 16px 0px #A5B0C226',
        padding: "0 10px",
        justifyContent: "space-between",
        backgroundColor: "white",
        borderRadius: "20px",
        fontFamily: "HelveticaNeue",
        width: "auto",
        margin: "40px 0 0px 0",
        cursor: "pointer",
    },
    title: {
        fontSize: "30px",
        fontFamily: "HelveticaNeue",
        textAlign: 'center'
    } as CSSProperties,
    desc: {
        // fontWeight: '400px',
        fontSize: "14px",
        fontFamily: "HelveticaNeue",
        textAlign: 'center',
        color: "#8F92A1",
        margin: '0 0 10px 0;'
    } as CSSProperties,
    profileCard: {
        borderRadius: "12px",
        boxShadow: '0px 10px 16px 0px #A5B0C226',
        padding: '20px',
        textAlign: "center",
        margin: '5px',
        background: 'white',
        width: "auto",


    } as CSSProperties,
    prfileContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    } as CSSProperties,
    prfileStyle: {
        width: '108px',
        height: '108px',
        borderRadius: "50%",
        objectFit: "cover",

    } as CSSProperties,
    list_icon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "15px 20px",
    } as CSSProperties,
    imgIcon: {
        width: "24px",
        height: "24px",
        objectFit: "contain",
    } as CSSProperties,
    editBtn: {
        padding: '5px 25px',
        color: '#25AAE1',
        fontSize: "14px",
        textAlign: "center",
        border: "1px solid #25AAE1",
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",

    } as CSSProperties,
    followContain: {
        display: 'flex',
        justifyContent: "space-between",
        padding: '2px 40px'
    } as CSSProperties,
    aboutTitle: {
        margin: "10px 0",
        fontSize: "12px",
        color: "#1E1F20",
        fontFamily: "HelveticaNeue",
        fontWeight: 700,
        textTransform: "uppercase"
    } as CSSProperties,
    socRow: {
        display: 'flex',
        alignItems: "center",
        margin: '2px 0'
    } as CSSProperties,
    socLink: {
        fontSize: "12px",
        fontFamily: "HelveticaNeue",
        marginLeft: "6px",
        color: "#818181",
        textDecoration: "none",
        wordBreak: 'break-all',
        cursor:"pointer"
    } as CSSProperties,
    iterHeading: {
        fontSize: "20px",
        fontFamily: "HelveticaNeue",
        color: "#1E1F20"
    } as CSSProperties,
    iterDesc: {
        fontSize: "16px",
        fontFamily: "HelveticaNeue",
        color: "#8F92A1"
    } as CSSProperties,
    iterContainer: {
        padding: '20px',
        border: '1px solid #8F92A1',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    } as CSSProperties,
    badge: {
        width: '4px',
        height: '4px',
        background: "red",
        position: "absolute",
        top: -3,
        right: "20px",
        borderRadius: "50%"
    } as CSSProperties,
    item_style: {
        fontSize: "18px",
        margin: "0 0 0 20px",
        color: "#818181",
        fontFamility: "HelveticaNeue",
        lineHeight: "26px",
    } as CSSProperties,
    notif_row: {
        display: "flex",
        margin: "0 0 20px 0",
    } as CSSProperties,
};


const WordBreak = styled("div")({
    wordBreak: 'break-all'
})

export function CustomTag({ title }: { title: string }) {
    const classes = useStyles();
    return (
        <Chip
            label={title}
            className={classes?.selectedChip}
        />


    )
}
export const ProfileCard = ({ handleFollow, renderAboutText, openContactModal, profile, ownerData, handleInsta,handleFacebook }: {
    handleFollow: () => void,
    ownerData: {
        id: string, type: string, attributes: {
            id: number,
            full_name: string,
            email: string,
            full_phone_number: string,
            gender: string,
            photo: string,
            date_of_birth: string,
            state: string,
            city: string,
            activated: boolean,
            is_following: boolean,
            phone_private: boolean,
            email_private: boolean,
            interests: string[],
        }
    },
    renderAboutText: (str: string) => string,
    openContactModal: () => void,
    profile: {
        id: string,
        type: string,
        attributes: {
            id: number,
            username: string,
            email: string,
            full_phone_number: string,
            name: string,
            photo: string,
            account_id: number,
            is_following: boolean,
            followers: number,
            followings: number,
            travel_bio: string,
            instagram: string,
            facebook: string,
            interests_name: string[],
            interests: {
                data: {
                    id: string,
                    type: string,
                    attributes: {
                        name: string,
                        activities: {
                            data: {
                                id: string,
                                type: string,
                                attributes: {
                                    name: string,
                                }
                            }[]
                        }
                    }
                }[]
            }
        }
    },
    handleInsta: () => void,
    handleFacebook: () => void
}) => {
    
    return (
        <Box style={webStyle.profileCard}>
            <Box style={webStyle.prfileContainer}>
                <img src={profile?.attributes?.photo ?? dummyProfile} style={webStyle.prfileStyle} />
            </Box>
            <Grid container wrap="wrap" spacing={3} style={{ marginTop: "10px" }}>
                <Grid lg={2} xl={2} md={2} xs={2}></Grid>
                <Grid lg={8} xl={8} md={8} xs={8}><Typography style={webStyle.title}>{profile?.attributes?.name}</Typography></Grid>
                <Grid style={{
                    justifyContent: 'left',
                    alignItems: 'center',
                    display: 'flex'
                }} lg={2} xl={2} md={2} xs={2}> <img onClick={openContactModal} src={active_info} style={{ width: '24px', height: "24px", objectFit: "contain", cursor: "pointer" }} /></Grid>

            </Grid>
            <p style={webStyle.desc}>@{profile?.attributes?.username}</p>
            {(profile?.attributes?.account_id == Number(ownerData?.id)) ? <Link style={{ textDecoration: "none" }} to={`/edit-profile`}><Button style={webStyle.editBtn}>Edit</Button></Link> : <Button onClick={handleFollow} style={webStyle.editBtn}>{profile?.attributes?.is_following ? "Remove" : "Follow"}</Button>}


            <Box style={webStyle.followContain}>
                <p style={webStyle.desc}><strong style={{ fontWeight: "bold", color: '#1E1F20' }}>{profile?.attributes?.followers}{profile?.attributes?.followers > 999 && 'k'} </strong>Followers</p>
                <p style={webStyle.desc}><strong style={{ fontWeight: "bold", color: '#1E1F20' }}>{`${profile?.attributes?.followings}`}{profile?.attributes?.followings > 999 && 'k'} </strong>Following</p>
            </Box>
            <Box style={{ textAlign: "left" }}>
                <p style={webStyle.aboutTitle}>About</p>
                <WordBreak dangerouslySetInnerHTML={{ __html: renderAboutText(profile?.attributes?.travel_bio) }}></WordBreak>
            </Box>
            {profile?.attributes?.instagram && <span style={webStyle.socRow}>
                <InstaIcon style={{ color: '#25AAE1', width: "18px", height: "18px" }} />
                <span onClick={handleInsta} style={webStyle.socLink}>{profile.attributes.instagram}</span>
            </span>}
            {profile?.attributes?.facebook && <span style={webStyle.socRow}>
                <FbIcon style={{ color: '#25AAE1', width: "18px", height: "18px" }} />
                <span onClick={handleFacebook} style={webStyle.socLink} >{profile.attributes.facebook}</span>
            </span>}

            <Box style={{ display: "flex", marginTop: "25px", flexWrap: "wrap" }}>
                {profile?.attributes?.interests_name?.map((title: string, index: number) => <CustomTag key={index} title={title} />)}
            </Box>
        </Box>
    )
}
export const StyledTabs = styled(({ className, ...other }) => {
    return (
        <Tabs

            {...other}
            style={{ width: "100%", margin: "0 40px" }}
            classes={{
                root: className,
                flexContainer: "flexContainer",
                indicator: "indicator",
            }}
            variant="scrollable"
            TabIndicatorProps={{ children: <span /> }}
            centered
        />
    );
})({
    "& .indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        height: '5px',
        paddingBottom: "5px",
        color: "#25AAE1",
        "& > span": {
            maxWidth: 5,
            height: 5,
            margin: "0 0 10px 0",
            width: "100%",
            backgroundColor: "#25AAE1",
            borderRadius: "50%",

        }
    },


    "& .flexContainer": {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        color: '#8F92A1',
        fontFamily: "Helvetica",
        fontSize: '14px',
        minWidth: '80px',
        width: "100%",
    },
    "& .MuiTab-wrapper": {
        textTransform: "none"
    },
    '& .Mui-selected': {
        color: '#1890ff',
    },
    '& button': {
        minWidth: 'auto'
    }
});



const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    bgcolor: "background.paper",
    borderRadius: 12,
    boxShadow: 24,
    padding: '10px 24px'

};

export const ContactModal = ({ open, handleContactModal, profileData }: { open: boolean, handleContactModal: any, profileData: any }) => {
    const classes = useStyles();
    return (
        <Box>
            <Modal
                open={open}
                onClose={handleContactModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px"
                    }}>
                        <Typography className={classes?.contentHead}>
                            Contact Information
                        </Typography>
                        <CloseIcon
                            onClick={handleContactModal}
                            style={{
                                color: '#25AAE1',
                                cursor: 'pointer'
                            }} />
                    </Box>

                    <Grid container style={{ flexWrap: "nowrap", flexDirection: "column" }}>
                        <Grid lg={12} style={webStyle.notif_row}>
                            <PhoneIcon style={{ color: "#25AAE1" }} />
                            <p style={webStyle.item_style}>{profileData?.phone_private ? "Contact number is private." : profileData?.full_phone_number}</p>
                        </Grid>
                        <Grid lg={12} style={webStyle.notif_row}>
                            <EmailIcon style={{ color: "#25AAE1" }} />
                            <p style={webStyle.item_style}>{profileData?.email_private ? "Email address is private." : profileData?.email}</p>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    );
};


// Customizable Area End
