// Customizable Area Start
import React from "react";
import { withStyles, Box } from "@material-ui/core";
import { SideMenuStyles } from "./SideMenu.styles.web";
import { NavLink } from "react-router-dom";

const activeHomeImage = require("./assets/home_active.png");
const notActiveHomeImage = require("./assets/home_not_active.png");
const activeExploreImage = require("./assets/explore_active.png");
const notActiveExploreImage = require("./assets/explore_not_active.png");
const activePostImage = require("./assets/post_active.png");
const notActivePostImage = require("./assets/post_not_active.png");
const activeSearchImage = require("./assets/search_active.png");
const notActiveSearchImage = require("./assets/search_not_active.png");
const activeChatImage = require("./assets/chat_active.png")
const notActiveChatsImage = require("./assets/chat_not_active.png");
const mainImage = require("./assets/main_image.png");
const setting_image = require("./assets/setting_icon.png");

const sideMenuItems = [
  {
    title: "Home",
    activeSrc: activeHomeImage,
    notActiveSrc: notActiveHomeImage,
    path: "/FanWall",
  },
  {
    title: "Explore",
    activeSrc: activeExploreImage,
    notActiveSrc: notActiveExploreImage,
    path: "/explore",
  },
  {
    title: "Search",
    activeSrc: activeSearchImage,
    notActiveSrc: notActiveSearchImage,
    path: "/AdvancedSearch",
  },
  {
    title: "Post",
    activeSrc: activePostImage,
    notActiveSrc: notActivePostImage,
    path: "/post-create",
  },
  {
    title: "Discussion Forum",
    activeSrc: activeChatImage,
    notActiveSrc: notActiveChatsImage,
    path: "/discussion-forum",
  },
];

interface Props {
  classes: any;
  children?: any;
}

interface S {
  activePath: string;
}

class SideMenu extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = { activePath: "" };
  }

  componentDidMount() {
    this.setState({
      activePath: window.location.pathname,
    });
  }

  render() {
    const { classes, children } = this.props;
    return (
      <Box className={classes.SideMenu}>
        <div className="side-menu">
          <NavLink to="/">
            <img src={mainImage} className="main-image" />
          </NavLink>
          <div className="side-menu-items">
            {sideMenuItems.map((item, index) => {
              return (
                <NavLink to={item.path} key={index} exact title={item.title}>
                  <img
                    src={
                      this.state.activePath.includes(item.path)
                        ? item.activeSrc
                        : item.notActiveSrc
                    }
                    className="side-menu-image"
                  />
                </NavLink>
              );
            })}
            <NavLink style={{ marginTop: "100px" }} to={"/FanWall"} exact title={"Setting"}>
              <img
                src={setting_image}
                className="side-menu-image"
              />
            </NavLink>
          </div>
        </div>
        <div className="side-component">{children}</div>
      </Box>
    );
  }
}
//@ts-ignore
export default withStyles(SideMenuStyles)(SideMenu);
// Customizable Area End
