Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const {baseURL} = require("../../../framework/src/config.js");
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.baseURL = baseURL;
exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";
exports.getTagsApiEndPoint ="bx_block_activities/user_activities/get_all_activities";
exports.getTagsApiMethod = "GET";
exports.createPostApiEndPoint = "bx_block_posts/posts";
exports.createPostApiMethod = "POST";
exports.getOnePostApiEndPoint = "bx_block_posts/posts";
exports.getOnePostApiMethod = "GET";
exports.editPostApiEndPoint = "bx_block_posts/posts";
exports.editPostApiMethod = "PUT";
exports.getTravellers = "bx_block_search/search_users?query=";
exports.title1="Enter following details";
exports.title2="Add Title";
exports.placeTitle="Add Place";
exports.daysTitle="Days";
exports.activityTitle="Add activity of every place as per days in next step with your friends. Enjoy 🤩";
exports.AddplaceTitle="+ add another place";
exports.InviteTravellersTitle="Invite travellers";
exports.SearchTitle="Search here";
exports.LoadingTitle="Loading...";
exports.AddTravellerTitle="Please Add Traveller";
exports.nextBtn="Next";
exports.addActivity="+ add activity";
exports.NoUserErrorMassage="Traveller Not Found";
exports.SaveBtnTitle="Save";
exports.lessTitle="Less";
exports.createBtnTitle="Create Now";
exports.createItineraryApiEnd="bx_block_itinerary/itinerary/create_itinerary";
exports.addTravellerApiEndPoint="bx_block_itinerary/itinerary/add_travelers";
exports.getItineraryApiEndPoint="bx_block_itinerary/itinerary/";
exports.createActivityApiEndPoint="bx_block_itinerary/itinerary_activities/create_daily_activity";
exports.errormassage="something went wrong";
exports.errormassage2=", Please login again.";
exports.successmassage="itinerary created successfully";
exports.getItineraryApiEndPoint="bx_block_itinerary/itinerary/";
exports.getItinenaryActivityApiEndPoint="bx_block_itinerary/itinerary_activities/day_daily_activites?itinerary_id=";
exports.editTitle="Edit";
exports.deleteTitle="Delete";
exports.deleteItineraryTitle="Delete Itinerary";
exports.deleteItineraryTitle2="Are you sure you want to delete? All the deteils updated in the itinerary will be lost if deleted?";
exports.deleteItinenaryApiEndPoint = "bx_block_itinerary/itinerary/delete_itinerary";
exports.deleteItinerarySuccessTitle = "Itinerary deleted successfully";
exports.getAllActivityApiEndPoint = "bx_block_itinerary/itinerary_activities/location_daily_activites";
exports.getItineraryInfoApiEndPoint = "bx_block_itinerary/itinerary/itinerary_info/";
exports.editSaveTitle="Save & Continue";
exports.editItineraryTitleApiEndPoint="bx_block_itinerary/itinerary/edit_itinerary";
exports.editItineraryRemoveTravelersApiEndPoint="bx_block_itinerary/itinerary/remove_travelers";
exports.removeItineraryplaceApiEndPoint="bx_block_itinerary/itinerary/remove_places";
exports.AddItineraryPlacesApiEndPoint="bx_block_itinerary/itinerary/add_places";
exports.editplacesApiEndPoint="bx_block_itinerary/itinerary/edit_place";
exports.removeItineraryplaceActivityApiEndPoint="bx_block_itinerary/itinerary_activities/remove_daily_activity";
exports.editPlaceApiEndPoint="bx_block_itinerary/itinerary_activities/edit_daily_activity";
exports.removeItineraryplaceActivityImageApiEndPoint="bx_block_itinerary/itinerary_activities/remove_photos_from_activity";
exports.addActivityimagesApiEndPoint= "bx_block_itinerary/itinerary_activities/add_photos_to_activity";
exports.editItineraryTitle = "Edit an itinenary";
exports.regionApiEndPoint = "End point";
exports.ItineraryTitle="Itinerary";
exports.logintitlealertmodal="Login / Signup";
exports.logindesalertmodal="You need to login / signup to participate in the discussion";
exports.imgMassage = "Attachment";
exports.nodatamsg= "N/A";
exports.requireTitle= 'Title is required';
exports.requirePlace= 'Place is required';
exports.requireDay= "Day is Required";
exports.requireName= 'Name is required';
exports.requirePlaces= 'Places is required';
exports.requireDescription= 'Description is required';
exports.requirePlans= "Plans is required";
exports.requireLocation= "Location is required";
exports.requireActivity= "Activity is required";
exports.requireAllData= "Please fill all days with activity";
exports.tabTitle="Itinerary is ready, Let's fill the activity 😎";
// Customizable Area End