// Customizable Area Start

export const ForumStyle = {
  mainContainer: {
    flexWrap: "nowrap",
    backgroundColor: "#F2F2F2",
    padding: "40px 20px 20px",
    minHeight: "100%",
    height:'100vh',
    "& .forum-container": {
      marginLeft: "10%",
      height:'100%',
      width: "80%",
      "& .search-container": {
        "& .MuiFormControl-root.MuiTextField-root.searchField": {
          width: '100%',
        },
        display: 'flex',
        margin: "25px 0",
        padding: "5px 12px",
        borderRadius: "6px",
        backgroundColor: 'white',
        "& .MuiInput-underline:before": {
          borderBottom: "none"
        },
        "& .MuiInput-underline:after": {
          borderBottom: "none"
        }
      },

    }
  },

} ;
// Customizable Area End
