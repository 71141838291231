// Customizable Area Start
import React from "react";
import { makeStyles } from "@material-ui/core";

import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  LocationDetails: {
    borderRadius: "12px",
    padding: "20px",
    border: "0.5px solid rgba(105,134,216,0.1)",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
    marginBottom: "15px",
    "& .explore-image-container": {
      scrollBehavior: "smooth",
      overflow: "hidden",
      height: "100%",
      borderRadius: "12px",
      width: "100%",
      maxHeight: "280px",
      position: "relative",
    },
    "& .explore-images": {
      height: "100%",
      width: "100%",
      borderRadius: "12px",
      objectFit: "fill",
      aspectRatio: "10/8",
    },
    "& .explore-location-name": {
      color: "#1e1f20",
      fontFamily: "Helvetica-Bold",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontSize: "22px",
      textOverflow: "ellipsis",
      fontWeight: "600",
      lineHeight: "27px",
    },
    "& .explore-city-description": {
      color: "#818181",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "26px",
      margin: "15px 0",
    },
  },
}));

interface Props {
  exploreName: string;
  description: string;
  exploreImage: string;
}

const ExploreLocationDetails: React.FC<Props> = ({
  description,
  exploreName,
  exploreImage,
}) => {
  const classes = useStyles();

  return (
    <div className={classes?.LocationDetails}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12}>
          <div className="explore-image-container">
            <img className="explore-images" src={exploreImage} alt="hotels" />
          </div>
        </Grid>
        <Grid item md={12} sm={12}>
          <Typography className="explore-location-name" title={exploreName}>
            {exploreName}
          </Typography>
          <div
            className="explore-city-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ExploreLocationDetails;
// Customizable Area End
