import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine as runEngineForChat } from "../../../framework/src/RunEngine";
import DataContext from "../../../components/src/DataContext";
import { toast } from "react-toastify";
import { apiCall } from "../../../components/src/utils.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  userDetail: any;
  gotToChatDetails: (data: any, user: {}) => void;
  reloadChatList: boolean;
  fetchNotficationBadge: () => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isLoader: boolean;
  chatHistorys: {}[];
  chatId: any;
  anchorEl: any;
  userList: any;
  searchQuery: string;
  currentPage: number;
  hasMoreData: boolean;
  hasUserMoreData: boolean;
  userDetail: { id?: number };
  currentUser: any;
  currentUserPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetUserCallID: string = "";
  apiGeChatRoomCallID: string = "";
  apiGeChatDetailsCallID: string = "";
  GetAllChatsId: string = "";
  getUserChatDetail: string = "";
  GetMoreChatsId: string = "";
  GetMoreUserId: string = "";
  static contextType: React.Context<string> = DataContext;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoader: false,
      chatId: NaN,
      anchorEl: null,
      userList: { data: [], meta: { total_pages: 0 } },
      searchQuery: "",
      currentPage: 1,
      chatHistorys: [],
      userDetail: {},
      currentUser: {},
      hasMoreData: false,
      currentUserPage: 1,
      hasUserMoreData: false,
      // Customizable Area End
    };
    runEngineForChat.attachBuildingBlock(
      this as IBlock,
      this.subScribedMessages
    );

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.searchQuery !== this.state.searchQuery) {
      await this.fetchUsers(1);
    }

    if (this.context?.data?.chatdata?.data) {
      const data = this.context?.data?.chatdata?.data?.attributes;
      const chat = this.state.chatHistorys;
      if (chat.length) {
        const index = chat.findIndex(
          (i: { chat_id?: number }) => i.chat_id === data.notify_item_id
        );
        if (index > -1) {
          chat.splice(index, 1);
          chat.unshift({
            chat_id: data.notify_item_id,
            last_message: data.description,
            unread_messages: data.unread_messages,
            last_message_datetime: data.updated_at,
            users: [
              {
                name: data.title,
                offline: false,
                profile_photo: data.profile_image,
                id: data.profile_id,
              },
            ],
          });
        } else {
          chat.unshift({
            chat_id: data.notify_item_id,
            last_message: data.description,
            unread_messages: data.unread_messages,
            last_message_datetime: data.updated_at,
            users: [
              {
                name: data.title,
                offline: false,
                profile_photo: data.profile_image,
                id: data.profile_id,
              },
            ],
          });
        }
        this.setState({ chatHistorys: chat });
        this.props.fetchNotficationBadge();
      }
      this.context.setData({
        ...this.context?.data,
        chatdata: {},
      });
    }

    if (this.props.reloadChatList !== prevProps.reloadChatList) {
      this.setState(
        {
          currentPage: 1,
          chatHistorys: [],
          hasMoreData: false,
        },
        () => {
          this.getLetestChats();
        }
      );
    }
  }
  async componentDidMount() {
    const data = localStorage.getItem("user_details");
    if (data) {
      this.setState({
        userDetail: JSON.parse(data),
      });
    }
    this.getLetestChats();
  }

  handleUserChat = async (user: { id: number }) => {
    this.setState({ currentUser: user });
    this.getUserChatDetail = await apiCall({
      endPoint: configJSON.createChatsApiEndPoint,
      method: "POST",
      contentType: configJSON.apiContentType,
      body: JSON.stringify({
        chat: {
          name: "private_chat",
          other_user_Id: user.id,
        },
      }),
    });
  };

  fetchMoreUser = async () => {
    this.setState({
      currentUserPage: this.state.currentUserPage + 1,
    });
    this.GetMoreUserId = await apiCall({
      endPoint:
        configJSON.searchUserEndpoint +
        `query=${this.state.searchQuery}&per_page=20&page=${this.state.currentUserPage}`,
      method: "GET",
      contentType: configJSON.apiContentType,
    });
  };

  fetchMoreData = async () => {
    this.setState({
      currentPage: this.state.currentPage + 1,
    });
    this.GetMoreChatsId = await apiCall({
      endPoint:
        configJSON.getChatsInfoApiEndPoint +
        `?per_page=20&page=${this.state.currentPage}`,
      method: "GET",
      contentType: configJSON.apiContentType,
    });
  };

  getLetestChats = async () => {
    this.GetAllChatsId = await apiCall({
      endPoint: configJSON.getChatsInfoApiEndPoint + "?per_page=20&page=1",
      method: "GET",
      contentType: configJSON.apiContentType,
    });
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.errors) {
        this.handleErrorResponseforChat(responseJson.errors);
        this.setState({ isLoader: false });
      } else if (responseJson) {
        this.checkApiResponseForChat(responseJson, apiRequestCallId);
      } else {
        this.handleErrorResponseforChat(errorReponse);
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start

  checkApiResponseForChat = (responseJson: any, apiRequestCallId: any) => {
    switch (apiRequestCallId) {
      case this.apiGetUserCallID:
        this.handleUserDetails(responseJson);
        break;
      case this.GetMoreUserId:
        if (responseJson.data.length) {
          const hasUserMoreData =
            responseJson?.data?.length >= 20
          this.setState({
            userList: {
              data: [...this.state.userList.data, ...responseJson.data],
              meta: responseJson.meta,
            },
            hasUserMoreData,
          });
        } else {
          this.setState({ currentUserPage: 1, hasUserMoreData: false });
        }
        break;
      case this.GetAllChatsId:
        this.handleChatDetails(responseJson);
        break;
      case this.getUserChatDetail:
        const id = responseJson?.data?.id ?? responseJson?.chat?.data?.id;
        if (id) {
          this.props.gotToChatDetails(id, {
            email: this.state.currentUser?.attributes?.email,
            id: this.state.currentUser?.id,
            name: this.state.currentUser?.attributes?.full_name,
            offline: true,
            profile_photo: this.state.currentUser?.attributes?.photo,
            unread_messages: 0,
          });
        }
        break;
      case this.GetMoreChatsId:
        this.handleGetMoreChatResponse(responseJson);
        break;
    }
  };

  handleGetMoreChatResponse = (responseJson: any) => {
    if (responseJson.chats_info.length) {
      const hasMoreData = responseJson?.chats_info?.length >= 20;
      this.setState({
        chatHistorys: [...this.state.chatHistorys, ...responseJson.chats_info],
        hasMoreData,
      });
    } else {
      this.setState({ hasMoreData: false });
    }
  };

  GetUserChatDetail = (responseJson: {
    data: { id: number };
    chat: { data: { id: number } };
  }) => {
    const id = responseJson?.data?.id ?? responseJson?.chat?.data?.id;
    if (id) {
      this.props.gotToChatDetails(id, this.state.currentUser);
    } else {
      toast.error(configJSON.errorMassage);
    }
  };

  handleUserDetails = (responseJson: { data: []; meta: [] }) => {
    const hasUserMoreData = responseJson?.data?.length >= 20;
    if (this.state.searchQuery !== "" && responseJson.data.length) {
      this.setState({
        userList: {
          data: responseJson.data,
          meta: responseJson.meta,
        },
        hasUserMoreData,
      });
    } else {
      this.setState({
        userList: { data: [], meta: { total_pages: 1 } },
        currentUserPage: 1,
        hasUserMoreData: false,
      });
    }
  };

  handleChatDetails = (responseJson: { chats_info: [] }) => {
    if (responseJson.chats_info.length) {
      const hasMoreData = responseJson?.chats_info?.length >= 20
      this.setState({ chatHistorys: responseJson.chats_info, hasMoreData });
    } else {
      this.setState({ hasMoreData: false });
    }
  };

  fetchUsers = (page: number) => {
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchUserEndpoint +
      `query=${this.state.searchQuery}&page=${page}&per_page=20`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngineForChat.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true });
    return true;
  };

  handleErrorResponseforChat = (errors: any) => {
    this.setState({ isLoader: false });
    this.parseApiErrorResponse(errors);
    this.parseApiCatchErrorResponse(errors);
    this.handleError1(errors);
  };
  handleError1 = (errorsForChat: any) => {
    if (Array.isArray(errorsForChat)) {
      if (
        errorsForChat[0].token === "Invalid token" ||
        errorsForChat[0].token === "Token has Expired"
      ) {
        toast.error(`${errorsForChat[0].token}, Please login again.`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      } else if (errorsForChat[0]) {
        toast.error(errorsForChat[0]);
        this.props.navigation.goBack();
      }
    }
    toast.error("Something went to wrong please retry");
  };
  checkBlockedUser = (blocked_by_user: boolean, blocked_by_me: boolean) => {
    if (blocked_by_user) {
      toast.warning(`You have blocked this user`)
    }
    else if (blocked_by_me) {
      toast.warning("You are blocked by this user")
    }
  }
  // Customizable Area End
}
