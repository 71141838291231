// Customizable Area Start
import React from "react";
import { withStyles, Box, Typography, Button } from "@material-ui/core";
import { ItineraryLocationStyles } from "./ItineraryLocation.styles.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ItineraryLocationDetails from "../../../components/src/ItineraryLocationDetails.web";
import ItineraryBookmarkDetails from "../../../components/src/ItineraryBookmarkDetails.web";
import ItineraryBookmarkUserDetails from "../../../components/src/ItineraryBookmarkUserDetails.web";
import SearchBar from "../../../components/src/SearchBar.web";
import ItineraryBookmarkController, {
  IExploreItems,
  IExploreLocation,
  IPost,
  Props,
} from "./ItineraryLocationController.web";
import Loader from "../../../components/src/Loader.web";
import InfiniteScroll from "react-infinite-scroll-component";
import { setStorageData } from "../../../framework/src/Utilities";

class ItineraryLocation extends ItineraryBookmarkController {
  constructor(props: Props) {
    super(props);
  }
  
  postBookMarkedDetails = (item: IPost) => {
    return (
      <ItineraryBookmarkUserDetails
        isDataAddable={true}
        id={item.id}
        bookmarkable_id={item.attributes.bookmarkable_id}
        descriptionBookmark={item.attributes.title}
        descriptionPost={
          item.attributes.bookmarked_item.data.attributes.description
        }
        images_and_videos={
          item.attributes.bookmarked_item.data.attributes.images_and_videos
        }
        location={item.attributes.bookmarked_item.data.attributes.location}
        name={item.attributes.bookmarked_item.data.attributes.uploader_name}
        userImage={
          item.attributes.bookmarked_item.data.attributes.uploader_image
        }
        recentTime={item.attributes.bookmarked_item.data.attributes.created_at}
        onDeleteItem={this.deleteBookMarkedItem}
        onEditItem={this.redirectToPostBookMarkPage}
        addLocationItem={this.addLocationItem}
      />
    );
  };

  locationBookMarkedDetails = (item: IExploreLocation) => {
    return (
      <ItineraryBookmarkDetails
        isDataAddable={true}
        id={item.id}
        bookmarkable_id={item.attributes.bookmarkable_id}
        descriptionBookmark={item.attributes.title}
        descriptionExploreItems={
          item.attributes.bookmarked_item.data.attributes.description
        }
        imageSrc={
          item.attributes.bookmarked_item.data.attributes.location_images[0].url
        }
        city_name={item.attributes.bookmarked_item.data.attributes.city_name}
        onDeleteItem={this.deleteBookMarkedItem}
        onEditItem={this.redirectToExploreLocationBookMarkPage}
        addLocationItem={this.addLocationItem}
        onRedirectRating={this.redirectToRatingDetailPage}
      />
    );
  };
  redirectToRatingDetailPage = (attributes: any) => {
    setStorageData('ItineraryData',JSON.stringify({itinerary: { ...this.props.data.itinerary }, currentTabline: "form2",historyTab:"bookmark"}));
    this.props.navigation.history.push(
      `/${attributes?.id}/rating`
    );
  };

  exploreItemsBookMarkedDetails = (item: IExploreItems) => {
    return (
      <ItineraryBookmarkDetails
        isDataAddable={true}
        id={item.id}
        bookmarkable_id={item.attributes.bookmarkable_id}
        descriptionBookmark={item.attributes.title}
        descriptionExploreItems={
          item.attributes.bookmarked_item.data.attributes.tag_line
        }
        exploreItemsName={item.attributes.bookmarked_item.data.attributes.name}
        location={item.attributes.bookmarked_item.data.attributes.location}
        ratings={item.attributes.bookmarked_item.data.attributes.rating}
        totalReviews={
          item.attributes.bookmarked_item.data.attributes.reviews_count
        }
        imageSrc={
          item.attributes.bookmarked_item.data.attributes.item_cover_image
        }
        onDeleteItem={this.deleteBookMarkedItem}
        onEditItem={this.redirectToExploreItemsBookMarkPage}
        addLocationItem={this.addLocationItem}
        onRedirectRating={this.redirectToRatingDetailPage.bind(this, item.attributes?.bookmarked_item?.data?.attributes)}
      />
    );
  };

  bookmarkedDetails = (item: IExploreItems | IExploreLocation | IPost) => {
    switch (item.attributes.bookmarkable_type) {
      case "BxBlockPosts::Post":
        return this.postBookMarkedDetails(item as IPost);
      case "BxBlockExplore::ExploreLocation":
        return this.locationBookMarkedDetails(item as IExploreLocation);
      case "BxBlockExplore::ExploreItem":
        return this.exploreItemsBookMarkedDetails(item as IExploreItems);
      default:
        break;
    }
  };

  imageURL = (photos: any, icon: string) => {
    if (photos && photos.length > 0) {
      return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${photos[0].photo_reference}&key=AIzaSyC3pWSyaP_sZV2xqH1X7vKLCBNRgJJ7hC8`;
    }
    return icon;
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Box className={classes?.ItineraryLocation}>
          <Box className="main-text-container itinerary-location-main-text-container">
            <Button
              data-test-id="back-button"
              className="back-button location-back-button"
              variant="contained"
              onClick={() => this.props.backToPreviousPage()}
            >
              <ArrowBackIosIcon className="back-button-image itinerary-back-button" />
              Back
            </Button>
            <Typography className="title-text">Select Location</Typography>
          </Box>
          <Box className="slider-tabs">
            <Box className="active-image-container">
              <Typography
                id="form-location"
                data-test-id="location-button"
                className={
                  this.state.activeTab === "search-location"
                    ? "active-item location-active-button"
                    : "in-active-item "
                }
                onClick={() =>
                  this.setState(
                    {
                      activeTab: "search-location",
                      page: 1,
                      hasMore: false,
                      isLoading: true,
                      searchLocationData: [],
                    },
                    async () => {
                      this.searchLocationApiCall();
                    }
                  )
                }
              >
                Search Location
              </Typography>
              {this.state.activeTab === "search-location" && (
                <Box className="active-border"></Box>
              )}
            </Box>
            <Box className="active-image-container">
              <Typography
              id="bookmark_history_id"
                data-test-id="bookmark-button"
                className={
                  this.state.activeTab === "bookmarked"
                    ? "active-item active-item-location"
                    : "in-active-item"
                }
                onClick={() =>
                  this.setState(
                    {
                      activeTab: "bookmarked",
                      page: 1,
                      hasMore: false,
                      isLoading: true,
                      searchBookmarkedData: [],
                    },
                    async () => {
                      this.searchBookmarkedLocationApiCall();
                    }
                  )
                }
              >
                Bookmarked
              </Typography>
              {this.state.activeTab === "bookmarked" && (
                <Box className="active-border"></Box>
              )}
            </Box>
          </Box>
          {this.state.activeTab === "search-location" && (
            <>
              <SearchBar
                data-test-id="search-id"
                searchText={this.state.searchText}
                onApiCall={(value) => {
                  this.setSearchValue(value);
                }}
              />
              <Typography className="search-result-text">
                Searched Results
              </Typography>
              <Box id="scrollableDiv" className="overflow-items-locations">
                {this.state.searchLocationData.length > 0 ? (
                  this.state.searchLocationData.map((item) => (
                    <ItineraryLocationDetails
                      data-test-id="location-details"
                      key={`${item.formatted_address}`}
                      address={`${item.formatted_address}`}
                      cityName={item.name}
                      imageSrc={this.imageURL(item.photos, item.icon)}
                      addLocationItem={this.addLocationItem}
                    />
                  ))
                ) : (
                  <p className="not-found-text">No data found</p>
                )}
              </Box>
            </>
          )}
          {this.state.activeTab === "bookmarked" && (
            <Box id="scrollableDiv" className="overflow-items-locations">
              <InfiniteScroll
                dataLength={this.state.searchBookmarkedData.length}
                next={this.fetchMoreDataBookMark}
                scrollableTarget="scrollableDiv"
                hasMore={this.state.hasMore}
                loader={<h4>Loading...</h4>}
                style={{
                  height: "unset !important",
                  overflow: "unset !important",
                }}
                className="display-flex"
              >
                {this.state.searchBookmarkedData.length > 0 ? (
                  this.state.searchBookmarkedData.map((item) =>
                    this.bookmarkedDetails(item)
                  )
                ) : (
                  <p className="not-found-text">No data found</p>
                )}
              </InfiniteScroll>
            </Box>
          )}
        </Box>
      </>
    );
  }
}
export default withStyles(ItineraryLocationStyles)(ItineraryLocation);
// Customizable Area End
