import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  TextField,
  FormControlLabel,
  Link,
  OutlinedInput,
  FormControl,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ErrorIcon from "@material-ui/icons/Error";
import { Formik } from "formik";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
// Customizable Area End
// Customizable Area Start
import { dashboard } from "./assets";
import EmailAccountRegistrationControllerBlock, {
  Props,
  configJSON,
} from "./EmailAccountRegistrationControllerBlock.web";
import Loader from "../../../components/src/Loader.web";
import { stateList } from "../../../components/src/utils.web";
import {Line} from './assets';
import Carousel from "react-material-ui-carousel";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const MuiBoxs = styled("div")({
  position: 'absolute',
  width: '100%',
  height: '300px',
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
  "& h3, & h1" : {
    color: '#ffffff',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '56px',
    letterSpacing: '1.8px',
    marginLeft: '70px',
    marginBottom: '0',
    fontFamily: 'Helvetica',
  },
  "& h1": {
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  "& .text-color":{
    color: "#ffffff",
    display: 'block',
  },
});

const MuiContainers = styled("div")({
  position: 'relative',
  "& .Carousel-box": { flex: 1, overflow: "hidden", height: "100%" },
  "& .main-img": {
    height: "100%",
    minHeight: "800px",
    width: "100%",
    objectFit: 'cover'
  }
});
// Customizable Area End
export default class EmailAccountRegistrationByEmail extends EmailAccountRegistrationControllerBlock {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.isLoading} />
        <Grid container style={{ overflowX: "hidden", height: "100%" }}>
          <Grid item xs={12} sm={6} md={6}>
          <MuiContainers>
              <Carousel
                animation="slide"
                swipe={true}
                indicators={false}
                autoPlay
              >
                {this.state.imgData.map((item ,i) => {
                  return (
                    <Box
                      key={i}
                      className="Carousel-box"
                    >
                      <MuiBoxs>
                        <h3>{configJSON.maintitle}</h3>
                        <img src={Line} alt="line-object" width='100%' />
                        <h1>
                          {configJSON[item.data+'11']}
                          <br/>
                          <span className="text-color">
                            {configJSON[item.data+'22']}
                          </span>
                        </h1>
                      </MuiBoxs>
                      <img
                        className="main-img"
                        src={item.img}
                      />
                    </Box>
                  );
                })}
              </Carousel>
                <Box>
                  <Button
                    data-test-id={"btnForLogInScreen"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="explore-button"
                  >
                    Explore
                  </Button>
                </Box>
              </MuiContainers>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="background-color"
            style={{ padding: "80px" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 0px",
              }}
            >
              <div
                test-goBack="go_back"
                className="go-back-button"
                onClick={() => this.navigationFunctuon()}
              >
                <ArrowBackIosIcon className="go-back-button-icon" />
              </div>
              <Typography
                variant="h4"
                component="h2"
                align="left"
                style={{ fontWeight: "bold", marginBottom: "20px" }}
              >
                Sign Up
              </Typography>
              <Typography variant="subtitle1" component="div" align="left">
                Enter your details, We know you will keep it real.
              </Typography>
              <Box sx={{ width: "100%", paddingTop: "30px" }}>
                <Formik
                  initialValues={{
                    term: "",
                    username: "",
                    name: "",
                    email: "",
                    password: "",
                    mobileNumber: "",
                    gender: "",
                    dob: "",
                    state: "",
                    city: "",
                  }}
                  validationSchema={this.validationSchema}
                  onSubmit={(values) => {
                    this.SignUpByEmail(values);
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    handleBlur,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <EmailAccountLoginForm
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        check={() => this.handleClickShowPassword()}
                        checkPassword={this.state.checkPassword}
                        error={this.state.error}
                        errorMsg={this.state.errorMsg}
                        isUserNameExist={this.isUserNameExist}
                        isExistUser={this.state.isExistUser}
                      />
                    </form>
                  )}
                </Formik>
              </Box>
              <Typography variant="subtitle1" component="div" align="center">
                Already have an account?
                <Link
                  href="EmailAccountLoginBlock"
                  style={{ color: "rgb(33, 150, 243" }}
                >
                  {" "}
                  Login
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const EmailAccountLoginForm = ({
  values,
  errors,
  touched,
  handleChange,
  check,
  checkPassword,
  error,
  errorMsg,
  handleBlur,
  isUserNameExist,
  isExistUser,
}: any) => {
  const maxDate = new Date().toISOString().split("T")[0]; // Get today's date in ISO format
  return (
    <>
      <div className="box-shadow-text-field">
        <TextField
          id="name"
          data-test-id="test_name"
          name="name"
          value={values.name}
          className="input-field"
          placeholder="Name"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      {errors.name && touched.name && (
        <p className="error mt-0 mb-20">{errors.name}</p>
      )}
      <div className="box-shadow-text-field">
        <TextField
          id="user_name"
          data-test-id="user_name"
          name="username"
          value={values.username}
          className="input-field"
          placeholder={configJSON.userNameTitle}
          variant="outlined"
          fullWidth
          onChange={(e) => {
            isUserNameExist(e);
            handleChange(e);
          }}
          onBlur={handleBlur}
        />
      </div>
      {errors.username && touched.username && (
        <p className="error mt-0 mb-20">{errors.username}</p>
      )}
      {isExistUser && <p className="error mt-0 mb-20">{isExistUser}</p>}
      <div className="box-shadow-text-field">
        <TextField
          placeholder="Email Address"
          variant="outlined"
          className="input-field"
          fullWidth
          id="email"
          name="email"
          type="email"
          onBlur={handleBlur}
          value={values.email}
          onChange={handleChange}
        />
      </div>
      {errors.email && touched.email && (
        <p className="error mt-0 mb-20">{errors.email}</p>
      )}
      <FormControl
        variant="outlined"
        fullWidth
        className="box-shadow-text-field"
      >
        <OutlinedInput
          id="password"
          name="password"
          placeholder="Password"
          className="input-field"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          type={checkPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment test-id="test-Input-adornment" position="end">
              <IconButton
                test-id="butt_test"
                aria-label="toggle password visibility"
                onClick={check}
                edge="end"
              >
                {checkPassword ? (
                  <VisibilityOffOutlinedIcon style={{ color: "#CBCBCB" }} />
                ) : (
                  <VisibilityOutlinedIcon style={{ color: "#CBCBCB" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {errors.password && touched.password && (
        <p className="error mt-0 mb-20">{errors.password}</p>
      )}
      {(errors?.password === "required" || values.password) && (
        <Box>
          <Box style={{ marginBottom: "10px" }}>
            {(errors?.password === "length" ||
              errors?.password === "letters") && (
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  style={{
                    borderTop: "2px solid rgb(33, 150, 243)",
                    width: "50%",
                  }}
                ></Box>
                <Box
                  style={{ borderTop: "2px solid #ccc", width: "50%" }}
                ></Box>
              </Box>
            )}
            {errors.password === undefined && (
              <Box
                style={{
                  borderTop: "2px solid rgb(33, 150, 243)",
                  width: "100%",
                }}
              ></Box>
            )}
            {errors.password === "required" && (
              <Box style={{ borderTop: "2px solid #ccc", width: "100%" }}></Box>
            )}
          </Box>
          <Box style={{ marginBottom: "15px" }}>
            {errors.password === "length" || errors.password === "required" ? (
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ display: "flex", alignItems: "center", color: "#ccc" }}
              >
                <CloseIcon style={{ marginRight: "10px" }} /> Minimum 8
                characters
              </Typography>
            ) : (
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ display: "flex", alignItems: "center", color: "#000" }}
              >
                <DoneIcon
                  style={{ marginRight: "10px", color: "rgb(33, 150, 243)" }}
                />{" "}
                Minimum 8 characters
              </Typography>
            )}
            {errors.password === "letters" ||
            errors.password === "required" ||
            errors.password === "length" ? (
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ display: "flex", alignItems: "center", color: "#ccc" }}
              >
                <CloseIcon style={{ marginRight: "10px" }} /> At least one
                capital latter
              </Typography>
            ) : (
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ display: "flex", alignItems: "center", color: "#000" }}
              >
                <DoneIcon
                  style={{ marginRight: "10px", color: "rgb(33, 150, 243)" }}
                />{" "}
                At least one capital latter
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <div className="box-shadow-text-field">
        <TextField
          id="mobileNumber"
          name="mobileNumber"
          type="number"
          placeholder="Mobile Number"
          className="input-field"
          variant="outlined"
          value={values.mobileNumber}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      {errors.mobileNumber && touched.mobileNumber && (
        <p className="error mt-0 mb-20">{errors.mobileNumber}</p>
      )}
      <select
        className={`select-field ${
          values.gender ? "black-color" : "placeholder-color"
        }`}
        id="gender"
        name="gender"
        value={values.gender}
        placeholder="Gender"
        onChange={handleChange}
      >
        <option value="" disabled={true} className="placeholder-color">
          {configJSON.gender1}
        </option>
        <option value="male" className="black-color">
          {configJSON.gender2}
        </option>
        <option value="female" className="black-color">
          {configJSON.gender3}
        </option>
        <option value="other" className="black-color">
          {configJSON.gender4}
        </option>
      </select>
      {errors.gender && touched.gender && (
        <p className="error mt-0 mb-20">{errors.gender}</p>
      )}
      <div className="box-shadow-text-field">
        <TextField
          id="dob"
          name="dob"
          type="date"
          placeholder="Birthday"
          variant="outlined"
          className="input-field"
          fullWidth
          value={values.dob}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ max: maxDate }}
        />
      </div>
      {errors.dob && touched.dob && (
        <p className="error mt-0 mb-20">{errors.dob}</p>
      )}
      <select
        className={`select-field ${
          values.state ? "black-color" : "placeholder-color"
        }`}
        id="state"
        name="state"
        value={values.state}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <option value="" disabled={true} className="placeholder-color">
          State
        </option>
        {stateList.sort().map((item) => (
          <option value={item} className="black-color">
            {item}
          </option>
        ))}
      </select>
      {errors.state && touched.state && (
        <p className="error mt-0 mb-20">{errors.state}</p>
      )}
      <div className="box-shadow-text-field">
        <TextField
          id="city"
          name="city"
          type="text"
          placeholder="City / Town"
          variant="outlined"
          className="input-field"
          fullWidth
          value={values.city}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
      {errors.city && touched.city && (
        <p className="error mt-0 mb-20">{errors.city}</p>
      )}
      {error && (
        <Typography
          variant="subtitle1"
          component="div"
          align="left"
          style={{ color: "red", display: "flex", paddingBottom: "15px" }}
        >
          <ErrorIcon style={{ marginRight: "8px" }} /> {errorMsg}
        </Typography>
      )}{" "}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "40px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="term"
              name="term"
              value={values.term}
              onChange={handleChange}
              style={{ color: "green" }}
            />
          }
          style={{
            color: "#353249",
            fontFamily: "Helvetica",
            fontSize: "14px",
          }}
          label="By Proceeding you agree to our Terms & conditions & Privacy Policy of lesser"
        />
      </Box>
      <Button
        test-handleSubmit="test-handleSubmit"
        style={{
          background: "rgb(33, 150, 243)",
          marginBottom: "30px",
          padding: "18px",
          textTransform: "none",
        }}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        Submit
      </Button>
    </>
  );
};
// Customizable Area End
