Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProductQuickview";
exports.labelBodyText = "ProductQuickview Body";
exports.getProductListAPiEndPoint = "/bx_block_products/products?per=15&page=1";
exports.categoryApiContentType = "application/json";
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
