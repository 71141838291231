// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import DataContext from "../../../components/src/DataContext";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isLoading: boolean;
  isLogin: boolean;
}

interface SS {
  id: any;
}

export default class ItinerarySectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType: React.Context<string> = DataContext
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
        isLoading: false,
        isLogin: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleLoginModal =() =>{
    this.setState({isLogin:!this.state.isLogin})
  }

}

// Customizable Area End