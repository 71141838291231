// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../../../components/src/utils.web";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  hotelList: {
    id: string;
    type: string;
    attributes: {
      item_cover_image: string;
      name: string;
      location: string;
      reviews_count: string;
      rating: string;
      tag_line: string;
    };
  }[];
  categoryId: string;
  isLoading: boolean;
  page: number;
  limit: number;
  total_page: number;
  hasMore: boolean;
  exploreId: string;
  cityName: string;
  category_name: string;
  itemName: string;
}

interface SS {
  id: any;
}

export default class HotelListController extends BlockComponent<Props, S, SS> {
  hotelListApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
      hotelList: [],
      page: 1,
      total_page: 1,
      hasMore: false,
      limit: 20,
      exploreId: "",
      cityName: "",
      categoryId: "",
      category_name: "",
      itemName: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const exploreId = this.props.navigation.getParam("id");
    const categoryId = this.props.navigation.getParam("categoryId");
    const cityName = this.props.navigation.history?.location?.state?.cityName;
    const itemName = this.props.navigation.history?.location?.state?.itemName;
    this.setState(
      {
        exploreId,
        cityName: cityName ?? "",
        isLoading: true,
        categoryId,
        itemName: itemName ?? "",
      },
      async () => {
        await this.getHotelListDetails();
      }
    );
  }

  getHotelListDetails = async () => {
    this.hotelListApiCallId = await apiCall({
      endPoint: `bx_block_explore/explore_items/?explore_location_id=${this.state.exploreId}&explore_category_id=${this.state.categoryId}&page=${this.state.page}&per_page=${this.state.limit}`,
      method: "GET",
    });
  };

  fetchMoreData = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      async () => {
        await this.getHotelListDetails();
      }
    );
  };

  redirectToHospitalDetailPage = (id: string) => {
    this.props.navigation.history.push(
      `/explore/${this.state.exploreId}/${this.state.category_name}/${id}`
    );
  };

  redirectToLoginPage = (errors: any) => {
    toast.error(`${errors[0].token}, Please login again.`);
    this.props.navigation.navigate("EmailAccountLoginBlock");
    return;
  };

  handleErrorHotel = (errors: any) => {
    if (Array.isArray(errors)) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        this.redirectToLoginPage(errors);
      } else if (errors[0]) {
        toast.error(errors[0]);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.goBack();
        return;
      }
    }
    toast.error("Something went to wrong please retry");
  };

  handleResponse = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.hotelListApiCallId && responseJson.items) {
      const total_pages = responseJson.items.meta.total_pages;
      const hasMore =
        this.state.page < responseJson.items.meta.total_pages ? true : false;

      this.setState({
        category_name: responseJson.category_name,
        hotelList: [...this.state.hotelList, ...responseJson.items.data],
        hasMore,
        total_page: total_pages,
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId && responseJson.errors) {
        this.handleErrorHotel(responseJson.errors);
      }
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        this.handleResponse(apiRequestCallId, responseJson);
      }

      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
