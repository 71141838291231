// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../../../components/src/utils.web";
import * as Yup from "yup";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  item_cover_image: string;
  isLoading: boolean;
  location: string;
  reviews_count: number;
  hotelId: string;
  rating: number;
  description: string;
  name: string;
  book_mark_title: string;
  book_mark_description: string;
  book_mark_id: string;
  itemImage: string;
  isLocation: boolean;
  exploreImage: string;
  exploreDescription: string;
  exploreLocationName: string;
}

interface SS {
  id: any;
}

export default class HotelBookMarkController extends BlockComponent<
  Props,
  S,
  SS
> {
  hotelDetailsApiCallId: string = "";
  bookMarkApiCallId: string = "";
  singleBookMarkApiCallId: string = "";
  exploreDetailsBookMarkApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      itemImage: "",
      isLoading: false,
      location: "",
      reviews_count: 0,
      hotelId: "",
      rating: 0,
      description: "",
      name: "",
      book_mark_description: "",
      book_mark_title: "",
      book_mark_id: "",
      item_cover_image: "",
      isLocation: true,
      exploreImage: "",
      exploreLocationName: "",
      exploreDescription: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const hotelId = this.props.navigation.getParam("hotelId");
    const bookMarkId = this.props.navigation.getParam("bookmarkId");
    const isLocation =
      this.props.navigation.history?.location?.state?.isLocation;
    this.setState(
      {
        hotelId: hotelId,
        book_mark_id: bookMarkId ?? "",
        isLocation: isLocation ?? false,
      },
      async () => {
        isLocation
          ? await this.getExploreDetailsInBookMark()
          : await this.getHotelDetails();
        this.state.book_mark_id && (await this.getBookMarkDetails());
      }
    );
  }

  getHotelDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.hotelDetailsApiCallId = await apiCall({
      endPoint: `bx_block_explore/explore_items/${this.state.hotelId}`,
      method: "GET",
    });
  };

  getExploreDetailsInBookMark = async () => {
    this.setState({
      isLoading: true,
    });
    this.exploreDetailsBookMarkApiCallId = await apiCall({
      endPoint: `bx_block_explore/explore_locations/${this.state.hotelId}`,
      method: "GET",
    });
  };

  getBookMarkDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.singleBookMarkApiCallId = await apiCall({
      endPoint: `bx_block_content_management/bookmarks/${this.state.book_mark_id}`,
      method: "GET",
    });
  };

  bookMarkApiCall = async (value: { title: string; description: string }) => {
    this.setState({
      isLoading: true,
    });
    const type = this.state.isLocation
      ? "BxBlockExplore::ExploreLocation"
      : "BxBlockExplore::ExploreItem";
    const data = this.state.book_mark_id
      ? {
        data: {
          attributes: {
            bookmarkable_type: type,
            title: value.title,
            description: value.description,
          },
        },
      }
      : {
        data: {
          attributes: {
            bookmarkable_type: type,
            bookmarkable_id: this.state.hotelId,
            title: value.title,
            description: value.description,
          },
        },
      };
    this.bookMarkApiCallId = this.state.book_mark_id
      ? await apiCall({
        endPoint: `bx_block_content_management/bookmarks/${this.state.book_mark_id}`,
        method: "PUT",
        contentType: "application/json",
        body: JSON.stringify(data),
      })
      : await apiCall({
        endPoint: `bx_block_content_management/bookmarks`,
        method: "POST",
        contentType: "application/json",
        body: JSON.stringify(data),
      });
  };

  formValidationSchema = Yup.object().shape({
    title: Yup.string().trim().required("Title is required"),
    description: Yup.string().trim().required("Description is required"),
  });

  redirectToGoBackPage = (errors: any) => {
    toast.error(errors[0]);
    this.props.navigation.history.goBack();
    return;
  };

  handleErrorBookMark = (errors: any) => {
    if (Array.isArray(errors)) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}, Please login again.`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      } else if (errors[0]) {
        this.redirectToGoBackPage(errors);
      }
    } else toast.error("Something went to wrong please retry");
  };

  handleResponseBookMark = async (apiRequestCallId: any, responseJson: any) => {
    if (
      apiRequestCallId === this.hotelDetailsApiCallId &&
      responseJson.items.data
    ) {
      const data = responseJson.items.data.attributes;
      this.setState({
        itemImage: data.item_cover_image,
        location: data.location,
        name: data.name,
        reviews_count: data.reviews_count,
        rating: data.rating,
        description: data.description,
      });
    } else if (
      apiRequestCallId === this.bookMarkApiCallId &&
      responseJson.data
    ) {
      const message = this.state.isLocation ? "Explore" : "Hotel";
      toast.success(
        this.state.book_mark_id
          ? `${message} bookmarked edited successfully`
          : `${message} bookmarked created successfully`
      );
      this.props.navigation.history.goBack();
    } else if (
      apiRequestCallId === this.singleBookMarkApiCallId &&
      responseJson.data
    ) {
      this.setState({
        book_mark_title: responseJson.data.attributes.title,
        book_mark_description: responseJson.data.attributes.description,
      });
    } else if (
      apiRequestCallId === this.exploreDetailsBookMarkApiCallId &&
      responseJson.data
    ) {
      const data = responseJson.data;
      this.setState({
        exploreImage: data.attributes.location_images[0].url,
        exploreLocationName: data.attributes.city_name,
        exploreDescription: data.attributes.description,
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        apiRequestCallId &&
        (responseJson.errors || responseJson.message) &&
        !(responseJson.items || responseJson.data)
      ) {
        this.handleErrorBookMark(responseJson.errors ?? responseJson.message);
      }
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        await this.handleResponseBookMark(apiRequestCallId, responseJson);
      }

      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
