// Customizable Area Start
import React from "react";

import {
  TextField,
  withStyles,
  Box,
  Typography,
  TextareaAutosize,
  Button,
  Chip,
} from "@material-ui/core";

import { Formik } from "formik";
import PostCreateController, { Props } from "./PostCreateController.web";
import { PostCreateStyles } from "./PostCreateStyles.web";
import { addIcon } from "./assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ClearIcon from "@material-ui/icons/Clear";
import CancelIcon from "@material-ui/icons/Cancel";
import Loader from "../../../components/src/Loader.web";
import PostOrItineraryContainer from "../../../components/src/PostOrItineraryContainer.web";
import GooglePlacesAutocomplete, {
  geocodeByAddress, getLatLng
} from "react-google-places-autocomplete";

const videoFormat = ["video/mp4", "video", "video/webm", "video/ogg"];

class PostCreate extends PostCreateController {
  constructor(props: Props) {
    super(props);
  }

  title = () => {
    return this.state.post_id ? "Edit Post" : "Create New";
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <PostOrItineraryContainer
          title={this.title()}
          navigation={this.props.navigation}
        >
          <Box className={classes.PostCreate}>
            <Box className="right-side-main-container post-right-side-component">
              {!this.state.openTagPage && (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    title: this.state.title,
                    description: this.state.description,
                    location: this.state.location,
                    images: this.state.images,
                    tags: this.state.selectedTags,
                    state: this.state.state,
                  }}
                  validationSchema={this.validationSchema}
                  onSubmit={(value) => {
                    this.handleSubmitData(value);
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    setFieldValue,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Typography
                          variant="h4"
                          className="right-side-header post-right-side-header"
                        >
                          Enter following details
                        </Typography>
                        <div
                          style={{
                            boxShadow: "3px 5px 11px rgba(165,176,194,0.15)",
                            borderRadius: 16,
                            marginBottom: 20,
                          }}
                        >
                          <TextField
                            className="input-field"
                            value={values.title}
                            onChange={(e) => {
                              setFieldValue("title", e.target.value);
                              this.setState({ title: e.target.value });
                            }}
                            variant="outlined"
                            placeholder="Title"
                            fullWidth
                            id="title"
                            name="title"
                            type="text"
                          />
                        </div>
                        {errors.title && touched.title && (
                          <p className="error" style={{ marginBottom: "20px" }}>
                            {errors.title}
                          </p>
                        )}
                        <div
                          style={{
                            boxShadow: "3px 5px 11px rgba(165,176,194,0.15)",
                            borderRadius: 16,
                            marginBottom: 20,
                          }}
                        >
                          <TextareaAutosize
                            className="text-area-field"
                            minRows={5}
                            placeholder="Description"
                            name="description"
                            value={values.description}
                            onChange={(e) => {
                              setFieldValue("description", e.target.value);
                              this.setState({
                                description: e.target.value,
                              });
                            }}
                          />
                        </div>
                        {errors.description && touched.description && (
                          <p className="error" style={{ marginBottom: "20px" }}>
                            {errors.description}
                          </p>
                        )}
                        <div
                          style={{
                            marginBottom: 20,
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 20,
                          }}
                        >
                          {values.images.length != 0 &&
                            values.images.map((item, index) => {
                              return (
                                <div className="uploaded-image" key={index}>
                                  <CancelIcon
                                    onClick={() => {
                                      const remainImage = values.images.filter(
                                        (img: any, idx: number) => idx !== index
                                      );
                                      this.setState({
                                        images: remainImage,
                                      });
                                      setFieldValue("images", remainImage);
                                    }}
                                  />
                                  {videoFormat.includes(item.type) ? (
                                    <video autoPlay src={item.url} muted/>
                                  ) : (
                                    <img src={item.url} />
                                  )}
                                </div>
                              );
                            })}

                          <div
                            className="upload-image-container"
                            onClick={() => {
                              this.upload.click();
                            }}
                          >
                            <img src={addIcon} className="add-image" />
                          </div>
                          <input
                            id="myInput"
                            type="file"
                            ref={(ref) => (this.upload = ref)}
                            style={{ display: "none" }}
                            onChange={(e: any) => {
                              for (const element of e.target.files) {
                                const file = element;
                                let reader = new FileReader();
                                reader.onloadend = () =>
                                  this.readerOnLoaded(
                                    values,
                                    file,
                                    reader,
                                    setFieldValue
                                  );
                                reader.readAsDataURL(file);
                              }
                            }}
                            accept="image/png, image/jpg, image/jpeg, video/mp4,video/webm,video/ogg"
                            multiple
                          />
                        </div>
                        {errors.images && touched.images && (
                          <p className="error" style={{ marginBottom: 20 }}>
                            {errors.images}
                          </p>
                        )}
                        <div
                          style={{
                            boxShadow: "3px 5px 11px rgba(165,176,194,0.15)",
                            borderRadius: 16,
                            marginBottom: 20,
                          }}
                        >
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY"
                            debounce={500}
                            selectProps={{
                              isClearable: true,
                              value: this.state.location
                                ? [
                                  {
                                    label: this.state.location,
                                    value: this.state.location,
                                  },
                                ]
                                : undefined,
                              classNamePrefix: "react-select",
                              className: "auto-fill-text-field",
                              placeholder: "Add Location",
                              noOptionsMessage: () => null,
                              onChange: async (event: any) => {
                                const location =
                                  event?.value?.description ?? "";
                                setFieldValue("location", location);
                                this.setState({
                                  location: location,
                                });
                                
                                let state: any;
                                let latLng:{lat:number, lng:number}={lat:0, lng:0};
                                if (location) {
                                  const data = await geocodeByAddress(location);
                                  latLng = await getLatLng(data[0]);
                                  if (data) {
                                    state = this.getStateName(data);
                                  }
                                }
                                setFieldValue(
                                  "state",
                                  state?.long_name ?? location
                                );
                                this.setState({
                                  state: state?.long_name ?? location,
                                  lat: latLng.lat,
                                  log: latLng.lng
                                });
                              },
                            }}
                          />
                        </div>
                        {errors.location && touched.location && (
                          <p className="error" style={{ marginTop: "20px" }}>
                            {errors.location}
                          </p>
                        )}
                        <div
                          style={{
                            boxShadow: "3px 5px 11px rgba(165,176,194,0.15)",
                            borderRadius: 16,
                            marginBottom: 20,
                          }}
                          onClick={this.openOrCloseTagPage}
                        >
                          <div className="text-field-outside-box chip-item-container">
                            {values.tags.length != 0 ? (
                              values.tags.map((item) => {
                                return (
                                  <Chip
                                    key={`${item.attributes.name}${item.id}`}
                                    label={item.attributes.name}
                                    className="selected-chip"
                                    deleteIcon={<ClearIcon />}
                                    onDelete={() => {
                                      if (
                                        values.tags.some(
                                          (tag) => tag.id === item.id
                                        )
                                      ) {
                                        const tag = values.tags.filter(
                                          (c) => c.id !== item.id
                                        );
                                        setFieldValue("tags", tag);
                                        this.setState({
                                          selectedTags: tag,
                                        });
                                      }
                                    }}
                                  />
                                );
                              })
                            ) : (
                              <p className="place-holder">Add Tags</p>
                            )}
                          </div>
                        </div>
                        {errors.tags && touched.tags && (
                          <p className="error" style={{ marginTop: "20px" }}>
                            {errors.tags}
                          </p>
                        )}
                        <Button
                          variant="contained"
                          className="published-button"
                          fullWidth
                          type="submit"
                        >
                          Publish Now
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              )}
              {this.state.openTagPage && (
                <div className="add-Tags">
                  <Button
                    variant="contained"
                    className="back-button"
                    onClick={this.openOrCloseTagPage}
                  >
                    <ArrowBackIosIcon className="back-button-image" /> Back
                  </Button>
                  <Typography variant="h4" className="main-heading">
                    Select Tags
                  </Typography>

                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      tags: this.state.selectedTags,
                    }}
                    validationSchema={this.validationTagsFormSchema}
                    onSubmit={(value) => {
                      this.tagsValuesSave(value.tags);
                    }}
                  >
                    {({
                      touched,
                      errors,
                      values,
                      handleSubmit,
                      setFieldValue,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="tag-items">
                            {this.state.tags.length > 0 ? (
                              this.state.tags.map((data) => {
                                return (
                                  <>
                                    <Typography
                                      variant="h5"
                                      className="tag-heading"
                                    >
                                      {data.attributes.name}
                                    </Typography>
                                    <div className="chip-item-container">
                                      {data.attributes.activities.data.map(
                                        (item) => (
                                          <Chip
                                            label={item.attributes.name}
                                            key={`${item.attributes.name}${item.id}`}
                                            onClick={() => {
                                              if (
                                                values.tags.some(
                                                  (tag) => tag.id === item.id
                                                )
                                              ) {
                                                setFieldValue(
                                                  "tags",
                                                  values.tags.filter(
                                                    (c) => c.id !== item.id
                                                  )
                                                );
                                              } else {
                                                const tag = {
                                                  ...item,
                                                  parentId: data.id,
                                                };
                                                setFieldValue("tags", [
                                                  ...values.tags,
                                                  tag,
                                                ]);
                                              }
                                            }}
                                            className={
                                              values.tags.findIndex(
                                                (tag) => tag.id === item.id
                                              ) != -1
                                                ? "selected-chip"
                                                : "un-selected-chip"
                                            }
                                          />
                                        )
                                      )}
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <p className="not-found-text">No tags found</p>
                            )}
                          </div>

                          {errors.tags && touched.tags && (
                            <p
                              className="error"
                              style={{
                                marginTop: "20px",
                                marginBottom: "15px",
                              }}
                            >
                              {errors.tags}
                            </p>
                          )}
                          <Button
                            variant="contained"
                            className="published-button"
                            fullWidth
                            type="submit"
                          >
                            Continue
                          </Button>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              )}
            </Box>
          </Box>
        </PostOrItineraryContainer>
      </>
    );
  }
}
export default withStyles(PostCreateStyles)(PostCreate);
// Customizable Area End
