import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils.web";
import DataContext from "../../../components/src/DataContext";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface IExploreLocation {
  id: string;
  type: string;
  attributes: {
    id: number;
    account_id: number;
    bookmarkable_id: number;
    bookmarkable_type: string;
    title: string;
    description: string;
    bookmarked_item: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          city_name: string;
          location_images: {
            id: number;
            filename: string;
            type: string;
            url: string;
          }[];
          description: string;
          latitude: number;
          longitude: number;
          categories: {
            data: {
              id: string;
              type: string;
              attributes: {
                id: number;
                name: string;
                category_cover_image: string;
              };
            }[];
          };
        };
      };
    };
  };
}

export interface IPost {
  id: string;
  type: string;
  attributes: {
    id: number;
    account_id: number;
    bookmarkable_id: number;
    bookmarkable_type: string;
    title: string;
    description: string;
    bookmarked_item: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          description: string;
          location: string;
          state: string;
          account_id: number;
          admin_user_id: string;
          created_at: string;
          uploader_name: string;
          uploader_image: string;
          comments: number;
          images_and_videos: {
            id: number;
            filename: string;
            type: string;
            url: string;
          }[];
        };
      };
    };
  };
}

export interface IExploreItems {
  id: string;
  type: string;
  attributes: {
    id: number;
    account_id: number;
    bookmarkable_id: number;
    bookmarkable_type: string;
    title: string;
    description: string;
    bookmarked_item: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          item_cover_image: string;
          name: string;
          location: string;
          tag_line: string;
          reviews_count: number;
          rating: number;
          explore_location_id: number;
          explore_category_id: number;
        };
      };
    };
  };
}

interface S {
  isLoading: boolean;
  currentPage: number;
  bookmarks: (IExploreItems | IExploreLocation | IPost)[];
  isMoreData: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyBookmarkController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End
  getAllBookmarksId = "";
  deleteBookMarkApiCallId = "";
  hasMoreBookmark = "";
  static contextType: React.Context<string> = DataContext;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      isLoading: false,
      currentPage: 1,
      bookmarks: [],
      isMoreData: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getAllBookmarks();
  }

  getAllBookmarks = async () => {
    this.setState({
      isLoading: true,
    });
    this.getAllBookmarksId = await apiCall({
      endPoint:
        configJSON.getAllBookmarksEndPoint +
        `?page=${this.state.currentPage}&per_page=10`,
      method: "GET",
      contentType: "application/json",
    });
  };

  dummyAddLocationItem = () => {};

  deleteBookMarkedItem = async (id: string) => {
    this.setState({
      isLoading: true,
    });
    this.deleteBookMarkApiCallId = await apiCall({
      endPoint: configJSON.deleteBookmarkApiEndPoint + id,
      method: "DELETE",
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson.errors) {
        this.handleError(responseJson.errors);
      }

      switch (apiRequestCallId) {
        case this.getAllBookmarksId:
          this.handleBookmarks(responseJson);
          break;
        case this.hasMoreBookmark:
          this.handleMoreBookmark(responseJson);
          break;
        case this.deleteBookMarkApiCallId:
          this.setState({
            isLoading: false,
          });
          this.getAllBookmarks();
          if (responseJson.message === configJSON.deleteBookmarkTitle) {
            toast.success(responseJson.message);
          } else {
            toast.error(responseJson.message);
          }
          break;
      }
    }
  }

  redirectToPostBookMarkPage = (bookmarkable_id: number, id: string) => {
    if (this.context && Object.keys(this.context).length !== 0) {
      this.context.setData({
        isMyBookmark: true,
      });
    }
    this.props.navigation.history.push(`/Bookmark2/${bookmarkable_id}/${id}`);
  };

  handleMoreBookmark = (responseJson: { data: [] }) => {
    if (responseJson.data.length) {
      const hasMore = responseJson.data.length < 10 ? false : true;
      this.setState({
        bookmarks: [...this.state.bookmarks, ...responseJson.data],
        isMoreData: hasMore,
      });
    } else {
      this.setState({
        isMoreData: false,
      });
    }
  };

  hasMoreBookmarks = async () => {
    this.setState({
      currentPage: this.state.currentPage + 1,
    });

    this.hasMoreBookmark = await apiCall({
      endPoint:
        configJSON.getAllBookmarksEndPoint +
        `?page=${this.state.currentPage}&per_page=10`,
      method: "GET",
      contentType: "application/json",
    });
  };

  redirectToExploreLocationBookMarkPage = (
    bookmarkable_id: number,
    id: string
  ) => {
    if (this.context && Object.keys(this.context).length !== 0) {
      this.context.setData({
        isMyBookmark: true,
      });
    }
    this.props.navigation.history.push(`/${bookmarkable_id}/bookmark/${id}`);
  };

  redirectToExploreItemsBookMarkPage = (
    bookmarkable_id: number,
    id: string
  ) => {
    if (this.context && Object.keys(this.context).length !== 0) {
      this.context.setData({
        isMyBookmark: true,
      });
    }
    this.props.navigation.history.push(`/${bookmarkable_id}/bookmark/${id}`);
  };

  handleBookmarks = (responseJson: { data: [] }) => {
    this.setState({
      isLoading: false,
    });
    if (responseJson.data.length) {
      const hasMore = responseJson.data.length < 10 ? false : true;

      this.setState({
        isMoreData: hasMore,
        bookmarks: responseJson.data,
      });
    }
  };

  handleError = (errors: { token: string }[]) => {
    const error = errors?.length;
    if (Array.isArray(errors) && error) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}${configJSON.errormassage2}`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }
    }
  };
  // Customizable Area Start
  redirectToRatingDetailPage = (attributes: any) => {
    if (this.context && Object.keys(this.context).length !== 0) {
      this.context.setData({
        isMyBookmark: true,
      });
    }

    this.props.navigation.history.push(`/${attributes?.id}/rating`);
  };
  // Customizable Area End
}
