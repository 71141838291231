// Customizable Area Start
import React from "react";

import { withStyles, Box, Typography } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";

import HotelListController, { Props } from "./HotelController.web";
import { HotelListStyles } from "./Hotel.styles.web";
import Loader from "../../../../components/src/Loader.web";
import BackButton from "../../../../components/src/BackButton.web";
import HotelDetailShow from "../../../../components/src/HotelDetailShow.web";

class HotelList extends HotelListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.HotelList}>
          <BackButton navigation={this.props.navigation} />
          <Typography className="page-title">
            All {this.state.itemName}s in {this.state.cityName}
          </Typography>
          <div
            id="scrollableDiv"
            style={{
              height: "calc(100% - 180px)",
              paddingRight: "10px",
              overflowY: "auto",
            }}
          >
            {this.state.hotelList.length > 0 ? (
              <InfiniteScroll
                dataLength={this.state.hotelList.length} //This is important field to render the next data
                next={this.fetchMoreData}
                scrollableTarget="scrollableDiv"
                hasMore={this.state.hasMore}
                loader={<h4>Loading...</h4>}
              >
                {this.state.hotelList.map((item) => (
                  <HotelDetailShow
                    onClick={() => this.redirectToHospitalDetailPage(item.id)}
                    key={item.id}
                    description={item.attributes.tag_line}
                    hotelImage={item.attributes.item_cover_image}
                    hotelName={item.attributes.name}
                    location={item.attributes.location}
                    rating={item.attributes.rating}
                    reviewCount={item.attributes.reviews_count}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <p className="not-found-text">
                No {this.state.itemName} detail available
              </p>
            )}
          </div>
        </Box>
      </>
    );
  }
}
//@ts-ignore
export default withStyles(HotelListStyles)(HotelList);
// Customizable Area End
