// Customizable Area Start
import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";

interface Props {
  classes: any;
  open: boolean;
  imageSrc: string;
  title: string;
  description?: string;
  onClose: () => void;
}

interface S {}

class VerificationModal extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, open, imageSrc, title, description, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes?.Modal}
        maxWidth="xs"
        fullWidth
      >
        <CloseIcon
          onClick={() => onClose()}
          style={{
            color: "#25AAE1",
            position: "absolute",
            top: "15px",
            right: "15px",
            cursor: "pointer",
          }}
        />
        <img className="modal-image" src={imageSrc} alt="image" />
        <Typography className="modal-title-text">{title}</Typography>
        {description && (
          <Typography className="modal-description-text">
            {description}
          </Typography>
        )}
      </Dialog>
    );
  }
}

const ModalStyles = {
  Modal: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      justifyContent: "center",
      alignItems: "center",
      padding: "40px",
      boxSizing: "border-box",
    },
    "& .modal-image": {
      height: 196,
      width: 196,
    },
    "& .modal-title-text": {
      fontFamily: "Helvetica-Bold",
      fontSize: "32px",
      fontWeight: "bold",
      lineHeight: "39px",
      textAlign: "center",
    },
    "& .modal-description-text": {
      color: "#818181",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "27px",
      textAlign: "center",
      marginTop: "10px",
    },
  },
};

export default withStyles(ModalStyles)(VerificationModal);
// Customizable Area End
