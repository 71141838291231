// Customizable Area Start
import React from "react";

import {
  withStyles,
  Box,
  Typography,
  Grid,
  TextField,
  TextareaAutosize,
  Button,
  Container,
} from "@material-ui/core";

import HotelRatingController, { Props } from "./HotelBookMarkController.web";
import { BookMarkStyles } from "./HotelBookMark.styles.web";
import Loader from "../../../../components/src/Loader.web";
import BackButton from "../../../../components/src/BackButton.web";
import HotelDetailShow from "../../../../components/src/HotelDetailShow.web";
import { Formik } from "formik";
import ExploreLocationDetails from "../../../../components/src/ExploreLocationDetails.web";

class HotelBookMark extends HotelRatingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.BookMark}>
          <BackButton navigation={this.props.navigation} />
          <Typography className="page-title">Bookmark</Typography>
          <Container maxWidth="lg" className="detail-container">
            <Grid container spacing={2}>
              <Grid item md={7} sm={6}>
                {!this.state.isLoading &&
                  (this.state.isLocation ? (
                    <ExploreLocationDetails
                      description={this.state.exploreDescription}
                      exploreImage={this.state.exploreImage}
                      exploreName={this.state.exploreLocationName}
                    />
                  ) : (
                    <HotelDetailShow
                      isBookMark
                      location={this.state.location}
                      description={this.state.description}
                      rating={String(this.state.rating)}
                      hotelImage={this.state.itemImage}
                      reviewCount={String(this.state.reviews_count)}
                      hotelName={this.state.name}
                    />
                  ))}
              </Grid>
              <Grid item md={5} sm={6}>
                <Formik
                  initialValues={{
                    title: this.state.book_mark_title,
                    description: this.state.book_mark_description,
                  }}
                  enableReinitialize
                  onSubmit={(value) => this.bookMarkApiCall(value)}
                  validationSchema={this.formValidationSchema}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="white-box-container">
                          <div className="box-shadow-text-field">
                            <TextField
                              placeholder="Title"
                              variant="outlined"
                              className="input-field"
                              fullWidth
                              id="title"
                              name="title"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.title && touched.title && (
                            <p className="error mb-20">{errors.title}</p>
                          )}
                          <div className="box-shadow-text-field">
                            <TextareaAutosize
                              className="text-area-field"
                              minRows={5}
                              placeholder="Description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="description"
                            />
                          </div>
                          {errors.description && touched.description && (
                            <p className="error mb-20">{errors.description}</p>
                          )}
                          <Button
                            fullWidth
                            className="bookmark-button"
                            variant="contained"
                            type="submit"
                          >
                            Bookmark Now
                          </Button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
}
//@ts-ignore
export default withStyles(BookMarkStyles)(HotelBookMark);
// Customizable Area End
