import React from "react";

import {
  Box,
  // Customizable Area Start
  styled,
  // Customizable Area End
} from "@material-ui/core";

import MyBookmarkController, {
  Props,
  configJSON,
  IExploreItems,
  IExploreLocation,
  IPost,
} from "./MyBookmarkController.web";
import ItineraryBookmarkDetails from "../../../components/src/ItineraryBookmarkDetails.web";
import ItineraryBookmarkUserDetails from "../../../components/src/ItineraryBookmarkUserDetails.web";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/src/Loader.web";

export default class MyBookmark extends MyBookmarkController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  postBookMarkedDetails = (item: IPost) => {
    return (
      <ItineraryBookmarkUserDetails
        isDataAddable={false}
        id={item.id}
        bookmarkable_id={item.attributes.bookmarkable_id}
        descriptionBookmark={item.attributes.title}
        descriptionPost={
          item.attributes.bookmarked_item.data.attributes.description
        }
        images_and_videos={
          item.attributes.bookmarked_item.data.attributes.images_and_videos
        }
        location={item.attributes.bookmarked_item.data.attributes.location}
        name={item.attributes.bookmarked_item.data.attributes.uploader_name}
        userImage={
          item.attributes.bookmarked_item.data.attributes.uploader_image
        }
        recentTime={item.attributes.bookmarked_item.data.attributes.created_at}
        onDeleteItem={this.deleteBookMarkedItem}
        onEditItem={this.redirectToPostBookMarkPage}
        addLocationItem={this.dummyAddLocationItem}
      />
    );
  };

  locationBookMarkedDetails = (item: IExploreLocation) => {
    return (
      <ItineraryBookmarkDetails
        isDataAddable={false}
        id={item.id}
        bookmarkable_id={item.attributes.bookmarkable_id}
        descriptionBookmark={item.attributes.title}
        descriptionExploreItems={
          item.attributes.bookmarked_item.data.attributes.description
        }
        imageSrc={
          item.attributes.bookmarked_item.data.attributes.location_images[0].url
        }
        city_name={item.attributes.bookmarked_item.data.attributes.city_name}
        onDeleteItem={this.deleteBookMarkedItem}
        onEditItem={this.redirectToExploreLocationBookMarkPage}
        onRedirectRating={this.redirectToRatingDetailPage.bind(
          this,
          item.attributes.bookmarked_item?.data?.attributes
        )}
        addLocationItem={this.dummyAddLocationItem}
      />
    );
  };

  exploreItemsBookMarkedDetails = (item: IExploreItems) => {
    return (
      <ItineraryBookmarkDetails
        isDataAddable={false}
        id={item.id}
        bookmarkable_id={item.attributes.bookmarkable_id}
        descriptionBookmark={item.attributes.title}
        descriptionExploreItems={
          item.attributes.bookmarked_item.data.attributes.tag_line
        }
        exploreItemsName={item.attributes.bookmarked_item.data.attributes.name}
        location={item.attributes.bookmarked_item.data.attributes.location}
        ratings={item.attributes.bookmarked_item.data.attributes.rating}
        totalReviews={
          item.attributes.bookmarked_item.data.attributes.reviews_count
        }
        imageSrc={
          item.attributes.bookmarked_item.data.attributes.item_cover_image
        }
        onDeleteItem={this.deleteBookMarkedItem}
        onEditItem={this.redirectToExploreItemsBookMarkPage}
        onRedirectRating={this.redirectToRatingDetailPage.bind(
          this,
          item.attributes.bookmarked_item?.data?.attributes
        )}
        addLocationItem={this.dummyAddLocationItem}
      />
    );
  };

  bookmarkedDetails = (item: IExploreItems | IExploreLocation | IPost) => {
    switch (item.attributes.bookmarkable_type) {
      case configJSON.PostType:
        return this.postBookMarkedDetails(item as IPost);
      case configJSON.ExploreLocationType:
        return this.locationBookMarkedDetails(item as IExploreLocation);
      case configJSON.ExploreItemType:
        return this.exploreItemsBookMarkedDetails(item as IExploreItems);
      default:
        break;
    }
  };

  render() {
    return (
      // Customizable Area Start
      <WrapperContainer id="scrollableDiv">
        <Loader loading={this.state.isLoading} />
        <InfiniteScroll
          dataLength={this.state.bookmarks.length}
          next={this.hasMoreBookmarks}
          scrollableTarget="scrollableDiv"
          hasMore={this.state.isMoreData}
          loader={<h4>{configJSON.loadingTitle}</h4>}
        >
          {this.state.bookmarks.length
            ? this.state.bookmarks.map((item) => {
                return (
                  <div style={{ marginBottom: "10px" }}>
                    {this.bookmarkedDetails(item)}
                  </div>
                );
              })
            : configJSON.noDataFoundError}
        </InfiniteScroll>
      </WrapperContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const WrapperContainer = styled(Box)({
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  background: "#FFFFFF",
  height: "90%",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  borderRadius: "20px",
  overflow: "auto",
});
// Customizable Area End
