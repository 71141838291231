import React, { CSSProperties } from "react";

import {
  Box,
  Typography,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import RightArrowIcon from "@material-ui/icons/ChevronRight";
// Customizable Area End

import SettingsController, { Props } from "../SettingsController.web";
import ConfirmationModal from "../../../../components/src/ConfirmationModal.web";
import AlertModal from "../../../../components/src/AlertModal.web";

export default class DeleteAccount extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainWrapper}>
        <Typography style={webStyle.heading} variant="h6">
          Delete Account
        </Typography>
        <Box sx={webStyle.list} onClick={this.handleVisibleDeleteModal}>
          <p
            style={{
              color: "#818181",
              fontSize: "20px",
              fontFamily: "HelveticaNeue",
            }}
          >
            Delete Account
          </p>
          <span style={webStyle.list_icon}>
            <RightArrowIcon style={{ color: "#818181" }} />
          </span>
        </Box>
        <Box sx={webStyle.list} onClick={this.handleVisibleDeactivateModal}>
          <p
            style={{
              color: "#818181",
              fontSize: "20px",
              fontFamily: "HelveticaNeue",
            }}
          >
            Deactive Account
          </p>
          <span style={webStyle.list_icon}>
            <RightArrowIcon style={{ color: "#818181" }} />
          </span>
        </Box>
        <ConfirmationModal
          onHandleConfirmation={this.onDeleteAccount}
          title="Delete Account"
          description="Are you sure want to delete? All the details updated in the itenerary will be lost if deleted?"
          open={this.state.openDeleteModal}
          handleCloseModal={this.handleVisibleDeleteModal}
        />
        <ConfirmationModal
          onHandleConfirmation={this.onDeactivateAccount}
          title="Deactivate Account"
          description="Do you wish to deactivate account?"
          open={this.state.openDeactivateModal}
          handleCloseModal={this.handleVisibleDeactivateModal}
        />
        <AlertModal
          open={this.state.openDeleteAlert}
          onHandleOk={this.onHandleDeleteAlert}
          title="Account is deleted"
        />
        <AlertModal
          open={this.state.openDeactivateAlert}
          onHandleOk={this.onHandleDeactivateAlert}
          title="Account is deactivated"
        />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    height: "90%",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "20px",
  },
  heading: {
    fontSize: "22px",
    color: "#000000",
    fontFamility: "HelveticaNeue",
    marginBottom: "40px",
  },
  list: {
    display: "flex",
    padding: "0 20px",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: "20px",
    fontFamily: "HelveticaNeue",
    position: "relative",
    margin: "20px 0",
    border: "1px solid #818181",
    cursor: "pointer",
  },
  list_icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  } as CSSProperties,
};
// Customizable Area End
