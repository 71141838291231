// @ts-nocheck
import React from "react"

// Customizable Area Start
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import ProductQuickviewController, { Props } from "./ProductQuickviewController";
import Scale from "../../../components/src/Scale";
// Customizable Area End

export default class ProductQuickview extends ProductQuickviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  allProductList=( name:string,image:string,price:number )=>{ 
    return(
      <div
      style={{
        width: '100%',
        marginTop: Scale(10),
        backgroundColor: "white",
        paddingBottom: Scale(15),
        borderWidth: Scale(0.4),padding:Scale(5),
      }}
    >
      <img
        style={{ width: "100%", height: hp(35) }}
        src={image }
      />
      <div style={{}}>
        <text
          style={{
            fontSize: hp(2),
            fontFamily: "DMSans-Bold",
            marginTop: Scale(2),
            color: "#000",
          }}
        >
          {name}
        </text>
      </div>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: Scale(2),
        }}
      >
        <text
          style={{
            fontSize: hp(1.7),
            fontFamily: "DMSans-Bold",
            color: "#64BA94",
          }}
        >
          Rs {Number(price)}
        </text>
      </div>
      <button style={{
        backgroundColor: "#142D50",
        width: Scale(25),
        height: hp(3.2),
        borderRadius: wp(5),
        color:"#fff",
        marginTop:Scale(2),
        borderColor: "#142D50",
      }}
      data-test-id={'button-data'}
      >
        View Details</button>
    </div>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
          <div style={{flex: 1, backgroundColor: "#f5f5f7"  }}>
            <div style={{  alignItems: "center", marginTop: Scale(20), marginBottom: Scale(20), width: '100%',justifyContent:'center'}}>
              {this.state.productList.map((item) => (
               this.allProductList(item.attributes.name,item.attributes.image,item.attributes.price)
              ))}
            </div>
          </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
