import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoader: boolean;
  forumList: { data: Array<any>, meta: { total_pages: number } },
  hasMoreData: boolean;
  userDetail: any;
  forumPage: number;
  searchQuery: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDiscussionForumCallID: string = "";
  apiGetLikeUnlikeForumCallID: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoader: false,
      forumList: {
        data: [],
        meta: {
          total_pages: 0
        }
      },
      hasMoreData: false,
      userDetail: {},
      forumPage: 1,
      searchQuery: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.handleErrorResponse(responseJson.errors);
        this.setState({ isLoader: false })
      } else if (responseJson) {
        this.checkApiResponse(responseJson, apiRequestCallId);
      } else {
        this.handleErrorResponse(errorReponse);
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start
  async componentDidMount() {
    const data = window.localStorage.getItem("user_details");
    if (data) {
      this.setState({
        userDetail: JSON.parse(data)
      })
    }
  }

  checkApiResponse = (responseJson: any, apiRequestCallId: any) => {
    switch (apiRequestCallId) {
      case this.apiGetDiscussionForumCallID:
        this.setState({ forumList: { data: [...this.state.forumList.data, ...responseJson.data], meta: responseJson.meta }, isLoader: false });
        break;

      default:
        break;
    }
  };

  fetchDiscussionForum = (page: number) => {

    let queryRes = "";
    const query = this.state.searchQuery;
    if (query !== "") {
      queryRes = `?query=${query}&page=${page}&per_page=10`
    } else {
      queryRes = `?page=${page}&per_page=10`
    }
    console.log("search with api=====" + queryRes)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDiscussionForumCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forumSearchEndpoint + queryRes
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true })
    return true;
  };



  handleLikeForumAction = (attr: any, index: number) => {
    const data = this.state.forumList.data[index];
    data.attributes = attr
    this.state.forumList.data[index] = data;
    this.setState({ forumList: { ...this.state.forumList, data: this.state.forumList.data } })
  }
  handleNextPage = async () => {
    console.log('next')
    this.setState({ forumPage: this.state.forumPage + 1 })
    await this.fetchDiscussionForum(this.state.forumPage);

  }



  handleErrorResponse = (errors: any) => {
    this.setState({ isLoader: false })
    this.parseApiErrorResponse(errors);
    this.parseApiCatchErrorResponse(errors);
    this.handleError(errors)


  };
  handleError = (errors: any) => {
    if (Array.isArray(errors)) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}, Please login again.`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      } else if (errors[0]) {
        toast.error(errors[0]);
        this.props.navigation.goBack();
        return;
      }
    }
    toast.error("Something went to wrong please retry");
  };


  // Customizable Area End
}
