// Customizable Area Start
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

export const apiCall = async (data: {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: any;
  token?: string;
}) => {
  const { contentType, method, endPoint, body, token } = data;

  const header: any = {
    token: token ? token : localStorage.getItem("authToken"),
  };
  if (contentType) {
    header["content-type"] = contentType;
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

export const stateList = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

export const setupWebSocket = (
  URL: any,
  token: any,
  itineraryId: any,
  chatId: any,
  handleMessage: any
) => {
  const webSocket = new WebSocket(`wss://${URL}/cable?token=${token}`);

  webSocket.onopen = () => {
    console.log("WebSocket connection established.");
    webSocket.send(
      JSON.stringify({
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "BxBlockItinerary::ItineraryGroupChatChannel",
          itinerary_id: itineraryId,
          chat_group_id: chatId,
        }),
      })
    );
  };

  webSocket.onclose = () => console.log("WebSocket connection closed.");
  webSocket.onerror = (error) => console.error("WebSocket error:", error);

  webSocket.onmessage = (event) => {
    let data = JSON.parse(event.data);

    if (data.message?.message) {
      data = JSON.parse(data.message.message);
      handleMessage(data);
    }
  };

  return webSocket;
};

export const setupOneToOneWebSocket = (
  URL: any,
  token: any,
  chatId: any,
  handleMessage: any
) => {
  const webSocket = new WebSocket(`wss://${URL}/cable?token=${token}`);

  webSocket.onopen = () => {
    console.log("WebSocket connection established.");
    webSocket.send(
      JSON.stringify({
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "ChatChannel",
          id: chatId,
        }),
        data: JSON.stringify({ id: chatId }),
      })
    );
  };

  webSocket.onclose = () => console.log("WebSocket connection closed.");
  webSocket.onerror = (error) => console.error("WebSocket error:", error);

  webSocket.onmessage = (event) => {
    let data = JSON.parse(event.data);

    if (data.message?.message) {
      data = JSON.parse(data.message.message);
      handleMessage(data);
    }
  };

  return webSocket;
};

export const formatNumber = (number: any) => {
  if(!number){
    return 0;
  }
  if (number >= 1e9) {
    return (number / 1e9).toFixed(1) + "b";
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + "m";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(1) + "k";
  } else {
    return number.toString();
  }
}
// Customizable Area Start
