// Customizable Area Start
import React from "react";
import { Typography, withStyles, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import OTPInput from "react-otp-input";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Formik } from "formik";
import * as Yup from "yup";

interface Props {
  classes: any;
  open: boolean;
  descriptionText: string;
  onClose: () => void;
  verifyEmailOtp: (otp: string) => void;
  resendOtp: () => void;
  errorMessage: string;
}

interface S {
  intervalTime: number;
  otpValue: string;
}

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .length(4, "OTP must be exactly 4 digits")
    .matches(/^[0-9]+$/, "OTP must contain only digits")
    .required("Otp is required"),
});

class OtpVerificationModal extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      intervalTime: 60,
      otpValue: "",
    };
  }

  componentDidMount() {
    if (this.state.intervalTime > 0) {
      setInterval(
        () =>
          this.setState((prevState) => ({
            intervalTime: prevState.intervalTime - 1,
          })),
        1000
      );
    }
  }

  countDown = () => {
    this.setState({
      intervalTime: this.state.intervalTime - 1,
    });
  };

  renderInput = (prop: any) => {
    const { value, ...rest } = prop;
    return (
      <input
        {...rest}
        type="password"
        value={value}
        autoComplete="off"
        style={{
          height: "60px",
          width: "60px",
          borderRadius: "16px",
          border: "0.5px solid rgba(105,134,216,0.1)",
          background: "#ffffff",
          backgroundBlendMode: "normal",
          textAlign: "center",
          boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
        }}
      />
    );
  };

  resendOtp = () => {
    this.setState(
      {
        intervalTime: 60,
      },
      () => {
        this.props.resendOtp();
      }
    );
  };

  render() {
    const { classes, open, descriptionText } = this.props;
    return (
      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes?.Modal}
        maxWidth="xs"
        fullWidth
      >
        <CloseIcon
          onClick={() => this.props.onClose()}
          style={{
            color: "#25AAE1",
            position: "absolute",
            top: "40px",
            right: "35px",
            cursor: "pointer",
          }}
        />
        <Formik
          enableReinitialize={true}
          initialValues={{
            otp: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(value) => {
            this.props.verifyEmailOtp(value.otp);
          }}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Typography className="modal-title">
                  OTP Verification
                </Typography>
                <Typography className="modal-description">
                  {descriptionText}
                </Typography>
                <OTPInput
                  value={values.otp}
                  onChange={(value) => {
                    setFieldValue("otp", value);
                  }}
                  numInputs={4}
                  renderSeparator={<span style={{ width: 15 }}> </span>}
                  renderInput={this.renderInput}
                />
                {errors.otp && (
                  <p className="error mb-20 mt-10">{errors.otp}</p>
                )}
                {this.state.intervalTime > 0 ? (
                  <Typography className="timer-text">
                    00 :{" "}
                    {this.state.intervalTime > 9
                      ? this.state.intervalTime
                      : `0${this.state.intervalTime}`}
                  </Typography>
                ) : (
                  <div className="resend-button" onClick={this.resendOtp}>
                    <span className="resend-button-text">Resend OTP</span>{" "}
                    <RefreshIcon className="refresh-icon" />
                  </div>
                )}
                {this.props.errorMessage && (
                  <p className="error mb-20 mt-10">{this.props.errorMessage}</p>
                )}
                <Button
                  variant="contained"
                  className="verify-button"
                  fullWidth
                  type="submit"
                >
                  Verify
                </Button>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    );
  }
}

const OtpModalStyles = {
  Modal: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "40px",
      boxSizing: "border-box",
    },
    "& .modal-title": {
      fontFamily: "Helvetica-Bold",
      fontSize: "32px",
      fontWeight: "bold",
      lineHeight: "40px",
      color: "#0A083A",
    },
    "& .modal-description": {
      color: "#6C6B6B",
      fontFamily: "Helvetica",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "32px",
      marginTop: "15px",
      marginBottom: "25px",
    },
    "& .verify-button": {
      marginTop: "40px",
      height: "72px",
      borderRadius: "4px",
      background: "#25aae1",
      backgroundBlendMode: "normal",
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "Helvetica",
        fontSize: "24px",
        fontWeight: "400",
        textTransform: "capitalize",
      },
    },
    "& .resend-button": {
      display: "flex",
      alignItems: "center",
      marginTop: "25px",
      gap: 5,
      marginBottom: "10px",
      cursor: "pointer",
    },
    "& .resend-button-text": {
      color: "#25aae1",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "400",
      textDecoration: "underline",
    },
    "& .refresh-icon": {
      height: 14,
      width: 14,
      color: "#25aae1",
    },
    "& .timer-text": {
      color: "#d81e1e",
      fontFamily: "Helvetica",
      fontSize: "24px",
      fontWeight: "400",
      marginTop: "10px",
    },
  },
};

export default withStyles(OtpModalStyles)(OtpVerificationModal);
// Customizable Area End
