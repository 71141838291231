import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
const configJSON = require("./config");
// Customizable Area End

import Bookmark2Controller, { Props } from "./Bookmark2Controller.web";
import BackButton from "../../../components/src/BackButton.web";

export default class Bookmark2 extends Bookmark2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { post } = this.state;
    return (
      // Customizable Area Start
      <WrapperContainer className="background-color">
        <Container disableGutters maxWidth={false} className="container">
          <Grid container component={Box} className="main-wrapper">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box style={{ paddingTop: "60px", marginLeft: "50px" }}>
                <BackButton navigation={this.props.navigation} />
                <Typography
                  style={{
                    fontSize: "35px ",
                    fontWeight: "bold",
                    padding: "50px 0 50px 0",
                  }}
                >
                  {configJSON.bookMarkPageTitle}
                </Typography>
                <Box className="cardWrapper" display="flex">
                  <Box
                    className="post-card"
                    style={{
                      boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.25)",
                      height: "450px",
                      width: "50%",
                      borderRadius: "15px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <Box
                      className="card-image-wrapper"
                      display="flex"
                      flexDirection="column"
                      style={{ margin: "30px 65px 65px 65px" }}
                    >
                      <Box display="flex">
                        <img
                          src={post.uploader_image}
                          className="bookmarkImage"
                          alt=""
                        />
                        <Box>
                          <Typography variant="subtitle1">
                            {post.uploader_name}
                          </Typography>
                          <Typography variant="subtitle2">
                            {post.location + " | " + post.created_at}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        className="image-box"
                        display="flex"
                        marginTop="20px"
                        justifyContent="space-between"
                      >
                        {post.images_and_videos?.length
                          ? post.images_and_videos[0].type == "image" && (
                              <img
                                src={post.images_and_videos[0].url}
                                className="bookmarkBigimage"
                                alt="Post Image"
                              />
                            )
                          : null}
                        {post.images_and_videos?.length
                          ? post.images_and_videos[0].type == "video" && (
                              <video className="bookmarkBigimage" controls>
                                <source
                                  src={post.images_and_videos[0].url}
                                  className="bookmarkBigimage"
                                  type="video/mp4"
                                />
                                {configJSON.videoErrorTitle}
                              </video>
                            )
                          : null}
                        <Box
                          marginLeft="20px"
                          style={{
                            width: "350px",
                            wordBreak: "break-all",
                          }}
                        >
                          {post.description}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="post-card"
                    style={{
                      boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.25)",
                      height: "450px",
                      width: "35%",
                      borderRadius: "15px",
                      marginLeft: "20px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <Box
                      className="card-image-wrapper"
                      style={{
                        margin: "30px 30px 50px 30px",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        height: "85%",
                      }}
                    >
                      <Box>
                        <div className="box-shadow-text-field">
                            <TextField
                              variant="outlined"
                              className="input-field"
                              fullWidth
                              multiline
                              minRows={1}
                              data-test-id="bookmark-title-input"
                              placeholder={configJSON.bookMarkPageInputTitle}
                              name="title"
                              onChange={this.handleData}
                              value={this.state.bookmarkData.title}
                            />
                          </div>
                        {this.state.bookmarkDataError.title ? (
                          <Typography
                            variant="caption"
                            display="block"
                            color="error"
                            gutterBottom
                          >
                            {this.state.bookmarkDataError.title}
                          </Typography>
                        ) : null}
                        <div className="box-shadow-text-field">
                            <TextField
                              variant="outlined"
                              className="input-field"
                              fullWidth
                              data-test-id="bookmark-description-input"
                              multiline
                              minRows={6}
                              onChange={this.handleData}
                              value={this.state.bookmarkData.description}
                              placeholder={configJSON.bookMarkPageInputDescription}
                              name="description"
                            />
                          </div>
                        {this.state.bookmarkDataError.description ? (
                          <Typography
                            variant="caption"
                            display="block"
                            color="error"
                            gutterBottom
                          >
                            {this.state.bookmarkDataError.description}
                          </Typography>
                        ) : null}
                      </Box>

                      <Button
                        style={{
                          marginTop: "20px",
                          height: "60px",
                          textTransform: "none",
                        }}
                        data-test-id="bookmark-button"
                        fullWidth
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={this.handleBookmark}
                      >
                        {configJSON.bookMarkBtnTitle}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </WrapperContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const WrapperContainer = styled("section")({
  "& .container": {
    width: "100%",
    minHeight: "100%",
  },
  "& .main-wrapper": {
    display: "flex",
    alignItems: "center",
    color: "#656565",
    "& .MuiGrid-item": {
      marginBottom: "20px",
    },
  },
  "& .cardWrapper": {
    "@media (max-width:1100px)": {
      flexWrap: "wrap",
    },
    "& .post-card": {
      "@media (max-width:1100px)": {
        width: "90% !important",
        marginLeft: "0 !important",
        marginBottom: "10px",
        height: "auto !important",
      },
      "@media (max-width:600px)": {
        width: "90% !important",
        marginLeft: "0 !important",
        marginBottom: "10px",
        flexWrap: "wrap",
      },
      "& .card-image-wrapper": {
        "@media (max-width:900px)": {
          margin: "15px !important",
        },
        "& .image-box": {
          "@media (max-width:600px)": {
            flexWrap: "wrap",
          },
        },
      },
    },
  },
  "& .search-container": {
    display: "flex",
    justifyContent: "space-between",
    padding: "30 20 25 0",
    fontSize: "20px",
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  "& .BookmarkBackButton": {
    height: "60px",
    width: "100px",
    backgroundColor: "#D0EBF4",
    border: "#D0EBF4",
    borderRadius: "16px",
    fontWeight: "bold",
    color: "#39ABBF",
  },
  "& .bookmarkImage": {
    height: "60px",
    width: "60px",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    marginRight: "20px",
  },
  "& .bookmarkBigimage": {
    height: "300px",
    width: "300px",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    objectFit: "cover",
  },
  "& .MuiInput-underline::before": {
    border: "transparent !important",
  },
  "& .MuiButton-containedPrimary": {
    background: "#25AAE1",
  },
});
// Customizable Area End
