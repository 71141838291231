import React from "react";
import {
    Modal,
    Box,
    makeStyles,
    Typography,
    Button,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    modalBox: {
        backgroundColor: "white",
        border: "2px solid red",
    },
    yes_button: {
        background: "#25AAE1",
        color: "#FFFFFF",
        boxShadow: '0px 8px 24px 0px #25AAE14D',
        textTransform: "capitalize",
        borderRadius: "12px",
        fontSize: "20px",
        padding: '4px 30px',
        width:"100%",
        "&:hover": {
            background: "#25AAE1",
        },
    },
    contentHead: {
        fontSize: "32px",
        textAlign: "center",
        marginTop: "10px",
        
    },
}));

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    bgcolor: "background.paper",
    borderRadius: 18,
    boxShadow: 24,
    padding: '10px 24px'

};
const AlertModal = ({ open, onHandleOk, title = "" }: { open: boolean, onHandleOk: any, title: string }) => {
    const classes = useStyles();

    return (
        <Box>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <Typography className={classes.contentHead}>
                            {title}
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            //   width: "90%",
                            display: 'flex',
                            margin: "auto",
                            marginTop: "25px",
                            marginBottom: "20px",
                            justifyContent:"center",
                            alignItems:"center"
                        }}
                    >
                        <Button className={classes.yes_button} onClick={onHandleOk}>
                            Ok
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default AlertModal;
