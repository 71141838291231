import React, { CSSProperties } from "react";

import {
    Box,
    Typography,
    // Customizable Area Start
    Grid,
    withStyles,
    TextField,
    InputAdornment,

    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start



// Customizable Area End

import DiscussionForumController, {
    Props,
} from "./DiscussionForumController.web";
import { ForumStyle } from "./DiscussionForum.styles.web";
import Loader from "../../../components/src/Loader.web";
import { searchIcon } from "./assets";
import InfiniteScroll from "react-infinite-scroll-component";
import ForumCard from "./ForumCard.web";
import { debounce } from 'lodash'

export class DiscussionForumList extends DiscussionForumController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.fetchDiscussionForum(this.state.forumPage);
    }
    handleSearch = () => {
        this.setState({ forumList: { data: [], meta: { total_pages: 0 } } })
        this.fetchDiscussionForum(1);
    }
    handleDebouncedSearch = async (e: any) => {
        this.setState({ searchQuery: e.target.value })
        debounce(this.handleSearch, 2000)();
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { forumList } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.mainContainer}>
                <Loader loading={this.state.isLoader} />
                {<Grid style={{ overflow: 'hidden' }} container className="forum-container">
                    <Grid style={{ height: "100%" }} item md={12} lg={10} xl={10} sm={12} xs={10} >
                        <Box bgcolor="#F2F2F2" sx={{
                            paddingTop: "30px", position: "sticky", zIndex: 11,
                            top: "0",
                            width: "100%",
                            margin: "auto",
                        }}>
                            <Typography
                                style={{
                                    fontSize: "22px",
                                    fontFamily: "HelveticaNeue",
                                    lineHeight: "28px",
                                    marginBottom: "10px"
                                }}
                                variant="h6"
                            >
                                Discussion Forum
                            </Typography>
                            <Box className="search-container">
                                <TextField name="search-forum" className="searchField" placeholder={"Search"} id="search-forum" data-test-id="search-forum" onChange={this.handleDebouncedSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={searchIcon} alt="searchIcon" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                            {forumList?.data?.length > 0 && <Typography
                                style={{
                                    fontSize: "18px",
                                    fontFamily: "HelveticaNeue",
                                    lineHeight: "28px",
                                    marginBottom: "10px"
                                }}
                                variant="h6"
                            >
                                All Forum
                            </Typography>}
                        </Box>
                        <Box id="scrollableDiv" style={{ overflowY: 'scroll', height: "65%" }}>
                            {forumList?.data?.length ? <InfiniteScroll
                                next={this.handleNextPage}
                                scrollableTarget="scrollableDiv"
                                loader={<>{"Loading..."}</>}
                                hasMore={this.state.forumPage < this.state.forumList.meta.total_pages}
                                dataLength={forumList?.data.length}>

                                {forumList?.data?.map((forum: any, index: number) => {
                                    return <ForumCard
                                        index={index}
                                        key={index}
                                        item={forum?.attributes}
                                        id={index.toString()}
                                        navigation={this.props.navigation}
                                        userDetails={this.state.userDetail}
                                        handleLikeForumAction={this.handleLikeForumAction}
                                    />
                                })}</InfiniteScroll> : "No Forum Found"}

                        </Box>

                    </Grid>

                </Grid>}
            </Box>
            // Customizable Area End
        );
    }
}
//@ts-ignore
export default withStyles(ForumStyle)(DiscussionForumList)


