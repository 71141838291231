// Customizable Area Start
import { Box, styled } from "@material-ui/core";

export const ItineraryDiscussionChatContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: "10px",
  "& .itinerary-header": {
    display: "flex",
    alignItems: "top",
    justifyContent: "space-between",
  },
  "& .title-text": {
    color: "#000",
    fontFamily: "Helvetica",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  "& .more-menu-button": {
    padding: "5px",
  },
  "& .more-menu-image": {
    width: "4px",
    height: "16px",
    flexShrink: 0,
  },
  "& .under-line": {
    marginTop: "20px",
    borderColor: "rgba(0, 0, 0, 0.12)",
    marginBottom: "10px",
  },
  "& .icon-container": {
    padding: "8px",
  },
});
// Customizable Area End
