// Customizable Area Start
import { styled } from "@material-ui/core";

export const ChatListingContainer = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  position: "relative",
  "& .chat-container-absolute": {
    position: "absolute",
    bottom: 0,
    maxHeight: "100%",
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  "& .chat-display": {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  "& .chat-message-container": {
    "&:hover .action-container-1": {
      display: "block !important",
    },
    "& .user-image-container": {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      position: "relative",
      "& .action-container-1": {
        position: "absolute",
        top: "0",
        right: "5px",
        display: "none",
        backgroundColor: "#fff",
        outline: "none",
        borderRadius: "10px",
        padding: "5px",
      },
      "& .edit-icon": {
        fill: "#849199",
        cursor: "pointer",
        height: "18px",
        width: "18px",
      },
      "& .delete-icon": {
        fill: "#849199",
        cursor: "pointer",
        height: "18px",
        width: "18px",
      },
      "& .user-image": {
        width: "38px",
        height: "38px",
        borderRadius: "12px",
      },
      "& .date-time-container": {
        display: "flex",
        alignItems: "center",
        gap: "6px",
        "& .user-name": {
          color: "#1E1F20",
          fontFamily: "Helvetica",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "20px",
        },
        "& .recent-time": {
          color: "#8F92A1",
          fontFamily: "Helvetica",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "20px",
        },
      },
    },
    "& .chat-message": {
      color: "#474A4D",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "22px",
      marginLeft: "53px",
      wordBreak: "break-all",
    },
    "& .message-image-container": {
      "& .message-image-container1": {
        position: 'relative',
        "& .message-image": {
          width: "80px",
          height: "80px",
          borderRadius: "8px",
          objectFit: "cover",
        },
        "& .delete-attachment-icon": {
          display: "none",
        },
        "&:hover .delete-attachment-icon": {
          display: "block !important",
          position: 'absolute',
          right: "-10px",
          top: "-10px",
          zIndex: 1,
          fontSize: "24px",
          // fill: "#ed7464",
          cursor: "pointer",
          fontWeight: "bold"
        }
      },
      paddingLeft: "53px",
      display: "flex",
      gap: "10px",
      flexWrap: "wrap",
    },
  },
  "& .created-date": {
    margin: "0 auto",
    width: "fit-content",
    padding: "0px 12px 0px",
    minHeight: "25px",
    textAlign: "center",
    textShadow: "0 1px 0 rgba(255,255,255,.4)",
    backgroundColor: "hsla(0,0%,100%,0.95)",
    borderRadius: "7.5px",
    boxShadow: "0 1px 0.5px rgba(11,20,26,.13)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Helvetica",
    fontSize: "12px",
  },
  "& .edited-text": {
    fontSize: "12px",
    color: "#3D3C40",
    opacity: "0.6",
    paddingLeft: "53px",
  },
});
// Customizable Area End
