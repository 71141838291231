import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ClickAwayListener,
  Chip,
  Dialog,
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import EmojiPicker from "emoji-picker-react";
import CloseIcon from "@material-ui/icons/Close";
import { Slide } from "react-slideshow-image";
import ClearIcon from "@material-ui/icons/Clear";

const menuIcon = require("../../../components/src/assets/menuicon.png");
const like = require("../../../components/src/assets/likeicon.png");
const disLike = require("../../../components/src/assets/unlikeicon.png");
const comment = require("../../../components/src/assets/commeticon.png");
const share = require("../../../components/src/assets/shareicon.png");
const bookmark = require("../../../components/src/assets/bookmarkSolid.png");
const leftScroll = require("../../../components/src/assets/scrolllefticon.png");
const rightScroll = require("../../../components/src/assets/scrollrighticon.png");
const sendBtn = require("../../../components/src/assets/send-btn.png");
const emoji = require("../../../components/src/assets/emoji.png");
import ConfirmationModal from "../../../components/src/ConfirmationModal.web";
import PostController, { Props } from "./PostController.web";
import SearchBar from "../../../components/src/SearchBar.web";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/src/Loader.web";
import { formatNumber } from "../../../components/src/utils.web";
const configJSON = require("./config");

export default class PostCard extends PostController {
  constructor(props: Props) {
    super(props);
  }
  onHandleConfirmationForPost = () => {
    const { item } = this.props;
    this.onHandleConfirmationPostDelete(item.id);
    this.handleClosePostDeleteModal();
  };
  onHandelConfirmations = () => {
    this.onHandleConfirmation(this.state.commentId, this.state.myCommentId);
    this.handleCloseModal();
  };
  onHandleMoreMenuClick = (event: any) => {
    const { item } = this.props;
    this.handleMenuClick(event, item.id);
  };
  gotToBookmark = () => {
    if (this.props?.isUnAuthorized) {
      this.props.handleLikePostAction()
      return;
    }
    const { item } = this.props;
    this.props.navigation.history.push(`/Bookmark2/${item.id}`);
  };

  renderUserProfile() {
    const { item } = this.props;
    return (
      <Box className="user-profile">
        <Box display="flex" alignItems="center">
          <div data-test-id='isUnAuthorized' onClick={() => {
            !this.props.isUnAuthorized && this.props.navigation.history.push(`/profile/${item.account_id}`)
          }}>
            <Avatar>
              <img
                style={{ width: "40px", height: "40px", objectFit: "cover" }}
                src={item.uploader_image}
                alt="avatar"
              />
            </Avatar>
          </div>
          <Box sx={{ marginLeft: "8px" }}>
            <Typography variant="subtitle2" className="user-name">
              <div data-test-id='isUnAuthorized'
                onClick={() => {
                  !this.props.isUnAuthorized && this.props.navigation.history.push(`/profile/${item.account_id}`)
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                {item?.uploader_name}
              </div>
            </Typography>
            <Typography variant="body2" className="user-location">
              {item?.location} | {item?.created_at}
            </Typography>
          </Box>
          <ConfirmationModal
            open={
              this.state.postMenuId === item.id
                ? this.state.deletePostModel
                : null
            }
            handleCloseModal={this.handleClosePostDeleteModal}
            onHandleConfirmation={this.onHandleConfirmationForPost}
            title="Remove Post"
            description="Are you sure you want to remove this Post?"
          />
        </Box>
        <Button
          data-test-id="more-menu-icon"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.props.isUnAuthorized ? this.props.handleLikePostAction : this.onHandleMoreMenuClick}
        >
          <img src={menuIcon} alt="menuIcon" className="menu-icon" />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={
            this.state.postMenuId === item.id ? this.state.postMenu : null
          }
          keepMounted
          open={
            this.state.postMenuId === item.id
              ? Boolean(this.state.postMenu)
              : false
          }
          onClose={this.handleClosePostMenu}
        >
          {item.account_id == this.props.userDetails?.id ? (
            <>
              <MenuItem
                data-test-id="edit-post"
                onClick={() =>
                  this.props.navigation.history.push(`/post-create/${item.id}`)
                }
              >
                {configJSON.editName}
              </MenuItem>
              <MenuItem onClick={this.handleOpenDeleteModal}>
                {configJSON.deleteName}
              </MenuItem>
            </>
          ) :
            <MenuItem
              data-test-id="report-button"
              onClick={() => this.handleReportPostModal(item.id)}
            >
              {configJSON.reportName}
            </MenuItem>
          }
        </Menu>
      </Box>
    );
  }

  renderMediaElements() {
    const { item } = this.props;

    return item.images_and_videos.map((items: any, i: number) => {
      if (items.type === "image") {
        return (
          <Box className="post" key={i + 0}>
            <img src={items.url} alt="fanwallImg" className="bcg-img" />
          </Box>
        );
      } else if (items.type === "video") {
        return (
          <Box className="post" key={i + 0}>
            <video className="bcg-img" ref={this.videoRefs[i]} controls>
              <source src={items.url} type="video/mp4" />
              {configJSON.browserSupportName}
            </video>
          </Box>
        );
      }
    });
  }
  getSuggestionListUsers = (data: any) => {
    const user =
      data?.users?.length > 1
        ? data.users.filter(
          (db: { id: number }) => db.id != this.state.userDetail.id
        )
        : data.users;
    return user;
  };

  renderPostLikeTool = () => {
    const { item } = this.props;

    return (
      <Box className="like-tools">
        <Box>
          <Button onClick={this.handlePostLike} data-test-id="likePost">
            <img
              src={item.liked_by_user == "true" ? like : disLike}
              alt="like-icon"
            />
            <CountNumber>{formatNumber(item?.likes) || 0}</CountNumber>
          </Button>
          <Button data-test-id="comment-icon" onClick={this.handleComment}>
            <img src={comment} alt="comment-icon" />
            <CountNumber>{formatNumber(this.state.commentCount)}</CountNumber>
          </Button>
          <Button data-test-id='share-icon' onClick={this.handleShare}>
            <img src={share} alt="share-icon" />
            <CountNumber>{formatNumber(item?.share_count) || 0}</CountNumber>
          </Button>
          <MuiModal
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="xs"
            fullWidth
            scroll="body"
            open={
              item.id === this.state.shareModalId
                ? this.state.shareModal
                : false
            }
            onClose={this.handleCloseShareModal}
          >
            <Box className="paper">
              <Box className="modal-title">
                <Typography variant="h4">Share</Typography>
                <CloseIcon
                  onClick={this.handleCloseShareModal}
                  style={{
                    color: "#25AAE1",
                    cursor: "pointer",
                  }}
                />
              </Box>
              <Box position="relative">
                <SearchBar
                  searchText={this.state.searchText}
                  placeHolder="Share To..."
                  onApiCall={this.setSearchTextValue}
                />
                {this.state.searchText && (
                  <Box className="dropdown-container">
                    <Box
                      id="scrollableSearch"
                      className="scrollableDiv dropdown-container-height"
                    >
                      <InfiniteScroll
                        dataLength={this.state.searchUserList.length}
                        next={this.fetchMoreSearchList}
                        scrollableTarget="scrollableSearch"
                        hasMore={this.state.searchListHasMorePage}
                        loader={<h4>Loading...</h4>}
                        style={{
                          height: "unset !important",
                          overflow: "unset !important",
                        }}
                      >
                        {this.state.searchUserList.length > 0 ? (
                          this.state.searchUserList.map((data) => (
                            <Box
                              mb={1}
                              key={data.id}
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box display="flex" alignItems="center">
                                <img
                                  src={data.attributes.photo}
                                  height="40px"
                                  width="40px"
                                  style={{ borderRadius: "50%" }}
                                />
                                <Box sx={{ marginLeft: "8px" }}>
                                  <Typography
                                    variant="subtitle2"
                                    className="user-name"
                                  >
                                    {data.attributes.full_name}
                                  </Typography>
                                  {data.attributes.username && (
                                    <Typography
                                      variant="body2"
                                      className="user-location"
                                    >
                                      @{data.attributes.username}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                              <Checkbox
                                color="primary"
                                checked={
                                  !!this.state.selectedUser.find(
                                    (rec) => rec.id === Number(data.id)
                                  )
                                }
                                onChange={(e) => {
                                  this.selectUserToSendMessage(
                                    e.target.checked,
                                    {
                                      id: Number(data.id),
                                      name: data.attributes.full_name,
                                    }
                                  );
                                }}
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </Box>
                          ))
                        ) : (
                          <Typography style={{ textAlign: "center" }}>
                            No users found
                          </Typography>
                        )}
                      </InfiniteScroll>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box mt={2} className="chip-item-container">
                {this.state.selectedUser.map((res) => {
                  return (
                    <Chip
                      key={`${res.name}${res.id}`}
                      label={res.name}
                      className="selected-chip"
                      deleteIcon={<ClearIcon />}
                      onDelete={() => {
                        this.selectUserToSendMessage(false, res);
                      }}
                    />
                  );
                })}
              </Box>
              <Box mt={2} mb={2}>
                <Typography variant="h5">
                  {configJSON.suggestedTitle}
                </Typography>
              </Box>
              <Box
                mb={2}
                className="scrollableDiv"
                sx={{
                  overflow: "auto",
                  height: { xs: 250, md: 300, lg: 325 },
                  width: "100%",
                }}
                id="scrollableChat"
              >
                <InfiniteScroll
                  dataLength={this.state.chatList.length}
                  next={this.fetchMoreChatList}
                  scrollableTarget="scrollableChat"
                  hasMore={this.state.chatListHasMorePage}
                  loader={<h4>Loading...</h4>}
                  style={{
                    height: "unset !important",
                    overflow: "unset !important",
                  }}
                >
                  {this.state.chatList.length > 0 ? (
                    this.state.chatList.map((data) => {
                      console.log(data)
                      const user = this.getSuggestionListUsers(data);
                      if (!user[0]?.blocked_by_me && !user[0]?.blocked_by_user) {
                        return (
                          <Box
                            mb={1}
                            key={user[0].id}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box display="flex" alignItems="center">
                              <img
                                src={user[0].profile_photo}
                                height="40px"
                                width="40px"
                                style={{ borderRadius: "50%" }}
                              />
                              <Box sx={{ marginLeft: "8px" }}>
                                <Typography
                                  variant="subtitle2"
                                  className="user-name"
                                >
                                  {user[0].name}
                                </Typography>
                                {user[0].user_name && (
                                  <Typography
                                    variant="body2"
                                    className="user-location"
                                  >
                                    @{user[0].user_name}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                            <Checkbox
                              color="primary"
                              checked={
                                !!this.state.selectedUser.find(
                                  (rec) => rec.id === user[0].id
                                )
                              }
                              onChange={(e) => {
                                this.selectUserToSendMessage(e.target.checked, {
                                  id: user[0].id,
                                  name: user[0].name,
                                });
                              }}
                              inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                          </Box>
                        );
                      }

                    })
                  ) : (
                    <Typography style={{ textAlign: "center" }}>
                      No users found
                    </Typography>
                  )}
                </InfiniteScroll>
              </Box>

              <Box>
                <Button
                  onClick={this.handleSharePost}
                  variant="contained"
                  size="large"
                  disabled={!this.state.selectedUser.length}
                  color="primary"
                  className="share-btn"
                >
                  {configJSON.sendBtn}
                </Button>
              </Box>
            </Box>
          </MuiModal>
        </Box>
        <Box>
          <Button data-test-id="bookmark" onClick={this.gotToBookmark}>
            <img style={{ width: "15px" }} src={bookmark} alt="book-icon" />
          </Button>
        </Box>
      </Box>
    );
  };

  renderComment = () => {
    const { item } = this.props;
    const open = Boolean(this.state.anchorEle);

    return (
      this.state.commentId === item.id && (
        <>
          <Box mt={2}>
            <OutlinedInput
              data-test-id="comment-field"
              id="outlined-adornment-weight"
              inputProps={{
                "aria-label": "weight",
              }}
              aria-describedby="outlined-weight-helper-text"
              placeholder="Write a Comment"
              style={{ width: "100%" }}
              onKeyDown={this.handleEnter}
              value={this.state.commentText}
              onChange={this.handleCommentText}
              endAdornment={
                <>
                  <InputAdornment
                    style={{ cursor: "pointer" }}
                    onClick={this.handleEmoji}
                    position="end"
                  >
                    <img src={emoji} alt="comment-icon" />
                  </InputAdornment>
                  <InputAdornment
                    style={{ cursor: "pointer" }}
                    onClick={this.handlePostComment}
                    position="end"
                  >
                    <img src={sendBtn} alt="comment-icon" />
                  </InputAdornment>
                </>
              }
            />
            {this.state.showEmojis && (
              <div className="handle-picker">
                <EmojiPicker onEmojiClick={this.onCommentEmojiClick} />
              </div>
            )}
          </Box>
          <Box style={{ maxHeight: "250px", overflow: "auto" }}>
            {this.state.comments.length ? (
              this.state.comments.map((commentData, index) => {
                return (
                  <Box key={index + 0}>
                    <Box className="commentBox">
                      <Box className="user-comment-profile">
                        <Avatar>
                          <img
                            src={commentData.account_image}
                            alt="comment-img"
                            width="40px"
                            height="40px"
                          />
                        </Avatar>
                        <Typography
                          variant="body1"
                          component="div"
                          className="user-comment-name"
                        >
                          {commentData.account_full_name}
                        </Typography>
                        <Typography
                          variant="body1"
                          component="div"
                          className="user-comment-time"
                        >
                          {commentData.created_at}
                        </Typography>
                      </Box>
                      <Box>
                        {commentData.comment_by_id ==
                          this.props.userDetails?.id && (
                            <>
                              <IconButton
                                aria-controls={`simple-menu-${commentData.id}`}
                                aria-haspopup="true"
                                data-test-id="comment-menu"
                                onClick={(event) =>
                                  this.handleCommentMenuClick(
                                    event,
                                    commentData.id
                                  )
                                }
                              >
                                <img
                                  src={menuIcon}
                                  alt="menuIcon"
                                  className="menu-icon"
                                />
                              </IconButton>
                              {commentData.id === this.state.tempMenuId && (
                                <Menu
                                  id={`simple-menu-${commentData.id}`}
                                  open={
                                    commentData.id === this.state.tempMenuId
                                      ? open
                                      : false
                                  }
                                  anchorEl={this.state.anchorEle}
                                  onClose={this.handleCommentMenuClose}
                                  PaperProps={{
                                    style: {
                                      maxHeight: 48 * 4.5,
                                      width: "100px",
                                    },
                                  }}
                                >
                                  <MenuItem
                                    data-test-id="comment-edit"
                                    onClick={() =>
                                      this.handleCommentMenuEdit(commentData.id)
                                    }
                                  >
                                    {configJSON.editName}
                                  </MenuItem>
                                  <MenuItem
                                    data-test-id="comment-remove"
                                    onClick={() =>
                                      this.handleCommentMenuRemove(commentData.id)
                                    }
                                  >
                                    {configJSON.removeName}
                                  </MenuItem>
                                </Menu>
                              )}
                            </>
                          )}
                      </Box>
                    </Box>
                    <ConfirmationModal
                      open={
                        commentData.id === this.state.tempMenuId
                          ? this.state.confirmModel
                          : false
                      }
                      handleCloseModal={this.handleCloseModal}
                      onHandleConfirmation={this.onHandelConfirmations}
                      title="Remove Comment"
                      description="Are you sure want to remove this comment?"
                    />
                    <Box mt={1} mb={1}>
                      <Typography>{commentData.content || ""}</Typography>
                    </Box>
                    <Box>
                      <img
                        src={like}
                        alt="like-icon"
                        style={{ marginRight: "10px" }}
                      />
                      <img src={comment} alt="comment-icon" />
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box mt={1}>{configJSON.commentErrorMassage}</Box>
            )}
          </Box>
        </>
      )
    );
  };

  render(): React.ReactNode {
    const properties = {
      infinite: false,
      prevArrow: (
        <button
          data-test-id="prev-btn"
          className="nav default-nav nav-btn"
        >
          <img src={leftScroll} width="100%" />
        </button>
      ),
      nextArrow: (
        <button
          data-test-id="next-btn"
          className="nav default-nav nav-btn"
        >
          <img src={rightScroll} width="100%" />
        </button>
      ),
    };
    const { item, index } = this.props;
    const { temp, showMore } = this.state;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <PostContainer>
            <Box className="post-bcg" key={index + 1}>
              {this.renderUserProfile()}
              {item.images_and_videos?.length ? (
                <Slide
                  data-test-id="slide-change"
                  onChange={(from: number, to: number) => {
                    this.setState({
                      temp: { ...temp, [index]: to + 1 },
                    });
                  }}
                  autoplay={false}
                  {...properties}
                >
                  {this.renderMediaElements()}
                </Slide>
              ) : null}
              {item.images_and_videos.length ? (
                <p className="indicator">
                  {temp[index] || 1}/{item?.images_and_videos.length}
                </p>
              ) : (
                ""
              )}
              <Typography
                variant="subtitle2"
                component="div"
                className="user-bio"
              >
                {item?.description.length <= 210 ||
                  showMore === index
                  ? item?.description
                  : item?.description.substring(0, 210) + "..."}
                {item?.description.length > 210 && (
                  <Typography
                    variant="subtitle2"
                    className="show-more"
                    data-test-id="show-more"
                    onClick={() => this.showMore(index)}
                    component="span"
                  >
                    {showMore === index ? configJSON.lessTitle : configJSON.moreTitle}
                  </Typography>
                )}
              </Typography>
              {this.renderPostLikeTool()}
              {this.renderComment()}
            </Box>
          </PostContainer>
        </ClickAwayListener>
      </>
    );
  }
}

const PostContainer = styled("div")({
  "& .post-bcg": {
    backgroundColor: "white",
    height: "auto",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 10px",
    borderRadius: "5px",
    padding: "20px 20px 10px 20px",
    position: "relative",
    border: "0.5px solid rgba(105, 134, 216, 0.10)",
    boxSizing: "border-box",
    marginBottom: "15px",
  },
  "& .react-slideshow-container .default-nav:hover, & .react-slideshow-container .default-nav:focus": {
    backgroundColor: "transparent",
  },
  "& .nav-btn": {
    width: "70px",
    background: "none",
    border: "0px",
    cursor: "pointer",
    "@media (max-width:600px)": {
      width: "50px",
    },
  },
  "@media (max-width:600px)": {
    "& .react-slideshow-container .default-nav:first-of-type": {
      marginRight: "-45px"
    },
    "& .react-slideshow-container .default-nav:last-of-type": {
      marginLeft: "-45px"
    },
  },

  "& .user-profile": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .user-name": {
    fontWeight: "bold",
  },
  "& .user-location": {
    fontSize: "12px",
    color: "#656565",
  },
  "& .show-more": {
    color: "#25AAE1",
    fontSize: "12px",
    cursor: "pointer",
  },
  "& .menu-icon": {
    height: "17px",
    marginTop: "10px",
  },
  "& .user-bio": {
    fontSize: "12px",
    paddingTop: "15px",
    wordWrap: "break-word",
  },
  "& .bcg-img": {
    width: "100%",
    marginTop: "20px",
    height: "380px",
    objectFit: "contain",
    "@media (max-width:600px)": {
      height: "220px"
    },
  },
  "& .like-tools": {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "15px",
    alignItems: "center",
    "@media (max-width:1200px)": {
      "& .MuiButton-root": {
        minWidth: "50px"
      }
    },
  },
  "& .MuiDrawer-paper": {
    width: "450px",
    boxSizing: "border-box",
    borderRadius: "16px 0 0 16px",
    background: "#EFBFED",
  },
  "& .iconImage": {
    height: "60px",
    width: "60px",
    backgroundColor: "red",
    borderRadius: "16px",
    marginRight: "20px",
    position: "relative",
    "&::before": {
      content: "",
      position: "absolute",
      top: "-1px",
      right: "0",
      width: "10px",
      height: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      backgroundColor: "blue",
      color: "white",
    },
  },
  "& .count": {
    position: "relative",
    "&::before": {
      content: "",
      position: "absolute",
      top: "-4px",
      right: "17px",
      width: "1em",
      height: "1em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      backgroundColor: "orange",
      color: "white",
      zIndex: 2,
      border: "3px solid white",
    },
  },
  "& .chatsTag": {
    fontWeight: "bold !important",
    paddingLeft: "20px",
    paddingTop: "20px",
    fontSize: "25px !important",
  },
  "& .post": {
    position: "relative",
  },
  "& .indicator": {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    width: "42px",
    backgroundColor: "rgba(239, 243, 239, 0.7)",
    padding: "2px 0px",
    borderRadius: "8px",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: "420px",
    "@media (max-width:600px)": {
      top: "280px"
    },
  },
  "& .commentBox": {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    "& .user-comment-profile": {
      display: "flex",
      alignItems: "center",
      "& .user-comment-name": {
        fontWeight: "bold",
        color: "black",
        fontSize: "12px",
        margin: "0 5px",
      },
      "& .user-comment-time": {
        fontSize: "12px",
      },
    },
  },
  "& .handle-picker": {
    "& .EmojiPickerReact": {
      marginLeft: "auto",
    },
  },
});
const CountNumber = styled("section")({
  marginLeft: "5px",
});
const MuiModal = styled(Dialog)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .paper": {
    backgroundColor: "#ffffff",
    padding: "20px 30px",
  },
  "& .MuiPaper-rounded": {
    borderRadius: "12px",
  },
  "& .MuiDialog-scrollBody": {
    width: "100%",
  },
  "& .modal-title": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  },
  "& .search-users": {
    width: "100%",
    borderRadius: "12px",
  },
  "& .MuiInputBase-input": {
    height: "0.5em",
  },
  "& .dropdown-container": {
    top: 62,
    left: 0,
    position: "absolute",
    zIndex: 9,
    background: "#fff",
    width: "100%",
    borderRadius: "12px",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
  },
  "& .dropdown-container-height": {
    height: "200px",
    overflow: "auto",
    padding: "10px",
    boxSizing: "border-box",
  },
  "& .share-btn": {
    width: "100%",
    background: "#25AAE1",
    color: "#FFFFFF",
    fontSize: "20px",
    padding: "4px 30px",
    textTransform: "capitalize",
  },
  "& .user-name": {
    fontWeight: "bold",
  },
  "& .user-location": {
    fontSize: "12px",
    color: "#656565",
  },
  "& .MuiCheckbox-root": {
    color: "#25AAE1",
  },
  "& .users-list": {
    height: "300px",
    overflowY: "auto",
  },
  "& .chip-item-container": {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  },
  "& .selected-chip": {
    borderRadius: "20px",
    border: "1px solid rgba(37,170,225,0.24)",
    cursor: "pointer",
    background: "#25aae1",
    height: "30px",
    backgroundBlendMode: "normal",
    "& .MuiChip-label": {
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: "400",
      fontFamily: "Helvetica",
      lineHeight: "16px",
      padding: "0 12px",
    },
  },
  "& .MuiChip-deleteIcon": {
    color: "#fff",
    width: "16px",
    height: "16px",
    margin: "0 5px 0 -10px",
  },
});
