// Customizable Area Start
import React, { useEffect, useState } from 'react'
import { TextField, Box, withStyles, InputAdornment, makeStyles } from "@material-ui/core";
import { CSSProperties } from '@material-ui/core/styles/withStyles';


// const profile = require("../src/profile.png");





interface Props {
    title: string;
    active: boolean;
    onHandle: (value: number) => void;
    activeKey: number;
    imageSource: any
}
const useStyles = makeStyles({
    list_item_active: {
        display: 'flex',
        padding: '0 10px',
        justifyContent: 'left',
        backgroundColor: 'white',
        borderRadius: '20px',
        fontFamily: "HelveticaNeue",
        position: "relative",
        width: '100%',
        margin: "20px 0",
        boxShadow: '0px 10px 16px 0px #A5B0C226',
        cursor: "pointer",
        '&::after': {
            content: "''",
            height: '40px',
            width: '5px',
            backgroundColor: '#25AAE1',
            top: '12px',
            right: '6px',
            position: 'absolute',
            borderRadius: '4px',
        },
        '& p': {
            fontSize: "20px",
            color: "#000000"

        }

    },
    list_item: {
        display: 'flex',
        padding: '0 10px',
        justifyContent: 'left',
        backgroundColor: 'white',
        borderRadius: '20px',
        fontFamily: "HelveticaNeue",
        position: "relative",
        width: '100%',
        margin: "20px 0",
        cursor: 'pointer',
        '& p': {
            fontSize: "20px",
            color: "#818181"
        }

    },
    list_icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        margin: '15px 20px',
    },
    root: {

    },
});

function CustomListItem({ activeKey, title, active, onHandle, imageSource }: Props) {
    const classes = useStyles();
    return (
        <Box onClick={() => onHandle(activeKey)} className={active ? classes.list_item_active : classes.list_item} >
            <span className={classes.list_icon}>
                {imageSource && <img
                    src={active ? imageSource?.active_src : imageSource?.src}
                    style={webStyles.imgIcon}
                />}
            </span>
            <p>{title}</p>

        </Box>
    )
}

const webStyles = {
    imgIcon: {
        width: "24px",
        height: "24px",
        objectFit: "contain",
    } as CSSProperties,

}
export default CustomListItem

// Customizable Area End