import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  filterPopUp: boolean;
  comments: { id: number; content: string; created_at: string; account_full_name: string; account_image: string; commentable_id: number; comment_by_id: number; }[];
  commentId: number;
  filterRef: any;
  filterActivities: { id: string; name: string; data: { id: string; name: string }[] }[];
  filterActivitiesId: {
    [keys: string]: string[]
  };
  userPost: {
    id: string;
    type: string;
    attributes: {
      id: number;
      location: string;
      name: string;
      description: string;
      admin_user_id: null | string;
      state: string;
      account_id: number;
      uploader_image: string;
      updated_at: string;
      model_name: string;
      uploader_name: string;
      created_at: string;
      images_and_videos: {
        id: number;
        url: string;
        type: string;
        filename: string;
      }[];
      likes: number;
      tags: string[];
      is_current_user: string;
      comments: number | null;
      liked_by_user: string;
      share_count: number;
      interests: {
        data: {
          type: string;
          id: string;
          attributes: {
            activities: {
              data: {
                id: string;
                attributes: {
                  name: string;
                };
                type: string;
              }[];
            };
            name: string;
          };
        }[];
      };
    };
  }[];
  isLogin: boolean;
  currentPage: number;
  hasMoreData: boolean;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  handleDeleteCommentId: string = "";
  AllPostPageId: string = "";
  AllPostId: string = "";
  searchApiId: string = "";
  activitiesId: string = "";
  searchActivityApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      commentId: NaN,
      filterPopUp: false,
      comments: [],
      filterRef: false,
      filterActivities: [],
      filterActivitiesId: {},
      userPost: [],
      isLoading: false,
      currentPage: 1,
      hasMoreData: false,
      isLogin: false,
      // Customizable Area End 
    };
    
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.handlePostFilter = this.handlePostFilter.bind(this)
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getAllPost();
  }

  handleLoginModal =() =>{
    this.setState({isLogin:!this.state.isLogin})
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.AllPostId:
        case this.searchApiId:
        case this.searchActivityApiId:
          this.setState({
            userPost: responseJson.data,
            isLoading: false,
            hasMoreData: true,
            currentPage: 1
          });
          break;
        case this.activitiesId:
          const data = responseJson.data.map((item: any) => {
            return {
              id: item.id,
              data: item?.attributes.activities.data.map((data: any) => {
                return {
                  name: data.attributes.name,
                  id: data.id,
                };
              }),
              name: item?.attributes.name,
            };
          });
          this.setState({filterActivities: data});
          break;

        case this.AllPostPageId:
          if (responseJson.data?.length) {
            this.setState({userPost: [...this.state.userPost, ...responseJson.data]});
          } else {
            this.setState({hasMoreData: false});
          }
          break;
      }
      this.setState({
        isLoading: false,
      })
    }
    // Customizable Area End
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  // Customizable Area Start
  getAllPost = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    this.setState({
      isLoading: true,
      userPost: []
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET")
    this.AllPostId = requestMessage.messageId;
      requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allPost + `?page=${this.state.currentPage}&per_page=10`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handlePost = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({
      currentPage: this.state.currentPage + 1
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    this.AllPostPageId = requestMessage.messageId;
    if (Object.keys(this.state.filterActivitiesId)?.length) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applyActivityFilter + JSON.stringify(this.state.filterActivitiesId) + `&page=${this.state.currentPage}&per_page=10`)
    } else if (this.state.txtInputValue) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applyFilter + this.state.txtInputValue + `&page=${this.state.currentPage}&per_page=10`)
      } else {
        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allPost + `?page=${this.state.currentPage}&per_page=10`);
    }
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleApplyFilter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setState({
        isLoading: true,
        userPost: []
      })
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      )
      this.searchApiId = requestMessage.messageId;
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applyFilter + this.state.txtInputValue + `&page=1&per_page=10`
      )
      runEngine.sendMessage(requestMessage.id, requestMessage)
    }
  }

  handlePostFilter = (event: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),"GET")
    this.activitiesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activities
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    this.setState({ filterPopUp: true, filterRef: event.currentTarget })
  }

  handleActivityFilter = (activityId: string, subActivityId: string, isSelected: boolean) => {
    if (isSelected) {
      const activityIndex = this.state.filterActivitiesId[activityId].indexOf(subActivityId);
      const filterActivity = this.state.filterActivitiesId[activityId];
      filterActivity.splice(activityIndex, 1);
      if (filterActivity.length) {
        this.setState({
          filterActivitiesId: {
            [activityId]: filterActivity,
            ...this.state.filterActivitiesId,
          }
        })
      } else {
        const filterActivitiesId = this.state.filterActivitiesId;
        delete filterActivitiesId[activityId];
        this.setState({filterActivitiesId});
      }

    } else {
      const activity = this.state.filterActivitiesId?.[activityId] || []
      this.setState({
        filterActivitiesId: {
          [activityId]: [...activity, subActivityId],
          ...this.state.filterActivitiesId,
        }
      })
    }

  }

  handleApplyActivityFilter = () => { 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      this.setState({
        isLoading: true,
        userPost: []
      })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
      this.searchActivityApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.applyActivityFilter + JSON.stringify(this.state.filterActivitiesId) + `&page=1&per_page=10`
    )
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    this.handleCloseFilter();
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleCloseFilter = () => {
    this.setState({ 
      filterPopUp: false, 
      filterRef: null
    })
  }
  // Customizable Area End
}
