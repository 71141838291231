import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import DataContext from "../../../components/src/DataContext";
import { removeStorageData } from "../../../framework/src/Utilities";
import { dashboard, Screen2, Screen3 } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  userName: string;
  password: string;
  confirmPassword: string;
  checkPassword: boolean;
  checkConfrimPassword: boolean;
  passwordNotmatch: boolean;
  accessToken: string | null;
  error: boolean;
  errorMsg: string;
  isLoading: boolean;
  CarouselImages: {id:number; img: ''; data: string;}[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordControllerBlock extends BlockComponent<
  Props,
  S,
  SS
> {
  forgotpasswordID: string = "";
  newPasswordID: string = "";
  static contextType: React.Context<string> = DataContext;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      userName: "",
      password: "",
      confirmPassword: "",
      checkPassword: false,
      checkConfrimPassword: false,
      passwordNotmatch: false,
      accessToken: localStorage.getItem("authToken"),
      error: false,
      errorMsg: "",
      isLoading: false,
      CarouselImages: [
        {
          data:'head1title',
          id: 1,
          img: dashboard,
        },
        {
          img: Screen2,
          id: 2,
          data: 'head1title'
        },
        {
          id: 3,
          data: 'head1title',
          img: Screen3,
        },
      ],
      // Customizable Area End
    };
  }

  handleClickShowPassword() {
    this.setState({ checkPassword: !this.state.checkPassword });
  }
  handleClickShowConfirmPassword() {
    this.setState({ checkConfrimPassword: !this.state.checkConfrimPassword });
  }

  navigationFunctuon() {
    this.props.navigation.goBack();
  }
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.forgotpasswordID) {
        if (responseJson.data) {
          this.saveLoggedInUserData(responseJson);
          this.context.setData({ email: this.state.userName });
          this.props.navigation.navigate("ForgotPasswordOTPBlock");
        } else {
          this.setState({ error: true, errorMsg: responseJson.errors[0].otp });
        }
      }
      if (apiRequestCallId === this.newPasswordID) {
        if (responseJson.data) {
          removeStorageData('authToken')
          this.props.navigation.navigate("EmailAccountLoginBlock");
        } else {
          this.showAlert("error", "error");
        }
      }
      this.setState({
        isLoading: false,
      });
    }
  }

  saveLoggedInUserData = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );
      this.send(msg);
    }
  };

  resetPassword(): any {
    this.setState({
      isLoading: true,
    });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.accessToken,
    };
    let data = {
      data: {
        token: this.state.accessToken,
        new_password: this.state.confirmPassword,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.newPasswordID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reset_pass
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  forgotPassowrdApi() {
    this.setState({
      isLoading: true,
    });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    let data = {};
    if (isNaN(Number(this.state.userName))) {
      data = {
        data: {
          attributes: {
            email: this.state.userName,
          },
        },
      };
    } else {
      data = {
        data: {
          attributes: {
            full_phone_number: this.state.userName,
          },
        },
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotpasswordID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generate_otp
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  forgotPassowrdFunction(value: any) {
    this.setState({
      userName: value.userName,
    });
    this.forgotPassowrdApi();
  }

  ResetpasswordFunction(value: any) {
    if (value.password !== value.confirmPassword) {
      this.setState({ passwordNotmatch: true });
      return false;
    }
    this.setState({
      password: value.password,
      confirmPassword: value.confirmPassword,
    });
    this.resetPassword();
  }
}
