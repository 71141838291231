import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  Grid,
  // Customizable Area Start
  Chip,
  Popper
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import InfiniteScroll from 'react-infinite-scroll-component';
// Customizable Area End

import FeedController, {
  Props, configJSON
} from "./FeedController.web";

import {
  searchIcon,
  filterIcon
} from "./assets";
import PostCard from "./Post.web";
import Loader from "../../../components/src/Loader.web";
import ConfirmationModal from "../../../components/src/ConfirmationModal.web";

export default class Feed extends FeedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {filterPopUp,filterRef,filterActivities,filterActivitiesId,isLoading,userPost,hasMoreData} = this.state;
    return (
      // Customizable Area Start
      <WrapperContainer >
        
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} className="left-side-container">
                <ConfirmationModal open={this.state.isLogin} handleCloseModal={this.handleLoginModal} onHandleCancel={()=>this.props.navigation.history.push('/OnboardingScreenBlock')} onHandleConfirmation={()=>this.props.navigation.history.push('/EmailAccountLoginBlock')} title={configJSON.logintitlealertmodal} description={configJSON.logindesalertmodal} isSignUp={true}/>
                <Box
                  className="search-box"
                  bgcolor="#f4f5fa"
                >
                  <Box
                    className="search-container"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    bgcolor="#fff"
                  >
                    <TextField
                      placeholder={configJSON.filterInputTitle1}
                      className="searchField"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={searchIcon} alt="searchIcon" />
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={(e) => this.handleApplyFilter(e)}
                      data-test-id="search-bhomio"
                      id="search-bhomio"
                      onChange={(e) => this.setInputValue(e.target.value)}
                    />
                    <Button
                      onClick={this.handlePostFilter}
                      data-test-id="filter"
                      className="filter-button"
                      aria-describedby="simple-popover"
                    >
                      <img
                        className="filter-icon"
                        src={filterIcon}
                        alt="filterIcon"
                      />{configJSON.filterBtnTitle}
                    </Button>
                    <FilterWrapper
                      open={filterPopUp}
                      anchorEl={filterRef}
                      placement="top-end"
                      id="simple-popover"
                    >
                      <Box
                        sx={{
                          width: {
                            xs: "280px",
                            sm: "350px",
                            md: "500px",
                            lg: "600px",
                          },
                        }}
                        className="filtermodel"
                      >
                        <Box className="filterheader">
                          <Box>
                            <Typography className="activetitle">{configJSON.activityTitle}</Typography>
                            <Typography className="subtitle">{configJSON.activitySubTitle}</Typography>
                          </Box>
                          <CloseIcon
                            data-test-id="filte-close-icon"
                            style={{
                              color: "#25AAE1",
                              cursor: "pointer",
                            }}
                            onClick={() => this.handleCloseFilter()}
                          />
                        </Box>
                        <Box className="scrollableDiv filter-box">
                          {filterActivities.length
                            ? filterActivities.map((item, index) => {
                              return (
                                <AdventurWrapper key={index + 1}>
                                  <Box className="adv-title">
                                    <Typography className="adv-title">{item.name}</Typography>
                                    <Box>
                                      {item.data.map((items) => (
                                        <Chip
                                          onClick={() =>
                                            this.handleActivityFilter(
                                              item.id,
                                              items.id,
                                              filterActivitiesId?.[
                                                item.id
                                              ]?.includes(items.id)
                                            )
                                          }
                                          variant="outlined"
                                          key={index}
                                          label={items.name}
                                          className={`adv-tags ${filterActivitiesId?.[
                                              item.id
                                            ]?.includes(items.id) &&
                                            "select-tag"
                                            }`}
                                          data-test-id="filter-activity-button"
                                        />
                                      ))}
                                    </Box>
                                  </Box>
                                </AdventurWrapper>
                              );
                            })
                            : null}
                        </Box>
                        <Box
                          justifyContent="end"
                          sx={{ padding: "10px" }}
                          component="div"
                          display="flex"
                        >
                          <Button
                            className="apply-btn"
                            variant="contained"
                            color="primary"
                            data-test-id="apply-filter-button"
                            onClick={this.handleApplyActivityFilter}
                            size="large"
                          >
                            {configJSON.filterBtn}
                          </Button>
                        </Box>
                      </Box>
                    </FilterWrapper>
                  </Box>
                  <Typography
                    className="feed-label"
                    component="h5"
                    variant="subtitle1"
                    >{configJSON.fanWallPostTitle1}</Typography>
                </Box>
              <Loader loading={isLoading} />
              <Box
                id="scrollableDiv"
                className="scrolling-feed-container"
                >
                {userPost?.length ? <InfiniteScroll
                  next={this.handlePost}
                  data-test-id='post-box'
                  scrollableTarget="scrollableDiv"
                  className="scroll-item-flex" 
                  loader={<>{configJSON.loadingMassage}</>}
                  hasMore={hasMoreData}
                  dataLength={userPost.length}
                >
                  {userPost.map((post, index) => {
                    return <PostCard
                            index={index}
                            userDetails={{}}
                            item={post?.attributes} id={index.toString()}
                            navigation={this.props.navigation}
                            updateShareCount={this.handleLoginModal}
                            isUnAuthorized={true}
                            handleDeletePostLocal={this.handleLoginModal}
                            handleLikePostAction={this.handleLoginModal}
                          />
                  })}</InfiniteScroll> : 
                  configJSON.noPostFoundTitle
                }
              </Box>
            </Grid>
      </WrapperContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const WrapperContainer = styled("section")({
  backgroundColor:'#f4f5fa',
  height: "calc(100vh - 98px)",
  "& .container": {
    width: '100%',
  },
  "& .search-box":{
    position: "sticky",
    top: "0",
    zIndex: 11,
    paddingTop: "30px",
    margin: "auto",
    width: "90%",
  },
  "& .main-wrapper": {
    position: "relative",
    height: "100%",
    display: "flex",
  },
  "& .scrolling-feed-container":{
    height: "calc( 100% - 145px )",
    margin: "auto",
    width: "90%", 
    overflowY: "scroll",
  },
  "& .left-side-container": {
    overflow: "hidden",
    margin:'0 auto',
    height: "100%",
  },
  "& .scroll-item-flex": {
    gap: "20px",
    flexDirection: "column",
    display: "flex",
  },
  "& .search-container": {
    width: "100%",
    boxSizing: "border-box",
    border: '1px solid #36aede',
    padding: "10px 26px",
    margin: "0px auto 10px auto",
    borderRadius:'15px',
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
  },
  "& .filter-icon": {
    paddingRight: "10px"
  },
  "& .filter-button": {
    color: '#656565'
  },
  "& .feed-label": {
    padding: "0 0 10 10",
    fontWeight: "bold",
    color: "black",
    fontSize: "20px",
  },
  "&  .MuiDrawer-paper": {
    background: '#EFBFED',
    boxSizing: 'border-box',
    borderRadius: '16px 0 0 16px',
    width: "450px",
  },
  "& .post": {
    position: "relative"
  },
});

const FilterWrapper = styled(Popper)({
  "& .filtermodel": {
    maxHeight: "80vh",
    backgroundColor: "white",
    flexDirection: "column",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px",
    paddingTop: "20px",
    paddingInline: "10px",
    borderRadius: "12px",
    display: "flex",
  },
  "& .filter-box":{
    height: "100%",
    maxHeight: "400px",
    overflow: "auto",
  },
  "& .filterheader": {
    justifyContent: "space-between",
    padding: "10px 15px 5px 15px",
    display: "flex",
    alignItems: 'center',
    height: "65px",
  },
  "& .subtitle": {
    fontSize: "12px",
    color: "#656565"
  },
  "& .activetitle": {
    fontSize: "20px",
  },
  "& .apply-btn": {
    fontSize: "20px",
    background: "#25AAE1",
    textTransform: "capitalize",
    padding: '4px 30px',
    color: "#FFFFFF",
  },
});
const AdventurWrapper = styled("div")({
  "& .adv-tags": {
    cursor: "pointer",
    margin: "5px 5px 5px 5px",
  },
  "& .adv-title": {
    padding: "10px 15px 5px 15px",
  },
  "& .select-tag": {
    "&:hover": {
      color: "#000000"
    },
    transition: "all 0.3s",
    color: "white !important",
    backgroundColor: "#25aae1 !important",
  }

});
// Customizable Area End
