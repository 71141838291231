// Customizable Area Start
export const SideMenuStyles = {
  SideMenu: {
    overflow: "hidden",
    height: "100%",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    "& .side-menu": {
      width: "170px",
      height: "100%",
      boxShadow: "0px 2px 10px rgba(0,0,0,0.13)",
      textAlign: "center",
      backgroundColor: "#FAFBFF",
      overflow: "auto",
      opacity: "0.9",
      ["@media (max-width:962px)"]: {
        width: "100px",
      },
      "& .main-image": {
        height: "72px",
        width: "54px",
        marginTop: "40px",
      },
      "& .side-menu-items": {
        display: "flex",
        flexDirection: "column",
        marginTop: "40px",
        gap: "30px",
        alignItems: "center",
      },
      "& .side-menu-image": { height: 48, width: 48 },
      "& .nav-link": {
        width: "fit-content",
      },
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(23,89,117,0.16)",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "rgb(0 4 5 / 16%)",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: " #f1f1f1",
      },
    },
    "& .side-component": {
      width: "calc(100% - 170px)",
      overflowY: "auto",
      scrollWidth: "none",
      height: "100%",
      ["@media (max-width:962px)"]: {
        width: "calc(100% - 100px)",
      },
    },
  },
};
// Customizable Area End
