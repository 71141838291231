// Customizable Area Start
export const ExploreStyles = {
  Explore: {
    padding: "40px 20px 20px",
    boxSizing: "border-box",
    height: "100%",
    backgroundColor: "#FAFBFF",
    "& .main-heading": {
      fontFamily: "Helvetica-Bold",
      fontSize: "22px",
      fontWeight: "600",
      color: "#000000",
    },
    "& .description-text": {
      color: "#a2a3a6",
      fontFamily: "Helvetica-Bold",
      fontSize: "14px",
      fontWeight: "400",
      linHeight: "20px",
      margin: "20px 0",
    },
    "& .not-found-text": {
      textAlign: "center",
      width: "100%",
      fontSize: "16px",
      fontFamily: "Helvetica",
      lineHeight: "20px",
      color: "#000000",
    },
  },
};
// Customizable Area End
