// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/utils.web";


export const configJSON = require("./config");
export interface Props {
  navigation: any;
  history: { push: ()=>{} , location: { state: { search: string }, pathname: string }}
  id: string;
  

}

interface S {
  exploreList: {
    id: string;
    type: string;
    attributes: {
      id: number;
      city_name: string;
      location_cover_image: string;
    };
  }[];
  LocationsSearch: string;
  isLoading: boolean;
  page: number;
  total_page: number;
  hasMore: boolean;
  isLogin: boolean;
  subcategorys: number;
}

interface SS {
  id: any;
}

export default class PlacesController extends BlockComponent<Props, S, SS> {
  exploreListApiCallId: string = "";
  exploreMoreListApiCallId: string = "";
  timerId: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      exploreList: [],
      isLoading: false,
      page: 1,
      total_page: 1,
      hasMore: false,
      isLogin: false,
      LocationsSearch: '',
      subcategorys: 0
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
  }

  getExploreList = async () => {
    const endPoint = this.state.LocationsSearch ? configJSON.searchBoonmarkApiEndPoint + this.state.page + '&query=' + this.state.LocationsSearch : configJSON.bookMarkApiEndpoint + this.state.page
    this.exploreListApiCallId = await apiCall({
      endPoint: endPoint,
      method: configJSON.validationApiMethodType,
    });
  };

  fetchMoreData = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getExploreList();
      }
    );
  };

  handleLoginModal = () => {
    this.setState({ isLogin: !this.state.isLogin })
  }

  handleResponse = async (apiRequestCallId: '', responseJson: any) => {
    const total_pages = responseJson.locations.meta.total_pages;
    const hasMore =
      this.state.page < responseJson.locations.meta.total_pages
        ? true
        : false;
    if (
      apiRequestCallId === this.exploreListApiCallId &&
      responseJson.locations
    ) {
      this.setState({
        exploreList: [
          ...this.state.exploreList,
          ...responseJson.locations.data
        ],
        hasMore,
        total_page: total_pages,
      });
    } else if (apiRequestCallId === this.exploreMoreListApiCallId && responseJson.locations) {
      const catData: {}[] = [];
      let subcategorys = this.state.exploreList.length <= 20 ? 0 : this.state.subcategorys;
      responseJson.locations.data.map((item: { attributes: { categories: { data: { attributes: { name: '', category_cover_image: '' } }[] }, city_name: '' } }) => {
        catData.push(item);
        item.attributes.categories.data?.length && item.attributes.categories.data.map((list) => {
          subcategorys++;
          catData.push({
            attributes: {
              location_cover_image: list.attributes.category_cover_image,
              city_name: list.attributes.name + configJSON.inTiltle + item.attributes.city_name
            }
          }
          )
        })
      })

      this.setState({
        exploreList: this.state.LocationsSearch ? catData : responseJson.locations.data,
        hasMore,
        total_page: total_pages,
        subcategorys
      });
      this.props.navigation.history.replace("/Places", { state: undefined })
    }
  };

  handleLocations = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.setState({
      LocationsSearch: event.target.value
    });
    this.timerId = setTimeout(this.handleLocationsTravellers, 2000) as unknown as NodeJS.Timeout;
  }

  handleLocationsTravellers = async () => {
    this.setState({
      page: 1,
      isLoading: true,
    })
    this.exploreMoreListApiCallId = await apiCall({
      endPoint: configJSON.searchBoonmarkApiEndPoint + "1&query=" + this.state.LocationsSearch,
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
    })
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        await this.handleResponse(apiRequestCallId, responseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
