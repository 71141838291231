import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  userDetails: any;

  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isLoader: boolean;
  tabValue: number;
  openContactModal: boolean;
  itineraryList: any;
  placeVisitedList: any;
  profileData: any;
  userPost: any;
  placeVisitedPostList: any;
  visiblePlaceVisitedPost: boolean;
  ownerData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetItineraryCallID: string = "";
  apiGetPlaceVisitedCallID: string = "";
  apiGetProfileDataCallID: string = "";
  apiGetUserPostCallID: string = "";
  apiGetPlaceVisitedPostCallID: string = "";
  apiPostFollowCallID: string = "";
  apiRemoveFollowCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoader: false,
      tabValue: 0,
      openContactModal: false,
      itineraryList: [],
      placeVisitedList: [

      ],
      profileData: {

      },
      userPost: [],
      placeVisitedPostList: {},
      visiblePlaceVisitedPost: false,
      ownerData: {
        "id": "67",
        "type": "profile",
        "attributes": {
          "id": 67,
          "travel_bio": "#china india",
          "instagram": "he",
          "facebook": "facebook",
          "account_id": 404,
          "name": "sachin",
          "email": "sk_4@yahoo.com",
          "full_phone_number": "8988383881",
          "followings": 0,
          "followers": 1,
          "is_following": false,
          "photo": "https://minio.b277191.dev.eastus.az.svc.builder.cafe/sbucket/lv5itt9fb03fuszug3vq53ptd8op",
          "interests_name": [
            "Jumping",
            "Jumping",
            "Jumping_2",
            "Hiking",
            "Jetskii"
          ],
          "interests": {
            "data": [
              {
                "id": "1",
                "type": "activity_category",
                "attributes": {
                  "name": "swimming",
                  "activities": {
                    "data": [
                      {
                        "id": "1",
                        "type": "activity",
                        "attributes": {
                          "name": "Jumping"
                        }
                      },
                      {
                        "id": "2",
                        "type": "activity",
                        "attributes": {
                          "name": "Jumping"
                        }
                      }
                    ]
                  }
                }
              },
              {
                "id": "2",
                "type": "activity_category",
                "attributes": {
                  "name": "swimming",
                  "activities": {
                    "data": [
                      {
                        "id": "3",
                        "type": "activity",
                        "attributes": {
                          "name": "Jumping_2"
                        }
                      }
                    ]
                  }
                }
              },
              {
                "id": "11",
                "type": "activity_category",
                "attributes": {
                  "name": "Adventure",
                  "activities": {
                    "data": [
                      {
                        "id": "4",
                        "type": "activity",
                        "attributes": {
                          "name": "Hiking"
                        }
                      }
                    ]
                  }
                }
              },
              {
                "id": "17",
                "type": "activity_category",
                "attributes": {
                  "name": "Water Activities",
                  "activities": {
                    "data": [
                      {
                        "id": "8",
                        "type": "activity",
                        "attributes": {
                          "name": "Jetskii"
                        }
                      }
                    ]
                  }
                }
              }
            ]
          }
        }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.handleErrorResponse(responseJson.errors);
        this.setState({ isLoader: false })
      } else if (responseJson) {
        this.checkApiResponse(responseJson, apiRequestCallId);
      } else {
        this.handleErrorResponse(errorReponse);
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start

  checkApiResponse = (responseJson: any, apiRequestCallId: any) => {
    switch (apiRequestCallId) {
      case this.apiGetItineraryCallID:
        this.setState({ itineraryList: responseJson, isLoader: false });
        break;
      case this.apiGetPlaceVisitedCallID:
        this.setState({ placeVisitedList: responseJson?.data, isLoader: false });
        break;
      case this.apiGetProfileDataCallID:
        this.setState({ profileData: responseJson?.profile?.data, isLoader: false });
        break;
      case this.apiGetUserPostCallID:
        this.setState({ userPost: responseJson?.data, isLoader: false });
        break;
      case this.apiGetPlaceVisitedPostCallID:
        this.setState({ placeVisitedPostList: responseJson?.data?.attributes, isLoader: false });
        this.setState({ visiblePlaceVisitedPost: !this.state.visiblePlaceVisitedPost })
        break;
      case this.apiPostFollowCallID:
        const profile=this.state.profileData;
        profile.attributes.followers = profile.attributes.followers+1;
        profile.attributes.is_following = true
        this.setState({profileData:profile});
        break;
      case this.apiRemoveFollowCallID:
        const profile1=this.state.profileData;
        profile1.attributes.is_following = false
        profile1.attributes.followers = profile1.attributes.followers-1;
        this.setState({profileData:profile1})
        break;
      default:
        break;
    }
  };

  fetchItinerary = (id: string | number) => {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetItineraryCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.itineraryListUrl+id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true })
    return true;
  };
  fetchPlaceVisited = (id: string | number) => {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPlaceVisitedCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userPlaceVisitedUrl + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true })
    return true;
  };
  fetchUserProfile = (id: number) => {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProfileDataCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userProfileUrl + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true })
    return true;
  };
  fetchUserPost = (id: string | number) => {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserPostCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userPostUrl + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true })
    return true;
  };
  fetchPlaceVisitedPost = (id: string) => {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPlaceVisitedPostCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userPlaceVisitedPostUrl + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true })
    return true;
  };
  createFollow = (id: any) => {

    const formData = new FormData()
    formData.append('follow[following_id]', id)
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostFollowCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createFollowUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  removeFollow = (id: any) => {
    
    const header = {
       "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRemoveFollowCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeFollowingUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({id})
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  goToSettings = () => {
    this.props.navigation.navigate("Settings5");
  };
  handleChange = (e: any, newValue: number) => {
    this.setState({ tabValue: newValue });
  };
  handleContactModal = () => {
    this.setState({ openContactModal: !this.state.openContactModal });
  };


  handleErrorResponse = (errors: any) => {
    this.setState({ isLoader: false })
    this.parseApiErrorResponse(errors);
    this.parseApiCatchErrorResponse(errors);
    this.handleError(errors)


  };
  handleError = (errors: any) => {
    if (Array.isArray(errors)) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}, Please login again.`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      } else if (errors[0]) {
        toast.error(errors[0]);
        this.props.navigation.goBack();
        return;
      }
    }
    toast.error("Something went to wrong please retry");
  };

  updateShareCount = (postId: number, share_count: number) => {
    const userUserPost = this.state.userPost;
    const postIndex = userUserPost.findIndex((data: any) => Number(data.id) === postId);
    if (postIndex !== -1) {
      const oneData = userUserPost[postIndex];
      oneData.attributes.share_count =
        oneData.attributes.share_count + share_count;
        userUserPost.splice(postIndex, 1, oneData);
      this.setState({
        userPost: userUserPost,
      });
    }
  };

  updateSharePostVisitedCount = (postId: number, share_count: number) => {
    const userSharePost = this.state.placeVisitedPostList.posts.data;
    const postIndex = userSharePost.findIndex((data: any) => Number(data.id) === postId);
    if (postIndex !== -1) {
      const oneData = userSharePost[postIndex];
      oneData.attributes.share_count =
        oneData.attributes.share_count + share_count;
        userSharePost.splice(postIndex, 1, oneData);
      this.setState({
        userPost:userSharePost,
      });
    }
  };
  // Customizable Area End
}
