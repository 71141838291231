// Customizable Area Start
export const HotelRatingStyles = {
  hotelRatings: {
    padding: "20px 20px 20px",
    boxSizing: "border-box",
    minHeight: "100%",
    backgroundColor: "#FAFBFF",
    
    "& .detail-container": {
      margin: "unset !important",
      padding: "0 !important",
    },
    "& .white-box-container": {
      border: "0.5px solid rgba(105,134,216,0.1)",
      borderRadius: "12px",
      padding: "20px",
      boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
      background: "#ffffff",
      backgroundBlendMode: "normal",
    },
    "& .not-found-text": {
      fontSize: "16px",
      textAlign: "center",
      lineHeight: "20px",
      color: "#000000",
      width: "100%",
      fontFamily: "Helvetica",
    },
    "& .text-area-field::placeholder": {
      color: "#d5d5d5",
    },
    "& .text-area-field": {
      outline: "none",
      fontSize: "18px",
      backgroundBlendMode: "normal",
      borderRadius: "16px",
      background: "#ffffff",
      fontFamily: "Helvetica",
      padding: "25.32px 14px",
      resize: "none",
      fontWeight: "400",
      width: "100%",
      border: "0.5px solid rgba(105,134,216,0.1)",
      color: "#353249",
    },
    "& .bookmark-button": {
      backgroundBlendMode: "normal",
      height: "72px",
      borderRadius: "4px",
      background: "#25aae1",
      "& .MuiButton-label": {
        fontWeight: "400",
        fontFamily: "Helvetica",
        color: "#ffffff",
        fontSize: "24px",
        textTransform: "capitalize",
      },
    },
    "& .page-title": {
      fontSize: "22px",
      fontFamily: "Helvetica-Bold",
      fontWeight: "600",
      margin: "15px 0 20px",
      lineHeight: "27px",
      color: "#000000",
    },
    "& .rating": {
      "& .MuiSvgIcon-root": {
        height: "40px",
        width: "40px",
      },
      "& .MuiRating-iconEmpty":{
        color:"rgb(149 143 143 / 26%)",
      }
    },
  },
};
// Customizable Area End
