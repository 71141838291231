// Customizable Area Start
import React from "react";

import {
  TextField,
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  TextareaAutosize,
  Chip,
} from "@material-ui/core";
import { Formik } from "formik";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import PostCreateController, { Props } from "./EditUserProfileController.web";
import { EditProfileStyles } from "./EditUserProfile.styles.web";
import Loader from "../../../components/src/Loader.web";
import BackButton from "../../../components/src/BackButton.web";
import VerificationModal from "../../../components/src/VerificationModal.web";
import OtpVerificationModal from "../../../components/src/OtpVerificationModal.web";
import { Upload, emailImage, phoneImage, profileAvatar } from "./assets";

class EditUserProfile extends PostCreateController {
  constructor(props: Props) {
    super(props);
  }

  imageUrl = (photo: any) => {
    let url;
    if (typeof photo === "object" && photo) {
      url = URL.createObjectURL(photo);
    } else if (
      typeof photo === "string" &&
      photo &&
      photo.startsWith("https")
    ) {
      url = photo;
    } else {
      url = profileAvatar;
    }

    return url;
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.Profile}>
          <BackButton navigation={this.props.navigation} />
          <Typography variant="h5" className="title">
            Edit Profile
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={{
              full_name: this.state.first_name,
              user_name: this.state.user_name,
              travel_bio: this.state.travel_bio,
              facebook: this.state.facebook,
              instagram: this.state.instagram,
              photo: this.state.photo,
              tags: [],
              email: this.state.email,
              mobile_number: this.state.mobile_number,
              isEmailPrivate: this.state.isEmailPrivate,
              isPhoneNumberPrivate: this.state.isPhoneNumberPrivate,
              userTags: this.state.userTags,
              verifiedEmail: this.state.email,
              verifiedPassword: this.state.mobile_number,
            }}
            validationSchema={this.validationSchema}
            onSubmit={(value) => {
              this.userDetailsUpdate({
                full_name: value.full_name,
                facebook: value.facebook.trim(),
                travel_bio: value.travel_bio,
                instagram: value.instagram.trim(),
                photo: value.photo,
                tags: value.tags.length > 0 ? value.tags : value.userTags,
                isEmailPrivate: value.isEmailPrivate,
                isPhoneNumberPrivate: value.isPhoneNumberPrivate,
                user_name: value.user_name,
              });
            }}
          >
            {({
              errors,
              values,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {!this.state.openTagPage && (
                    <>
                      <Grid container spacing={2} className="white-box">
                        <Grid item md={6} sm={6} className="left-part">
                          <div className="image-main-container mb-20">
                            <div
                              className="image-container classa"
                              onClick={this.handleImageClick}
                            >
                              <img
                                src={this.imageUrl(values.photo)}
                                alt="image"
                              />
                              <Upload />
                              <input
                                accept="image/*"
                                ref={(ref) => (this.inputFileRef = ref)}
                                id="file-input"
                                type="file"
                                name="photo"
                                style={{ display: "none" }}
                                onChange={(e: any) =>
                                  setFieldValue("photo", e.target.files[0])
                                }
                              />
                            </div>
                            <label className="image-side-text">
                              Profile Picture
                            </label>
                          </div>
                          {errors.photo && (
                            <p className="error mb-20">{errors.photo}</p>
                          )}
                          <div className="box-shadow-text-field">
                            <TextField
                              className="input-field"
                              value={values.full_name}
                              onChange={handleChange}
                              variant="outlined"
                              placeholder="Full Name"
                              name="full_name"
                              fullWidth
                              id="full_name"
                              type="text"
                            />
                          </div>
                          {errors.full_name && (
                            <p className="error mb-20">{errors.full_name}</p>
                          )}
                          <div className="box-shadow-text-field">
                            <TextField
                              className="input-field"
                              value={values.user_name}
                              onChange={(e) => {
                                this.isUserNameExist(e, values)
                                handleChange(e)
                              }}
                              variant="outlined"
                              placeholder="User Name"
                              name="user_name"
                              fullWidth
                              id="user_name"
                              type="text"
                            />
                          </div>
                          {errors.user_name && (
                            <p className="error mt-0 mb-20">{errors.user_name}</p>
                          )}
                          {this.state.isExistUser && (
                            <p className="error mt-0 mb-20">{this.state.isExistUser}</p>
                          )}
                          <div className="box-shadow-text-field">
                            <TextareaAutosize
                              className="text-area-field"
                              minRows={5}
                              placeholder="Travel Bio"
                              value={values.travel_bio}
                              onChange={handleChange}
                              name="travel_bio"
                            />
                          </div>
                          <div className="box-shadow-text-field">
                            <TextField
                              className="input-field"
                              value={values.email}
                              onChange={(e) =>
                                setFieldValue("email", e.target.value)
                              }
                              variant="outlined"
                              placeholder="Email Address"
                              fullWidth
                              id="email"
                              name="email"
                              type="email"
                            />
                          </div>
                          {errors.email && (
                            <p className="error mb-20">{errors.email}</p>
                          )}
                          {values.verifiedEmail != values.email &&
                            errors.email === "Please verify email" && (
                              <div className="right-align-items mb-20">
                                <label
                                  className="verify-label"
                                  onClick={this.verifyEmail.bind(this,values.email)}
                                >
                                  Verify
                                </label>
                              </div>
                            )}
                          <div className="right-align-items mb-20">
                            <label
                              htmlFor="check-box-email"
                              className="checkbox-label"
                            >
                              Make it private
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                name="isEmailPrivate"
                                id="check-box-email"
                                checked={values.isEmailPrivate}
                                onChange={handleChange}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                          <div className="box-shadow-text-field">
                            <TextField
                              className="input-field"
                              value={values.mobile_number}
                              onChange={(e) =>
                                setFieldValue("mobile_number", e.target.value)
                              }
                              variant="outlined"
                              placeholder="Mobile Number"
                              fullWidth
                              id="mobile_number"
                              name="mobile_number"
                              type="text"
                            />
                          </div>
                          {errors.mobile_number && (
                            <p className="error mb-20">
                              {errors.mobile_number}
                            </p>
                          )}
                          {values.verifiedPassword != values.mobile_number &&
                            errors.mobile_number ===
                            "Please verify password" && (
                              <div className="right-align-items mb-20">
                                <label
                                  className="verify-label"
                                  onClick={this.verifyPhone.bind(this,values.mobile_number)}
                                >
                                  Verify
                                </label>
                              </div>
                            )}
                          <div className="right-align-items mb-20">
                            <label
                              htmlFor="check-box-number"
                              className="checkbox-label"
                            >
                              Make it private
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="check-box-number"
                                name="isPhoneNumberPrivate"
                                checked={values.isPhoneNumberPrivate}
                                onChange={handleChange}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </Grid>
                        <Grid item md={6} sm={6} className="right-part">
                          <Typography className="form-inner-label mb-20">
                            Social media
                          </Typography>
                          <div className="box-shadow-text-field">
                            <TextField
                              className="input-field"
                              value={values.instagram}
                              onChange={handleChange}
                              name="instagram"
                              variant="outlined"
                              placeholder="Instagram user ID"
                              fullWidth
                              id="instagram"
                              type="text"
                            />
                          </div>
                          <div className="box-shadow-text-field">
                            <TextField
                              className="input-field"
                              value={values.facebook}
                              onChange={handleChange}
                              variant="outlined"
                              placeholder="Facebook user ID"
                              name="facebook"
                              fullWidth
                              id="facebook"
                              type="text"
                            />
                          </div>
                          <Typography className="form-inner-label mb-20">
                            Interests
                          </Typography>
                          <div className="box-shadow-text-field">
                            <div
                              className="text-field-outside-box chip-item-container"
                              onClick={() =>
                                this.setState({
                                  openTagPage: true,
                                })
                              }
                            >
                              {values.tags.length != 0 ? (
                                values.tags.map((item: any) => {
                                  return (
                                    <Chip
                                      key={`${item.attributes.name}${item.id}`}
                                      label={item.attributes.name}
                                      className="selected-chip"
                                    />
                                  );
                                })
                              ) : (
                                <p className="place-holder">
                                  Activity of Interest
                                </p>
                              )}
                            </div>
                          </div>
                          {errors.tags && (
                            <p className="error">{errors.tags}</p>
                          )}
                          <div className="chip-item-container">
                            {this.state.userTags.map((item) => {
                              return (
                                <button
                                  className="chip-item"
                                  key={`${item.id}${item.attributes.name}`}
                                >
                                  {item.attributes.name}
                                </button>
                              );
                            })}
                          </div>
                        </Grid>
                      </Grid>

                      <div className="button-container">
                        <Button
                          variant="contained"
                          className="published-button"
                          type="submit"
                        >
                          Done
                        </Button>
                      </div>
                    </>
                  )}

                  {this.state.openTagPage && (
                    <Grid container spacing={2} className="white-box">
                      <div className="add-Tags">
                        <Button
                          onClick={() =>
                            this.setState({
                              openTagPage: false,
                            })
                          }
                          className="back-button"
                          variant="contained"
                        >
                          <ArrowBackIosIcon className="back-button-image" />{" "}
                          Back
                        </Button>
                        <Typography className="main-heading" variant="h4">
                          Select Tags
                        </Typography>

                        <Formik
                          validationSchema={this.validationTagsFormSchema}
                          enableReinitialize={true}
                          initialValues={{
                            tags:
                              values.tags.length > 0
                                ? values.tags
                                : values.userTags,
                          }}
                          onSubmit={(value) => {
                            setFieldValue("tags", value.tags);
                            this.setState({
                              openTagPage: false,
                            });
                          }}
                        >
                          {({
                            touched,
                            errors,
                            values,
                            handleSubmit,
                            setFieldValue,
                          }) => {
                            return (
                              <form onSubmit={handleSubmit}>
                                <div className="tag-items">
                                  {this.state.tags.length > 0 ? (
                                    this.state.tags.map((data) => {
                                      return (
                                        <>
                                          <Typography
                                            className="tag-heading"
                                            variant="h5"
                                          >
                                            {data.attributes.name}
                                          </Typography>
                                          <div className="chip-item-container">
                                            {data.attributes.activities.data.map(
                                              (item) => (
                                                <Chip
                                                  key={`${item.attributes.name}${item.id}`}
                                                  label={item.attributes.name}
                                                  onClick={() => {
                                                    if (
                                                      values.tags.some(
                                                        (tag) =>
                                                          tag.id === item.id
                                                      )
                                                    ) {
                                                      setFieldValue(
                                                        "tags",
                                                        values.tags.filter(
                                                          (c) =>
                                                            c.id !== item.id
                                                        )
                                                      );
                                                    } else {
                                                      const tag = {
                                                        ...item,
                                                        parentId: data.id,
                                                      };
                                                      setFieldValue("tags", [
                                                        ...values.tags,
                                                        tag,
                                                      ]);
                                                    }
                                                  }}
                                                  className={
                                                    values.tags.findIndex(
                                                      (tag) =>
                                                        tag.id === item.id
                                                    ) != -1
                                                      ? "selected-chip"
                                                      : "un-selected-chip"
                                                  }
                                                />
                                              )
                                            )}
                                          </div>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <p className="not-found-text">
                                      No tags found
                                    </p>
                                  )}
                                </div>

                                {errors.tags && touched.tags && (
                                  <p
                                    className="error"
                                    style={{
                                      marginBottom: "15px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    {errors.tags}
                                  </p>
                                )}
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    className="published-button"
                                    fullWidth
                                    style={{ marginBottom: 0 }}
                                  >
                                    Continue
                                  </Button>
                                </div>
                              </form>
                            );
                          }}
                        </Formik>
                      </div>
                    </Grid>
                  )}
                </form>
              );
            }}
          </Formik>
        </Box>
        <VerificationModal
          open={this.state.emailVerificationModal}
          imageSrc={emailImage}
          title="Check your mail"
          description="Please check your mail to confirm and get started"
          onClose={this.closeEmailVerificationModal}
        />
        <VerificationModal
          open={this.state.emailSuccessFullyVerifiedModal}
          imageSrc={emailImage}
          title="Email Updated"
          onClose={this.closeEmailSuccessFullyVerifiedModal}
        />
        {this.state.emailOtpVerificationModal && (
          <OtpVerificationModal
            open={this.state.emailOtpVerificationModal}
            onClose={this.closeEmailOtpVerificationModal}
            verifyEmailOtp={this.verifyEmailOtp}
            descriptionText={`Enter the 4 digit OTP sent via email to ${this.state.updateEmail}`}
            resendOtp={this.resendEmailOtp}
            errorMessage={this.state.errorMessage}
          />
        )}

        <VerificationModal
          open={this.state.phoneVerificationModal}
          imageSrc={phoneImage}
          title="Check your phone"
          description="Please check your phone to confirm and get started"
          onClose={this.closePhoneVerificationModal}
        />
        <VerificationModal
          open={this.state.phoneSuccessFullyVerifiedModal}
          imageSrc={phoneImage}
          title="Phone Number Updated"
          onClose={this.closePhoneSuccessFullyVerifiedModal}
        />
        {this.state.phoneOtpVerificationModal && (
          <OtpVerificationModal
            open={this.state.phoneOtpVerificationModal}
            onClose={this.closePhoneOtpVerificationModal}
            verifyEmailOtp={this.verifyPhoneOtp}
            descriptionText={`Enter the 4 digit OTP sent via SMS on your phone ${this.state.updatedPhoneNumber}`}
            resendOtp={this.resendPhoneOtp}
            errorMessage={this.state.errorMessage}
          />
        )}
      </>
    );
  }
}

//@ts-ignore
export default withStyles(EditProfileStyles)(EditUserProfile);
// Customizable Area End
