import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/utils.web";
import { toast } from "react-toastify";
import React, { RefObject } from "react";

// Customizable Area Start
interface IUser {
  id: number;
  name: string;
  email: string;
  offline: boolean;
  profile_photo: string;
  user_name: string;
  blocked_by_user: boolean;
  blocked_by_me: boolean;
}

interface IChat {
  chat_id: number;
  name: string;
  chat_type: number;
  users: IUser[];
  unread_messages: number;
  last_message: string;
  last_message_datetime: string;
 
}
// Customizable Area End

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  index: number;
  item: any;
  userDetails: any;
  handleLikePostAction: any;
  handleDeletePostLocal: any;
  chatListAgainApiCall?: () => void;
  updateShareCount: (postId: number, share_count: number) => void;
  isUnAuthorized?: boolean;
}

interface S {
  // Customizable Area Start
  temp: {
    [keys: number]: string | number | any;
  };
  txtInputValue: string;
  like: boolean;
  commentId: number;
  anchorEle: any;
  filterPopUp: boolean;
  comments: {
    id: number;
    content: string;
    created_at: string;
    account_full_name: string;
    account_image: string;
    commentable_id: number;
    comment_by_id: number;
  }[];
  commentText: string;
  token: string;
  showEmojis: boolean;
  confirmModel: boolean;
  myCommentId: number;
  tempMenuId: number;
  showMore: number;
  postMenu: any;
  postMenuId: number;
  deletePostModel: boolean;
  shareModalId: number;
  shareModal: boolean;
  currentPostId: number;
  commentCount: number;
  editCommentData: {
    [keys: string]: string | number;
  };
  sharePostURL: string;
  chatList: IChat[];
  userDetail: any;
  searchText: string;
  searchUserList: {
    id: string;
    type: string;
    attributes: {
      full_name: string;
      email: string;
      full_phone_number: string;
      gender: string;
      date_of_birth: string;
      username: string | null;
      state: string;
      city: string;
      allow_push_notification: boolean;
      in_app_push_notification: boolean;
      activated: boolean;
      photo: string;
      is_following: boolean;
      interests: string[];
      is_followed_by_current_user: boolean;
    };
  }[];
  selectedUser: { name: string; id: number }[];
  chatListPageNumber: number;
  chatListHasMorePage: boolean;
  searchListPageNumber: number;
  searchListHasMorePage: boolean;
  isLoading: boolean;
  isInViewport: boolean[],
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PostController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  AllPostId: string = "";
  handleDeleteCommentId: string = "";
  PostCommentId: string = "";
  handlePostCommentId: string = "";
  likePostId: string = "";
  handleUpdateCommentId: string = "";
  handleDeletePostId: string = "";
  activitiesId: string = "";
  searchActivityApiId: string = "";
  searchApiId: string = "";
  reportPostId: string = "";
  getChatListApiCallId: string = "";
  searchUserListApiCallId: string = "";
  sendMessageToUserApiCallId: string = "";
  videoRefs: RefObject<HTMLVideoElement>[];
  observer: IntersectionObserver[];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.videoRefs = this.props.item.images_and_videos.map(() => React.createRef());
    this.observer = this.props.item.images_and_videos.map((_: {}, index: number) =>
      new IntersectionObserver((entries) => this.handleIntersection(index, entries), {
        threshold: 0.5,
      })
    );
    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      like: false,
      commentId: NaN,
      anchorEle: false,
      filterPopUp: false,
      token: localStorage.getItem("authToken") || "",
      comments: [],
      myCommentId: NaN,
      commentText: "",
      showEmojis: false,
      confirmModel: false,
      temp: {},
      postMenuId: NaN,
      tempMenuId: NaN,
      showMore: NaN,
      postMenu: "",
      deletePostModel: false,
      shareModal: false,
      currentPostId: NaN,
      commentCount: 0,
      shareModalId: NaN,
      editCommentData: {},
      sharePostURL: "",
      chatList: [],
      userDetail: "",
      searchText: "",
      searchUserList: [],
      selectedUser: [],
      chatListHasMorePage: false,
      chatListPageNumber: 1,
      searchListHasMorePage: false,
      searchListPageNumber: 1,
      isLoading: false,
      isInViewport: Array(this.props.item.images_and_videos.length).fill(false),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handlePostLike = this.handlePostLike.bind(this);
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }
  reportAPIResponce = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.reportPostId) {
      console.log("");
    }
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleApiResponseInPost(responseJson, apiRequestCallId);
      this.setState({
        isLoading: false,
      });
      this.reportAPIResponce(from, message);
    }

    // Customizable Area Start
    // Customizable Area End
  }

  // web events

  // Customizable Area Start

  handleLikePostResponse = (responseJson: any) => {
    if (responseJson.message === "Unlike Successfully") {
      this.props.item.likes = --this.props.item.likes;
      this.props.item.liked_by_user = "false";
    } else {
      this.props.item.likes = ++this.props.item.likes;
      this.props.item.liked_by_user = "true";
    }
    this.props.handleLikePostAction(this.props.item, this.props.index);
  };

  handleApiResponseInPost = (responseJson: any, apiRequestCallId: string) => {
    switch (apiRequestCallId) {
      case this.likePostId:
        this.handleLikePostResponse(responseJson);
        break;
      case this.handleUpdateCommentId:
        this.getPostComment(this.state.currentPostId);
        break;
      case this.handleDeletePostId:
        this.props.handleDeletePostLocal(this.props.index);
        break;
      case this.PostCommentId:
        if (responseJson.data.length) {
          this.setState({
            comments: responseJson.data.map(
              (i: { attributes: {} }) => i.attributes
            ),
          });
        }
        break;
      case this.handleDeleteCommentId:
        this.setState({
          commentCount: this.state.commentCount - 1,
        });
        this.getPostComment(this.state.currentPostId);
        break;
      case this.handlePostCommentId:
        this.setState({
          comments: [...this.state.comments, responseJson.data.attributes],
          commentCount: this.state.commentCount + 1,
        });
        break;
      case this.getChatListApiCallId:
        this.handleChatListResponse(responseJson);
        break;
      case this.searchUserListApiCallId:
        this.handleSearchListResponse(responseJson);
        break;
      case this.sendMessageToUserApiCallId:
        this.handleSendMessageResponse(responseJson);
        break;
    }
  };

  handleChatListResponse = (responseJson: any) => {
    if (responseJson.chats_info && responseJson.pagination) {
      const total_pages = responseJson.pagination.total_pages;
      const chatListHasMorePage =
        this.state.chatListPageNumber < total_pages ? true : false;
      this.setState({
        chatList: [...this.state.chatList, ...responseJson.chats_info],
        chatListHasMorePage,
      });
    }
  };

  handleSearchListResponse = (responseJson: any) => {
    if (responseJson.data) {
      const total_pages = responseJson.meta.total_pages;
      const searchListHasMorePage =
        this.state.searchListPageNumber < total_pages ? true : false;
      this.setState({
        searchUserList: [
          ...this.state.searchUserList,
          ...responseJson.data.filter(
            (data: any) => data.id !== this.state.userDetail.id
          ),
        ],
        searchListHasMorePage,
      });
    }
  };

  handleSendMessageResponse = (responseJson: any) => {
    if (responseJson.success) {
      this.props.updateShareCount &&
        this.props.updateShareCount(
          this.state.shareModalId,
          responseJson.success.length
        );
      this.setState({
        shareModal: false,
        selectedUser: [],
        searchUserList: [],
        chatList: [],
        chatListHasMorePage: false,
        chatListPageNumber: 1,
        searchListHasMorePage: false,
        searchListPageNumber: 1,
      });
      this.props.chatListAgainApiCall && this.props.chatListAgainApiCall();
      toast.success("User send post successfully.");
    }
  };

  async componentDidMount() {
    this.setState({
      commentCount: this.props.item.comments || 0,
    });
    const data = localStorage.getItem("user_details");
    if (data) {
      this.setState({
        userDetail: JSON.parse(data),
      });
    }


    this.videoRefs.forEach((ref, index) => {
      if (ref.current) {
        this.observer[index].observe(ref.current);
      }
    });
  }

  handleIntersection = (index: number, entries: {isIntersecting:boolean}[]) => {
    const isInView = entries[0].isIntersecting;
    this.setState((prevState: Readonly<S>) => {
      const newIsInViewport = [...prevState.isInViewport];
      newIsInViewport[index] = isInView;
      return { isInViewport: newIsInViewport };
    });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (this.props.item.comments !== prevProps.item.comments) {
      this.setState({
        commentCount: this.props.item.comments || 0,
      });
    }
    this.state.isInViewport.forEach((isVisible, index) => {
      if (prevState.isInViewport?.length && prevState.isInViewport[index] && !isVisible) {
        // Video has gone out of view, pause it
        if (this.videoRefs[index].current) {
          this.videoRefs[index].current?.pause();
        }
      }
    });
  }

  handlePostLike = () => {
    if (this.props?.isUnAuthorized) {
      this.props.handleLikePostAction()
      return;
    }
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likePostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likePost
    );
    const id = this.props.item.id;
    const body = JSON.stringify({
      data: {
        attributes: {
          likeable_id: id,
          likeable_type: configJSON.blockType,
        },
      },
    });
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCommentMenuEdit = (id: number) => {
    const comment = this.state.comments.filter((item) => item.id === id);
    this.setState({
      commentText: comment[0].content,
      editCommentData: comment[0]
    });
    this.handleCommentMenuClose();
  };

  getPostComment = (id: number) => {
    this.setState({
      currentPostId: id,
    });
    const headers = {
      token: this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPostComments + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onHandleConfirmation = (commentId: number, myCommentId: number) => {
    const headers = {
      token: this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.handleDeleteCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postComment + `/${myCommentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    const body = JSON.stringify({
      commentable_type: configJSON.blockType,
      commentable_id: commentId,
    });
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      commentId,
    });
  };

  onHandleConfirmationPostDelete = (postId: number) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allPost + `/${postId}`
    );
    this.handleDeletePostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePostComment = () => {
    const id = this.props.item.id;
    const value = this.state.commentText;
    if (value) {
      const body = JSON.stringify({
        content: value,
        commentable_id: id,
        commentable_type: "BxBlockPosts::Post"
      });
      this.setState({
        commentId: id,
      });
      const headers = {
        token: this.state.token,
        "Content-Type": configJSON.validationApiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      if (this.state.editCommentData?.id) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );
        this.handleUpdateCommentId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.postComment + `/${this.state.editCommentData.id}`
        );
      } else {
        this.handlePostCommentId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.postComment
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    this.setState({ commentText: "" });
  };

  handleClosePostDeleteModal = () => {
    this.setState({ deletePostModel: false });
  };

  handleMenuClick = (event: any, id: number) => {
    this.setState({ postMenu: event.currentTarget, postMenuId: id });
  };

  handleClosePostMenu = () => { this.setState({ postMenu: null }); };

  handleOpenDeleteModal = () => { this.setState({ deletePostModel: true, postMenu: null }); };

  handleComment = () => {
    if (this.props?.isUnAuthorized) {
      this.props.handleLikePostAction()
      return;
    }
    const id = this.props.item.id;
    if (this.state.commentId !== id) {
      this.setState({ commentId: id });
      this.getPostComment(id);
    } else {
      this.setState({ commentId: NaN });
    }
  };
  handleShare = () => {
    if (this.props?.isUnAuthorized) {
      this.props.handleLikePostAction()
      return;
    }
    const id = this.props.item.id;
    this.setState(
      {
        shareModal: true,
        shareModalId: id,
        sharePostURL: window.location.origin + `/PostList/${id}`,
        isLoading: true,
      },
      () => {
        this.getChatListData();
      }
    );
  };
  handleCloseShareModal = () => {
    this.setState({
      searchListPageNumber: 1,
      shareModalId: NaN,
      chatListPageNumber: 1,
      searchUserList: [],
      chatList: [],
      searchListHasMorePage: false,
      selectedUser: [],
      chatListHasMorePage: false,
      shareModal: false,
    });
  };

  handleSharePost = async () => {
    this.setState({
      isLoading: true,
    });
    const formData = new FormData();
    formData.append("chat[post_id]", this.state.shareModalId.toString());
    formData.append("chat[message][message_text]", this.state.sharePostURL);
    formData.append("chat[message][message_type]", "text");
    formData.append(
      "chat[share_user_ids]",
      `[${this.state.selectedUser.map((data) => data.id)}]`
    );
    formData.append("chat[name]", "private_chat");
    this.sendMessageToUserApiCallId = await apiCall({
      endPoint: "/bx_block_chat/private_chats/shared_post",
      method: "POST",
      body: formData,
    });
  };

  handleEnter = (event: any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      this.handlePostComment();
      this.setState({
        commentText: "",
        showEmojis: false,
      });
    }
  };
  handleEmoji = () => {
    this.setState({
      showEmojis: !this.state.showEmojis,
    });
  };
  handleCommentText = (event: any) => {
    this.setState({
      commentText: event.target.value,
    });
  };
  onCommentEmojiClick = (emojiObject: any) => {
    let emoji = emojiObject.emoji;
    let commentText = this.state.commentText + emoji;
    this.setState({ commentText, showEmojis: false });
  };
  handleCommentMenuClose = () => {
    this.setState({ anchorEle: false });
  };
  handleCommentMenuClick = (event: any, id: number) => {
    this.setState({ anchorEle: event.currentTarget, tempMenuId: id });
  };
  handleCloseModal = () => {
    this.setState({
      tempMenuId: NaN,
      confirmModel: false,
    });
  };

  handleCommentMenuRemove = (postId: number) => {
    this.handleCommentMenuClose();
    this.setState({
      myCommentId: postId,
      confirmModel: true,
    });
  };

  handleClickAway = () => { this.setState({ commentId: NaN }); };

  showMore = (number: number) => { this.setState({ showMore: this.state.showMore >= 0 ? NaN : number }); };

  handleReportPostModal = (id: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    this.setState({ postMenu: null });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportEndponit
    );
    this.reportPostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        reportable_type: "BxBlockPosts::Post",
        reportable_id: id,
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  fetchMoreChatList = async () => {
    this.setState({
      chatListPageNumber: this.state.chatListPageNumber + 1,
    }, () => {
      this.getChatListData();
    }
    );
  };

  getChatListData = async () => {
    this.getChatListApiCallId = await apiCall({
      endPoint: `bx_block_chat/chats?&page=${this.state.chatListPageNumber}&per_page=10`,
      method: "GET",
    });
  };

  fetchMoreSearchList = async () => {
    this.setState({
      searchListPageNumber: this.state.searchListPageNumber + 1,
    }, () => {
      this.getSearchUserList();
    }
    );
  };

  getSearchUserList = async () => {
    this.searchUserListApiCallId = await apiCall({
      endPoint: `bx_block_search/search_users?query=${this.state.searchText}&page=${this.state.searchListHasMorePage}&per_page=10`,
      method: "GET",
    });
  };

  selectUserToSendMessage = (
    checked: boolean,
    data: { id: number; name: string }
  ) => {
    if (checked) {
      this.setState({
        selectedUser: [...this.state.selectedUser, data],
      });
    } else {
      this.setState({
        selectedUser: this.state.selectedUser.filter(
          (item) => item.id !== data.id
        ),
      });
    }
  };
  setSearchTextValue = (value: string) => {
    this.setState(
      {
        searchUserList: [],
        searchListHasMorePage: false,
        searchListPageNumber: 1,
        searchText: value,
      }, () => {
        this.getSearchUserList();
      }
    );
  };
  // Customizable Area End
}
