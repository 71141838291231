import React from "react";
import {
    makeStyles,
    Button,
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
const useStyles = makeStyles((theme) => ({
    back_button: {
        height: "58px",
        width: "100px",
        boxShadow: "none",
        borderRadius: "8px",
        backgroundColor: "#a9d9ed !important",
        marginBottom: "20px",
        "& .MuiButton-label": {
            color: "#25aae1",
            fontSize: "14px",
            textTransform: "capitalize",
            fontWeight: "600",
            "& .back-button-image": {
                height: "15px",
                fill: "#25aae1",
            },
        },
    },
}));


const BackButton = ({ navigation }: any) => {
    const classes = useStyles();

    return (
        <>
            <Button
                variant="contained"
                className={classes?.back_button}
                onClick={() => navigation?.goBack()}
            >
                <ArrowBackIosIcon className="back-button-image" /> Back
            </Button>
        </>
    );
};

export default BackButton;
