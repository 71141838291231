import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  OutlinedInput,
  FormControl,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import ForgotPasswordControllerBlock, {
  Props,
} from "./ForgotPasswordControllerBlock.web";
import { Formik } from "formik";
import * as Yup from "yup";
import {Line} from './assets';
import Loader from "../../../components/src/Loader";
import Carousel from "react-material-ui-carousel";
// Customizable Area End
export const configJSON = require("./config");
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area Start
// Customizable Area End
const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required")
    .matches(configJSON.valii, "Please enter valid password"),
  confirmPassword: Yup.string()
    .min(8, "Confirm password should be of minimum 8 characters length")
    .required("Confirm Password is required"),
});

const MuiDiv = styled("div")({
  position: 'absolute',
  width: '100%',
  height: '300px',
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
  "& h3, & h1" : {
    marginBottom: '0',
    color: '#ffffff',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    lineHeight: '56px',
    letterSpacing: '1.8px',
    marginLeft: '70px',
    fontSize: '18px',
  },
  "& h1": {
    fontWeight: 'normal',
    fontSize: '60px',
    fontStyle: 'normal',
  },
  "& .text-color":{
    display: 'block',
    color: "#ffffff",
  },
});

const MuiMainContainers = styled("div")({
  position: 'relative',
  "& .main-img": {
    width: "100%",
    height: "100vh",
    minHeight: "800px",
    objectFit: 'cover'
  },
  "& .Carousel-box": { flex: 1, overflow: "hidden", height: "100%" },
});
// Customizable Area End
export default class NewPasswordBlock extends ForgotPasswordControllerBlock {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.isLoading} />
        <Grid container style={{ overflowX: "hidden", height: "100%" }}>
          <Grid item xs={6}>
            <Box style={{ position: "relative" }}>
            <MuiMainContainers>
              <Carousel
                autoPlay
                swipe={true}
                indicators={false}
                animation="slide"
              >
                {this.state.CarouselImages.map((item ,i) => {
                  return (
                    <Box
                      className="Carousel-box"
                      key={i}
                    >
                      <MuiDiv>
                        <h3>{configJSON.maintitle}</h3>
                        <img src={Line} alt="line-object" width='100%' />
                        <h1>
                          {configJSON[item.data+'11']}
                          <br/>
                          <span className="text-color">
                            {configJSON[item.data+'22']}
                          </span>
                        </h1>
                      </MuiDiv>
                      <img
                        className="main-img"
                        src={item.img}
                      />
                    </Box>
                  );
                })}
              </Carousel>
                <Box>
                  <Button
                    data-test-id={"btnForLogInScreen"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="explore-button"
                  >
                    Explore
                  </Button>
                </Box>
              </MuiMainContainers>
              <Box>
                <Button
                  data-test-id={"btnForLogInScreen"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="explore-button"
                  // onClick={() => this.navigationNavigate("EmailAccountLoginBlock", "")}
                >
                  Explore
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} style={{ padding: "80px" }}>
            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                padding: "10px 0px",
              }}
            >
              <div
                test-goBack="go_back"
                className="go-back-button"
                onClick={() => this.navigationFunctuon()}
              >
                <ArrowBackIosIcon className="go-back-button-icon" />
              </div>
              <Typography
                component="h2"
                align="left"
                variant="h4"
                style={{ fontWeight: "bold", marginBottom: "20px" }}
              >
                Reset Password
              </Typography>
              <Typography variant="subtitle1" component="div" align="left">
                Set new password here!
              </Typography>
              <Box sx={{ width: "100%", paddingTop: "30px" }}>
                <Formik
                  test-id={"test-formki"}
                  initialValues={{ new: "", confirmPassword: "", password: "" }}
                  validationSchema={validationSchema}
                  onSubmit={(values, actions) => {
                    this.ResetpasswordFunction(values);
                  }}
                >
                  {({
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit} test-id="onSubitId">
                      <NewPasswordForm
                        values={values}
                        error={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        showpassword={() => this.handleClickShowPassword()}
                        checkPassword={this.state.checkPassword}
                        showconfirmPassword={() =>
                          this.handleClickShowConfirmPassword()
                        }
                        checkconfirmPassword={this.state.checkConfrimPassword}
                        passwordNotmatch={this.state.passwordNotmatch}
                      />
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export const NewPasswordForm = ({
  values,
  error,
  handleChange,
  showpassword,
  handleBlur,
  touched,
  checkPassword,
  showconfirmPassword,
  checkconfirmPassword,
}: any) => {
  return (
    <>
      <FormControl
        variant="outlined"
        fullWidth
        className="box-shadow-text-field"
      >
        <OutlinedInput
          data-test-id="password"
          id="password"
          name="password"
          className="input-field"
          placeholder="Password"
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
          type={checkPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={showpassword}
                edge="end"
              >
                {checkPassword ? (
                  <VisibilityOutlinedIcon style={{ color: "#CBCBCB" }} />
                ) : (
                  <VisibilityOffOutlinedIcon style={{ color: "#CBCBCB" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {error?.password && touched.password && (
        <p className="error mb-20 mt-0">{error.password}</p>
      )}
      {(error?.password === "required" || values.password) && (
        <Box>
          <Box style={{ marginBottom: "10px" }}>
            {(error?.password === "length" ||
              error?.password === "letters") && (
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  style={{
                    borderTop: "2px solid rgb(33, 150, 243)",
                    width: "50%",
                  }}
                ></Box>
                <Box
                  style={{ borderTop: "2px solid #ccc", width: "50%" }}
                ></Box>
              </Box>
            )}
            {error.password === undefined && (
              <Box
                style={{
                  borderTop: "2px solid rgb(33, 150, 243)",
                  width: "100%",
                }}
              ></Box>
            )}
            {error.password === "required" && (
              <Box style={{ borderTop: "2px solid #ccc", width: "100%" }}></Box>
            )}
          </Box>
          <Box style={{ marginBottom: "15px" }}>
            {error.password === "length" || error.password === "required" ? (
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ display: "flex", alignItems: "center", color: "#ccc" }}
              >
                <CloseIcon style={{ marginRight: "10px" }} /> Minimum 8
                characters
              </Typography>
            ) : (
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ display: "flex", alignItems: "center", color: "#000" }}
              >
                <DoneIcon
                  style={{ marginRight: "10px", color: "rgb(33, 150, 243)" }}
                />{" "}
                Minimum 8 characters
              </Typography>
            )}
            {error.password === "letters" ||
            error.password === "required" ||
            error.password === "length" ? (
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ display: "flex", alignItems: "center", color: "#ccc" }}
              >
                <CloseIcon style={{ marginRight: "10px" }} /> At least one
                capital latter
              </Typography>
            ) : (
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ display: "flex", alignItems: "center", color: "#000" }}
              >
                <DoneIcon
                  style={{ marginRight: "10px", color: "rgb(33, 150, 243)" }}
                />{" "}
                At least one capital latter
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <FormControl
        variant="outlined"
        fullWidth
        className="box-shadow-text-field"
      >
        <OutlinedInput
          data-test-id="confirm_password"
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Confirm Password"
          className="input-field"
          value={values.confirmPassword}
          onBlur={handleBlur}
          onChange={handleChange}
          type={checkconfirmPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={showconfirmPassword}
                edge="end"
              >
                {checkconfirmPassword ? (
                  <VisibilityOutlinedIcon style={{ color: "#CBCBCB" }} />
                ) : (
                  <VisibilityOffOutlinedIcon style={{ color: "#CBCBCB" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {error?.confirmPassword && touched.confirmPassword && (
        <p className="error mb-20 mt-0">{error.confirmPassword}</p>
      )}
      {values.password !== values.confirmPassword && (
        <Box style={{ color: "red", display: "flex", paddingBottom: "15px" }}>
          {" "}
          Password don't match
        </Box>
      )}
      <Button
        test-handleSubmit="test-handleSubmit"
        style={{
          background: "rgb(33, 150, 243)",
          textTransform: "none",
          marginBottom: "30px",
          padding: "18px",
        }}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        Submit
      </Button>
    </>
  );
};
// Customizable Area End
