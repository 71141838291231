import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  isLoading: boolean;
  blocked_users: {
    data: {
      id: string;
      type: string;
      attributes: {
        user_id: number;
        full_name: string;
        username: string;
        profile_photo: string;
      };
    }[];
    meta: {
      blocked_contacts: number;
      total_pages: number;
    };
  };
  isOpenModal: boolean;
  isMoreData: boolean;
  currentPage: number;
  selected_userId: string;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BlockedUsersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  getBlockedUsersID = "";
  putUnblockUserID = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      blocked_users: {
        data: [],
        meta: {
          blocked_contacts: 0,
          total_pages: 0,
        },
      },
      isOpenModal: false,
      currentPage: 1,
      isMoreData: false,
      selected_userId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    console.log("component did mount");
    this.fetchBlockedUsers();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson.errors) {
        this.handleError(responseJson.errors);
      }

      switch (apiRequestCallId) {
        case this.getBlockedUsersID:
          this.setState({
            blocked_users: {
              data: [...this.state.blocked_users.data, ...responseJson?.data],
              meta: responseJson.meta,
            },
            isLoading: false,
          });
          break;
        case this.putUnblockUserID:
          const index = this.state.blocked_users.data.findIndex(
            (item) => item.id === this.state.selected_userId
          );
          if (index > -1) {
            this.state.blocked_users.data.splice(index, 1);
          }
          this.setState({
            blocked_users: {
              data: this.state.blocked_users.data,
              meta: {
                total_pages: this.state.blocked_users.meta.total_pages,
                blocked_contacts:
                  this.state.blocked_users.meta.blocked_contacts - 1,
              },
            },
            selected_userId: "",
            isOpenModal: false,
          });
          toast.success(responseJson?.message);
          break;
        default:
          break;
      }
    }
  }

  handleError = (errors: { token: string }[]) => {
    const error = errors?.length;
    if (Array.isArray(errors) && error) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        // toast.error(`${errors[0].token}${configJSON.errormassage2}`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
      } else {
        // toast.error(configJSON.errormassage);
      }
    }
  };

  // Customizable Area Start
  fetchBlockedUsers = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBlockedUsersID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockedUsersEndpoint +
        `?page=${this.state.currentPage}&per_page=15`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  hasMoreBlockedUsers = async () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      () => this.fetchBlockedUsers()
    );
  };

  onHandleConfirmation = () => {
    let token = window.localStorage.getItem("authToken");
    const formData = new FormData();
    formData.append("follow[id]", this.state.selected_userId);
    const header = {
      // "Content-Type": configJSON.postContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unblockUserEndpoint
    );
    this.putUnblockUserID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  onCloseModal = () => {
    this.setState({ isOpenModal: false });
  };
  onOpenModal = (userId: string) => {
    this.setState({ isOpenModal: true, selected_userId: userId });
  };

  // Customizable Area End
}
