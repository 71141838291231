import React, { CSSProperties } from "react";

import {
  Box,
  Typography,

  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CustomListItem from "../../../components/src/CustomListItem.web";
import AboutUs from "./SettingsItems/AboutUs.web";
import Notifications from "./SettingsItems/Notifications.web";
import ChangePasswordComp from "./SettingsItems/ChangePassword.web";
import PrivacyPolicy from "./SettingsItems/PrivacyPolicy.web";
import TermsAndCondition from "./SettingsItems/TermsAndCondition.web";
import ContactUs from "./SettingsItems/ContactUs.web";
import DeleteAccount from "./SettingsItems/DeleteAccount.web";
import BlockedUsers from "./SettingsItems/BlockedUsers.web";

import {
  active_contactUsIcon,
  active_deleteIcon,
  active_infoIcon,
  active_notificationIcon,
  bookmarkIcon,
  active_passowordIcon,
  active_policyIcon,
  active_termsConditionIcon,
  contactUsIcon,
  deleteIcon,
  infoIcon,
  logoutIconActive,
  notificationIcon,
  passowordIcon,
  policyIcon,
  termsConditionIcon,
  selectedBookmarkIcon,
  dndActiveImg,
  dndDeactiveImg,
} from "./assets";

// Customizable Area End

import SettingsController, {
  Props,
  configJSON,
} from "./SettingsController.web";
import BackButton from "../../../components/src/BackButton.web";
import ConfirmationModal from "../../../components/src/ConfirmationModal.web";
import MyBookmark from "../../Bookmark2/src/MyBookmark.web";
import DataContext from "../../../components/src/DataContext";

export class Settings5 extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderComponent = () => {
    switch (this.state.activeIndex) {
      case 0:
        return (
          <AboutUs
            setNotificationData={this.props.setNotificationData}
            navigation={this.props.navigation}
            id={""}
          />
        );
      case 1:
        return <Notifications navigation={this.props.navigation} id={""} />;
      case 2:
        return (
          <MyBookmark navigation={this.props.navigation} id={"MyBookmark"} />
        );
      case 3:
        return (
          <ChangePasswordComp
            setNotificationData={this.props.setNotificationData}
            navigation={this.props.navigation}
            id={""}
          />
        );
      case 4:
        return (
          <PrivacyPolicy
            setNotificationData={this.props.setNotificationData}
            navigation={this.props.navigation}
            id={""}
          />
        );
      case 5:
        return (
          <TermsAndCondition
            setNotificationData={this.props.setNotificationData}
            navigation={this.props.navigation}
            id={""}
          />
        );
      case 6:
        return (
          <ContactUs
            setNotificationData={this.props.setNotificationData}
            navigation={this.props.navigation}
            id={""}
          />
        );
      case 7:
        return (
          <DeleteAccount
            setNotificationData={this.props.setNotificationData}
            navigation={this.props.navigation}
            id={""}
          />
        );
      case 8:
        return <BlockedUsers {...this.props} />;

      default:
        break;
    }
  };
  // Customizable Area End

  render() {
    const settingList = [
      {
        title: "About Us",
        img_src: { active_src: active_infoIcon, src: infoIcon },
      },
      {
        title: "Notification",
        img_src: { active_src: active_notificationIcon, src: notificationIcon },
      },
      {
        title: configJSON.bookmarkTitle,
        img_src: { active_src: selectedBookmarkIcon, src: bookmarkIcon },
      },
      {
        title: "Change Password",
        img_src: { active_src: active_passowordIcon, src: passowordIcon },
      },
      {
        title: "Privacy Policy",
        img_src: { active_src: active_policyIcon, src: policyIcon },
      },
      {
        title: "Terms and conditions",
        img_src: {
          active_src: active_termsConditionIcon,
          src: termsConditionIcon,
        },
      },
      {
        title: "Contact Us",
        img_src: { active_src: active_contactUsIcon, src: contactUsIcon },
      },
      {
        title: "Delete Account",
        img_src: { active_src: active_deleteIcon, src: deleteIcon },
      },
      {
        title: "Blocked Users",
        img_src: { active_src: dndActiveImg, src: dndDeactiveImg },
      },
    ];
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainWrapper}>
        <Grid container style={{ flexWrap: "nowrap" }}>
          <Grid item md={5} style={webStyle.leftSection}>
            <BackButton navigation={this.props.navigation} />
            <Typography
              style={{
                fontSize: "22px",
                fontFamily: "HelveticaNeue",
                lineHeight: "28px",
              }}
              variant="h6"
            >
              {configJSON.labelTitleText}
            </Typography>
            {settingList.map((item: any, index: number) => (
              <CustomListItem
                key={index}
                imageSource={item?.img_src}
                activeKey={index}
                onHandle={this.onHandleActive}
                title={item.title}
                active={this.state.activeIndex === index}
              />
            ))}
            <Box
              onClick={this.handleVisibleLogoutModal}
              sx={webStyle.logoutStyle}
            >
              <span style={webStyle.list_icon}>
                <img
                  src={logoutIconActive}
                  style={webStyle.imgIcon}
                  alt="logout"
                />
              </span>
              <p
                style={{
                  color: "#25AAE1",
                  fontSize: "20px",
                  fontFamily: "HelveticaNeue",
                }}
              >
                Log Out
              </p>
            </Box>
          </Grid>
          <Grid item md={7} style={webStyle.rightSection}>
            {this.renderComponent()}
          </Grid>
        </Grid>
        <ConfirmationModal
          onHandleConfirmation={this.handleLogout}
          title="Logout"
          description="Are you sure want to log out?"
          open={this.state.openLogoutModal}
          handleCloseModal={this.handleVisibleLogoutModal}
        />
      </Box>
      // Customizable Area End
    );
  }
}
export default (props: any) => (
  <DataContext.Consumer>
    {({ notificationData, setNotificationData }: any) => {
      return (
        <Settings5
          {...props}
          notificationData={notificationData}
          setNotificationData={setNotificationData}
        />
      );
    }}
  </DataContext.Consumer>
);

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "HelveticaNeue",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#F2F2F2",
  },
  leftSection: {
    padding: "30px",
    margin: "0 10px",
  },
  rightSection: {
    padding: "10px",
    margin: "0 10px",
    borderRadius: "20px",
    maxHeight: "875px",
  },
  logoutStyle: {
    display: "flex",
    padding: "0 10px",
    justifyContent: "left",
    backgroundColor: "white",
    borderRadius: "20px",
    fontFamily: "HelveticaNeue",
    position: "relative",
    width: "100%",
    margin: "40px 0 20px 0",
    border: "1px solid #25AAE1",
    cursor: "pointer",
  },
  list_icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "15px 20px",
  } as CSSProperties,
  imgIcon: {
    width: "24px",
    height: "24px",
    objectFit: "contain",
  } as CSSProperties,
};
// Customizable Area End
