// Customizable Area Start
export const HotelListStyles = {
  HotelList: {
    padding: "20px 20px 20px",
    boxSizing: "border-box",
    minHeight: "100%",
    backgroundColor: "#FAFBFF",
    "& .page-title": {
      fontFamily: "Helvetica-Bold",
      fontSize: "22px",
      fontWeight: "600",
      lineHeight: "27px",
      color: "#000000",
      margin: "15px 0 20px",
    },
    "& .not-found-text": {
      textAlign: "center",
      color: "#000000",
      width: "100%",
      lineHeight: "20px",
      fontSize: "16px",
      fontFamily: "Helvetica",
    },
  },
};
// Customizable Area End
