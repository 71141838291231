// Customizable Area Start
import React, { useState } from "react";
import { Box, Button, Typography, Grid, TextField } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Formik } from "formik";
import OtpInput from "react-otp-input";

import ErrorIcon from "@material-ui/icons/Error";
import OTPInputAuthControllerBlock, {configJSON} from "./ForgotPasswordOTPControllerBlocks.web";
import Loader from "../../../components/src/Loader.web";
import Carousel from "react-material-ui-carousel";
import {Line} from './assets';
const themes = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const MuiDiv = styled("div")({
  position: 'absolute',
  width: '100%',
  height: '300px',
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
  "& h3, & h1" : {
    marginBottom: '0',
    color: '#ffffff',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    lineHeight: '56px',
    letterSpacing: '1.8px',
    marginLeft: '70px',
    fontSize: '18px',
  },
  "& h1": {
    fontWeight: 'normal',
    fontSize: '60px',
    fontStyle: 'normal',
  },
  "& .text-color":{
    display: 'block',
    color: "#ffffff",
  },
});

const MuiMainContainers = styled("div")({
  position: 'relative',
  "& .main-img": {
    width: "100%",
    height: "100%",
    objectFit: 'cover'
  },
  "& .Carousel-box": { flex: 1, overflow: "hidden", height: "100%" },
});

export default class ForgotpasswordOTP extends OTPInputAuthControllerBlock {
  renderInput = (props: any) => {
    const { value, ...rest } = props;
    return (
      <input
        {...rest}
        type="password"
        value={value}
        autoComplete="off"
        style={{
          width: "50px",
          height: "50px",
          margin: "5px",
          fontSize: "24px",
          borderRadius: "5px",
          border: "1px solid gray",
          textAlign: "center",
          // Hide the input text and show stars instead
          color: "transparent",
          textShadow: "0 0 0 #000",
        }}
      />
    );
  };
  render() {
    return (
      <>
        <Loader loading={this.state.loading} />
        <ThemeProvider theme={themes}>
          <Grid container style={{ height: "100%", overflowX: "hidden" }}>
            <Grid item xs={6}>
            <MuiMainContainers>
              <Carousel
                autoPlay
                swipe={true}
                indicators={false}
                animation="slide"
              >
                {this.state.CarouselImg.map((item ,i) => {
                  return (
                    <Box
                      className="Carousel-box"
                      key={i}
                    >
                      <MuiDiv>
                        <h3>{configJSON.maintitle}</h3>
                        <img src={Line} alt="line-object" width='100%' />
                        <h1>
                          {configJSON[item.data+'11']}
                          <br/>
                          <span className="text-color">
                            {configJSON[item.data+'22']}
                          </span>
                        </h1>
                      </MuiDiv>
                      <img
                        className="main-img"
                        src={item.img}
                      />
                    </Box>
                  );
                })}
              </Carousel>
                <Box>
                  <Button
                    data-test-id={"btnForLogInScreen"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="explore-button"
                  >
                    Explore
                  </Button>
                </Box>
              </MuiMainContainers>
            </Grid>
            <Grid item xs={6} style={{ padding: "80px" }}>
              <Box
                sx={{
                  padding: "10px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  test-goBack="go_back"
                  className="go-back-button"
                  onClick={() => this.props.navigation.goBack()}
                >
                  <ArrowBackIosIcon className="go-back-button-icon" />
                </div>
                <Typography
                  variant="h4"
                  component="h2"
                  align="left"
                  style={{ fontWeight: "bold", marginBottom: "20px" }}
                >
                  OTP Verification
                </Typography>
                {isNaN(Number(this.state.useName)) ? (
                  <Typography variant="subtitle1" component="div" align="left">
                    Enter the 4 digits OTP sent via Email on your phone <br />
                    {this.state.useName}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" component="div" align="left">
                    Enter the 4 digits OTP sent via SMS on your phone <br />
                    {this.state.useName}
                  </Typography>
                )}
                <Box sx={{ width: "100%", paddingTop: "30px" }}>
                  <Formik
                    initialValues={{ one: "", two: "", three: "", four: "" }}
                    // onSubmit={this.onSubmitHandler}
                    // validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                      this.otpVerify(values);
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      errors,
                      setFieldTouched,
                      touched,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <>
                          <Box
                            style={{
                              display: "flex",
                              gap: "30px",
                              marginBottom: "25px",
                            }}
                          >
                            <OtpInput
                              //@ts-ignore
                              value={this.state.otps}
                              onChange={this.handleChangesss}
                              numInputs={4}
                              renderSeparator={<span> </span>}
                              renderInput={this.renderInput}
                              inputStyle={{
                                height: "60px",
                                width: "60px",
                                marginRight: "20px",
                                textAlign: "center",
                                marginBottom: "30px",
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: "4px",
                                boxShadow: "0px 10px 25px -3px rgba(0,0,0,0.21",
                              }}
                            />
                          </Box>
                          {this.state.error && (
                            <Typography
                              variant="subtitle1"
                              component="div"
                              align="left"
                              style={{
                                color: "red",
                                display: "flex",
                                paddingBottom: "15px",
                              }}
                            >
                              <ErrorIcon style={{ marginRight: "8px" }} />{" "}
                              {this.state.errorMsg}
                            </Typography>
                          )}

                          {this.state.oneMinTimer > 0 ? (
                            <Typography
                              variant="subtitle1"
                              component="div"
                              align="left"
                              style={{ color: "red", paddingBottom: "40px" }}
                            >
                              {this.state.oneMinTimer < 10
                                ? `00:0${this.state.oneMinTimer}`
                                : `00:${this.state.oneMinTimer}`}
                            </Typography>
                          ) : (
                            <Box>
                              <Button
                                test-id="resend_test"
                                onClick={() =>
                                  this.resendOtp(this.state.useName)
                                }
                                style={{ color: "rgb(33, 150, 243" }}
                              >
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    fontWeight: 600,
                                    paddingRight: "5px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Resend OTP
                                </span>{" "}
                                <RefreshIcon />
                              </Button>
                            </Box>
                          )}
                          <Button
                            test-handleSubmit="test-handleSubmit"
                            style={{
                              background: "rgb(33, 150, 243)",
                              margin: "30px 0",
                              padding: "18px",
                              textTransform: "capitalize",
                              fontWeight: 600,
                              color: "#fff",
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            disabled={this.state.otps.length <= 3}
                          >
                            Verify
                          </Button>
                        </>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
    );
  }
}

export const OTPForgotPassowrd = ({
  values,
  handleChangee,
  resendOtpp,
  errorr,
  errorMsg,
  oneMinTimer,
  otp,
  set,
}: any) => {
  return (
    <>
      {/* {errorr && <Typography variant="subtitle1" component="div" align="left" style={{ color: 'red', display: 'flex', paddingBottom: '15px' }}>
                <ErrorIcon style={{ marginRight: '8px' }} /> {errorMsg}
            </Typography>} */}
      <Box>
        <Button
          test-id="resend_test"
          onClick={resendOtpp}
          style={{ color: "rgb(33, 150, 243" }}
        >
          <span
            style={{
              textDecoration: "underline",
              fontWeight: 600,
              paddingRight: "5px",
            }}
          >
            Resend OTPssss
          </span>{" "}
          <RefreshIcon />
        </Button>
      </Box>
      <Button
        test-handleSubmit="test-handleSubmit"
        style={{
          background: "rgb(33, 150, 243)",
          margin: "30px 0",
          padding: "18px",
        }}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        Verify
      </Button>
    </>
  );
};
// Customizable Area End
