Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";

exports.apiMethodPUT = "PUT";
exports.apiMethodPOST = "POST";
exports.apiMethodPATCH = "PATCH";
exports.apiMethodGET = "GET";
exports.showAllForumsEndpoint= "bx_block_forum/forums";
exports.forumSearchEndpoint= "bx_block_forum/forums/forum_search";
exports.likeUnlikeForumEndpoint= "bx_block_like/likes";
exports.commentForumEndpoint= "bx_block_comment/comments";
exports.getAllCommentForumEndpoint= "bx_block_forum/forums/forum_comments/";
exports.getSpecificCommentEndpoint= "bx_block_comment/comments/5";
// exports.deleteForumCommentEndpoint = "bx_block_comment/comments/5";
exports.voteCommentEndpoint="bx_block_vote/votes";
// exports.downVoteCommentEndpoint="bx_block_vote/votes";
exports.blockType= "BxBlockForum::Forum";
exports.commentErrorMassage= "No Comment Yet";
exports.editName= "Edit";
exports.removeName= "Remove";


// Customizable Area End
