// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../../../components/src/utils.web";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  imageUrl: { id: number; url: string; filename: string; type: string }[];
  isLoading: boolean;
  locationId: string;
  cityName: string;
  description: string;
  direction: { lat: number; lng: number };
  zoom: number;
  currentImageIndex: number;
  categories: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      category_cover_image: string;
    };
  }[];
}

interface SS {
  id: any;
}

export default class ExploreDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  exploreDetailsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      imageUrl: [],
      isLoading: false,
      locationId: "0",
      cityName: "",
      description: "",
      direction: { lat: 0, lng: 0 },
      zoom: 11,
      currentImageIndex: 0,
      categories: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const id = this.props.navigation.getParam("id");
    this.setState(
      {
        locationId: id,
      },
      async () => {
        await this.getExploreDetails();
      }
    );
  }

  getExploreDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.exploreDetailsApiCallId = await apiCall({
      endPoint: `bx_block_explore/explore_locations/${this.state.locationId}`,
      method: "GET",
    });
  };

  goToPreviousImage = () => {
    this.setState({
      currentImageIndex:
        this.state.currentImageIndex === 0
          ? this.state.imageUrl.length - 1
          : this.state.currentImageIndex - 1,
    });
  };

  goToNextImage = () => {
    this.setState({
      currentImageIndex:
        (this.state.currentImageIndex + 1) % this.state.imageUrl.length,
    });
  };

  redirectToHotelPage = (categoryId: string, itemName: string) => {
    this.props.navigation.history.push(
      `/explore/${this.state.locationId}/${categoryId}`,
      {
        cityName: this.state.cityName,
        itemName,
      }
    );
  };

  redirectToBookmarkPages = () => {
    this.props.navigation.history.push(`/${this.state.locationId}/bookmark`, {
      isLocation: true,
    });
  };

  handleErrorExploreDetails = (error: any) => {
    if (Array.isArray(error)) {

      if (
        error[0].token === "Token has Expired" ||
        error[0].token === "Invalid token"
      ) {
        window.localStorage.removeItem("authToken");

    toast.error(`${error[0].token}, Please login again.`);
    window.localStorage.removeItem("user_details");
    
    this.props.navigation.navigate("EmailAccountLoginBlock");
   
    return;
  } else if (error[0]) {
    toast.error(error[0]);
    this.props.navigation.goBack();
    return;
  }
}
toast.error("Something went to wrong please retry");
  };

  handleResponseExploreDetails = async (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (
      apiRequestCallId === this.exploreDetailsApiCallId &&
      responseJson.data
    ) {
      const data = responseJson.data.attributes;
      this.setState({
        cityName: data.city_name,
        description: data.description,
        direction: {
          lat: data.latitude,
          lng: data.longitude,
        },
        imageUrl: data.location_images,
        categories: data.categories.data,
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId && responseJson.errors) {
        this.handleErrorExploreDetails(responseJson.errors);
      }
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        this.handleResponseExploreDetails(apiRequestCallId, responseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
