import { Button, Typography, styled } from '@material-ui/core';
import React, { Component } from 'react';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
const RemoveUser = require("./assets/noun-remove-user-2636683.png")
const configJSON = require("./config.js");
type MyProps = {
    item: {
        [keys: string]: string | number | boolean;
        photo: string;
        is_following: boolean
    };
    onFollowClick: (isClicked: boolean, id: number | string | boolean) => void;
    navigation: { history: { push: (id: string) => void } }
};
type MyState = {
    isClicked: boolean;
    userDetail: { id: string }
};

export default class UserCard extends Component<MyProps, MyState> {
    constructor(props: MyProps) {
        super(props);
        this.state = {
            isClicked: false,
            userDetail: { id: "" }
        }
    }

    componentDidMount(): void {
        this.setState({
            isClicked: this.props.item.is_following
        })
        const user = localStorage.getItem("user_details")
        if (user) {
            this.setState({
                userDetail: JSON.parse(user)
            })
        }
    }

    handleChangeUser = () => {
        this.setState({
            isClicked: !this.state.isClicked
        })
        this.props.onFollowClick(!this.state.isClicked, this.props.item.id)
    }

    render(): React.ReactNode {
        const { item } = this.props;
        return (
            <CardWrapper>
                <img src={item.photo} alt="user-image" className='user-image' />
                <Typography style={{ cursor: "pointer" }} variant='h6' onClick={() => this.props.navigation.history.push(`/profile/${item.id}`)}>{item.full_name}</Typography>
                <Typography className='user-id-title' variant='body2'>{"@" + `${item?.username ? item.username : item?.full_name}`}</Typography>
                {this.state.isClicked ?
                    <Button size='small'
                        variant="contained"
                        color="default"
                        style={this.state.userDetail.id == item.id ? { display: "none" } : {}}
                        startIcon={<img width="16.5px" height="12px" src={RemoveUser} alt='remove-user' />}
                        onClick={this.handleChangeUser}
                    >
                        {configJSON.unFollowName}
                    </Button>
                    : <Button size='small'
                        variant="contained"
                        color="primary"
                        style={this.state.userDetail.id == item.id ? { display: "none" } : {}}
                        startIcon={<PersonAddOutlinedIcon />}
                        onClick={this.handleChangeUser}
                    >
                        {configJSON.followName}
                    </Button>}

            </CardWrapper>
        )
    }
}
const CardWrapper = styled("div")({
    width: "100%",
    textAlign: "center",
    "& .MuiButton-root": {
        textTransform: "capitalize"
    },
    "& .user-id-title": {
        color: "gray"
    },
    "& .user-image": {
        width: "58px",
        height: "58px",
        objectFit: "cover",
        borderRadius: "12px"
    }
});