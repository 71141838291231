import React, { CSSProperties } from "react";
// Customizable Area Start
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LandingPageController, {
    Props,
} from "./LandingPageController.web";
import { format } from 'date-fns';
import { styled } from "@material-ui/core/styles";
import { img49d3d570fbf71727d79042aff84e12e35ba682ac, img719b6be65b08e5fb0a76de0bd586e804ee9022e0, img306c017d8f0751798712a2cf0b01b8f26f13d05b, img408258c639f252092f672a84a7f842d0aee4e3c4 } from "./assets"
import Loader from "../../../components/src/Loader.web";
// Customizable Area End
// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
const DivBox = styled('div')({
    color: "#000000",
    fontFamily: 'HelveticaNeue',
    lineHeight:"25px"
});
const FooterBox = styled(Grid)({
    padding: "120px 172px 0 172px",
    background: "#0E1D28",
    "@media (max-width:900px)": {
        padding: "5px"
    },
    "& .left-sec": {
        "& .footer-logo-title": {
            color: "#FFF",
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "28px;",
            width: '290px'
        }
    },
    "& .right-sec": {
        display: "flex",
        "& .right-box": {
            padding: "0 10px",
            "& .footer-title": {
                fontSize: "18px",
                fontWeight: 700,
                marginBottom: "10px",
                color: "#FFF",
                fontFamily: "HelveticaNeue"
            },
            "& .footer-desc": {
                fontSize: "16px",
                color: "#FFF",
                fontFamily: "HelveticaNeue",
                lineHeight: "25px",
                textDecoration: "none",
                padding: 0,
                justifyContent: "start",
                textTransform: "capitalize",
                margin: 0,
            },
            "& .social-link": {
                display: "flex",
                flexDirection: "column"
            },
            "& .app-img": {
                width: "193px",
                objectFit: "contain",
                marginTop: "61px"
            }

        },
        "& .footer-nav-btn": {
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            "& .footer-btn": {
                padding: "0",
                width: "67px"
            },

        }

    },

    "& .copy-right": {
        textAlign: "center",
        marginTop: "78px",
        fontFamily: "HelveticaNeue",
        "& button": {
            padding: 0,
            color: "#FFF",
            textTransform: "capitalize",
            fontFamily: "HelveticaNeue",
        }
    },
    "& .footer-btn_row": {
        "@media (max-width:900px)": {
            padding: "10px 0 !important",
            justifyContent: "center",
            "& img": {
                width: "100px"
            }
        },
        display: "flex",
        gap: "35px",
        padding: "50px 0"
    }
});
const Logobox = styled(Box)({
    cursor: "pointer",
    "& .logoname": {
        "@media (max-width:900px)": {
            fontSize: "1.5rem !important"
        }
    },
    "& img": {
        height: 50,
        "@media (max-width:900px)": {
            height: 30
        }
    }
});
// Customizable Area End
export default class LandingPageTC extends LandingPageController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        this.scrollToTop();
        this.getTOC();
        this.fetchContactUs()
    }


    render() {
        return (
            <>
            <Loader loading={this.state.isLoading} />
            <ThemeProvider theme={theme}>
                <Container>
                    <Grid container alignItems="center" style={webStyle.aboutMainBox}>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="h5" style={webStyle.aboutBhomio}>Terms & Condtion</Typography>
                                <Typography style={webStyle.updateText}>Updated: {this.state?.Termsandconditions?.created_at}</Typography>
                                <DivBox
                                    dangerouslySetInnerHTML={{ __html: this.state?.Termsandconditions?.content ?? '' }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

                </ThemeProvider>
                <FooterBox>
                    <Box alignItems="center">
                        <Grid container>
                            <Grid className="left-sec" md={5} xs={12}>
                                <Logobox
                                    data-test-id="logo-btn"
                                    onClick={this.scrollToTop}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gridGap: "20px"
                                    }}
                                >
                                    <img src={img719b6be65b08e5fb0a76de0bd586e804ee9022e0} />
                                    <Typography
                                        className="logoname"
                                        variant="h4"
                                        style={{ color: "white", lineHeight: 0 }}
                                    >
                                        bhomio
                                    </Typography>
                                </Logobox>
                                <p className="footer-logo-title">
                                    Plan Your Next Adventure With Ease! The ultimate tool for
                                    hassle-free travel planning.
                                </p>
                            </Grid>
                            <Grid className="right-sec" md={7} xs={12}>
                                <Grid
                                    className="right-box footer-nav-btn"
                                    md={4}
                                    xs={12}
                                >
                                    <p className="footer-title">Services</p>
                                    <Button
                                        data-test-id="go-home"
                                        onClick={this.scrollToTop}
                                        className="footer-desc footer-btn"
                                    >
                                        Home
                                    </Button>
                                    <Button
                                        data-test-id="go-place"
                                        onClick={this.goToFooterNavigation.bind(this, "Places")}
                                        className="footer-desc footer-btn"
                                    >
                                        Explore
                                    </Button>
                                    <Button
                                        data-test-id="go-feed"
                                        onClick={this.goToFooterNavigation.bind(this, "Feed")}
                                        className="footer-desc footer-btn"
                                    >
                                        Feed
                                    </Button>
                                    <Button
                                        onClick={this.goToFooterNavigation.bind(
                                            this,
                                            "EmailAccountLoginBlock"
                                        )}
                                        data-test-id="go-login"
                                        className="footer-desc footer-btn"
                                    >
                                        Login
                                    </Button>
                                    <Button
                                        data-test-id="go-onboard"
                                        onClick={this.goToFooterNavigation.bind(
                                            this,
                                            "OnboardingScreenBlock"
                                        )}
                                        className="footer-desc footer-btn"
                                    >
                                        Signup
                                    </Button>
                                </Grid>
                                <Grid className="right-box" md={4} xs={12}>
                                    <p className="footer-title">Contact us</p>
                                    <p style={{ marginBottom: "30px" }} className="footer-desc">
                                        {this.state.contact_us?.address}
                                    </p>
                                    <p style={{ marginBottom: "20px" }} className="footer-desc">
                                        {`Customer Services: ${this.state.contact_us?.full_phone_number}`}
                                    </p>
                                    <p style={{ marginBottom: "20px" }} className="footer-desc">
                                        {`Email: ${this.state.contact_us?.email}`}
                                    </p>
                                    <p className="footer-desc">
                                        (Mon-Fri, 9am - 6pm)
                                    </p>
                                </Grid>
                                <Grid className="right-box" md={4} xs={12}>
                                    <p className="footer-title">Social Media </p>
                                    <Button data-test-id="facebook" onClick={this.openSocialLink.bind(this, "https://www.facebook.com/")} className="footer-desc social-link">Facebook</Button>
                                    <Button data-test-id="twitter" onClick={this.openSocialLink.bind(this, "https://twitter.com/")} className="footer-desc social-link">Twitter</Button>
                                    <Button data-test-id="instagram" onClick={this.openSocialLink.bind(this, "https://instagram.com/travelwithbhomio?igshid=MTNiYzNiMzkwZA==")} className="footer-desc social-link">Instagram</Button>
                                    <Button data-test-id="youtube" onClick={this.openSocialLink.bind(this, "https://www.youtube.com/")} className="footer-desc social-link">Youtube</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid md={5} className="'left-sec" />
                            <Grid md={7} className="right-sec">
                                <Grid
                                    className="right-box"
                                    md={12}
                                    xs={12}
                                >
                                    <img
                                        className="app-img"
                                        style={{ marginRight: "24px" }}
                                        src={img306c017d8f0751798712a2cf0b01b8f26f13d05b}
                                        alt="Google Play"
                                    />
                                    <img
                                        className="app-img"
                                        src={img408258c639f252092f672a84a7f842d0aee4e3c4}
                                        alt="App Store"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <p className="footer-desc copy-right">
                        2023 Bhomio | <Button data-test-id="about-us" onClick={this.goToFooterNavigation.bind(
                            this,
                            "AboutUs"
                        )}>About Us</Button> | <Button data-test-id="term-condition" onClick={this.goToFooterNavigation.bind(
                            this,
                            "TermAndCondition"
                        )}>Terms & Condition</Button> | <Button data-test-id="privacy-policy" onClick={this.goToFooterNavigation.bind(
                            this,
                            "PrivacyPolicy"
                        )}>Privacy Policy</Button>

                    </p>
                </FooterBox>
            </>
        )
    }
    // Customizable Area End
}

const webStyle = {
    aboutBhomio: {
        fontWeight: "bold",
        color: "rgba(34, 34, 34, 1)",
        textAlign: "left",
        textAlignVertical: "top",
    } as CSSProperties,
    updateText: {
        color: "rgba(148, 149, 155, 1)",
        padding: "15px 0px 10px",
    } as CSSProperties,
    aboutBhomioText: {
        color: "rgba(148, 149, 155, 1)",
        fontSize: "19px",
        fontWeight: 400,
        padding: "15px 0 10px",
    },
    termsCondOrderInner: {
        listStyleType: "decimal",
        color: "rgba(148, 149, 155, 1)",
        fontSize: "19px",
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    } as CSSProperties,
    termsCondOrder: {
        listStyleType: "decimal",
        paddingLeft: "30px",
    } as CSSProperties,
    termsCond: {
        fontWeight: "bold",
        color: "rgba(34, 34, 34, 1)",
        textAlign: "left",
        fontFamily: "Poppins-SemiBold",
        fontSize: "20px",
        padding: "15px 0 10px",
    } as CSSProperties,
    mainHeadPosition: {
        textAlign: "center",
        padding: "30px 0",
        margin: "0 0 30px",
        borderBottom: "1px solid #ccc",
    },
    aboutMainBox: {
        margin: "20px 0",
    },
    image_Image1: {
        height: "70px",
        resizeMode: "contain",
    } as CSSProperties,
    image_Image2: {
        height: "70px",
        resizeMode: "contain",
    } as CSSProperties,
    sectionFooter: {
        position: "relative",
        height: "450px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginTop: "20px",
    },
    image_bgFooter: {
        position: "absolute",
        zIndex: -1,
        width: "100%",
    } as CSSProperties,
    footer_head: {
        color: "#fff",
        textAlign: "center",
        padding: "20px 0",
        marginBottom: "20px",
        fontWeight: "bold",
    } as CSSProperties,
    footerBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "60px",
        padding: "30px 0",
    },
    signUpFooterBtn: {
        backgroundColor: "#fff",
        border: "1px solid rgb(37, 170, 225)",
        color: "rgb(37, 170, 225)",
        padding: "10px 30px",
        borderRadius: "10px",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: "bold",
        marginTop: "20px",
    } as CSSProperties,
    image_dotFooter: {
        position: "absolute",
        top: "-55px",
        left: "5px",
        zIndex: -1,
    } as CSSProperties,
    listIntro: {
        fontFamily: "Poppins-SemiBold",
        fontWeight: "bold",
    } as CSSProperties,
}