// Customizable Area Start
export const ItineraryLocationStyles = {
  ItineraryLocation: {
    "& .overflow-items-locations": {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      height: "auto",
      overflowY: "auto",
      maxHeight: "370px",
      paddingRight: "10px",
    },
    "& .overflow-items-bookmarked": {
      overflowY: "auto",
      maxHeight: "500px",
      paddingRight: "10px",
    },
    "& .display-flex": {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      height: "auto",
    },
    "& .back-button": {
      height: "58px",
      width: "100px",
      boxShadow: "none",
      borderRadius: "8px",
      backgroundColor: "#a9d9ed !important",
      "& .MuiButton-label": {
        color: "#25aae1",
        fontSize: "14px",
        textTransform: "capitalize",
        fontWeight: "600",
        "& .back-button-image": {
          height: "15px",
          fill: "#25aae1",
        },
      },
    },
    "& .title-text": {
      fontFamily: "Helvetica-Bold",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#000",
    },
    "& .main-text-container": {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      marginBottom: "10px",
    },
    "& .slider-tabs": {
      display: "flex",
      gap: "30px",
      margin: "20px 0",
    },
    "& .active-item": {
      color: "#25aae1",
      fontFamily: "Helvetica-Bold",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20px",
      cursor: "pointer",
    },
    "& .in-active-item": {
      color: "#8f92a1",
      fontFamily: "Helvetica-Bold",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20px",
      cursor: "pointer",
    },
    "&  .active-image-container": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "5px",
    },
    "& .active-border": {
      width: "5px",
      height: "5px",
      background: "#25aae1",
      textAlign: "center",
      borderRadius: "2px",
    },
    "& .search-result-text": {
      lineHeight: "27px",
      fontFamily: "Helvetica-Bold",
      fontSize: "22px",
      fontWeight: "400",
      color: "#000",
      margin: "20px 0",
    },
  },
};
// Customizable Area End
