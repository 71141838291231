import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Button, AppBar, Toolbar, Grid, Container, InputAdornment, TextField,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
import LandingPageController, {
    Props,
} from "./LandingPageController.web";
import DehazeIcon from '@material-ui/icons/Dehaze';
import { img719b6be65b08e5fb0a76de0bd586e804ee9022e0 } from "./assets"
import { SearchOutlined } from "@material-ui/icons";
// Customizable Area End
// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },

});
const Headerbox = styled(Box)({
    // position: 'fixed', 
    // left: 0, 
    // right: 0, 
    // padding: 0,
    "& .menubutton": {
        fontSize: "18px",
        textDecoration: "none",
        textTransform: "capitalize",
        color: "#222222",
        fontFamily: "HelveticaNeue",
        "@media (max-width:1100px)": {
            fontSize: '15px !important',
        },
    },
    '& .menutButton-active': {
        fontSize: "18px",
        color: "#25AAE1",
        textTransform: "capitalize",
        fontFamily: "HelveticaNeue",
        "@media (max-width:1100px)": {
            fontSize: '15px !important',
        },

    },
    "& .menubutton_home": {
        fontSize: "18px",
        textDecoration: "none",
        color: "white",
        textTransform: "capitalize",
        "@media (max-width:1100px)": {
            fontSize: '15px !important',
        },
    },
    "& .menubutton_home-active": {
        fontSize: "18px",
        textDecoration: "none",
        color: "white",
        textTransform: "capitalize",
        textDecorationLine: "underline",
        textUnderlineOffset: "10px",
        textDecorationThickness: "2px",
        textDecorationColor: "red",
        "@media (max-width:1100px)": {
            fontSize: '15px !important',
            marginRight: "0px",
        },
    },

    "& .navigtion_nav": {
        "@media (max-width:768px)": {
            padding: "0 !important",
        },
        "& .toolbar": {
            display: "flex",
            padding: 0,
            justifyContent: "space-between",
            background: "white",
            zIndex: 999,
            width: "100%",
            position: 'sticky',
            top: 0
        },
        "& .toolbar-home ": {
            display: "flex",
            padding: 0,
            justifyContent: "space-between",
            background: "#0e1d2891",
            zIndex: 999,
            width: "100%",
            position: 'fixed',
            top: 0
        }

    },
    "@media (max-width:1100px)": {
        marginBottom: "30px !important",
    },

});
const Logobox = styled(Box)({
    cursor: "pointer",
    "& .logoname": {
        "@media (max-width:1100px)": {
            fontSize: '1.5rem !important',
        },
    },
    "& img": {
        height: 50,
        "@media (max-width:1100px)": {
            height: 30,
        },
    },
});

const Menuboxwrap = styled(Box)({
    width: "100%",
    "& .toggle_btn": {
        display: 'none',
        "@media (max-width:1100px)": {
            display: 'block',
        },
    },
    "& .menu_mbl_toggle": {
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-between",
        "@media (max-width:1200px)": {
            paddingLeft:'25px'
        },
        "& .nav-row": {
            display: 'flex',
            alignItems: 'center',
            "& .search-bar": {
                paddingRight: "30px",
                width: "375px",
                display: 'flex',
                justifyContent: "end",
                "& .search-box-home": {
                    background: "#E7E9EFCC",
                    padding: "5px",
                    borderRadius: "10px",

                    "& input": {
                        color: 'white',
                        fontFamily: "HelveticaNeue",
                        fontSize: "16px",
                        fontWeight: "400",


                    }
                },
                "& .search-box": {
                    background: "white",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #C4EEFF",
                    "& input": {
                        color: '#94959B',
                        fontFamily: "HelveticaNeue",
                        fontSize: "16px",
                        fontWeight: "400",


                    }
                },
                "& .search-input-text": {
                    padding: "13px 13px 13px 30px !important",
                },
                "@media (max-width:1100px)": {
                    margin: "0 0 20px 0",
                    width: "auto",
                    padding: 0

                },

            },
            "& .login-btn": {
                backgroundColor: "rgb(37, 170, 225)",
                color: "#fff",
                padding: "10px 30px",
                marginRight: "20px",
                borderRadius: "6px",
                textTransform: "capitalize",
                "@media (max-width:1100px)": {
                    margin: "0 0 20px 0"
                },
            },
            "& .signup-btn": {
                backgroundColor: "#fff",
                border: "1px solid rgb(37, 170, 225)",
                color: "rgb(37, 170, 225)",
                padding: "10px 30px",
                borderRadius: "6px",
                textTransform: "capitalize",
                "@media (max-width:1100px)": {
                    margin: "0 0 20px 0",
                    width: "100%"
                },
            },
            "& .signupWithHome": {
                backgroundColor: "transparent",
                border: "1px solid #FFFF",
                color: "#FFFF",
                padding: "10px 30px",
                borderRadius: "6px",
                textTransform: "capitalize",
                "@media (max-width:1100px)": {
                    margin: "0 0 20px 0",
                },
            },
            "@media (max-width:1100px)": {
                flexDirection: "column"
            },
        },
        "@media (max-width:1100px)": {
            display: 'none',
        },
    },
    "& .menu_mbl_toggle.active": {
        display: 'flex',
        justifyContent: "space-between",
        "@media (max-width:1100px)": {
            display: 'flex',
            flexDirection: 'column',
            left: '0',
            textAlign: 'left',
            background: '#bbe6f8',
            width: '100vw',
            top: '55px',
            position: 'absolute',
            gap: '20px',
            padding: '20px',
        },
    },
    "@media (max-width:1100px)": {
        width: "auto",
    },
});


const ChildrenContainer = styled(Container)({
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    "@media (max-width:1100px)": {
        padding: "5px",
    },
})

const ToolBarContainer = styled(Container)({
    display: "flex",
    padding: '24px 72px',
    "@media (max-width:1150px)": {
        padding: '24px 40px',

    },
    "@media (max-width:1100px)": {
        padding: '10px 20px',
    },
})

const ButtonDiv = styled('div')({
   display: "flex",
   "@media (max-width:1100px)": {
    gap:'20px'
},
})


const navItems = [{ title: "Home", path: '/LandingHomePage' }, { title: 'Explore', path: '/Places' }, { title: "Feed", path: '/Feed' }]
// Customizable Area End
class LandingPageBlock extends LandingPageController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { children } = this.props;
        let urlRoute: any = children?.props?.location?.pathname;
        const isHome = urlRoute === '/LandingHomePage';
        const isExplore = urlRoute === '/Places';
        return (
            <ThemeProvider theme={theme}>
                <Headerbox className="header_box">
                    {/* <CssBaseline /> */}
                    <AppBar component="nav" className="navigtion_nav" style={{ position: "relative", backgroundColor: "#fff", display: "flex", justifyContent: "center", boxShadow: "none" }}>
                        <Toolbar className={isHome ? "toolbar-home" : "toolbar"}>
                            <ToolBarContainer maxWidth="xl">
                                <Grid lg={2} xs={6}>
                                    <Logobox data-test-id="logo-btn" onClick={this.goToNavigation.bind(this, "/LandingHomePage")} sx={{ display: "flex", alignItems: "center", gridGap: "20px" }}>
                                        <img src={img719b6be65b08e5fb0a76de0bd586e804ee9022e0} />
                                        <Typography className="logoname"
                                            variant="h4"
                                            style={{ color: isHome ? "white" : "black", lineHeight: 0 }}
                                        >
                                            bhomio
                                        </Typography>
                                    </Logobox>
                                </Grid>
                                <Grid lg={10} md={10} xs={6} style={{ height: "100%", display: "flex", justifyContent: "end", }}>
                                    <Menuboxwrap>
                                        <DehazeIcon data-test-id="DehazeIcon" style={{ color: "#25aae1" }} className="toggle_btn" onClick={this.menuButton} />
                                        <Box className={this.state.activeFolg ? "menu_mbl_toggle active" : "menu_mbl_toggle"} >
                                            <Box className="nav-row">
                                                {navItems.map((item: any, index) => (
                                                    <Button key={item}
                                                        className={isHome ? "menubutton_home" : urlRoute === item.path ? "menutButton-active" : "menubutton"}
                                                        data-test-id={"renderPages" + index}
                                                        onClick={this.goToNavigation.bind(this, item.path)}
                                                    >
                                                        {isHome && item.title === "Home" ? <span className="menubutton_home-active">{item.title}</span> : item.title}

                                                    </Button>
                                                ))}
                                            </Box>
                                            <Box className="nav-row">


                                                {!isExplore && <Box className="search-bar">
                                                    <TextField
                                                        size="medium"
                                                        className={isHome ? "search-box-home" : "search-box"}
                                                        placeholder="Search for destinations"
                                                        name="searchField"
                                                        id="searchField"
                                                        onChange={this.handleDebouncedSearch}
                                                        data-test-id="search-bar"
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    style={{ display: "flex", alignItems: "center" }}
                                                                >
                                                                    <div>
                                                                        {" "}
                                                                        <SearchOutlined style={{ color: !isHome ? "#94959B" : "white" }} />
                                                                    </div>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Box>}
                                                <ButtonDiv>
                                                <Button test-Id="test_id" onClick={this.goToNavigation.bind(this, "EmailAccountLoginBlock")} className="login-btn">Login</Button>
                                                <Button test-Id="test_id_signUp" onClick={this.goToNavigation.bind(this, "OnboardingScreenBlock")} className={isHome ? "signupWithHome" : "signup-btn"}
                                                >SignUp</Button>
                                                </ButtonDiv>
                                            </Box>
                                        </Box>
                                    </Menuboxwrap>
                                </Grid>
                            </ToolBarContainer>

                        </Toolbar>
                        <ChildrenContainer maxWidth="xl">
                            <>
                                {children}
                            </>
                        </ChildrenContainer>

                    </AppBar>


                </Headerbox>
            </ThemeProvider>
        )
    }
    // Customizable Area End
}
export default LandingPageBlock;