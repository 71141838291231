import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Grid } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { logo } from "./assets";
import Carousel from "react-material-ui-carousel";
import EmailAccountLoginControllerBlock, {
  Props, configJSON,
} from "./EmailAccountLoginControllerBlock.web";
import {Line} from './assets';
// Customizable Area End
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  // carouselImage: {
  //   width: "100%",
  //   height:"100%",
  //   objectFit:"cover"
  // },
  // carousel: {
  //   flex: 1,
  //   // maxHeight: 400,
  //   overflow: "hidden",
  //   height:'100vh'
  // },
  indicatorIconWrap: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "center",
    transform: "translateY(-120%)",
  },
  indicatorIcon: {
    width: 35,
    height: 35,
    marginLeft: 5,
    marginRight: 5,
    overflow: "hidden",
    display: "flex",
  },
};
const MIMG = styled("img")({
  marginBottom: "50px",
  cursor: "pointer",
});
const MBox = styled("div")({
  position: 'absolute',
  width: '100%',
  height: '300px',
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
  "& h3, & h1" : {
    color: '#ffffff',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '56px',
    letterSpacing: '1.8px',
    display: 'flex',
    marginLeft: '70px',
    marginBottom: '0',
    fontFamily: 'Helvetica',
  },
  "& h1": {
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 'bold'
  },
  "& .text-color":{
    color: "#25AAE1",
    display: "inline-block",
    marginRight: '10px'
  },
  "& .sub-head":{
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: '30px',
    marginTop: '10px',
    marginLeft: '70px',
    color: '#ffffff',
    fontFamily: 'Helvetica',
  }
});
// Customizable Area End
export default class OnboardingScreenBlock extends EmailAccountLoginControllerBlock {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ overflowX: "hidden", height: "100%" }}>
          <Grid item xs={6}>
            <Box style={{ position: "relative" }}>
              <Carousel
                animation="slide"
                swipe={true}
                index={this.state.index}
                indicators={false}
                autoPlay
              >
                {this.state.imgData.map((item: any, i: any) => {
                  return (
                    <Box
                      key={i}
                      style={{ flex: 1, overflow: "hidden", height: "100%" }}
                    >
                      <MBox>
                        <h3>{configJSON.maintitle}</h3>
                        <img src={Line} alt="line-object" width='100%' />
                        <h1><span className="text-color">{configJSON[item.data+'1']}</span>{configJSON[item.data+'2']}</h1>
                        <p className="sub-head">
                          {configJSON[item.data+'3']}
                        </p>
                      </MBox>
                      <img
                        style={{
                          height: "100%",
                          objectFit: "cover",
                          width: "100%",
                        }}
                        src={item.img}
                      />
                    </Box>
                  );
                })}
              </Carousel>
              <Box>
                <Button
                  data-test-id={"btnForExploreInScreen"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="explore-button"
                >
                  Explore
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ padding: "80px 140px", backgroundColor: "#fafbff" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 0px",
              }}
            >
              <MIMG src={logo} width={70} onClick={()=>this.navigationNavigate("LandingHomePage", "")} />
              <Typography
                variant="h4"
                component="h2"
                align="left"
                style={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                Welcome
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                align="left"
                style={{ fontFamily: "Helvetica" }}
              >
                Onboarding Description Title Goes Here Onboarding Title Goes
                Here.
              </Typography>

              <Box sx={{ width: "100%", paddingTop: "30px" }}>
                <Button
                  data-test-id={"btnForLogInScreen"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{
                    background: "rgb(33, 150, 243)",
                    marginBottom: "30px",
                    padding: "18px",
                    fontFamily: "Helvetica",
                    textTransform: "unset",
                  }}
                  onClick={() =>
                    this.navigationNavigate("EmailAccountLoginBlock", "")
                  }
                >
                  Login
                </Button>
                <Button
                  data-test-id={"btnForRegistrationByEmail"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{
                    background: "rgb(33, 150, 243)",
                    marginBottom: "30px",
                    padding: "18px",
                    fontFamily: "Helvetica",
                    textTransform: "unset",
                  }}
                  onClick={() =>
                    this.navigationNavigate(
                      "EmailAccountRegistrationByEmail",
                      "email_account"
                    )
                  }
                >
                  Sign up with Email Address
                </Button>
                <Button
                  data-test-id={"btnForRegistrationByNumber"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{
                    background: "rgb(33, 150, 243)",
                    marginBottom: "30px",
                    padding: "18px",
                    fontFamily: "Helvetica",
                    textTransform: "unset",
                  }}
                  onClick={() =>
                    this.navigationNavigate(
                      "EmailAccountRegistrationByEmail",
                      "sms_account"
                    )
                  }
                >
                  Sign up with Mobile Number
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}
