export const img776b907357aa7652d09f21b602bee032e66e1365 = require("../assets/776b907357aa7652d09f21b602bee032e66e1365.png");
export const img505fb2c3c369f3b4d47461661c6e7184888bd45a = require("../assets/505fb2c3c369f3b4d47461661c6e7184888bd45a.png");
export const imge763138523348e1ecc81562e35c8a536390db0f9 = require("../assets/e763138523348e1ecc81562e35c8a536390db0f9.png");
export const img8307cbf2113f95f10f644ec9e7100a7669a3fbd0 = require("../assets/8307cbf2113f95f10f644ec9e7100a7669a3fbd0.png");
export const img87309a54cdc9662da88764fdc804a4df693edaac = require("../assets/87309a54cdc9662da88764fdc804a4df693edaac.png");
export const imgee2a1b6ce584c1ac0b4f45592fc77aaf295189f8 = require("../assets/ee2a1b6ce584c1ac0b4f45592fc77aaf295189f8.png");
export const img49d3d570fbf71727d79042aff84e12e35ba682ac = require("../assets/49d3d570fbf71727d79042aff84e12e35ba682ac.png");
export const img74d0313b58cf72fef9e643d79a7ccb1b72b0089a = require("../assets/74d0313b58cf72fef9e643d79a7ccb1b72b0089a.png");
export const imgd93ee4e273a0d377d07c3d01097557936281c419 = require("../assets/d93ee4e273a0d377d07c3d01097557936281c419.png");
export const img500c25e419158a64099e6cb1a732df3f7a6a5d64 = require("../assets/500c25e419158a64099e6cb1a732df3f7a6a5d64.png");
export const imgbd4c73c611c36637d455c8546e8b3df65c275cae = require("../assets/bd4c73c611c36637d455c8546e8b3df65c275cae.png");
export const imgc62a0f383aeae60d7449362bf96fdb993e659d63 = require("../assets/c62a0f383aeae60d7449362bf96fdb993e659d63.png");
export const img408258c639f252092f672a84a7f842d0aee4e3c4 = require("../assets/408258c639f252092f672a84a7f842d0aee4e3c4.png");
export const img0a9be4f521807f108347d7cadca6b0a36b50e2ef = require("../assets/0a9be4f521807f108347d7cadca6b0a36b50e2ef.png");
export const imgaa5f9b6e83a8d9e439a7aa2e14e8c5c8aef1e1ed = require("../assets/aa5f9b6e83a8d9e439a7aa2e14e8c5c8aef1e1ed.png");
export const img719b6be65b08e5fb0a76de0bd586e804ee9022e0 = require("../assets/719b6be65b08e5fb0a76de0bd586e804ee9022e0.png");
export const imgf063fb5a3ec5f90dea29669478885c7900bd66da = require("../assets/f063fb5a3ec5f90dea29669478885c7900bd66da.png");
export const imgff58e03bc3ce517343d6a37eaf9b02fc946a72b1 = require("../assets/ff58e03bc3ce517343d6a37eaf9b02fc946a72b1.png");
export const img306c017d8f0751798712a2cf0b01b8f26f13d05b = require("../assets/306c017d8f0751798712a2cf0b01b8f26f13d05b.png");
export const img3ed69a747f4fe354f5cc338c57454d5efb89077e = require("../assets/3ed69a747f4fe354f5cc338c57454d5efb89077e.png");
export const imgb877e5a4d3bdaf87d42fb59cb4a57bae5a80ea37 = require("../assets/b877e5a4d3bdaf87d42fb59cb4a57bae5a80ea37.png");
export const imga603b3828d2b8842a241d8920a91b2ab44577cef = require("../assets/a603b3828d2b8842a241d8920a91b2ab44577cef.png");
export const img8c4d4e69c0ce7da578c2c18cc4bc44a54b6e7c91 = require("../assets/8c4d4e69c0ce7da578c2c18cc4bc44a54b6e7c91.png");
export const imga9e9b9e8d1ebcd3f39c38dae0f3df178f817b2ff = require("../assets/a9e9b9e8d1ebcd3f39c38dae0f3df178f817b2ff.png");
export const round_arrowImg = require("../assets/round_arrow.png");
export const cardImg = require("../assets/card_img.png");
export const cardProfileImg = require("../assets/card_profile.png");
export const bannerImg = require("../assets/banner.png");
export const phoneImg = require("../assets/phone.png");
export const cardImg1 = require("../assets/card_img1.png");
export const cardImg2 = require("../assets/card_img2.png");
export const cardVectorImg = require("../assets/vector.png");
export const travelCardImg = require("../assets/travelCard.png");
export const travelPopupImg =require("../assets/travelPopupImg.png");
export const postCardImg =require("../assets/postCardImg.png");
export const bookmarkCard=require("../assets/bookmarkCard.png");
export const notificationCardImg=require("../assets/notificationCardImg.png");
export const notificationPopupImg=require("../assets/notificationPopupImg.png");
export const iPhone14Img=require("../assets/iPhone14.png")
export const VectorleftImg=require("../assets/Vectorleft.png")
export const ovalImg=require("../assets/oval.png")
export const bookmardkPopupImg=require("../assets/bookmardkPopup.png")
export const blueBGImg=require("../assets/blueBG.png")
export const aronProfileImg=require("../assets/aronProfile.png")
export const phoneProfileImg=require("../assets/phoneProfile.png")
export const featureImg1=require("../assets/featureImg1.png")
export const featureImg2=require("../assets/featureImg2.png")
export const featureImg3=require("../assets/featureImg3.png")
export const featureImg4=require("../assets/featureImg4.png")
export const featureImg5=require("../assets/featureImg5.png")
export const featureImg6=require("../assets/featureImg6.png")
export const fe_icon1=require("../assets/fe_icon1.png")
export const fe_icon2=require("../assets/fe_icon2.png")
export const fe_icon3=require("../assets/fe_icon3.png")
export const fe_icon4=require("../assets/fe_icon4.png")
export const fe_icon5=require("../assets/fe_icon5.png")
export const fe_icon6=require("../assets/fe_icon6.png")
export const roundMobileAppImg=require("../assets/roundMobileApp.png")
export const banner1Img=require("../assets/banner1.png")
export const banner2Img=require("../assets/banner2.png")
export const banner3Img=require("../assets/banner3.png")
export const cardProfileImg2 = require("../assets/card_profile2.png");
export const cardProfileImg3 = require("../assets/card_profile3.png");
export const cardImg3 = require("../assets/card_img3.png");
export const cardImg4 = require("../assets/card_img4.png");
export const cardImg5 = require("../assets/card_img5.png");
export const cardImg6 = require("../assets/card_img6.png");


