Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.create_email_otp = "account_block/accounts/create_email_otp";
exports.create_sms_otp = "account_block/accounts/create_sms_otp";
exports.verifyEmailOtp ="account_block/accounts/verify_email_otp";
exports.verifysmsOtp = "account_block/accounts/verify_sms_otp";
exports.signUpResendOtp ="account_block/accounts/resend_otp";
exports.acoount =  "account_block/accounts"
exports.errorEmailNotValid = "Email not valid.";
exports.pass = "Admin@123"
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.endPoint = ""
exports.vali= /[A-Z][a-zA-Z]*/
exports.httpPutMethod = "PUT";
exports.Activitieslist = "bx_block_activities/user_activities/get_all_activities";
exports.imageUpload ="account_block/accounts/photo";
exports.selectActivites = "bx_block_activities/user_sub_activities/add_sub_activities";
exports.userNameValidationTitle = "User Name is required";
exports.userNameTitle = "User Name";
exports.uniqueUsernameExist = "account_block/accounts/unique_username_check";
exports.isUserNameValid = "Username valid";
exports.gender1 = "Gender";
exports.gender2 = "Male";
exports.gender3 = "Female";
exports.gender4 = "Other";
exports.head1title11 = "Hello,";
exports.head1title22 = "Guest";
exports.maintitle = 'Let’s Go!';
// Customizable Area End
