// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/utils.web";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  enableField: boolean;
  post: {
    uploader_image: string;
    images_and_videos: { type: string; url: string }[];
    location: string;
    created_at: string;
    uploader_name: string;
    description: string;
  };
  bookmarkData: {
    [keys: string]: string | number;
  };
  bookmarkDataError: {
    title: string;
    description: string;
  };
  myPostId: string;
  bookMarkId: string;
}

interface SS {
  id: any;
}

export default class Bookmark2Controller extends BlockComponent<Props, S, SS> {
  PostId: string = "";
  handlePostBookMarkId: string = "";
  specificBookMarkId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      enableField: false,
      post: {
        uploader_image: "",
        images_and_videos: [],
        location: "",
        created_at: "",
        uploader_name: "",
        description: "",
      },
      bookmarkData: {},
      bookmarkDataError: {
        title: "",
        description: "",
      },
      myPostId: "",
      bookMarkId: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    const id = this.props.navigation.getParam("id");
    const bookMarkId = this.props.navigation.getParam("bookMarkId");
    this.setState(
      {
        myPostId: id,
        bookMarkId: bookMarkId ?? "",
      },
      () => {
        this.getPost(id);
        bookMarkId && this.getBookMarkDetails();
      }
    );
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.PostId) {
        this.setState({
          post: responseJson.data.attributes,
        });
      } else if (apiRequestCallId === this.handlePostBookMarkId) {
        if (responseJson.data.id) {
          const message = this.state.bookMarkId
            ? "Bookmarked updated successfully"
            : "Bookmarked created successfully";
          toast.success(message);
          this.props.navigation.history.goBack();
        }
      } else if (apiRequestCallId === this.specificBookMarkId) {
        if (responseJson.data) {
          const data = responseJson.data.attributes;
          this.setState({
            bookmarkData: { title: data.title, description: data.description },
          });
        }
      }
    }
  }

  getPost = async (id: string) => {
    this.PostId = await apiCall({
      endPoint: `${configJSON.PostId}/${id}`,
      method: "GET",
      contentType: configJSON.validationApiContentType,
    });
  };

  getBookMarkDetails = async () => {
    this.specificBookMarkId = await apiCall({
      endPoint: `bx_block_content_management/bookmarks/${this.state.bookMarkId}`,
      method: "GET",
      contentType: "application/json",
    });
  };

  handleData = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({
      bookmarkData: {
        ...this.state.bookmarkData,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleBookmark = async () => {
    if (this.state.bookmarkData.title && this.state.bookmarkData.description) {
      this.setState({
        bookmarkDataError: {
          title: "",
          description: "",
        },
      });
      const body = this.state.bookMarkId
        ? JSON.stringify({
          data: {
            attributes: {
              bookmarkable_type: "BxBlockPosts::Post",
              ...this.state.bookmarkData,
            },
          },
        })
        : JSON.stringify({
          data: {
            attributes: {
              bookmarkable_type: "BxBlockPosts::Post",
              bookmarkable_id: this.state.myPostId,
              ...this.state.bookmarkData,
            },
          },
        });

      this.handlePostBookMarkId = await (this.state.bookMarkId
        ? apiCall({
          endPoint: `bx_block_content_management/bookmarks/${this.state.bookMarkId}`,
          method: "PUT",
          contentType: configJSON.validationApiContentType,
          body,
        })
        : apiCall({
          endPoint: configJSON.postBookMark,
          method: "POST",
          contentType: configJSON.validationApiContentType,
          body,
        }));
    } else {
      if (
        !this.state.bookmarkData.title &&
        !this.state.bookmarkData.description
      ) {
        this.setState({
          bookmarkDataError: {
            title: "Please Enter Title",
            description: "Please Enter Description",
          },
        });
      } else if (!this.state.bookmarkData.title) {
        this.setState({
          bookmarkDataError: { description: "", title: "Please Enter Title" },
        });
      } else if (!this.state.bookmarkData.description) {
        this.setState({
          bookmarkDataError: {
            title: "",
            description: "Please Enter Description",
          },
        });
      }
    }
  };
}

// Customizable Area End
