import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

import React from "react";
import * as Yup from "yup";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

// Customizable Area End

export interface S {
  // Customizable Area Start
  arr: any;
  category: any;
  image: any;
  data: boolean;
  errorMsg: string;
  errorFlag: boolean;
  accessToken: string | null;
  fileData: any;
  isLoading: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
export default class MoreStepsControllerBlock extends BlockComponent<
  Props,
  S,
  SS
> {
  ActivitieslistId: string = "";
  imageId: string = "";
  selectActivitesId: string = "";
  inputRef = React.createRef<HTMLInputElement>();

  async componentDidMount() {
    super.componentDidMount();
    this.getActivitieslist();
  }
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      category: [],
      fileData: [],
      accessToken: localStorage.getItem("authToken"),
      arr: {
        data: [
          {
            id: "1",
            type: "",
            attributes: {
              name: "",
              activities: {
                data: [
                  {
                    id: "1",
                    type: "activity",
                    attributes: {
                      name: "",
                    },
                  },
                ],
              },
            },
          },
        ],
      },
      image: [],
      data: false,
      errorMsg: "",
      errorFlag: false,
      isLoading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.inputRef = React.createRef();
  }

  imageIdFun = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.imageId) {
      if (responseJson?.data) {
        this.setState({ data: true });
      }
    }
  };

  selectActivitesIdFun = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.selectActivitesId) {
      if (responseJson.message) {
        this.props.navigation.navigate("FanWall");
      }
    }
  };

  ActivitieslistIdFun = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.ActivitieslistId) {
      if (responseJson) {
        this.setState({ arr: responseJson });
      }
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.imageIdFun(from, message);
      this.selectActivitesIdFun(from, message);
      this.ActivitieslistIdFun(from, message);
      this.setState({
        isLoading: false,
      });
    }
  }

  getActivitieslist = () => {
    this.setState({
      isLoading: true,
    });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.accessToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ActivitieslistId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Activitieslist
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleImageClick = () => {
    this.inputRef.current?.click();
  };

  validationTagsFormSchema = Yup.object().shape({
    tags: Yup.array().min(3, "Please select atleast 3 hobbies"),
  });

  imageUploadApi = () => {
    this.setState({
      isLoading: true,
    });
    const header = {
      token: this.state.accessToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.imageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.imageUpload
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      this.state.fileData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleImageChange = (event: any) => {
    const file = event.target.files[0];
    this.setState({ image: event.target.files[0] });
    const formdata = new FormData();
    formdata.append("profile_photo", file);
    this.setState({ fileData: formdata }, () => this.imageUploadApi());
  };

  navigationFunctuon = () => {
    this.props.navigation.goBack();
  }

  handleSubmit = (data: {
    tags: {
      id: number;
      attributes: { name: string };
      parentId: string;
    }[];
  }) => {
    this.setState({
      isLoading: true,
    });
    const tag = data.tags.reduce((acc: any, { id, parentId }) => {
      if (!acc[parentId]) {
        acc[parentId] = [];
      }
      acc[parentId].push(String(id));
      return acc;
    }, {});
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.accessToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.selectActivitesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.selectActivites
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(tag)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}

// Customizable Area End
