// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}
// Customizable Area End
export interface S {
    // Customizable Area Start
    oneMinTimer: number;
    accessToken: string | null; 
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start
export default class SplashScreenController extends BlockComponent<
    Props,
    S,
    SS
> {
    interval: any
    async componentDidMount() {
        super.componentDidMount();
            setTimeout(() => {
                this.functionCall()
            }, 2000);
    }

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.state = {
            // Customizable Area Start  
            oneMinTimer: 2,
            accessToken: localStorage.getItem("authToken"),
            // Customizable Area End
        };
    }

    functionCall = () => {
        this.state.accessToken ? this.props.navigation.navigate("FanWall") : this.props.navigation.navigate("LandingHomePage")
    }; 
}
// Customizable Area End