import React, { CSSProperties } from "react";
// Customizable Area Start
import { Container, Grid, Box, Typography, Button } from "@material-ui/core";
import LandingPageController, { Props } from "./LandingPageController.web";
import { styled } from "@material-ui/core/styles";

import {
    cardProfileImg,
    img719b6be65b08e5fb0a76de0bd586e804ee9022e0,
    img306c017d8f0751798712a2cf0b01b8f26f13d05b,
    img408258c639f252092f672a84a7f842d0aee4e3c4,
    cardImg1,
    cardImg2,
    cardVectorImg,
    travelCardImg,
    travelPopupImg,
    postCardImg,
    bookmarkCard,
    notificationCardImg,
    notificationPopupImg,
    iPhone14Img,
    bookmardkPopupImg,
    ovalImg,
    VectorleftImg,
    aronProfileImg,
    phoneProfileImg,
    featureImg1,
    featureImg2,
    featureImg3,
    featureImg4,
    featureImg5,
    featureImg6,
    fe_icon1,
    fe_icon2,
    fe_icon3,
    fe_icon4,
    fe_icon5,
    fe_icon6,
    blueBGImg,
    roundMobileAppImg,
    banner1Img,
    banner2Img,
    banner3Img,
    cardProfileImg2,
    cardProfileImg3,
    cardImg3,
    cardImg4,
    cardImg6,
    cardImg5
} from "./assets";
import Carousel from "react-material-ui-carousel";
// Customizable Area End
// Customizable Area Start

const ContainerBlock = styled(Box)({
    padding: "50px",
    "@media (max-width:900px)": {
        padding: "0px",
    },
    "& .feature-heading": {
        color: "#14183E",
        fontFamily: "HelveticaNeue",
        fontWeight: 600,
        fontSize: "40px",
        textAlign: "center",
        marginBottom: "50px",
        "@media (max-width:900px)": {
            fontSize: "24px",
            marginBottom: "10px"
        }
    }
});
const BannerBox = styled(Box)({
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    position: "relative",
    "& .container-banner": {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        height: "100%",
        left: 0,
        top: 0,
        padding: "68px",
        "@media (max-width:900px)": {
            padding: '15px',
            height: "100%"
        },
        "& .sec-1": {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            "@media (max-width:900px)": {
                alignItems: "start",
                flexDirection: "column",
                justifyContent: "end",
                height: "100%",
                marginBottom: "15px",
                padding: "5px"
            },

            "& h1": {
                fontFamily: "HelveticaNeue",
                fontSize: "56px",
                fontWeight: 700,
                // lineHeight: "72px",
                letterSpacing: "0em",
                "@media (max-width:900px)": {
                    fontSize: "16px",
                    lineHeight: "20px",
                    margin: 0
                }
            },
            "& p": {
                fontFamily: "HelveticaNeue",
                fontSize: "24px",
                fontWeight: 400,
                lineHeight: "40px",
                "@media (max-width:900px)": {
                    fontSize: "12px",
                    margin: 0,
                    lineHeight: "12px"
                }
            }
        },
        "& .planning-btn": {
            backgroundColor: "rgb(37, 170, 225)",
            maxWidth: "200px",
            color: "#fff",
            padding: "20px",
            borderRadius: "6px",
            textTransform: "capitalize",
            fontFamily: "HelveticaNeue",
            fontSize: "18px",
            "@media (max-width:900px)": {
                margin: "10px 0px 10px 0",
                padding: "2px 5px",
                fontSize: "10px",
                maxWidth: "85px",
                borderRadius: "2px"
            }
        }
    },
    "& .sec-2": {
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
        height: "100%",
        paddingBottom: "150px",
        flexDirection: "column",
        position: "relative",
        "@media (max-width:900px)": {
            height: "100%",
            padding: "5"
        },
        "& .feature-card": {
            width: "365px",
            background:
                "linear-gradient(151.39deg, rgba(238, 238, 238, 0.798748) 8.36%, rgba(216, 216, 216, 0.396699) 92.32%)",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0px 0px 10px 0px #FFFFFF7A inset",
            display: "flex",
            position: "relative",
            "@media (max-width:900px)": {
                width: "100%",
                padding: "5px"
            },
            "& h2": {
                fontFamily: "HelveticaNeue",
                fontSize: "28px",
                color: "#222222",
                fontWeight: 600,
                lineHeight: "42px",
                letterSpacing: "0px",
                textAlign: "left",
                margin: 0,
                "@media (max-width:900px)": {
                    fontSize: "14px",
                    lineHeight: "20px"
                }
            },
            "& p": {
                fontFamily: "HelveticaNeue",
                fontSize: "20px",
                color: "#222222",
                fontWeight: 500,
                margin: "10px 0",
                "@media (max-width:900px)": {
                    fontSize: "12px",
                    lineHeight: "15px",
                    margin: "5px 0"
                }
            },
            "& .ph": {
                color: "white"
            },
            "& .card-profile_img": {
                "@media (max-width:900px)": {
                    width: "20px"
                }
            },
            "& .images-row": {
                position: "relative",
                "@media (max-width:900px)": {
                    width: "100%"
                },
                "& .img1": {
                    position: "absolute",
                    top: "10px",
                    right: "0px",
                    zIndex: 1,
                    "@media (max-width:900px)": {
                        width: "40px"
                    }
                },
                "& .img2": {
                    position: "absolute",
                    top: "50px",
                    right: "50px",
                    "@media (max-width:900px)": {
                        width: "32px",
                        top: "30px",
                        right: "30px"
                    }
                }
            },
            "& .image-profile_row": {
                display: "flex",
                padding: "10px 0",
                "@media (max-width:900px)": {
                    padding: "4px 0",
                },
                "& img": {
                    width: "40px",
                    height: "40px",
                    "@media (max-width:900px)": {
                        width: "20px",
                        height: "20px"
                    }
                },
                "& .img-count": {
                    borderRadius: "50%",
                    background: "#FFC042",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "HelveticaNeue",
                    fontSize: "18px",
                    fontWeight: " 500",
                    color: "#222222",
                    "@media (max-width:900px)": {
                        width: "20px",
                        height: "20px",
                        fontSize: "12px"
                    }
                }
            },
            "& .vector_img": {
                bottom: "-28px",
                left: "-75px",
                position: "absolute",
                "@media (max-width:900px)": {
                    width: "30px",
                    left: "-15px",
                    bottom: "-10px",
                    position: "absolute"
                }
            }
        }
    },

    "@media (max-width:900px)": {
        height: "200px",
        width: "100%"
    }
});
const ServiceSection = styled(Grid)({
    background:
        "linear-gradient(180deg, #E2F6FC 14.25%, rgba(255, 255, 255, 0.00) 100%)",
    position: "relative",
    padding: "50px 50px",
    "@media (max-width:900px)": {
        padding: "30px"
    },
    "& .ser-row1": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "15px",
        padding: "30px 0",
        "& .ser-sec1": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            position: "relative",
            width: "fit-content",
            marginRight: "30px",
            "@media (max-width: 900px)": {
                marginRight: "0px",
            },
            "& .ser-img": {
                width: "270px",
                "@media (max-width:900px)": {
                    width: "250px",
                    margin: 0,
                }
            },
            "& .travel-popup": {
                position: "absolute",
                top: "45px",
                left: "-35px",
                width: "300px",
                "@media (max-width: 900px)": {
                    width: "290px",
                    left: "-45px",
                    top: "50px"
                }
            }
        },
        "& .ser-sec2": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            position: "relative",
            width: "fit-content",
            marginLeft: "30px",
            "@media (max-width: 900px)": {
                marginLeft: "0px",
            },
            "& .ser-img": {
                width: "377px",
                "@media (max-width:900px)": {
                    width: "275px",
                }
            },
            "& .aron-profile": {
                position: "absolute",
                top: "-25px",
                left: "-25px",
                width: "65px",
                "@media (max-width:900px)": {
                    top: "-20px",
                    left: "0px",
                    width: "55px"
                }
            }
        }
    },
    "& .ser-row2": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        position: "relative",
        // width: "fit-content",
        "& .ser-sec1": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            position: "relative",
            width: "fit-content",
            maxWidth: "400px",
            "& .ser-img": {
                width: "378px",
                height: "778px",
                "@media (max-width:900px)": {
                    width: "275px",
                    height: "515px",
                }
            },
            "& .travel-popup": {
                position: "absolute",
                top: "45px",
                left: "10px",
                width: "300px",
                "@media (max-width: 900px)": {
                    width: "290px",
                    left: "-2px",
                    top: "50px"
                }
            }
        },
        "& .ser-img": {
            width: "400px",
            "@media (max-width:900px)": {
                width: "275px"
            }
        },
        "& .phon-profile": {
            position: "absolute",
            top: "25px",
            left: -15,
            width: "90px",
            "@media (max-width:900px)": {
                left: "-10px",
                top: 0,
                width: "72px"
            }
        }
    },
    "& .ser-row3": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& .bookmark": {
            position: "relative",
            "& .ser-img": {
                width: "220px",
                "@media (max-width:900px)": {
                    width: "275px"
                }
            },
            "& .bookmark-popup": {
                position: "absolute",
                top: "60px",
                left: "-15px",
                width: "220px",
                "@media (max-width:900px)": {
                    width: "285px",
                    top: "70px",
                    left: "-20px"
                }
            }
        },
        "& .notification": {
            position: "relative",
            "& .ser-img": {
                width: "240px",
                "@media (max-width:900px)": {
                    width: "275px"
                }
            },
            "& .notification-popup": {
                position: "absolute",
                top: "70px",
                left: "-22px",
                width: "362px",
                "@media (max-width:900px)": {
                    width: "300px",
                    left: "-10px"
                }
            }
        },
        "& .oval-row": {
            display: "flex",
            alignItems: "center",
            padding: "20px 0",
            width: "100%",
            marginRight: "60px",
            justifyContent: "center",
            "& .ser-img": {
                width: "60px",
                "@media (max-width:900px)": {
                    width: "75px"
                }
            },
            "& .arrow_img": {
                marginLeft: "60px",
                width: "50px",
                "@media (max-width:900px)": {
                    width: "40px"
                }
            }
        }
    }
});
const FeatureSection = styled(Grid)({
    position: "relative",
    "@media (max-width:900px)": {
        padding: "30px"
    },
    "& .feature_box": {
        padding: "0 20px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        "@media (max-width:900px)": {
            height: "auto"
        },
        "& .image-container": {
            position: "relative",
            height: "290px",
            "@media (max-width:900px)": {
                height: "170px"
            },
            "& .fe-icon0": {
                position: "absolute",
                top: -35,
                left: -32,
                width: "55px",
                height: "58px",
                "@media (max-width:900px)": {
                    width: "45px",
                    height: "45px",
                    top: -25,
                    left: -21,
                },
            },
            "& .fe-icon1": {
                position: "absolute",
                top: -40,
                left: -30,
                width: "63px",
                height: "63px",
                "@media (max-width:900px)": {
                    width: "45px",
                    height: "45px",
                    top: -35,
                    left: -20,
                },
            },
            "& .fe-icon2": {
                position: "absolute",
                top: -24,
                left: -38,
                width: "84px",
                height: "84px",
                "@media (max-width:900px)": {
                    width: "45px",
                    height: "45px",
                    top: -14,
                    left: -18,
                },
            },
            "& .fe-icon3": {
                position: "absolute",
                top: -45,
                left: -35,
                width: "75px",
                height: "75px",
                "@media (max-width:900px)": {
                    width: "50px",
                    height: "50px",
                    top: -32,
                    left: -22,
                },
            },
            "& .fe-icon4": {
                position: "absolute",
                top: -40,
                left: -38,
                width: "70px",
                height: "70px",
                "@media (max-width:900px)": {
                    width: "45px",
                    height: "45px",
                    top: -25,
                    left: -20,
                },
            },
            "& .fe-icon5": {
                position: "absolute",
                top: -70,
                left: -50,
                width: "115px",
                height: "115px",
                "@media (max-width:900px)": {
                    width: "50px",
                    height: "50px",
                    top: -25,
                    left: -31,
                },
            },

            "& .feature_img0": {
                width: "271px",
                height: "213px",
                "@media (max-width:900px)": {
                    width: "225px",
                    height: "180px"
                },
            },
            "& .feature_img1": {
                width: "370px",
                height: "250px",
                marginTop: "-20px",
                "@media (max-width:900px)": {
                    width: "250px",
                    height: "180px",
                },
            },
            "& .feature_img2": {
                width: "299px",
                height: "232px",
                "@media (max-width:900px)": {
                    width: "225px",
                    height: "175px",
                },
            },
            "& .feature_img3": {
                width: "326px",
                height: "286px",
                "@media (max-width:900px)": {
                    width: "200px",
                    height: "180px"
                },
            },
            "& .feature_img4": {
                width: "328px",
                height: "250px",
                "@media (max-width:900px)": {
                    width: "200px",
                    height: "180px"
                },
            },
            "& .feature_img5": {
                width: "250px",
                height: "286px",
                "@media (max-width:900px)": {
                    width: "160px",
                    height: "180px",
                },
            },
        },

        "& .image_title": {
            fontSize: "24px",
            color: "#222222",
            fontFamily: "HelveticaNeue",
            textAlign: "center",
            fontWeight: 600,
            lineHeight: "30px"
        },
        "& .image-des": {
            fontSize: "16px",
            color: "#94959B",
            fontFamily: "HelveticaNeue",
            textAlign: "center",
            lineHeight: "25px"
        }
    }
});
const AppSection = styled(Grid)({
    display: "flex",
    borderRadius: "30px",
    position: "relative",
    "& .blue-bg": {
        objectFit: "cover",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0",
        top: "0",
        zIndex: -9,
        borderRadius: "30px"
    },
    "& .left-sec": {
        position: "relative",
        "& .pink-circle": {
            width: "68px",
            height: "68px",
            transform: "rotate(30deg)",
            position: 'absolute',
            background: "#CC1CED",
            boxShadow: "0px -8px 12px 0px rgba(255, 255, 255, 0.50) inset",
            filter: "drop-shadow(0px 4px 12px rgba(204, 28, 237, 0.40))",
            top: 230,
            left: 570,
            borderRadius: "50%",
        },
        "& img": {
            width: "655px",
            "@media (max-width:900px)": {
                width: "100%"
            },


        }
    },
    "& .right-sec": {
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
        padding: "67px",
        "@media (max-width:900px)": {
            padding: "10px",
            alignItems: "center"
        },
        "& h1": {
            fontSize: "56px",
            color: "#FFF",
            fontFamily: "HelveticaNeue",
            fontWeight: 600,
            lineHeight: "72px",
            marginBottom: "24px",
            "@media (max-width:900px)": {
                fontSize: "36px",
                textAlign: "center"
            }
        },
        "& p": {
            color: "#FFF",
            fontSize: "20px",
            fontFamily: "HelveticaNeue",
            fontWeight: "400",
            lineHeight: "32px",
            opacity: "0.6462",
            marginTop: "0px",
            "@media (max-width:900px)": {
                fontSize: "16px",
                lineHeight: "30px"
            }
        },
        "& .app-img": {
            display: "flex",
            width: "100%",
            paddingTop: "30px",
            flexWrap: "wrap",
            "@media (max-width:900px)": {
                width: "100%",
                flexWrap: "wrap"
            },
            "& img": {
                width: "215px",
                "@media (max-width:900px)": {
                    width: "100px",
                    height: "auto"
                },
            },
            "& .img-play": {
                marginRight: "24px",
                "@media (max-width:900px)": {
                    width: "100px",
                    height: "auto"
                },
            }
        }
    }
});
const FooterBox = styled(Grid)({
    padding: "60px 50px 0 100px",
    background: "#0E1D28",
    "@media (max-width:900px)": {
        padding: "50px 0px 0px 50px"
    },
    "@media (max-width:600px)": {
        padding: "50px 0px 0px 30px",
    },
    "@media (max-width:400px)": {
        padding: "20px 5px 0px 10px",
    },
    "& .left-sec": {
        "& .footer-logo-title": {
            color: "#FFF",
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "28px;",
            width: '290px'
        }
    },
    "& .right-sec": {
        display: "flex",
        "@media (max-width:400px)": {
            flexDirection:'column'
        },
        "& .right-box": {
            padding: "0 10px",
            "& .footer-title": {
                fontSize: "18px",
                fontWeight: 700,
                marginBottom: "10px",
                color: "#FFF",
                fontFamily: "HelveticaNeue",
                "@media (max-width:400px)": {
                    paddingTop:'20px'
                },
            },
            "& .footer-desc": {
                fontSize: "16px",
                color: "#FFF",
                fontFamily: "HelveticaNeue",
                lineHeight: "25px",
                textDecoration: "none",
                padding: 0,
                justifyContent: "start",
                textTransform: "capitalize",
                margin: 0,
            },
            "& .social-link": {
                display: "flex",
                flexDirection: "column"
            },
            "& .app-img": {
                width: "193px",
                objectFit: "contain",
                marginTop: "61px",
                "@media (max-width:480px)": {
                    width: "140px",
                },
            }

        },
        "& .footer-nav-btn": {
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            "& .footer-btn": {
                padding: "0",
                width: "67px"
            },

        }

    },

    "& .copy-right": {
        textAlign: "center",
        marginTop: "78px",
        fontFamily: "HelveticaNeue",
        "& button": {
            padding: 0,
            color: "#FFF",
            textTransform: "capitalize",
            fontFamily: "HelveticaNeue",
        }
    },
    "& .footer-btn_row": {
        "@media (max-width:900px)": {
            padding: "10px 0 !important",
            justifyContent: "center",
            "& img": {
                width: "100px"
            }
        },
        display: "flex",
        gap: "35px",
        padding: "50px 0"
    }
});
const Logobox = styled(Box)({
    cursor: "pointer",
    "& .logoname": {
        "@media (max-width:900px)": {
            fontSize: "1.5rem !important"
        }
    },
    "& img": {
        height: 50,
        "@media (max-width:900px)": {
            height: 30
        }
    }
});
const CarouselContainer = styled(Carousel)({
    width: "100%"
});

// Customizable Area End
export default class LandingPageHome extends LandingPageController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    goToItineraryCration = () => {
        this.props.navigation.navigate("ItinerarySection");
    };
    async componentDidMount(): Promise<void> {
        this.fetchContactUs()
    }



    render() {
        const featureData = [
            {
                img: featureImg1,
                title: "Itinerary Builder",
                des: "Craft your journey with a user-friendly interface that lets you swiftly map out your trip, customize activities to your liking, and effortlessly add or remove stops along the way.",
                icon: fe_icon1
            },
            {
                img: featureImg2,
                title: "Collaborative Planning",
                des: "Enrich your travel experience by inviting loved ones to join in on the planning process. Together, design the perfect itinerary and ensure everyone's preferences are catered to.",
                icon: fe_icon2
            },
            {
                img: featureImg3,
                title: "Save locations",
                des:
                    "Discover new destinations from various sections of our platform and effortlessly bookmark them. Seamlessly integrate these saved spots into your travel plans whenever you're ready.",
                icon: fe_icon3
            },
            {
                img: featureImg4,
                title: "Explorer's Forum",
                des:
                    "Dive into a thriving community of globetrotters eager to discuss and share insights about upcoming destinations. Pose questions, offer advice, or simply share your excitement about a future journey.",
                icon: fe_icon4
            },
            {
                img: featureImg5,
                title: "Community Updates",
                des:
                    "Chronicle your adventures by sharing captivating images and videos from your travels. Let the community relive your experiences and draw inspiration for their own expeditions.",
                icon: fe_icon5
            },
            {
                img: featureImg6,
                title: "Direct Message",
                des:
                    "Forge deeper connections with fellow travelers by diving into private conversations. Share stories, seek advice, or simply chat about shared passions for exploring the world.",
                icon: fe_icon6
            }
        ];
        const carouselContent = [
            {
                title: "Plan your next adventure with ease!",
                img: banner1Img,
                desc: "The ultimate tool for hassle-free travel planning.",
                card_title: "Bali Trip!",
                card_des1: "Travellers",
                card_des2: "Travel Itinerary",
                cardImg1: cardImg1,
                cardImg2: cardImg2
            },
            {
                title: "Discover with Bhomio and uncover unique destinations",
                img: banner2Img,
                desc: "The ultimate tool for hassle-free travel planning.",
                card_title: "Rajasthan Trip!",
                card_des1: "Travellers",
                card_des2: "Travel Itinerary",
                cardImg1: cardImg3,
                cardImg2: cardImg4
            },
            {
                title: "Share your travels effortlessly, inspiring others to embark on their own adventures",
                img: banner3Img,
                desc: "The ultimate tool for hassle-free travel planning.",
                card_title: "Manali Trip!",
                card_des1: "Travellers",
                card_des2: "Travel Itinerary",
                cardImg1: cardImg5,
                cardImg2: cardImg6
            }
        ];

        return (
            <>
                <CarouselContainer
                    indicators={true}
                    autoPlay={true}
                    animation="slide"
                    timeout={1000}
                    activeIndicatorIconButtonProps={{
                        style: { color: 'transparent', background: "#DF6951", width: "32px", height: "3px", margin: "0 10px", padding: 0, borderRadius: 0 }

                    }}
                    indicatorIconButtonProps={{
                        style: {
                            background: '#FFFF',
                            padding: 0, borderRadius: 0,
                            width: "32px", height: "3px", margin: "0 10px",
                            color: 'transparent',
                        }
                    }}

                    indicatorContainerProps={{
                        style: {
                            marginTop: '-40px',
                            textAlign: 'center',
                            position: "absolute",

                        }

                    }}
                >
                    {carouselContent.map(
                        ({
                            title,
                            img,
                            desc,
                            card_title,
                            card_des1,
                            card_des2,
                            cardImg1,
                            cardImg2
                        }: {
                            title: string;
                            img: any;
                            desc: string;
                            card_title: string;
                            card_des1: string;
                            card_des2: string;
                            cardImg1: any;
                            cardImg2: any;
                        }) => (
                            <BannerBox>
                                <Box
                                    style={{
                                        position: "relative",
                                        height: "100%",
                                        width: "100%"
                                    }}
                                >
                                    <img
                                        src={img}
                                        style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            height: "100%"
                                        }}
                                    />
                                </Box>
                                <Container className="container-banner" maxWidth="xl">
                                    <Grid item className="sec-1" md={7} xs={6} sm={7} lg={7}>
                                        <h1>{title}</h1>
                                        <p>{desc}</p>
                                        <Button
                                            onClick={this.goToItineraryCration}
                                            test-Id="test_id"
                                            className="planning-btn"
                                        >
                                            Start Planning!
                                        </Button>
                                    </Grid>
                                    <Grid item className="sec-2" md={5} xs={6} sm={5} lg={5}>
                                        <Box className="feature-card">
                                            <Grid item md={6} xs={6}>
                                                <h2>{card_title}</h2>
                                                <p className="ph">{card_des1} -</p>
                                                <Box className="image-profile_row">
                                                    <img
                                                        className="card-profile_img"
                                                        src={cardProfileImg}
                                                    />
                                                    <img
                                                        className="card-profile_img"
                                                        src={cardProfileImg2}
                                                    />
                                                    <img
                                                        className="card-profile_img"
                                                        src={cardProfileImg3}
                                                    />
                                                    <span className="img-count">+6</span>
                                                </Box>
                                                <p>{card_des2}</p>
                                            </Grid>
                                            <Grid className="images-row" item md={6} xs={6}>
                                                <img className="img1" src={cardImg1} />
                                                <img className="img2" src={cardImg2} />
                                            </Grid>
                                            <img className="vector_img" src={cardVectorImg} />
                                        </Box>
                                    </Grid>
                                </Container>
                            </BannerBox>
                        )
                    )}
                </CarouselContainer>
                <ServiceSection container>
                    <Grid className="ser-row1" item xs={12} sm={12} md={4}>
                        <Box className="ser-sec1">
                            <img className="ser-img" src={travelCardImg} alt="service-img" />
                            <img className="travel-popup" src={travelPopupImg} />
                        </Box>
                        <Box className="ser-sec2">
                            <img className="ser-img" src={postCardImg} />
                            <img className="aron-profile" src={aronProfileImg} />
                        </Box>
                    </Grid>
                    <Grid className="ser-row2" item xs={12} sm={12} md={4}>
                        <Box className="ser-sec1">
                            <img className="ser-img" src={iPhone14Img} alt="service-img" />
                            <img className="phon-profile" src={phoneProfileImg} />
                        </Box>

                    </Grid>
                    <Grid className="ser-row3" item xs={12} sm={12} md={4}>
                        <Box className="bookmark">
                            <img className="ser-img" src={bookmarkCard} alt="service-img" />
                            <img className="bookmark-popup" src={bookmardkPopupImg} />
                        </Box>
                        <Box className="oval-row">
                            <img className="ser-img" src={ovalImg} alt="service-img" />
                            <img
                                className="arrow_img"
                                src={VectorleftImg}
                                alt="service-img"
                            />
                        </Box>
                        <Box className="notification">
                            <img className="ser-img" src={notificationCardImg} />
                            <img className="notification-popup" src={notificationPopupImg} />
                        </Box>
                    </Grid>
                </ServiceSection>

                <ContainerBlock>
                    <Typography variant="h4" className="feature-heading">
                        Features To Cater All Your Needs
                    </Typography>
                    <FeatureSection container alignItems="center">
                        {featureData.map(
                            ({
                                img,
                                title,
                                des,
                                icon
                            }: {
                                img: any;
                                title: string;
                                des: string;
                                icon: any;
                            }, index: number) => (
                                <Grid item xs={12} sm={12} md={4}>
                                    <Box sx={webStyle.postImageArea} className="feature_box">
                                        <Box className="image-container">
                                            <img className={"feature_img" + index} src={img} alt="Banner" />
                                            <img className={"fe-icon" + index} src={icon} />
                                        </Box>
                                        <p className="image_title">{title}</p>
                                        <p className="image-des">{des}</p>
                                    </Box>
                                </Grid>
                            )
                        )}
                    </FeatureSection>
                    <AppSection container>
                        <img className="blue-bg" src={blueBGImg} />
                        <Grid className="left-sec" item md={6} lg={6}>
                            <span className="pink-circle" />
                            <img src={roundMobileAppImg} />
                        </Grid>
                        <Grid className="right-sec" item md={6} lg={6}>
                            <h1>Building a travel community</h1>
                            <p>
                                Plan & post your perfect trips with us. Get travel tips,
                                destination information and inspiration from people around you.
                            </p>
                            <Box className="app-img">
                                <img
                                    className="img-play"
                                    src={img306c017d8f0751798712a2cf0b01b8f26f13d05b}
                                    alt="Google Play"
                                />
                                <img
                                    src={img408258c639f252092f672a84a7f842d0aee4e3c4}
                                    alt="App Store"
                                />
                            </Box>
                        </Grid>
                    </AppSection>
                </ContainerBlock>
                <FooterBox>
                    <Box alignItems="center">
                        <Grid container>
                            <Grid className="left-sec" md={5} xs={12}>
                                <Logobox
                                    data-test-id="logo-btn"
                                    onClick={this.scrollToTop}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gridGap: "20px"
                                    }}
                                >
                                    <img src={img719b6be65b08e5fb0a76de0bd586e804ee9022e0} />
                                    <Typography
                                        className="logoname"
                                        variant="h4"
                                        style={{ color: "white", lineHeight: 0 }}
                                    >
                                        bhomio
                                    </Typography>
                                </Logobox>
                                <p className="footer-logo-title">
                                    Plan Your Next Adventure With Ease! The ultimate tool for
                                    hassle-free travel planning.
                                </p>
                            </Grid>
                            <Grid className="right-sec" md={7} xs={12}>
                                <Grid
                                    className="right-box footer-nav-btn"
                                    md={2}
                                    xs={3}
                                >
                                    <p className="footer-title">Services</p>
                                    <Button
                                        data-test-id="go-home"
                                        onClick={this.scrollToTop}
                                        className="footer-desc footer-btn"
                                    >
                                        Home
                                    </Button>
                                    <Button
                                        data-test-id="go-place"
                                        onClick={this.goToFooterNavigation.bind(this, "Places")}
                                        className="footer-desc footer-btn"
                                    >
                                        Explore
                                    </Button>
                                    <Button
                                        data-test-id="go-feed"
                                        onClick={this.goToFooterNavigation.bind(this, "Feed")}
                                        className="footer-desc footer-btn"
                                    >
                                        Feed
                                    </Button>
                                    <Button
                                        onClick={this.goToFooterNavigation.bind(
                                            this,
                                            "EmailAccountLoginBlock"
                                        )}
                                        data-test-id="go-login"
                                        className="footer-desc footer-btn"
                                    >
                                        Login
                                    </Button>
                                    <Button
                                        data-test-id="go-onboard"
                                        onClick={this.goToFooterNavigation.bind(
                                            this,
                                            "OnboardingScreenBlock"
                                        )}
                                        className="footer-desc footer-btn"
                                    >
                                        Signup
                                    </Button>
                                </Grid>
                                <Grid className="right-box" md={8} xs={6}>
                                    <p className="footer-title">Contact us</p>
                                    <p style={{ marginBottom: "10px" }} className="footer-desc">
                                        {this.state.contact_us?.address}
                                    </p>
                                    <p style={{ marginBottom: "10px" }} className="footer-desc">
                                        {`Customer Services: ${this.state.contact_us?.full_phone_number}`}
                                    </p>
                                    <p style={{ marginBottom: "10px" }} className="footer-desc">
                                        {`Email: ${this.state.contact_us?.email}`}
                                    </p>
                                    <p className="footer-desc">
                                        (Mon-Fri, 9am - 6pm)
                                    </p>
                                </Grid>
                                <Grid className="right-box" md={2} xs={3}>
                                    <p className="footer-title">Social Media </p>
                                    <Button data-test-id="facebook" onClick={this.openSocialLink.bind(this, "https://www.facebook.com/")} className="footer-desc social-link">Facebook</Button>
                                    <Button data-test-id="twitter" onClick={this.openSocialLink.bind(this, "https://twitter.com/")} className="footer-desc social-link">Twitter</Button>
                                    <Button data-test-id="instagram" onClick={this.openSocialLink.bind(this, "https://instagram.com/travelwithbhomio?igshid=MTNiYzNiMzkwZA==")} className="footer-desc social-link">Instagram</Button>
                                    <Button data-test-id="youtube" onClick={this.openSocialLink.bind(this, "https://www.youtube.com/")} className="footer-desc social-link">Youtube</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid md={5} className="'left-sec" />
                            <Grid md={7} className="right-sec">
                                <Grid
                                    className="right-box"
                                    md={12}
                                    xs={12}
                                >
                                    <img
                                        className="app-img"
                                        style={{ marginRight: "24px" }}
                                        src={img306c017d8f0751798712a2cf0b01b8f26f13d05b}
                                        alt="Google Play"
                                    />
                                    <img
                                        className="app-img"
                                        src={img408258c639f252092f672a84a7f842d0aee4e3c4}
                                        alt="App Store"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <p className="footer-desc copy-right">
                        2023 Bhomio | <Button data-test-id="about-us" onClick={this.goToFooterNavigation.bind(
                            this,
                            "AboutUs"
                        )}>About Us</Button> | <Button data-test-id="term-condition" onClick={this.goToFooterNavigation.bind(
                            this,
                            "TermAndCondition"
                        )}>Terms & Condition</Button> | <Button data-test-id="privacy-policy" onClick={this.goToFooterNavigation.bind(
                            this,
                            "PrivacyPolicy"
                        )}>Privacy Policy</Button>

                    </p>
                </FooterBox>
            </>
        );
    }
    // Customizable Area End
}

const webStyle = {
    mainHeadPosition: {
        textAlign: "center",
        padding: "0"
    } as CSSProperties,
    mainHead: {
        color: "rgba(34, 34, 34, 1)",
        fontFamily: "HelveticaNeue",
        fontWeight: "bold"
    } as CSSProperties,

    postImageArea: {
        margin: "30px 0"
    } as CSSProperties,
};
