import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,

} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { logo, dashboard } from "./assets";

import SplashScreenController, {
  Props,
} from "./SplashScreenController.web";
// Customizable Area End
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End
export default class SplashScreen extends SplashScreenController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ overflowX: 'hidden', height: '100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <img src={logo} width={100}></img>
        </Grid>
      </ThemeProvider>
    )
  }
  // Customizable Area End
}