// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../../../components/src/utils.web";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  isLoading: boolean;
  location: string;
  reviews_count: number;
  hotelId: string;
  rating: number;
  description: string;
  why_book_this: string;
  tag_line: string;
  name: string;
  direction: { lat: number; lng: number };
  restaurantImage: string;
  zoom: number;
  exploreId: string;
  imageUrl: { id: number; url: string; filename: string; type: string }[];
  currentImageIndex: number;
}

interface SS {
  id: any;
}

export default class HotelDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  hotelDetailsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
      hotelId: "0",
      name: "",
      location: "",
      rating: 0,
      reviews_count: 0,
      tag_line: "",
      why_book_this: "",
      description: "",
      direction: { lat: 0, lng: 0 },
      restaurantImage: "",
      zoom: 11,
      exploreId: "",
      imageUrl: [],
      currentImageIndex: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const hotelId = this.props.navigation.getParam("hotelId");
    const exploreId = this.props.navigation.getParam("id");
    this.setState(
      {
        hotelId: hotelId,
        exploreId,
      },
      async () => {
        await this.getHotelDetails();
      }
    );
  }

  getHotelDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.hotelDetailsApiCallId = await apiCall({
      endPoint: `bx_block_explore/explore_items/${this.state.hotelId}`,
      method: "GET",
    });
  };

  goToPreviousImageInDetails = () => {
    this.setState({
      currentImageIndex:
        this.state.currentImageIndex === 0
          ? this.state.imageUrl.length - 1
          : this.state.currentImageIndex - 1,
    });
  };

  goToNextImageInDetails = () => {
    this.setState({
      currentImageIndex:
        (this.state.currentImageIndex + 1) % this.state.imageUrl.length,
    });
  };

  redirectToRatingPages = () => {
    this.props.navigation.history.push(`/${this.state.hotelId}/rating`);
  };

  redirectToBookmarkPages = () => {
    this.props.navigation.history.push(`/${this.state.hotelId}/bookmark`);
  };

  
  
  
  
  handleErrorHotelDetails = (error: any) => {
    if (Array.isArray(error)) {

      if (
        error[0].token === "Token has Expired" ||
        error[0].token === "Invalid token"
      ) {
        window.localStorage.removeItem("authToken");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        window.localStorage.removeItem("user_details");
        toast.error(`${error[0].token}, Please login again.`);
        
        return;
      } else if (error[0]) {
        
        toast.error(error[0]);
        this.props.navigation.goBack();
        return;
      }

    }
    toast.error("Something went to wrong please retry");

  };

  handleResponseHotelDetails = async (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.hotelDetailsApiCallId && responseJson.items) {
      const data = responseJson.items.data.attributes;
      this.setState({
        name: data.name,
        description: data.description,
        direction: {
          lat: data.latitude,
          lng: data.longitude,
        },
        imageUrl: data.item_images,
        location: data.location,
        tag_line: data.tag_line,
        reviews_count: data.reviews_count,
        rating: data.rating,
        why_book_this: data.why_book_this,
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.errors) {
        this.handleErrorHotelDetails(responseJson.errors);
      }
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        this.handleResponseHotelDetails(apiRequestCallId, responseJson);
      }

      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
