Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.followerList ="bx_block_followers/follower_following"
exports.FOllowerBLock = "bx_block_followers/block_user"
exports.followeringCreate = "bx_block_followers/follows"
exports.itineraryListUrl="bx_block_itinerary/itinerary/itineraries_for_account?account_id=";
exports.userProfileUrl= "bx_block_profile/profiles/";
exports.userPostUrl="bx_block_posts/posts/users_posts/";
exports.userPlaceVisitedUrl="bx_block_place_visited/place_visiteds/users_place_visited/"
exports.userPlaceVisitedPostUrl="bx_block_place_visited/place_visiteds/"


exports.createFollowUrl = "bx_block_followers/follows";
exports.removeFollowingUrl = "bx_block_followers/remove_following";
exports.GET="GET";
exports.POST="POST";
exports.DELETE="DELETE";
exports.FollowRemoveAPIEndPoint = "bx_block_followers/remove_following";
exports.followersBlockFollower ="bx_block_followers/block_follower";
exports.followersRemoveFollower ="bx_block_followers/remove_follower";
exports.followerSearchFollowers = "bx_block_followers/follower_search";
exports.FollowingRemoveFollowing = "bx_block_followers/remove_following";
exports.categoryApiContentType = "application/json";
exports.followingBlcok ="bx_block_followers/block_user";
exports.reportEndponit = "bx_block_report/reports"
exports.usernameValidationMessage = "User name is required";
exports.uniqueUsernameExist = "account_block/accounts/unique_username_check";
exports.isUserNameValid = "Username valid";
exports.followingSeachKey = "bx_block_followers/following_search";
exports.following_search = "/bx_block_followers/following_search?search_by_following"
exports.PostErrortitle = "Post not created yet";
exports.placeErrortitle = "Place not visited yet";
// Customizable Area End

