// Customizable Area Start

export const PostCreateStyles = {
  PostCreate: {
    "& .right-side-main-container": {
      borderRadius: "20px",
      border: "0.5px solid rgba(105,134,216,0.1)",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
      padding: 40,
      marginBottom: 20,
    },
    "& .right-side-header": {
      fontFamily: "Helvetica-Bold",
      fontSize: 20,
      fontWeight: "400",
      lineHeight: "24px",
      color: "#000000",
      marginBottom: "40px",
    },
    "& .input-field input": {
      backgroundBlendMode: "normal",
      padding: "25.32px 14px",
    },
    "& .input-field input::placeholder": {
      color: "#d5d5d5",
      opacity: 1,
    },
    "& .input-field input:-ms-input-placeholder": {
      color: "#d5d5d5",
    },
    "& .input-field input::-ms-input-placeholder": {
      color: "#d5d5d5",
    },
    "& .input-field .MuiInputBase-formControl": {
      color: "#353249",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
    },
    "&  .input-field .MuiOutlinedInput-notchedOutline": {
      border: "0.5px solid rgba(105,134,216,0.1)",
      borderRadius: "16px",
    },
    "& .text-field-outside-box": {
      border: "0.5px solid rgba(105,134,216,0.1)",
      borderRadius: "16px",
      padding: "15.5px 12px",
      cursor: "pointer",
    },
    "& .text-area-field": {
      borderRadius: "16px",
      border: "0.5px solid rgba(105,134,216,0.1)",
      outline: "none",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      color: "#353249",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
      width: "100%",
      padding: "25.32px 14px",
      resize: "none",
    },
    "& .text-area-field:focus": {
      outline: "none",
    },
    "& .text-area-field::placeholder": {
      color: "#d5d5d5",
    },
    "& .published-button": {
      padding: "15px",
      borderRadius: "4px",
      background: "#25aae1",
      backgroundBlendMode: "normal",
      "& .MuiButton-label": {
        color: "#ffffff",
        fontFamily: "Helvetica",
        fontSize: "24px",
        fontWeight: "400",
        textTransform: "capitalize",
      },
    },
    "& .place-holder": {
      color: "#d5d5d5",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
      margin: "9px 0",
    },
    "& .not-found-text": {
      width: "100%",
      color: "#000000",
      textAlign: "center",
      fontSize: "16px",
      fontFamily: "Helvetica",
      lineHeight: "20px",
    },
    "& .uploaded-image": {
      width: 148,
      height: 132,
      borderRadius: 8,
      position: "relative",
      display: "inline-block",
      flex: "0 0 148px",
      boxSizing: "border-box",
      "& .MuiSvgIcon-root": {
        height: "24px",
        width: "22px",
        position: "absolute",
        right: "-8px",
        top: "-10px",
        cursor: "pointer",
        zIndex: "1",
      },
      "& img": {
        objectFit: "cover",
        height: "100%",
        width: "100%",
        borderRadius: "8px",
        border: "1px solid rgba(151,151,151,0.21)",
        boxSizing: "border-box",
      },
      "& video": {
        objectFit: "cover",
        boxSizing: "border-box",
        height: "100%",
        border: "1px solid rgba(151,151,151,0.21)",
        width: "100%",
        borderRadius: "8px",
      },
    },
    "& .upload-image-container": {
      border: "1px solid rgba(151,151,151,0.21)",
      width: 148,
      height: 132,
      borderRadius: 8,
      background: "#ffffff",
      backgroundBlendMode: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      cursor: "pointer",
    },
    "& .add-image": {
      height: 28,
      width: 28,
    },
    "& .selected-chip": {
      borderRadius: "20px",
      border: "1px solid rgba(37,170,225,0.24)",
      background: "#25aae1",
      backgroundBlendMode: "normal",
      height: "39px",
      cursor: "pointer",
      "& .MuiChip-label": {
        color: "#ffffff",
        fontFamily: "Helvetica",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "19px",
        padding: "0 16px",
      },
    },
    "& .MuiChip-deleteIcon": {
      color: "#fff",
      width: "19px",
      height: "19px",
      margin: "0 5px 0 -14px",
    },
    "& .chip-item-container": {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
    },
    "& .un-selected-chip": {
      borderRadius: "20px",
      border: "1px solid rgba(37,170,225,0.24)",
      cursor: "pointer",
      background: "#fafbff",
      backgroundBlendMode: "normal",
      height: "39px",
      "& .MuiChip-label": {
        color: "#000000",
        fontFamily: "Helvetica",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "19px",
        padding: "0 16px",
      },
    },
    "& .add-Tags": {
      "& .back-button": {
        height: "58px",
        width: "100px",
        boxShadow: "none",
        borderRadius: "8px",
        backgroundColor: "#a9d9ed",
        "& .MuiButton-label": {
          color: "#25aae1",
          fontSize: "14px",
          textTransform: "capitalize",
          fontWeight: "400",
          "& .back-button-image": {
            height: "15px",
            fill: "#25aae1",
          },
        },
      },
      "& .main-heading": {
        marginTop: "30px",
        marginBottom: "20px",
        color: "#0a083a",
        fontFamily: "Helvetica",
        fontSize: "24px",
        fontWeight: "400",
        lineHeight: "29px",
      },
      "& .tag-heading": {
        color: "#0a083a",
        fontFamily: "Helvetica",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "24px",
        marginBottom: "20px",
        marginTop: "20px",
      },
      "& .tag-items": {
        maxHeight: "565px",
        height: "100%",
        overflowY: "auto",
        marginBottom: "20px",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(23,89,117,0.16)",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "rgb(0 4 5 / 16%)",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: " #f1f1f1",
        },
      },
    },
  },
};
// Customizable Area End
