export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");

export const infoIcon = require('../assets/infoIcon.png')
export const active_infoIcon = require('../assets/active_info.png');
export const contactUsIcon = require('../assets/contactUsIcon.png')
export const active_contactUsIcon = require('../assets/active_contactUsIcon.png');
export const deleteIcon = require('../assets/deleteIcon.png');
export const active_deleteIcon = require('../assets/active_deleteIcon.png');
export const logoutIconActive = require('../assets/logoutIconActive.png');
export const notificationIcon = require('../assets/notificationIcon.png');
export const active_notificationIcon = require('../assets/active_notifiationIcon.png');
export const passowordIcon = require('../assets/passwordIcon.png');
export const active_passowordIcon = require('../assets/active_passwordIcon.png');
export const policyIcon = require('../assets/policyIcon.png');
export const active_policyIcon = require('../assets/active_policyIcon.png');
export const termsConditionIcon = require('../assets/termsConditionIcon.png');
export const active_termsConditionIcon = require('../assets/active_termsConditionIcon.png');
export const bookmarkIcon = require('../assets/bookmark.png');
export const selectedBookmarkIcon = require('../assets/selected-bookmark.png');
export const dndActiveImg = require('../assets/dnd_active.svg');
export const dndDeactiveImg = require('../assets/dnd_deactive.svg');



