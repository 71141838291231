import React from "react";
import {
    Modal,
    Box,
    makeStyles,
    Typography,
    Button,
} from "@material-ui/core";
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
    modalBox: {
        backgroundColor: "white",
        border: "2px solid red",
    },
    yes_button: {
        background: "#25AAE1",
        color: "#FFFFFF",
        boxShadow: '0px 8px 24px 0px #25AAE14D',
        textTransform: "capitalize",
        borderRadius: "12px",
        fontSize: "20px",
        padding: '4px 30px',
        width: "100%",
        "&:hover": {
            background: "#25AAE1",
        },
    },
    contentHead: {
        fontSize: "25px",
        textAlign: "left",
        marginTop: "10px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

    },

}));

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 18,
    boxShadow: 24,
    padding: '20px 30px'

};
const ConfimeAction = ({ open, onHandleRemoveFollower, onHandleBlock, onHandleReport, followerclose ,title = "" }: { followerclose : any , open: boolean, onHandleReport: any, onHandleRemoveFollower: any, onHandleBlock: any, title: string }) => {
    const classes = useStyles();

    return (
        <Box>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <Typography className={classes.contentHead}>
                            {title}
                            <Button className="confirm_btn" onClick={followerclose} style={{
                                paddingRight: "0"
                            }}> <CloseIcon style={{color:"#25aae1"}} /></Button>

                        </Typography>
                    </Box>
                    <Box
                        style={{
                            //   width: "90%",
                            display: 'flex',
                            margin: "auto",
                            marginTop: "25px",
                            marginBottom: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: 'column'
                        }}
                    >
                        <Button className="confirm_btn" onClick={onHandleRemoveFollower} style={{
                            padding: '15px 15px', width: '100%', minHeight: '50px', margin: '0 0 20px', border: '1px solid #80808054',
                            fontSize: '16px',
                            textTransform: 'capitalize',
                            letterSpacing: '0', display: 'flex',
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            Remove follower
                            <ArrowForwardIosIcon className="back-button-image" style={{ marginLeft: '15px', color:"#25aae1" }} />
                        </Button>
                        <Button className="confirm_btn" onClick={onHandleBlock} style={{
                            padding: '15px 15px', width: '100%', minHeight: '50px', margin: '0 0 20px', border: '1px solid #80808054', fontSize: '16px',
                            textTransform: 'capitalize',
                            letterSpacing: '0', display: 'flex',
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            Block    <ArrowForwardIosIcon className="back-button-image" style={{ marginLeft: '15px', color:"#25aae1" }} />
                        </Button>
                        <Button className="confirm_btn" onClick={onHandleReport} style={{
                            padding: '15px 15px', width: '100%', minHeight: '50px', margin: '0 0 ', border: '1px solid #80808054', fontSize: '16px',
                            textTransform: 'capitalize',
                            letterSpacing: '0', display: 'flex',
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            Report    <ArrowForwardIosIcon className="back-button-image" style={{ marginLeft: '15px' , color:"#25aae1" }} />
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ConfimeAction;
