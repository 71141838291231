// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { apiCall } from "../../../components/src/utils.web";
import { geocodeByAddress } from "react-google-places-autocomplete";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  openTagPage: boolean;
  tags: {
    id: string;
    attributes: {
      name: string;
      activities: {
        data: { id: number; attributes: { name: string } }[];
      };
    };
  }[];
  selectedTags: {
    id: number;
    attributes: { name: string };
    parentId: string;
  }[];
  title: string;
  description: string;
  images: { url: any; fileName: string; type: string }[];
  location: string;
  isLoading: boolean;
  post_id: string;
  state: string;
  lat: number;
  log: number;
}

interface SS {
  id: any;
}

export default class PostCreateController extends BlockComponent<Props, S, SS> {
  upload: any;
  tagApiCallId: string = "";
  createPostApiCallId: string = "";
  singlePostApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      openTagPage: false,
      tags: [],
      selectedTags: [],
      title: "",
      description: "",
      images: [],
      location: "",
      isLoading: false,
      post_id: "",
      state: "",
      lat: NaN,
      log: NaN
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const post_id = this.props.navigation.getParam("id");
    this.setState({ post_id }, async () => {
      await this.getTags();
      if (post_id) {
        await this.getPostDetails();
      }
    });
  }

  openOrCloseTagPage = () => {
    this.setState({ openTagPage: !this.state.openTagPage });
  };

  validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("Title is required"),
    description: Yup.string()
      .trim()
      .required("Description is required"),
    location: Yup.string()
      .trim()
      .required("Location is required"),
    state: Yup.string()
      .trim()
      .required("State is required"),
    images: Yup.array()
      .min(1, "One image or video is required")
      .max(5, "Maximum 5  or video  upload"),
    tags: Yup.array().min(1, "One tag is required"),
  });

  validationTagsFormSchema = Yup.object().shape({
    tags: Yup.array().min(1, "One tag is required"),
  });

  tagsValuesSave = (
    tags: { id: number; attributes: { name: string }; parentId: string }[]
  ) => {
    this.setState({
      openTagPage: false,
      selectedTags: tags,
    });
  };

  getTags = async () => {
    this.setState({
      isLoading: true,
    });
    this.tagApiCallId = await apiCall({
      method: configJSON.getTagsApiMethod,
      endPoint: configJSON.getTagsApiEndPoint,
    });
  };

  dataURLtoFile = (dataUrl: any, fileName: string) => {
    let arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };

  readerOnLoaded = (
    values: any,
    file: any,
    reader: any,
    setFieldValue: any
  ) => {
    values.images = [
      ...values.images,
      {
        url: reader.result,
        fileName: file.name,
        type: file.type,
      },
    ];
    setFieldValue("images", values.images);
    this.setState({ images: values.images });
  };

  handleSubmitData = async (data: {
    title: string;
    description: string;
    location: string;
    images: any[];
    tags: {
      id: number;
      attributes: { name: string };
      parentId: string;
    }[];
  }) => {
    const tag = data.tags.reduce((acc: any, { id, parentId }) => {
      if (!acc[parentId]) {
        acc[parentId] = [];
      }
      acc[parentId].push(String(id));
      return acc;
    }, {});
    const formData = new FormData();
    formData.append("[data][attributes][name]", data.title);
    formData.append("[data][attributes][description]", data.description);
    formData.append("[data][attributes][location]", data.location);
    formData.append("[data][attributes][activities_data]", JSON.stringify(tag));
    data.images.forEach((image: any) => {
      formData.append(
        "[data][attributes][images][]",
        this.dataURLtoFile(image.url, image.fileName)
      );
    });
    formData.append("[data][attributes][state]", this.state.state);
    formData.append("[data][attributes][latitude]", this.state.lat.toString());
    formData.append("[data][attributes][longitude]", this.state.log.toString());
    this.setState({
      isLoading: true,
    });
    
    this.createPostApiCallId = await (this.state.post_id
      ? apiCall({
          method: configJSON.editPostApiMethod,
          endPoint: `${configJSON.editPostApiEndPoint}/${this.state.post_id}`,
          body: formData,
        })
      : apiCall({
          method: configJSON.createPostApiMethod,
          endPoint: configJSON.createPostApiEndPoint,
          body: formData,
        }));
  };

  handleError = (errors: any) => {
    if (Array.isArray(errors)) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}, Please login again.`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
      } else if (errors[0].Post) {
        toast.error(`${errors[0].Post}`);
        return false;
      }
    }
    toast.error("Something went to wrong please retry");
  };

  promiseManage = (url: any) => {
    return new Promise((resolve) => {
      let blobString = this.toDataURL(url);
      resolve(blobString);
    });
  };

  getStateName = (data: any) => {
    const stateName = data[0].address_components.find((component: any) =>
      component.types.includes("administrative_area_level_1")
    );
    return stateName;
  };

  handleResponse = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.tagApiCallId) {
      if (responseJson.data) {
        this.setState({
          tags: responseJson.data,
        });
      } else {
        this.setState({
          tags: [],
        });
      }
    } else if (apiRequestCallId === this.createPostApiCallId) {
      if (responseJson.data) {
        toast.success(
          this.state.post_id
            ? "Post is updated successfully"
            : "Post is created successfully."
        );
        this.props.navigation.navigate("FanWall");
      } else {
        toast.error("Something went to wrong please try again.");
      }
    } else if (apiRequestCallId === this.singlePostApiCallId) {
      if (responseJson.data) {
        const selectedTags = responseJson.data.attributes.interests.data.flatMap(
          (item: any) =>
            item.attributes.activities.data.map((activity: any) => ({
              parentId: item.id,
              id: activity.id,
              attributes: {
                name: activity.attributes.name,
              },
            }))
        );
        const imageUrlPromise: any[] = responseJson.data.attributes.images_and_videos.map(
          async (image: any) => {
            const url = await this.promiseManage(image.url);
            return {
              url,
              fileName: image.filename,
              type: image.type,
            };
          }
        );
        let photos = await (Promise as any).allSettled(imageUrlPromise);

        this.setState({
          title: responseJson.data.attributes.name,
          description: responseJson.data.attributes.description,
          location: responseJson.data.attributes.location,
          state: responseJson.data.attributes.state,
          images: photos.map((image: any) => ({
            url: image.value.url,
            fileName: image.value.fileName,
            type: image.value.type,
          })),
          selectedTags,
        });
      }
    }
  };

  getPostDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.singlePostApiCallId = await apiCall({
      endPoint: `${configJSON.getOnePostApiEndPoint}/${this.state.post_id}`,
      method: configJSON.getOnePostApiMethod,
    });
  };

  toDataURL = async (url: any) =>
    await fetch(url)
      .then((response: any) => response.blob())
      .then(
        (blob: any) =>
          new Promise((resolve: any, reject: any) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.errors) {
        this.handleError(responseJson.errors);
      }
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        this.handleResponse(apiRequestCallId, responseJson);
      }

      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
