import React from "react";
import {
    Modal,
    Box,
    makeStyles,
    Typography,
    Button,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
const configJSON = require('./config.js')
const useStyles = makeStyles((theme) => ({
    modalBox: {
        backgroundColor: "white",
        border: "2px solid red",
    },
    yes_button: {
        background: "#25AAE1",
        color: "#FFFFFF",
        boxShadow: '0px 8px 24px 0px #25AAE14D',
        textTransform: "capitalize",
        borderRadius: "12px",
        fontSize: "20px",
        padding: '4px 30px',
        "&:hover": {
            background: "#25AAE1",
        },
    },
    no_button: {
        background: "#FFFFFF",
        color: "#25AAE1",
        marginRight: "30px",
        textTransform: "capitalize",
        borderRadius: "12px",
        border:'1px solid #25AAE1',
        fontSize: "20px",
        padding: '4px 30px',
        "&:hover": {
            background: "#FFFFFF",
        },
    },
    contentHead: {
        fontSize: "32px",
        textAlign: "left",
        marginTop: "20px",
    },
    content: {
        fontSize: "18px",
        padding: "20px 0",
        textAlign: "left",
        // paddingLeft: "-10px",
        color: "#818181"
    },
    contentSpan: {
        color: "#BE9E44",
    },
    container: {
        "@media (max-width:450px)": {
            width: "100%",
            boxSizing: "border-box"
        },
    }
}));

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    bgcolor: "background.paper",
    borderRadius: 12,
    boxShadow: 24,
    padding: '10px 24px'
    
};
const ConfirmationModal = ({ open, onHandleCancel=()=>{}, handleCloseModal, onHandleConfirmation ,title="",description="",isSignUp=false}: any) => {
    const classes = useStyles();

    return (
        <Box>
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes?.container} sx={style}>
                    <Box>
                        <Typography className={classes?.contentHead}>
                           {title}
                        </Typography>
                    </Box>
                    <CloseIcon 
                    onClick={handleCloseModal}
                    style={{
                        color: '#25AAE1' ,
                        position: 'absolute',
                        top: '15px',
                        right: '15px',
                        cursor:'pointer'

                    }} />
                    <Box
                        style={{
                            display: "flex",
                            width: "100%",
                            margin: "auto",
                            marginTop: "10px",
                        }}
                    >
                        <Typography className={classes?.content}>
                            {description}
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            //   width: "90%",
                            display: 'flex',
                            margin: "auto",
                            marginTop: "25px",
                            marginBottom: "20px",
                        }}
                    >
                        <Button className={classes?.no_button} onClick={isSignUp?onHandleCancel:handleCloseModal}>
                            {isSignUp ? configJSON.signupname : configJSON.Noname}
                        </Button>
                        <Button className={classes?.yes_button} onClick={onHandleConfirmation}>
                        {isSignUp ? configJSON.loginname : configJSON.Yesname}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ConfirmationModal;
