import React from "react";

import {
  Box,
  Typography,

  // Customizable Area Start
  Button,
  Input,
  InputAdornment,
  IconButton,
  FormControl,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Formik } from "formik";
import * as Yup from "yup";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { configJSON } from "../Settings5Controller";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "length")
    .required("required")
    .matches(configJSON.valii, "letters"),
  confirmPassword: Yup.string()
    .min(8, "Confirm password should be of minimum 8 characters length")
    .required("Password is required"),
});
// Customizable Area End

import SettingsController, { Props } from "../SettingsController.web";
import AlertModal from "../../../../components/src/AlertModal.web";

export default class ChangePassword extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainWrapper}>
        <Typography style={webStyle.heading} variant="h6">
          Change Password
        </Typography>
        <Box sx={{ width: "100%", paddingTop: "30px", height: "100%" }}>
          <Formik
            data-test-id="loginFormik"
            initialValues={{ confirmPassword: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.updatePassword(values);
              actions?.resetForm();
            }}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              setFieldTouched,
              touched,
              values,
            }: any) => (
              <form data-test-id="loginForm" onSubmit={handleSubmit}>
                <NewPasswordForm
                  values={values}
                  error={errors}
                  touched={touched}
                  handleChange={handleChange}
                  showpassword={this.handleClickShowPassword}
                  checkPassword={this.state.checkPassword}
                  showconfirmPassword={this.handleClickShowConfirmPassword}
                  checkconfirmPassword={this.state.checkConfrimPassword}
                  passwordNotmatch={this.state.passwordNotmatch}
                />
              </form>
            )}
          </Formik>
        </Box>
        <AlertModal
          open={this.state.passwordChangeAlert?.visible}
          onHandleOk={this.handlePasswordAlert}
          title={this.state.passwordChangeAlert?.message}
        />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    height: "90%",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "20px",
  },
  heading: {
    fontSize: "22px",
    color: "#000000",
    fontFamility: "HelveticaNeue",
    margin: "0 0 30px 0",
  },
  notificaton_item: {
    fontSize: "22px",
    color: "#818181",
    fontFamility: "HelveticaNeue",
    lineHeight: "26px",
  },
  notif_row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0 10px 0",
  },
  inputControlStyle: {
    marginBottom: "30px",
    boxShadow: "0px 10px 16px 0px #A5B0C2",
    height: "50px",
    justifyContent: "center",
    padding: "2px 20px",
    width: "auto",
    position: "relative",
  },
};

export const NewPasswordForm = ({
  values,
  error,
  handleChange,
  showpassword,
  checkPassword,
  showconfirmPassword,
  checkconfirmPassword,
}: any) => {
  return (
    <Box
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ width: "100%", flexDirection: "column", display: "flex" }}>
        <FormControl
          variant="standard"
          style={{
            marginBottom: "30px",
            boxShadow: "0px 1px 4px 0px #A5B0C2",
            height: "50px",
            justifyContent: "center",
            padding: "2px 20px",
            width: "auto",
            position: "relative",
            borderRadius: "12px",
          }}
        >
          {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
          <Input
            disableUnderline={true}
            data-test-id="password"
            id="password"
            name="password"
            placeholder="password"
            // label="password"
            value={values?.password}
            onChange={handleChange}
            type={checkPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={showpassword}
                  edge="end"
                >
                  {checkPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {(error?.password === "required" || values?.password) && (
          <Box>
            <Box style={{ marginBottom: "10px" }}>
              {(error?.password === "length" ||
                error?.password === "letters") && (
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box
                    style={{
                      borderTop: "2px solid rgb(33, 150, 243)",
                      width: "50%",
                    }}
                  />
                  <Box style={{ borderTop: "2px solid #ccc", width: "50%" }} />
                </Box>
              )}
              {error?.password === undefined && (
                <Box
                  style={{
                    borderTop: "2px solid rgb(33, 150, 243)",
                    width: "100%",
                  }}
                />
              )}
              {error?.password === "required" && (
                <Box style={{ borderTop: "2px solid #ccc", width: "100%" }} />
              )}
            </Box>
            <Box style={{ marginBottom: "15px" }}>
              {error?.password === "length" ||
              error?.password === "required" ? (
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#ccc",
                  }}
                >
                  <CloseIcon style={{ marginRight: "10px" }} /> Minimum 8
                  characters
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#000",
                  }}
                >
                  <DoneIcon
                    style={{ marginRight: "10px", color: "rgb(33, 150, 243)" }}
                  />{" "}
                  Minimum 8 characters
                </Typography>
              )}
              {error?.password === "letters" ||
              error?.password === "required" ||
              error?.password === "length" ? (
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#ccc",
                  }}
                >
                  <CloseIcon style={{ marginRight: "10px" }} /> At least one
                  capital latter
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#000",
                  }}
                >
                  <DoneIcon
                    style={{ marginRight: "10px", color: "rgb(33, 150, 243)" }}
                  />{" "}
                  At least one capital latter
                </Typography>
              )}
            </Box>
          </Box>
        )}
        <FormControl
          variant="standard"
          fullWidth
          style={{
            marginBottom: "30px",
            boxShadow: "0px 1px 4px 0px #A5B0C2",
            height: "50px",
            justifyContent: "center",
            padding: "2px 20px",
            width: "auto",
            position: "relative",
            borderRadius: "12px",
          }}
        >
          {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
          <Input
            data-test-id="confirm_password"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm Password"
            disableUnderline={true}
            // label="confirmPassword"
            value={values?.confirmPassword}
            onChange={handleChange}
            type={checkconfirmPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={showconfirmPassword}
                  edge="end"
                >
                  {checkconfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {values?.password !== values?.confirmPassword && (
          <Box style={{ color: "red" }}> password don't matches</Box>
        )}
      </Box>
      <Button
        test-handleSubmit="test-handleSubmit"
        style={{
          background: "rgb(33, 150, 243)",
          marginBottom: "30px",
          padding: "18px",
        }}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        Submit
      </Button>
    </Box>
  );
};

// Customizable Area End
