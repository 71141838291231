import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  withStyles,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Menu,
  Avatar,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ChatController, { Props, S, configJSON } from "./ChatController.web";
import { NotificationStyle } from "./NotificationStyle.web";
import { searchIcon, dummyProfile } from "./assets";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
// Customizable Area End

export class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleSearch = (target: any) => {
    this.setState({ anchorEl: target });
  };
  handleDebouncedSearch = (e: any) => {
    let value = e.target.value;
    let target = e.currentTarget;

    this.setState({ searchQuery: value });
    debounce(this.handleSearch.bind(this, target), 2000)();
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  formatTime = (date: any) => {
    return date.format("h:mm A");
  };

  formatDate = (date: any) => {
    let today = moment();
    let yesterday = moment().subtract(1, "day");
    if (date.isSame(today, "day")) {
      return this.formatTime(date);
    } else if (date.isSame(yesterday, "day")) {
      return "Yesterday";
    } else {
      return date.format("MMM D");
    }
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const isSearchQuery = this.state.searchQuery !== "";
    const openSearchQuery = open && isSearchQuery;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box display="flex" flexDirection="column" style={{ height: "100%", flex: "1 1 auto", overflowY: "auto" }}>
        <Grid lg={12} sm={12} className="notification-heading">
          <Typography className="chat-title-tex">Chats</Typography>
        </Grid>
        <Box
          id="menu-button"
          aria-controls={openSearchQuery ? "scrallableDiv" : undefined}
          aria-haspopup={isSearchQuery && "true"}
          aria-expanded={openSearchQuery ? "true" : undefined}
          className="search-container"
        >
          <TextField
            name="search-user"
            className="searchField"
            placeholder={"Search"}
            id="searchContainer"
            data-test-id="search-forum"
            onChange={this.handleDebouncedSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} alt="searchIcon" />
                </InputAdornment>
              ),
            }}
          />
          {this.state.searchQuery !== "" && (
            <Menu
              id="user-list"
              aria-labelledby="menu-button"
              anchorEl={anchorEl}
              open={open}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{
                style: {
                  marginTop: "50px",
                  minWidth: "18%",
                  height: "200px",
                  overflowY: "auto",
                },
                id: "userlist"
              }}
            >
              <InfiniteScroll

                scrollableTarget="userlist"
                next={this.fetchMoreUser}
                data-test-id="scroll-bar"
                hasMore={this.state.hasUserMoreData}
                dataLength={this.state.userList?.data.length}
                loader={
                  <h4 style={{ marginLeft: "20px" }}>
                    {configJSON.LoadingTitle}
                  </h4>
                }
                height="180px"

              >
                {this.state.userList?.data?.map((user: any) => (
                  <MenuItem
                    className="user-list"
                    data-test-id="user-list"
                    onClick={() => this.handleUserChat(user)}
                  >
                    <Avatar
                      src={user?.attributes?.photo}
                      alt={"avtar"}
                      style={{ marginRight: "5px" }}
                    />
                    
                    
                    {user?.attributes?.full_name}
                  
                  </MenuItem>
                ))}
               
                {!this.state.userList?.data?.length && (<div className="error-title">{configJSON.dataNotFoundTitle}</div>)}
              
              </InfiniteScroll>
            </Menu>
          )}
        </Box>
        <Box className="message-view" id="chathistory" style={{ height: "70%" }}>
          <InfiniteScroll


            dataLength={this.state.chatHistorys.length}
            next={this.fetchMoreData}
            data-test-id="scroll-bar"
            hasMore={this.state.hasMoreData}
            scrollableTarget="chathistory"
            loader={
              <h4 style={{ marginLeft: "20px" }}>
                {configJSON.LoadingTitle}

              </h4>

            }

          >
            {this.state.chatHistorys?.map(

              ({
                unread_messages, last_message, last_message_datetime, chat_id, users, blocked_by_me, blocked_by_user
              }: any) => {
                const user = users.length > 1 ? users.filter((user: { id: number }) => user.id != this.state.userDetail.id)[0] : users[0];
                return (
                  
                  <Box
                    
                  
                
                  onClick={(user?.blocked_by_me || user?.blocked_by_user) ? this.checkBlockedUser.bind(this, user?.blocked_by_user, user?.blocked_by_me) : this.props.gotToChatDetails.bind(this, chat_id, { ...user, unread_messages })}
                   
                  className="notification-list"
                  >
                    <Box className="avatar-row avatar-row-padding-0">
                      <img
                        className="avatar-img"
                        src={user.profile_photo ?? dummyProfile}
                      />

                      
                      
                      <span className={`chat-online ${user.offline ? "chat-offline" : ""}`} />

                    </Box>
                    <Box className="content-row">
                      <Box className="avatar-title-row">
                        <p className="user-name" title={user.name}>
                          {user.name}
                        </p>
                        <p className="time">
                          {this.formatDate(moment(last_message_datetime))}
                        </p>
                      </Box>
                      <Box className="avatar-title-row">
                        <p className="last-message" title={last_message}>
                          {last_message}
                        </p>


                        {unread_messages != "0" && <div className="total-read-remaining-text">{unread_messages}</div>}
                      </Box>
                    </Box>
                  </Box>
                )
              }
            )}
          </InfiniteScroll>
        </Box>
      </Box>

      // Customizable Area End
    );
  }
}
//@ts-ignore
export default withStyles(NotificationStyle)(Chat);
// Customizable Area Start
export const Badge = () => (
  <span
    style={{
      width: "6px",
      height: "6px",
      background: "#FF4E4E",
      position: "absolute",
      top: 0,
      right: 0,
      borderRadius: "50%"
    }}
  />
);
// Customizable Area End
