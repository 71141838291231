// Customizable Area Start
import { Box, styled } from "@material-ui/core";

export const ChatSendMessageContainer = styled(Box)({
  flex: "0 0 auto",
  "& .message-input": {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      border: "0.5px solid rgba(105, 134, 216, 0.10)",
      background: "#FFF",
      boxShadow: "0px 10px 16px 0px rgba(165, 176, 194, 0.15)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0.5px solid rgba(105, 134, 216, 0.10)",
    },
    "& input::placeholder": {
      color: "#8F92A1",
      opacity: 1,
    },
    "& input:-ms-input-placeholder": {
      color: "#8F92A1",
    },
    "& input::-ms-input-placeholder": {
      color: "#8F92A1",
    },
    "& .MuiInputBase-input": {
      color: "#000",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
    },
  },
  "& .message-image": {
    width: "80px",
    height: "80px",
    borderRadius: "8px",
    objectFit: "cover",
  },
  "& .message-image-container": {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
  },
  "& .cancel-icon-container": {
    position: "relative",
    "& .cancel-icon": {
      position: "absolute",
      right: "-10px",
      top: "-10px",
      cursor: "pointer",
      zIndex: 1,
    },
  },
  "& .emoji-icon": {
    width: "18px",
    height: "18px",
  },
  "& .add-icon": {
    width: "18px",
    height: "18px",
  },
  "& aside.EmojiPickerReact.epr-main": {
    position: "absolute",
    bottom: "30px",
    right: "0px",
    backgroundColor: "white",
    zIndex: 1,
  },
});
// Customizable Area End
