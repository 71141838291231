// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Cancel";

interface Props {
  chatData: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        message: string;
        account_id: number;
        chat_id: number;
        created_at: string;
        updated_at: string;
        is_mark_read: false;
        message_type: string;
        attachments: {
          id: number;
          url: string;
          attachment_type: string;
        }[];
        account: {
          id: number;
          name: string;
          email: string;
          profile_photo: string;
        };
      };
    };
  };
  openDeleteModal: (id: string) => void;
  openEditModal: (id: string, message: string) => void;
  openDeleteAttachmentModal:(message_id:string,attachment_id:number)=>void;
}
interface S {
  timeAgo: string;
  formattedDateText: string;
  isMenuOpen: boolean;
  loggedInUserDetails: {
    id: string;
  };
  message: null | string[];
}

const urlRegex = /(https?:\/\/[^\s]+)/g || /(http?:\/\/[^\s]+)/g;

class ChatMessage extends Component<Props, S> {
  messageRef: any = null;
  interval: any;
  constructor(props: Props) {
    super(props);
    this.messageRef = React.createRef();
    this.state = {
      timeAgo: "",
      formattedDateText: "",
      isMenuOpen: false,
      loggedInUserDetails: { id: "" },
      message: null,
    };
  }

  componentDidMount() {
    let userData = window.localStorage.getItem("user_details");
    if (userData) {
      this.setState({
        loggedInUserDetails: JSON.parse(userData),
      });
    }
    this.getTimeDifference();
    this.interval = setInterval(this.getTimeDifference, 60000);
  }

  convertUrlsToLinks(message: string) {
    const linkRegex = /(https?:\/\/[^\s]+)/g;

    return message.replace(linkRegex, '<a href="$1" target="_blank">$1</a>');
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getTimeDifference = () => {
    const text = moment(
      this.props.chatData.data.attributes.created_at
    ).fromNow();
    this.setState({ timeAgo: text });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: any
  ): void {
    if (
      prevProps.chatData.data.attributes.created_at !==
      this.props.chatData.data.attributes.created_at
    ) {
      this.getTimeDifference();
    }
  }

  render() {
    const { chatData } = this.props;
    return (
      <>
        <div className="chat-message-container">
          <Box className="user-image-container">
            {Number(this.state.loggedInUserDetails.id) ==
              chatData.data.attributes.account_id && (
                <Box className="action-container-1">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gridColumnGap="8px"
                    className="action-container-2"
                  >
                    {chatData.data.attributes.message_type == "text" && (
                      <EditIcon
                        className="edit-icon"
                        onClick={() =>
                          this.props.openEditModal(
                            chatData.data.id,
                            chatData.data.attributes.message
                          )
                        }
                      />
                    )}
                    <DeleteIcon
                      className="delete-icon"
                      onClick={() => this.props.openDeleteModal(chatData.data.id)}
                    />
                  </Box>
                </Box>
              )}
            <img
              className="user-image"
              alt="user"
              src={chatData.data.attributes.account.profile_photo}
            />
            <Box className="date-time-container">
              <Typography
                className="user-name"
                title={chatData.data.attributes.account.name}
              >
                {chatData.data.attributes.account.name}
              </Typography>
              <Typography className="recent-time" title={this.state.timeAgo}>
                {this.state.timeAgo}
              </Typography>
            </Box>
          </Box>
          {chatData.data.attributes.message_type != "image" && (
            <Typography className="chat-message">
              {chatData.data.attributes.message.split(urlRegex)
                ? chatData.data.attributes.message
                  .split(urlRegex)
                  .map((url, index) => {
                    if (url.match(urlRegex)) {
                      return (
                        <a
                          key={index}
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {url}
                        </a>
                      );
                    } else {
                      return <span key={index}>{url}</span>;
                    }
                  })
                : chatData.data.attributes.message}
            </Typography>
          )}
          {/* {chatData.data.attributes.created_at !==
            chatData.data.attributes.updated_at && (
            <Typography className="edited-text">(edited)</Typography>
          )} */}
          {chatData.data.attributes.attachments &&
            chatData.data.attributes.attachments.length > 0 && (
              <Box className="message-image-container" paddingLeft="53px">
                {chatData.data.attributes.attachments.map((data, index) =>
                  data.attachment_type.startsWith("image") ? (
                    <Box className="message-image-container1">
                      <img className="message-image" src={data.url} key={index} />
                      <CloseIcon
                        className="delete-attachment-icon"
                        onClick={() => this.props.openDeleteAttachmentModal(chatData.data.id,data.id)}
                      />

                    </Box>
                  ) : (
                    <Box className="message-image-container1">
                      <video
                        className="message-image"
                        src={data.url}
                        controls
                        key={index}
                      />

                      <CloseIcon
                        className="delete-attachment-icon"
                        onClick={() => this.props.openDeleteAttachmentModal(chatData.data.id,data.id)}
                      />

                    </Box>
                  )
                )}
              </Box>
            )}
        </div>
      </>
    );
  }
}

export default ChatMessage;
// Customizable Area End
