import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type AllType = string | number | boolean;
// Customizable Area End

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  AllPosts: { id: number; created_at: string; location: string; description: string; images_and_videos: { type: string, url: string }[]; likes: number | string; comments: number | string; uploader_name: string; liked_by_user: string; uploader_image: string; account_id: number; admin_user_id: number }[];
  filterActivities: { id: string; name: string; data: { id: string; name: string }[] }[];
  commentId: number;
  comments: { id: number; content: string; created_at: string; account_full_name: string; account_image: string; commentable_id: number; comment_by_id: number; }[];
  tabValue: number;
  txtInputValue: string;
  filterActivitiesId: {
    [keys: string]: string[];
  };
  peoples: {
    [keys: string]: AllType;
    photo: string;
    is_following: boolean
  }[];
  userPost: {
    [keys: number]: string | number;
    attributes: {
      share_count:number,
    }
  }[];
  hasMoreData: boolean;
  userDetail: any;
  locationValue: string;
  currentPage: number;
  hasMoreUserData: boolean;
  currentUserPage: number;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId = "";
  likePostId: string = "";
  handleDeleteCommentId: string = "";
  handlePostCommentId: string = "";
  AllPostId: string = "";
  searchUserApiId: string = "";
  getAllUserId: string = "";
  searchApiId: string = "";
  AllUserPageId: string = "";
  PostCommentId: string = "";
  activitiesId: string = "";
  followId: string = "";
  AllPostPageId: string = "";
  handleDeletePostId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tabValue: 0,
      txtInputValue: "",
      AllPosts: [],
      userPost: [],
      userDetail: {},
      token: localStorage.getItem("authToken") || "",
      peoples: [],
      commentId: NaN,
      hasMoreUserData: false,
      comments: [],
      locationValue: "",
      filterActivities: [],
      isLoading: false,
      hasMoreData: false,
      filterActivitiesId: {},
      currentUserPage: 1,
      currentPage: 1,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.getAllPost();
    const user = localStorage.getItem("user_details")
    if (user) {
      this.setState({
        userDetail: JSON.parse(user)
      })
    }
    this.handlePostFilter();
    this.getAllUsers();
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const hasData = responseJson?.data?.length < 10 ? false : true;
      switch (apiRequestCallId) {
        case this.AllPostId:
        case this.searchApiId:
          this.setState({
            userPost: responseJson?.data || [],
            isLoading: false,
            hasMoreData: hasData,
            currentPage: 1
          });
          break;
        case this.AllUserPageId:
          this.handleUserData(responseJson)

          break;
        case this.handleDeletePostId:
          this.getAllPost();
          break;
        case this.handlePostCommentId:
        case this.handleDeleteCommentId:
          this.getPostComment(this.state.commentId);
          this.getAllPost();
          break;
        case this.likePostId:
          this.getAllPost();
          break;
        case this.PostCommentId:
          if (responseJson.data.length) {
            this.setState({
              comments: responseJson.data.map((i: { attributes: {} }) => i.attributes)
            });
          }
          break;
        case this.AllPostPageId:
          if (responseJson.data?.length) {
            this.setState({
              userPost: [...this.state.userPost, ...responseJson.data],
              hasMoreData: hasData,
              isLoading: false
            });
          } else {
            this.setState({
              hasMoreData: false,
              isLoading: false
            });
          }
          break;
        case this.searchUserApiId:
        case this.getAllUserId:
          this.handleMoreUserData(responseJson)
          break;
        case this.activitiesId:
          const data = responseJson.data.map((item: any) => {
            return {
              id: item.id,
              name: item?.attributes.name,
              data: item?.attributes.activities.data.map((data: any) => {
                return {
                  id: data.id,
                  name: data.attributes.name
                }
              })
            }
          });
          this.setState({
            filterActivities: data
          });
          break;
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      txtInputValue: "",
      tabValue: newValue,
    })
  }

  handleUserData = (responseJson: { data: [] }) => {
    if (responseJson.data?.length) {
      const peoples = responseJson.data.map((item: {
        id: string; attributes: {
          [keys: string]: AllType
          photo: string;
          is_following: boolean
        }
      }) => {
        return {
          id: item.id,
          ...item.attributes
        }
      });
      this.setState({
        peoples: [...this.state.peoples, ...peoples]
      });
    } else {
      this.setState({
        hasMoreUserData: false
      });
    }
  }

  handleMoreUserData = (responseJson: { data: [] }) => {
    if (responseJson.data?.length) {
      const hasUserData = responseJson.data.length < 25 ? false : true;
      const peoples = responseJson.data.map((item: {
        id: string; attributes: {
          [keys: string]: AllType;
          photo: string;
          is_following: boolean
        }
      }) => {
        return {
          id: item.id,
          ...item.attributes
        }
      });
      this.setState({
        peoples,
        hasMoreUserData: hasUserData,
        currentUserPage: 1,
        isLoading: false
      });
    } else {
      this.setState({
        peoples: [],
        hasMoreUserData: false,
        currentUserPage: 1,
        isLoading: false
      })
    }
  }

  handleFollower = (isClicked: boolean, id: AllType) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followId = requestMessage.messageId;
    if (isClicked) {
      const headers = {
        "token": this.state.token
      };
      const formData = new FormData()
      formData.append("follow[following_id]", id.toString())
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.followerApiEndPoint
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      )
    } else {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "DELETE"
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.unFollowerApiEndPoint
      )
      const body = JSON.stringify({
        id
      })
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )
    }
    runEngine.sendMessage(requestMessage.id, requestMessage)

  }

  handleLikePost = (attr: any, index: number) => {
    const data = this.state.userPost[index];
    data.attributes = attr
    this.state.userPost[index] = data;
    this.setState({ userPost: this.state.userPost })
  }
  handleDeletePost = (index: number) => {
    const data = this.state.userPost
    data.splice(index, 1);
    this.setState({ userPost: data })
  }

  getAllPost = () => {
    this.setState({ isLoading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    this.AllPostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allPost + `?page=1&per_page=10`
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  handlePostLike = (id: number) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likePostId = requestMessage.messageId;
    const body = JSON.stringify({
      data: {
        attributes: {
          likeable_id: id,
          likeable_type: configJSON.blockType
        }
      }
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likePost
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  };

  getPostComment = (id: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPostComments + id
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  onHandleConfirmation = (commentId: number, myCommentId: number) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = JSON.stringify({
      commentable_id: commentId,
      commentable_type: configJSON.blockType
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    )
    this.handleDeleteCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postComment + `/${myCommentId}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    this.setState({
      commentId
    })
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  onHandleConfirmationPostDelete = (postId: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.handleDeletePostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allPost + `/${postId}`
    )
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handlePostComment = (id: number, value: string, isEdit: { [keys: string]: string | number; }) => {
    if (value) {
      const body = JSON.stringify({
        commentable_id: id,
        commentable_type: "BxBlockPosts::Post",
        content: value
      })
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token
      };
      this.setState({
        commentId: id
      })
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.handlePostCommentId = requestMessage.messageId;
      const editId = isEdit?.id;
      if (editId) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.postComment + `/${editId}`
        )
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        )
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.postComment
        )
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        )
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      )
      runEngine.sendMessage(requestMessage.id, requestMessage)
    }
  }

  handlePostFilter = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activities
    )
    this.activitiesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleActivityFilter = (activityId: string, subActivityId: string, isSelected: boolean) => {

    if (isSelected) {
      const filterActivity = this.state.filterActivitiesId[activityId];
      const activityIndex = this.state.filterActivitiesId[activityId].indexOf(subActivityId);
      filterActivity.splice(activityIndex, 1);
      if (filterActivity.length) {
        this.setState({
          filterActivitiesId: {
            [activityId]: filterActivity,
            ...this.state.filterActivitiesId,
          }
        })
      } else {
        const filterActivitiesId = this.state.filterActivitiesId;
        delete filterActivitiesId[activityId];
        this.setState({
          filterActivitiesId
        })
      }

    } else {
      const activity = this.state.filterActivitiesId?.[activityId] || []
      this.setState({
        filterActivitiesId: {
          ...this.state.filterActivitiesId,
          [activityId]: [...activity, subActivityId],
        }
      })
    }
  }

  setSearchInputValue = (text: string) => {
    this.setState({
      txtInputValue: text
    });
  }

  handleApplyFilter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (!this.state.txtInputValue) {
        switch (this.state.tabValue) {
          case 0:
            this.getAllUsers();
            break;
          case 1:
            this.getAllPost();
            break;
          default:
            this.getAllUsers();
            break;
        }
      } else {
        this.setState({ isLoading: true });
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        )
        const headers = {
          "Content-Type": configJSON.validationApiContentType,
          "token": this.state.token
        };
        if (this.state.tabValue === 0) {
          this.searchUserApiId = requestMessage.messageId;
        } else {
          this.searchApiId = requestMessage.messageId;
        }
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          headers
        )
        const query = this.state.tabValue === 0 ? configJSON.applyUserFilter + this.state.txtInputValue + `&page=1&per_page=25` : configJSON.applyPostFilter + this.state.txtInputValue + `&page=1&per_page=10`
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          query
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
      }
    }
  }

  getAllUsers = () => {
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allUser + `?page=1&per_page=25`
    )
    this.getAllUserId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handlePost = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    this.setState({
      currentPage: this.state.currentPage + 1,
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    this.AllPostPageId = requestMessage.messageId;
    if (Object.keys(this.state.filterActivitiesId)?.length) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applyActivityFilter + JSON.stringify(this.state.filterActivitiesId) + `&page=${this.state.currentPage}&per_page=10`
      )
    } else if (this.state.txtInputValue) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applyPostFilter + this.state.txtInputValue + `&page=${this.state.currentPage}&per_page=10`
      )
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allPost + `?page=${this.state.currentPage}&per_page=10`
      )
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleUser = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({
      currentUserPage: this.state.currentUserPage + 1
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    this.AllUserPageId = requestMessage.messageId;
    if (this.state.txtInputValue) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applyUserFilter + this.state.txtInputValue + `&page=${this.state.currentUserPage}&per_page=25`
      )
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allUser + `?page=${this.state.currentUserPage}&per_page=25`
      )
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleLocationData = (event: React.ChangeEvent<{ value: string }>) => {
    this.setState({ locationValue: event.target.value })
  }

  applyActivityFilter = () => {
    this.setState({ isLoading: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.locationApiEndPoint + `activity=${JSON.stringify(this.state.filterActivitiesId)}&location=${this.state.locationValue}&page=1&per_page=10`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    this.AllPostId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  updateShareCount = (postId: number, share_count: number) => {
    const allUserPost = this.state.userPost;
    const postIndex = allUserPost.findIndex((data: any) => Number(data.id) === postId);
    if (postIndex !== -1) {
      const oneData = allUserPost[postIndex];
      oneData.attributes.share_count =
        oneData.attributes.share_count + share_count;
        allUserPost.splice(postIndex, 1, oneData);
      this.setState({
        userPost: allUserPost,
      });
    }
  };
  
  // Customizable Area End
}
