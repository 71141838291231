// Customizable Area Start
import React from "react";

import { Grid, Box, Typography, styled, OutlinedInput, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

import PlacesController, { Props, configJSON } from "./PlacesController.web";
import Loader from "../../../components/src/Loader.web";
import ImageLabel from "../../../components/src/ImageLabel.web";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmationModal from "../../../components/src/ConfirmationModal.web";

class Place extends PlacesController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount(): Promise<void> {
    const paramSearch = this.props?.history?.location?.state?.search;
    if (paramSearch) {
       this.setState({ LocationsSearch: paramSearch },()=>this.handleLocationsTravellers());
    } else {
      await this.getExploreList();
    }
  }

  render() {
    const data = this.state.total_page != 1 ? 20 * this.state.total_page + this.state.subcategorys : this.state.exploreList.length;
    return (
      <ExploreContainer>
        <Loader loading={this.state.isLoading} />
        <ConfirmationModal open={this.state.isLogin} handleCloseModal={this.handleLoginModal} onHandleCancel={()=>this.props.navigation.history.push('/OnboardingScreenBlock')} onHandleConfirmation={()=>this.props.navigation.history.push('/EmailAccountLoginBlock')} title={configJSON.logintitlealertmodal} description={configJSON.logindesalertmodal} isSignUp={true}/>
        <div className="ExploreContainers">
          <OutlinedInput className="search-box"
            id="outlined-adornment-amount"
            placeholder={configJSON.searchbtnExampleTitle}
            value={this.state.LocationsSearch}
            data-test-id='search-locations'
            startAdornment={<InputAdornment position="start"><SearchIcon color="disabled" /></InputAdornment>}
            onChange={this.handleLocations}
          />
          <div className="text-head">
            {this.state.LocationsSearch ?
            (<>
              <Typography className="main-heading">{configJSON.resTitle+ ` '${this.state.LocationsSearch}'`}</Typography>
              <Typography className="main-heading-2">{this.state.exploreList.length+configJSON.ofTiltle+ data +configJSON.ResultTiltle}</Typography>
            </>): <Typography className="main-heading">{configJSON.ExploreTiltle}</Typography>}
          </div>
          <div
            id="scrollableDiv"
            className="scrollable"
          >
            {this.state.exploreList.length > 0 ? (
              <InfiniteScroll
                dataLength={this.state.exploreList.length}
                next={this.fetchMoreData}
                scrollableTarget="scrollableDiv"
                hasMore={this.state.hasMore}
                loader={<h4>{configJSON.LoadingTilte}</h4>}
                className="infiniteScroll"
              >
                <Grid container spacing={3}>
                  {this.state.exploreList.map((item) => (
                    <Grid item md={3} sm={4} xs={12} key={item.id}>
                      <ImageLabel
                        imageClick={() =>
                          this.handleLoginModal()
                        }
                        imageSrc={item.attributes.location_cover_image}
                        name={item.attributes.city_name}
                      />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            ) : (
              <p className="not-found-text">{configJSON.NoExploreData}</p>
            )}
          </div>
        </div>
      </ExploreContainer>
    );
  }
}
const ExploreContainer = styled('div')({
    backgroundColor: "#FAFBFF",
    height:'calc(100vh - 98px)',
    "& .ExploreContainers":{
      margin: "0 auto",
      boxSizing: "border-box",
      width: "80%",
      height: "100%",
      padding: "40px 20px 20px",
      "@media (max-width:1200px)": {
        width: "100%",
      },
    },
    "& .scrollable":{
      height: "calc(100% - 106px)",
      overflowY: "auto",
      overflowX: "hidden",
    },
    "& .infiniteScroll":{
      height: "unset !important",
      overflow: "unset !important",
    },
    "& .text-head":{
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
    },
    "& .main-heading-2":{
      color: '#94959B'
    },
    "& .main-heading": {
      fontFamily: "Helvetica-Bold",
      fontSize: "20px",
      fontWeight: "600",
      color: "#222222",
      margin: "30px 0 10px 0",
    },
    "& .description-text": {
      margin: "20px 0",
      fontSize: "14px",
      fontFamily: "Helvetica-Bold",
      color: "#a2a3a6",
      linHeight: "20px",
      fontWeight: "400",
    },
    "& .not-found-text": {
      width: "100%",
      fontFamily: "Helvetica",
      fontSize: "16px",
      textAlign: "center",
      color: "#000000",
      lineHeight: "20px",
    },
    "& .search-box .MuiOutlinedInput-notchedOutline":{
      borderColor: '#36aede'
    },
    "& .search-box":{
      borderRadius: '15px',
      width: "100%",
    },
});
export default Place;
// Customizable Area End
