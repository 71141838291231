// Customizable Area Start
export const HotelDetailsStyles = {
  HotelDetails: {
    padding: "20px 20px 20px",
    boxSizing: "border-box",
    minHeight: "100%",
    backgroundColor: "#FAFBFF",
    "& .container": {
      padding: "0 !important",
      margin: "unset !important",
    },
    "& .image-count-container": {
      justifyContent: "center",
      position: "absolute",
      right: "0",
      bottom: "10px",
      left: "0",
      display: "flex",
      "& label": {
        fontSize: "18px",
        fontFamily: "Helvetica",
        lineHeight: "24px",
        color: "white",
        backgroundColor: "#c5bfbf",
        padding: "3px 12px",
        borderRadius: "10px",
      },
    },
    "& .location-image-container": {
      scrollBehavior: "smooth",
      position: "relative",
      marginBottom: "15px",
    },
    "& .carousal-button": {
      position: "absolute",
      top: "0",
      bottom: "0",
      alignItems: "center",
      display: "flex",
      "& button": {
        outline: "none",
        opacity: "0.8",
        borderRadius: "10px",
        width: "48px",
        height: "48px",
        color: "white",
        backgroundColor: "#c5bfbf",
        border: 0,
        cursor: "pointer",
      },
    },
    "& .left": { left: 25 },
    "& .right": { right: 25 },
    "& .white-box": {
      borderRadius: "12px",
      padding: "20px",
      border: "0.5px solid rgba(105,134,216,0.1)",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
    },
    "& .hotel-images": {
      display: "block",
      maxHeight: "300px",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "12px",
      marginBottom: "15px",
    },
    "& .google-maps": {
      height: "200px",
      width: "100%",
      margin: "15px 0",
      borderRadius: "12px",
      overflow: "hidden",
    },
    "& .city-name": {
      color: "#1c1c1c",
      fontFamily: "Helvetica-Bold",
      fontSize: "32px",
      fontWeight: "600",
      lineHeight: "39px",
    },
    "& .city-description": {
      color: "#818181",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "26px",
      margin: "15px 0",
    },
    "& .direction-name": {
      color: "#1C1C1C",
      fontFamily: "Helvetica-Bold",
      fontSize: "26px",
      fontWeight: "600",
      lineHeight: "31px",
    },
    "& .name-text-container": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "15px",
      marginBottom: "10px",
    },
    "& .hotel-name": {
      color: "#1e1f20",
      fontFamily: "Helvetica-Bold",
      fontSize: "22px",
      fontWeight: "600",
      lineHeight: "27px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .book-mark-icon": { width: 18, height: 21, cursor: "pointer" },
    "& .hotel-location": {
      color: "#818181",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "17px",
      display: "flex",
      alignItems: "center",
    },
    "& .rating-star": {
      fill: "orange",
      height: "18px",
      width: "18px",
    },
    "& .ratings": {
      color: "#1e1f20",
      fontFamily: "Helvetica",
      fontSize: "22px",
      fontWeight: "400",
      display: "flex",
      alignItems: "center",
    },
    "& .location-icon": {
      fill: "#25aae1",
      height: "18px",
      width: "18px",
    },
    "& .total-reviews": {
      color: "#25aae1",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "400",
      whiteSpace: "nowrap",
    },
    "& .view-rating-button": {
      borderRadius: "18px",
      border: "1px solid #25aae1",
      padding: "7px 15px",
      "& .MuiButton-label": {
        color: "#25aae1",
        fontFamily: "Helvetica",
        fontSize: "18px",
        fontWeight: "400",
        textTransform: "capitalize",
      },
    },
  },
};
// Customizable Area End
