export const active_info = require("../assets/active_info.png");
export const dummyProfile = require("../assets/profilePic.png");
export const place = require("../assets/place.png");
export const place1 = require("../assets/place1.png");
export const notificationIcon = require("../assets/notificationIcon.png");
import React from "react";
export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const phoneImage = require("../assets/phone_image.png");
export const emailImage = require("../assets/email_image.png");
export const profileAvatar = require("../assets/profile.png");
export const profileTabIcon_1 = require("../assets/profileTabIcon_1.png");
export const profileTabIcon_2 = require("../assets/profileTabIcon_2.png");
export const profileTabIcon_3 = require("../assets/profileTabIcon_3.png");
export const profileTabIcon_4 = require("../assets/profileTabIcon_4.png");
export const profileTabIcon_5 = require("../assets/profileTabIcon_5.png");

export const Upload = ({ height = 25, width = 25, ...props }) => {
  return (
    <div
      style={{
        borderRadius: "50%",
        border: "5px solid #fff",
        padding: "6px",
        width: "25px",
        height: "25px",
        textAlign: "center",
        position: "absolute",
        bottom: " 0",
        background: "rgb(33, 150, 243)",
        right: "0"
      }}
    >
      <svg
        fill="none"
        height={height}
        viewBox="0 0 20 20"
        width={width}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clip-rule="evenodd"
          d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z"
          fill="#fff"
          fill-rule="evenodd"
        />
      </svg>
    </div>
  );
};
