// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall, setupWebSocket } from "../../../components/src/utils.web";
const { baseURL } = require("../../../framework/src/config");

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  itineraryData: {
    id: number | string;
    title?: string;
    travelers: {
      id?: number;
      itinerary_id?: number;
      account_id: number;
      created_at?: string;
      updated_at?: string;
      full_name?: string;
      email?: string;
      full_phone_number?: string;
      profile_photo: string;
    }[];
    owner_id: number;
  };
}

interface S {
  chatId: number;
  isLoading: boolean;
  message: string;
  media: any[];
  messageId: string;
  scrollToBottom: boolean;
  chatListData: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        message: string;
        account_id: number;
        chat_id: number;
        created_at: string;
        updated_at: string;
        is_mark_read: false;
        message_type: string;
        attachments: {
          id: number;
          url: string;
          attachment_type: string;
        }[];
        account: {
          id: number;
          name: string;
          email: string;
          profile_photo: string;
        };
      };
    };
  }[];
  haveMoreChat: boolean;
  currentPage: number;
  limit: number;
  activeChatUser: number[];
  currentItineraryUser: number[];
  attachmentId: number;
}

interface SS {
  id: any;
}

export default class ItineraryDiscussionChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  createGroupChatApiCallId: string = "";
  getChatListApiCallId: string = "";
  sendMessageApiCallId: string = "";
  deleteMessageApiCallId: string = "";
  editMessageApiCAllId: string = "";
  addUserToGroupChatApiCallId: string = "";
  removeUserToGroupChatApiCallId: string = "";
  deleteMessageAttachmentApiCallId: string = "";
  webSocket: any = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      chatId: 0,
      isLoading: false,
      message: "",
      media: [],
      messageId: "",
      chatListData: [],
      scrollToBottom: false,
      haveMoreChat: true,
      currentPage: 1,
      limit: 15,
      activeChatUser: [],
      currentItineraryUser: [],
      attachmentId: NaN,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.setState(
      {
        currentItineraryUser: [
          ...this.props.itineraryData.travelers.map((obj) => obj.account_id),
        ],
      },
      () => {
        this.createGroupChat();
      }
    );
  }

  webSocketConnection = () => {
    const URL = baseURL.replace(/^https?:\/\//i, "");
    const token = localStorage.getItem("authToken");
    this.webSocket = setupWebSocket(
      URL,
      token,
      this.props.itineraryData.id,
      this.state.chatId,
      this.toHandleWebSocketMessage
    );
  };

  toHandleWebSocketMessage = (item: any) => {
    if (item.messages === "new_post_created") {
      let { chatListData } = this.state;
      let userData: any = localStorage.getItem("user_details");
      if (userData) {
        userData = JSON.parse(userData);
        if (userData.id) {
          userData = userData.id;
        }
      }
      const messages = {
        data: { ...item.data },
      };
      chatListData = [messages, ...chatListData];
      const isCurrentUser = userData == item.data.attributes.account_id;
      if (isCurrentUser) {
        this.setState({
          chatListData,
          scrollToBottom: isCurrentUser,
        });
      } else {
        this.setState({
          chatListData,
        });
      }
    } else if (item.messages === "message_deleted") {
      let { chatListData } = this.state;
      const { id } = item;
      this.setState({
        chatListData: chatListData.filter((item) => item.data.id != id),
      });
    } else if (item.messages === "message_edited_successfully") {
      const index = this.state.chatListData.findIndex(
        (data) => data.data.id == item.id
      );
      if (index != -1) {
        const { chatListData } = this.state;
        const oneData = this.state.chatListData[index];
        oneData.data.attributes.message = item.data.message;
        oneData.data.attributes.updated_at = item.data.updated_at;
        chatListData.splice(index, 1, oneData);
        this.setState({
          chatListData,
        });
      }
    }else if (item.messages === 'attachment_deleted') {
      const index = this.state.chatListData.findIndex(
        (data: any) => data.data.id == item.id
      );
      if (index != -1) {
        const { chatListData } = this.state;
        const oneData = this.state.chatListData[index];
        oneData.data.attributes.attachments = item.data.attachments;
        oneData.data.attributes.updated_at = item.data.updated_at;
        chatListData.splice(index, 1, oneData);
        this.setState({
          chatListData,
        });
      }
    }
  };

  async componentWillUnmount() {
    this.webSocket.send(
      JSON.stringify({
        command: "unsubscribe",
        identifier: `{"channel":"BxBlockItinerary::ItineraryGroupChatChannel","itinerary_id":${this.props.itineraryData.id},"chat_group_id":${this.state.chatId}}`,
      })
    );
    this.webSocket.close();
  }

  getChatList = async () => {
    this.getChatListApiCallId = await apiCall({
      endPoint: `bx_block_itinerary/itinerary_group_chat/list_groups_chat_messages?itinerary_id=${this.props.itineraryData.id}&chat[chat_group_id]=${this.state.chatId}&page=${this.state.currentPage}&per_page=${this.state.limit}`,
      method: "GET",
      contentType: "application/json",
    });
  };

  getMoreApiChatList = async () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      () => {
        this.getChatList();
      }
    );
  };

  createGroupChat = async () => {
    this.createGroupChatApiCallId = await apiCall({
      endPoint: `bx_block_itinerary/itinerary_group_chat/create_chat`,
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({
        itinerary_id: this.props.itineraryData.id,
        chat: {
          title: this.props.itineraryData.title,
          users_ids: this.state.currentItineraryUser,
        },
      }),
    });
  };

  sendMessage = async () => {
    const formData = new FormData();
    formData.append("itinerary_id", String(this.props.itineraryData.id));
    formData.append("chat[chat_group_id]", String(this.state.chatId));
    formData.append(
      "chat[message][message_text]",
      this.state.message.length > 0 ? this.state.message : "N/A"
    );
    let message_type: any;
    if (this.state.message.length > 0 && this.state.media.length > 0) {
      message_type = "text";
    } else if (this.state.media.length > 0) {
      message_type = "image";
    } else {
      message_type = "text";
    }
    if (this.state.media.length) {
      this.state.media.forEach((image: any) => {
        formData.append("photos[]", image);
      });
    }
    formData.append("chat[message][message_type]", message_type);
    this.sendMessageApiCallId = await apiCall({
      endPoint: `bx_block_itinerary/itinerary_group_chat/send_message_to_group_chat`,
      method: "POST",
      body: formData,
    });
  };

  deleteChatMessage = async (deleteMessageId: string) => {
    this.setState({
      messageId: deleteMessageId,
    });
    this.deleteMessageApiCallId = await apiCall({
      endPoint: `bx_block_itinerary/itinerary_group_chat/delete_message_from_group_chat`,
      method: "DELETE",
      contentType: "application/json",
      body: JSON.stringify({
        itinerary_id: Number(this.props.itineraryData.id),
        chat: {
          chat_group_id: this.state.chatId,
          message: {
            message_id: Number(deleteMessageId),
          },
        },
      }),
    });
  };
  deleteAttachmentMessage = async (deleteMessageId: string, deleteAttachmentId: number) => {
    this.setState({
      messageId: deleteMessageId,
      attachmentId: deleteAttachmentId
    });
    const index = this.state.chatListData.findIndex((item: any) => item.data.id == deleteMessageId)
    const attachments = this.state.chatListData[index].data.attributes.attachments;
    const message=this.state.chatListData[index].data.attributes.message;
    if((message==='Attachment' || message==='N/A') && attachments.length==1){
      this.deleteChatMessage(deleteMessageId)
    }else{
      this.deleteMessageAttachmentApiCallId = await apiCall({
        endPoint: `bx_block_itinerary/itinerary_group_chat/delete_message_from_group_chat`,
        method: "DELETE",
        contentType: "application/json",
        body: JSON.stringify({
          itinerary_id: Number(this.props.itineraryData.id),
          chat: {
            chat_group_id: this.state.chatId,
            message: {
              message_id: Number(deleteMessageId),
              attachment_id: deleteAttachmentId
            },
          },
        }),
      });
    }
  };

  editChatMessage = async (message: string, messageId: string) => {
    this.setState({
      messageId,
    });
    this.editMessageApiCAllId = await apiCall({
      endPoint:
        "bx_block_itinerary/itinerary_group_chat/edit_message_in_group_chat",
      method: "PUT",
      contentType: "application/json",
      body: JSON.stringify({
        itinerary_id: this.props.itineraryData.id,
        chat: {
          chat_group_id: this.state.chatId,
          message: {
            message_id: Number(messageId),
            message_text: message,
            message_type: "text",
          },
        },
      }),
    });
  };

  removeUsersGroupChat = async (arr: number[]) => {
    this.removeUserToGroupChatApiCallId = await apiCall({
      endPoint:
        "bx_block_itinerary/itinerary_group_chat/remove_users_from_chat",
      contentType: "application/json",
      method: "DELETE",
      body: JSON.stringify({
        itinerary_id: this.props.itineraryData.id,
        chat: {
          chat_group_id: this.state.chatId,
          users_ids: arr,
        },
      }),
    });
  };

  addUsersGroupChat = async (arr: number[]) => {
    this.addUserToGroupChatApiCallId = await apiCall({
      endPoint: "bx_block_itinerary/itinerary_group_chat/add_users_to_chat",
      contentType: "application/json",
      method: "POST",
      body: JSON.stringify({
        itinerary_id: this.props.itineraryData.id,
        chat: {
          chat_group_id: this.state.chatId,
          users_ids: arr,
        },
      }),
    });
  };

  findArrayDifference = (arr1: number[], arr2: number[]) => {
    if (arr1.every((element, index) => element === arr2[index])) {
      return {
        differenceInArr1: [],
        differenceInArr2: [],
      };
    }

    const diff1 = arr1.filter((element) => !arr2.includes(element));
    const diff2 = arr2.filter((element) => !arr1.includes(element));

    return {
      differenceInArr1: diff1,
      differenceInArr2: diff2,
    };
  };

  submitMessageOrMedia = (message: any, media: any[]) => {
    this.setState({ media, message }, () => {
      this.sendMessage();
    });
  };

  handleError = (errors: any) => {
    if (Array.isArray(errors)) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}, Please login again.`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
    }
    toast.error("Something went to wrong please retry");
  };

  handleCreateGroupResponse = (responseJson: any) => {
    if (responseJson.message && responseJson.group_chat) {
      this.setState(
        {
          chatId: responseJson.group_chat.chat_group_id,
          activeChatUser: [
            ...responseJson.group_chat.users.map((item: any) => item.id),
          ],
        },
        () => {
          this.webSocketConnection();
          this.getChatList();
          const difference = this.findArrayDifference(
            this.state.activeChatUser,
            this.state.currentItineraryUser
          );
          if (difference.differenceInArr1.length > 0) {
            this.removeUsersGroupChat(difference.differenceInArr1);
          }
          if (difference.differenceInArr2.length > 0) {
            this.addUsersGroupChat(difference.differenceInArr2);
          }
        }
      );
    }
  };

  handleChatListResponse = (responseJson: any) => {
    if (responseJson.message && responseJson.messages) {
      const haveMoreChat =
        responseJson.pagination.current_page <
          responseJson.pagination.total_pages
          ? true
          : false;
      const reverseData = responseJson.messages;
      this.setState({
        chatListData: [...this.state.chatListData, ...reverseData],
        scrollToBottom:
          responseJson.pagination.current_page == 1 ? true : false,
        haveMoreChat,
      });
    }
  };

  handleSendMessage = (responseJson: any) => {
    if (responseJson.message && responseJson.chat_message.data) {
      const data = JSON.stringify({
        command: "message",
        identifier: `{"channel":"BxBlockItinerary::ItineraryGroupChatChannel","itinerary_id":${this.props.itineraryData.id},"chat_group_id":${this.state.chatId}}`,
        data: JSON.stringify({
          action: "speak",
          message: JSON.stringify({
            messages: "new_post_created",
            data: responseJson.chat_message.data,
          }),
        }),
      });
      this.webSocket.send(data);
      this.setState({ message: "", media: [], scrollToBottom: true });
    }
  };

  handleDeleteMessageResponse = (responseJSon: any) => {
    if (responseJSon.message) {
      const data = JSON.stringify({
        command: "message",
        identifier: `{"channel":"BxBlockItinerary::ItineraryGroupChatChannel","itinerary_id":${this.props.itineraryData.id},"chat_group_id":${this.state.chatId}}`,
        data: JSON.stringify({
          action: "speak",
          message: JSON.stringify({
            messages: "message_deleted",
            id: this.state.messageId,
          }),
        }),
      });
      this.webSocket.send(data);
    }
  };
  handleDeleteMessageAttachment = (responseJSon: any) => {
    if (responseJSon.data.id === this.state.messageId) {
      const data = JSON.stringify({
        command: "message",
        identifier: `{"channel":"BxBlockItinerary::ItineraryGroupChatChannel","itinerary_id":${this.props.itineraryData.id},"chat_group_id":${this.state.chatId}}`,
        data: JSON.stringify({
          action: "speak",
          message: JSON.stringify({
            messages: "attachment_deleted",
            id: this.state.messageId,
            data: responseJSon.data.attributes
          }),
        }),
      });
      this.webSocket.send(data);
    }
  }

  handleEditMessageResponse = (responseJSon: any) => {
    if (
      responseJSon.status &&
      responseJSon.message &&
      responseJSon.chat_message
    ) {
      const data = JSON.stringify({
        command: "message",
        identifier: `{"channel":"BxBlockItinerary::ItineraryGroupChatChannel","itinerary_id":${this.props.itineraryData.id},"chat_group_id":${this.state.chatId}}`,
        data: JSON.stringify({
          action: "speak",
          message: JSON.stringify({
            messages: "message_edited_successfully",
            id: this.state.messageId,
            data: responseJSon.chat_message,
          }),
        }),
      });
      this.webSocket.send(data);
    }
  };

  handleResponseDiscussionChat = async (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    switch (apiRequestCallId) {
      case this.createGroupChatApiCallId:
        this.handleCreateGroupResponse(responseJson);
        break;
      case this.getChatListApiCallId:
        this.handleChatListResponse(responseJson);
        break;
      case this.sendMessageApiCallId:
        this.handleSendMessage(responseJson);
        break;
      case this.deleteMessageApiCallId:
        this.handleDeleteMessageResponse(responseJson);
        break;
      case this.editMessageApiCAllId:
        this.handleEditMessageResponse(responseJson);
      case this.deleteMessageAttachmentApiCallId:
        this.handleDeleteMessageAttachment(responseJson)
        break;
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.errors) {
        this.handleError(responseJson.errors);
      }
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        this.handleResponseDiscussionChat(apiRequestCallId, responseJson);
      }

      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
