// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import * as Yup from "yup";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import DataContext from "../../../components/src/DataContext";
import { apiCall } from "../../../components/src/utils.web";
import { ArrayHelpers } from "formik";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  isUnAuth?: boolean;
  handleLoginModal?: () => void;
}

interface S {
  isLoading: boolean;
  itinerary: { owner_id?: string; id?: string; title: string; addplaces: { place_id?: number; id?: number; lat?: number; lng?: number; place: string; days: number; plans: { day_rank?: number; Day: number; activity: { activity_id?: number; images: { url: string; type: string; fileName: string }[]; isSaved: boolean; title: string; description: string; location: string; }[]; name: string; }[] }[]; traveller: { [keys: string]: string | number | boolean; photo: string }[] };
  currentTabline: string;
  travellerSearch: string;
  token: string;
  searchedTraveller: { [keys: string]: string | number | boolean; photo: string; id: string }[];
  hasMoreTravellers: boolean;
  currentPage: number;
  isTravellerAdded: boolean;
  days: number[];
  isUsersExpand: boolean;
  isAllActivitySaved: boolean;
  isLocationPageOpen: boolean;
  placeIndex: number;
  index: number;
  activityIndex: number;
  searchText: string;
  isBookmarked: boolean;
  itinenarys: { itinerary_id?: number; owner_id?: string; id?: string; title: string; addplaces: { place_id?: number; id?: number; place: string; days: number; plans: { Day: number; activity: { images: { url: string; type: string; fileName: string }[]; isSaved: boolean; title: string; description: string; location: string; }[]; name: string; }[] }[]; traveller: { [keys: string]: string | number | boolean; photo: string }[] };
  isEdit: boolean;
  anchorEl: any;
  userDetail: { id?: string }
  removePlaces: number[];
  removeTraveller: (string | number | boolean)[];
  addTraveller: (string | number | boolean)[];
  removeActivity: {
    itinerary_id?: number;
    location_id?: number;
    day_rank?: number;
    activity_id?: number;
  }[];
  removeActivityImages: {
    itinerary_id?: number;
    location_id?: number;
    day_rank?: number;
    activity_id?: number;
    photo_urls: string[];
  }[];
  isTravellerSearching: boolean;
}

interface SS {
  id: any;
}

export default class ItineraryCreateController extends BlockComponent<
  Props,
  S,
  SS
> {
  travellerSearchApiId = "";
  getItinenaryApiID = "";
  moreTravellerSearchApiId = "";
  createItineraryApiId = "";
  getItineraryApiId = "";
  addTravellerApiId = "";
  addActivityApiId = "";
  getItinenaryInfoApiID = "";
  editItineraryTitleApiEndPointId = "";
  editItineraryplaceApiEndPointId = "";
  editItineraryRemoveTravelersApiEndPointID = "";
  removeItineraryplaceApiEndPointId = "";
  AddItineraryPlacesApiEndPointId = "";
  addActivityApiEndPointId = "";
  removeActivityApiEndPointId = "";
  editPlaceApiEndPointId = "";
  removeActivityImageApiEndPointId = "";
  addActivityimagesApiEndPointId = "";
  editItineraryAddTravelersApiEndPointID = "";
  timerId: NodeJS.Timeout | null = null;
  static contextType: React.Context<string> = DataContext
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
      currentTabline: "form1",
      itinerary: { title: "", addplaces: [{ place: "", days: 0, plans: [] }], traveller: [] },
      travellerSearch: "",
      token: localStorage.getItem("authToken") || "",
      searchedTraveller: [],
      hasMoreTravellers: false,
      currentPage: 1,
      isTravellerAdded: false,
      days: [1, 2, 3, 4, 5],
      isUsersExpand: false,
      isAllActivitySaved: false,
      isLocationPageOpen: false,
      placeIndex: 0,
      index: 0,
      activityIndex: 0,
      searchText: "",
      isBookmarked: false,
      itinenarys: { title: "", addplaces: [{ place: "", days: 0, plans: [] }], traveller: [] },
      isEdit: false,
      anchorEl: null,
      userDetail: {},
      removePlaces: [],
      addTraveller: [],
      removeTraveller: [],
      removeActivity: [],
      removeActivityImages: [],
      isTravellerSearching: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson?.errors?.length) {
        this.handleError(responseJson.errors);
        return;
      }

      switch (apiRequestCallId) {
        case this.getItinenaryApiID:
          this.handleAddActivitys(responseJson)
          break;
        case this.travellerSearchApiId:
          this.handleTravellerSearchApi(responseJson);
          break;

        case this.moreTravellerSearchApiId:
          if (responseJson.data?.length) {
            const hasData = responseJson?.data?.length > 10 ? false : true;
            const searchedTraveller = responseJson.data.map((item: { id: string; attributes: {} }) => {
              return {
                id: item.id,
                ...item.attributes
              };
            });

            this.setState({
              searchedTraveller: [...this.state.searchedTraveller, ...searchedTraveller],
              hasMoreTravellers: hasData,
              isTravellerSearching: false
            });
          } else {
            this.setState({
              hasMoreTravellers: false
            });
          }
          break;
        case this.createItineraryApiId:
          this.handleTravellerApi(responseJson)
          break;
        case this.getItineraryApiId:
          this.handleAddActivity(responseJson)
          break;
        case this.addActivityApiId:
          this.handleRediration(responseJson);
          break;
        case this.editItineraryplaceApiEndPointId:
        case this.AddItineraryPlacesApiEndPointId:
        case this.editItineraryTitleApiEndPointId:
        case this.removeItineraryplaceApiEndPointId:
        case this.editItineraryRemoveTravelersApiEndPointID:
        case this.editItineraryAddTravelersApiEndPointID:
          this.setState({ isLoading: false })
          this.handleEditedValuesRediraction(responseJson, 1);
          break;
        case this.addActivityApiEndPointId:
        case this.addActivityimagesApiEndPointId:
        case this.removeActivityImageApiEndPointId:
        case this.editPlaceApiEndPointId:
        case this.removeActivityApiEndPointId:
          this.handleEditedValuesRediraction(responseJson, 2);

          break;
        default:
          break;
      }
    }
  }

  handleEditedValuesRediraction = (responseJson: { errors: [] }, editFrom: number) => {
    console.log('responseJson', responseJson, editFrom);
    
    if (responseJson?.errors?.length) {
      this.handleError(responseJson.errors);
      this.getItinenaryData(this.state?.itinenarys?.itinerary_id);
    } else if (editFrom === 1) {
      this.setState({ isLoading: false });
      this.getItinenaryData(this.state?.itinenarys?.itinerary_id);
    } else if (editFrom === 2) {
      this.setState({ isLoading: false });
      this.props.navigation.goBack();
    }
  }

  handleTravellerSearchApi = (responseJson: { data: { id: string; attributes: { photo: string; }; }[] }) => {
    const hasData = responseJson?.data?.length > 10 ? false : true;
    if (responseJson.data?.length) {
      const searchedTraveller = responseJson.data.map((item) => {
        return {
          id: item.id,
          ...item.attributes
        };
      });
      this.setState({
        searchedTraveller,
        hasMoreTravellers: hasData,
        currentPage: 1
      });
    } else {
      toast.error(configJSON.NoUserErrorMassage);
      this.setState({
        hasMoreTravellers: false
      });
    }
    this.setState({
      isTravellerSearching: false
    })
  }
  handleTravellerApi = (responseJson: { itinerary: { id: number; } }) => {
    if (responseJson.itinerary) {
      this.handleAddTraveller(responseJson.itinerary);
      this.handleGetActivity(responseJson.itinerary);
    } else {
      toast.error(configJSON.errormassage);
    }
  }

  handleAddActivitys = async (responseJson: { itinerary: { places: { id?: number; place_title: string; place: string; days: number; plans: { day_rank: number; day_title: string; Day: number; activity: { photos: string[]; isSaved: boolean; title: string; description: string; location: string; }[]; name: string; }[] }[]; travellers: { [keys: string]: string | number | boolean; image: string }[]; title: string; itinerary_locations: { itinerary_id: number; id: number; title: string; days: string }[]; } }) => {
    const { itinerary } = responseJson;
    const itinerarys = {
      ...itinerary, addplaces: itinerary.places.map((item) => {
        let plans = [...item.plans];
        if (plans.length < item.days) {
          for (let i = 0; i < item.days - item.plans.length; i++) {
            plans.push({ day_title: `Day ${item.plans.length + i + 1}`, name: `Day ${item.plans.length + i + 1}`, day_rank: item.plans.length + i + 1, Day: item.plans.length + i + 1, activity: [] })
          }
        }
        return {
          ...item, place: item.place_title, plans: plans.sort((a, b) => a.day_rank - b.day_rank).map((plan) => {
            return {
              ...plan, name: plan.day_title,
              activity: plan.activity.map((activitys) => {
                return {
                  ...activitys, isSaved: true, images: activitys.photos.map((item) => {
                    return { url: item, type: "", fileName: "" }
                  })
                }
              })

            }
          })
        }
      }), traveller: itinerary.travellers.map((item) => {
        return {
          ...item,
          full_name: item.name,
          photo: item.image
        }
      })
    };

    this.setState({
      itinenarys: itinerarys,
      isLoading: false,
      itinerary: { ...this.state.itinerary, ...itinerarys },
      currentTabline: "form2"
    });

  }

  handleRemoveActivity = ({arrayHelpers,activityIndex,activity_id,day_rank,place_id}:{arrayHelpers: ArrayHelpers, activityIndex: number, activity_id?: number, day_rank?: number, place_id?: number}) => {
    arrayHelpers.remove(activityIndex);
    if (this.state.isEdit && activity_id && day_rank && place_id) {
      this.setState({
        removeActivity: [...this.state.removeActivity, { itinerary_id: this.state.itinenarys.itinerary_id, activity_id: activity_id, day_rank: day_rank, location_id: place_id }]
      })
    }
    this.isAllActivitySaved(true);
  }

  handleremoveplace = (arrayHelpers: ArrayHelpers, index: number, id: number | undefined) => {
    arrayHelpers.remove(index);
    if (id) {
      this.setState({
        removePlaces: [...this.state.removePlaces, id]
      })
    }
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  }

  handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleTabs = () => {
    this.setState({
      currentTabline: "form1",
      anchorEl: null,
    });
  }

  async componentDidMount() {
    const itinerary: any = await getStorageData("ItineraryData",true)
    if (this.context?.data?.test) {
      this.setState({ ...this.context.data.test });
    } else {
       this.getBookedMarkData(itinerary);
    }
    const id = this.props.navigation.getParam("id");
    const data = localStorage.getItem("user_details")
    if (data) {
      this.setState({
        userDetail: JSON.parse(data)
      })

    }
    if (id) {
      this.setState({
        isEdit: true,
        isAllActivitySaved: true
      })
      this.getItinenaryData(id);

    }

  }

  getItinenaryData = async (id?: number) => {
    this.setState({
      isLoading: true,
    })
    this.getItinenaryApiID = await apiCall({
      endPoint: configJSON.getItineraryInfoApiEndPoint + id,
      method: "GET",
      contentType: configJSON.exampleApiContentType,
    });
  }
  handleError = (errors: { token: string }[]) => {
    const error = errors?.length
    if (Array.isArray(errors) && error) {
      if (errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}${configJSON.errormassage2}`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
      } else {
        toast.error(configJSON.errormassage);
      }
    }
  }
  handleRediration = (responseJson: { message: string }) => {
    if (responseJson.message === "Daily activity creation successful") {
      this.setState({
        isLoading: false,
      });
      toast.success(configJSON.successmassage);
      this.props.navigation.history.push(`/profile/${this.state.userDetail.id}`, { isItineraryCreated: true });
    }
  }
  handleAddTraveller = (itinerary: { id: number }) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.addTravellerApiId = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addTravellerApiEndPoint
    );
    const body = JSON.stringify({
      "itinerary_id": itinerary.id,
      "travelers": this.state.itinerary.traveller.map((item) => {
        return {
          "account_id": item.id
        }
      })
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleAddActivity = (responseJson: { id: number, itinerary_locations: { id: number }[] ,message:string}) => {
    if (responseJson.itinerary_locations) {
      const addplaces = this.state.itinerary.addplaces.map((item, index) => {
        return {
          id: responseJson.itinerary_locations[index].id,
          ...item
        }
      })
      this.setState({
        itinerary: { ...this.state.itinerary, addplaces },
      })
      addplaces.map((places) => {
        places.plans.map((plans) => {
          if(plans.activity.length>0){
            plans.activity.map((activity) => {
              const formData = new FormData();
              formData.append("itinerary_id", responseJson.id.toString());
              formData.append("location_id", places.id.toString());
              formData.append("day_rank", plans.Day.toString());
              formData.append("activity_title", activity.title);
              formData.append("activity_desc", activity.description);
              formData.append("activity_loc", activity.location);
              activity.images.forEach((image) => {
                formData.append(
                  "photos[]",
                  this.dataURLtoFile(image.url, image.fileName)
                );
              });
  
              const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
              );
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "POST"
              );
              this.addActivityApiId = requestMessage.messageId;
              const headers = {
                token: this.state.token,
              };
              requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.createActivityApiEndPoint
              );
  
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
              )
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                headers
              );
              runEngine.sendMessage(requestMessage.id, requestMessage)
            })
          }
          else{
            const response={message:"Daily activity creation successful"};
            this.handleRediration(response);
          }
          
        })
      })
    } else {
      toast.error(configJSON.errormassage);
    }
  }

  dataURLtoFile = (dataUrl: any, fileName: string) => {
    let arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };

  handleGetActivity = (itinerary: { id: number }) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getItineraryApiId = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getItineraryApiEndPoint + itinerary.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleTravellers = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.value) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.setState({
        travellerSearch: event.target.value
      });
      this.timerId = setTimeout(this.handleSearchTravellers, 2000) as unknown as NodeJS.Timeout;
    } else {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.setState({
        searchedTraveller: []
      })
    }
  };

  handleSearchTravellers = () => {
    this.setState({
      isTravellerSearching: true,
      searchedTraveller: []
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.travellerSearchApiId = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTravellers +
      this.state.travellerSearch +
      `&page=1&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  };

  handleUsers = (value: boolean) => {
    if (this.state.itinerary.traveller.length > 5) {
      this.setState({
        isUsersExpand: value
      })
    }
  }

  validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(configJSON.requireTitle),
    addplaces: Yup.array()
      .of(
        Yup.object().shape({
          place: Yup.string().required(configJSON.requirePlace),
          days: Yup.number().min(1, configJSON.requireDay).required(configJSON.requireDay),
        })
      )
      .required(configJSON.requirePlaces),
  });

  validationSchema1 = Yup.object().shape({
    addplaces: Yup.array()
      .of(
        Yup.object().shape({
          place: Yup.string().required(configJSON.requirePlace),
          days: Yup.number().min(1, configJSON.requireDay).required(configJSON.requireDay),
          plans: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required(configJSON.requireName),
              activity: Yup.array().of(
                Yup.object().shape({
                  location: Yup.string().required(configJSON.requireLocation),
                  title: Yup.string().required(configJSON.requireTitle),
                })
              ).required(configJSON.requireActivity),
            })
          ).required(configJSON.requirePlans),
        })
      )
      .required(configJSON.requirePlaces),
  });

  handleSerachedText = (values: { addplaces: { place: string }[] }, placeIndex: number) => {
    return this.state.searchText.length > 0 ? this.state.searchText : values.addplaces[placeIndex].place
  }

  isAllActivitySaved = (value: boolean) => {
    if (this.state.isAllActivitySaved !== value) {
      this.setState({
        isAllActivitySaved: value
      });
    }
  }

  fetchMoreData = () => {
    this.setState({
      currentPage: this.state.currentPage + 1
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.moreTravellerSearchApiId = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTravellers +
      this.state.travellerSearch +
      `&page=${this.state.currentPage}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  handleEditedPlaceData = async (value: { addplaces: []; title: string; }) => {
    this.setState({ isLoading: true });
    const places = value.addplaces.filter((place: { place: string; place_id: number; days: number }, index: number,) => {
      const data = this.state.itinenarys.addplaces.findIndex((items) => items.place_id == place.place_id);
      const places = this.state.itinenarys.addplaces[data];
      return place.place_id && places.place != place.place || place.place_id && places.days != place.days;
    });

    if (places.length) {
      places.forEach(async (item: { place_id: string, place: string; days: number }) => {
        const body = JSON.stringify({
          "itinerary_id": this.state.itinenarys.itinerary_id,
          "place": {
            "place_id": item.place_id,
            "title": item.place,
            "days": item.days
          }
        })
        this.editItineraryplaceApiEndPointId = await apiCall({
          endPoint: configJSON.editplacesApiEndPoint,
          method: "PUT",
          contentType: configJSON.exampleApiContentType,
          body
        });
      })
    }

    if (this.state.itinenarys.title != value.title) {
      const body = JSON.stringify({
        "itinerary_id": this.state.itinenarys.itinerary_id,
        "title": value.title,
      })
      this.editItineraryTitleApiEndPointId = await apiCall({
        endPoint: configJSON.editItineraryTitleApiEndPoint,
        method: "PUT",
        contentType: configJSON.exampleApiContentType,
        body
      });
    }

    const addplaces = value.addplaces.filter((item: { place_id: number }) => !item.place_id);

    if (addplaces.length) {
      const body = JSON.stringify({
        "itinerary_id": this.state.itinenarys.itinerary_id,
        "places": addplaces.map((item: { place: string; days: string }) => {
          return {
            "title": item.place,
            "days": item.days,
          }
        })
      })
      this.AddItineraryPlacesApiEndPointId = await apiCall({
        endPoint: configJSON.AddItineraryPlacesApiEndPoint,
        method: "POST",
        contentType: configJSON.exampleApiContentType,
        body
      });
    }


    if (this.state.removePlaces.length) {
      const body = JSON.stringify({
        "itinerary_id": this.state.itinenarys.itinerary_id,
        "places": this.state.removePlaces.map((item) => {
          return {
            "place_id": item
          }
        })
      })
      this.removeItineraryplaceApiEndPointId = await apiCall({
        endPoint: configJSON.removeItineraryplaceApiEndPoint,
        method: "DELETE",
        contentType: configJSON.exampleApiContentType,
        body
      });
    }

    if (this.state.removeTraveller.length) {
      const body = JSON.stringify({
        "itinerary_id": this.state.itinenarys.itinerary_id,
        "travelers": this.state.removeTraveller.map((item) => { return { "account_id": item } })
      })
      this.editItineraryRemoveTravelersApiEndPointID = await apiCall({
        endPoint: configJSON.editItineraryRemoveTravelersApiEndPoint,
        method: "DELETE",
        contentType: configJSON.exampleApiContentType,
        body
      });
    }

    if (this.state.addTraveller.length) {
      const body = JSON.stringify({
        "itinerary_id": this.state.itinenarys.itinerary_id,
        "travelers": this.state.addTraveller.map((item) => { return { "account_id": item } })
      })
      this.editItineraryAddTravelersApiEndPointID = await apiCall({
        endPoint: configJSON.addTravellerApiEndPoint,
        method: "POST",
        contentType: configJSON.exampleApiContentType,
        body
      });
    }

    if (!places.length && this.state.itinenarys.title == value.title && !addplaces.length && !this.state.removePlaces.length && !this.state.removeTraveller.length && !this.state.addTraveller.length) {
      this.setState({ currentTabline: "form2", isLoading: false, anchorEl: null });
    }
  }

  handleSubmitData = async (value: any) => {
    if (this.state.isEdit) {
      this.handleEditedPlaceData(value)
    } else {
      let itinerary = value
      itinerary.addplaces = itinerary.addplaces.map((item: any) => {
        const plans = []
        for (let i = 0; i < item.days; i++) {
          plans.push({ name: `Day ${i + 1}`, Day: i + 1, activity: [] })
        }
        return { ...item, plans }
      });
      if (this.props?.isUnAuth) {
        this.props.handleLoginModal?.()
        setStorageData('ItineraryData', JSON.stringify({ itinerary: { ...this.state.itinerary, ...itinerary }, currentTabline: "form2" }));
      } else if (itinerary.addplaces.length) {
        this.setState({
          itinerary: { ...this.state.itinerary, ...itinerary }, currentTabline: "form2",
        });
      }
    }
  };

  handleItinerary = (value: { addplaces: { place_id?: number; lat?: number, lng?: number, place: string; days: number; plans: { day_rank?: number; Day: number; activity: { activity_id?: number; images: { url: string; type: string; fileName: string; }[]; isSaved: boolean; title: string; description: string; location: string; }[]; name: string; }[]; }[]; }) => {
    if (this.state.isEdit) {
      const newActivity: { day_rank: number | undefined; place_id: number | undefined; activity_id?: number; images: { url: string; type: string; fileName: string; }[]; isSaved: boolean; title: string; description: string; location: string; }[] = [];
      const newImages: { day_rank: number | undefined; place_id: number | undefined; image: { url: string, type: string, fileName: string, }[]; activity_id?: number }[] = [];
      const editedPlace: { day_rank: number | undefined; place_id: number | undefined; activity_id?: number; images: { url: string; type: string; fileName: string; }[]; isSaved: boolean; title: string; description: string; location: string; }[] = [];
      value.addplaces.forEach((place, placeIndex) => {
        place.plans.forEach((plan, planIndex) => {
          plan.activity.forEach((activitys, activitysIndex) => {
            if (!activitys.activity_id) {
              newActivity.push({ ...activitys, day_rank: plan.day_rank, place_id: place.place_id });
            } else {
              const existactivity = this.state.itinenarys.addplaces[placeIndex].plans[planIndex].activity[activitysIndex];
              if (existactivity.title != activitys.title || existactivity.description != activitys.description || existactivity.location != activitys.location) {
                editedPlace.push({ ...activitys, day_rank: plan.day_rank, place_id: place.place_id });
              }
              activitys.images.forEach(image => {
                if (image.fileName) {
                  const index = newImages.findIndex(i => i.activity_id === activitys.activity_id);
                  if (index > -1) {
                    newImages[index].image.push(image);
                  } else {
                    newImages.push({ image: [image], day_rank: plan.day_rank, place_id: place.place_id, activity_id: activitys.activity_id })
                  }
                }
              })
            }
          })
        })
      });
      if (newActivity.length) {
        newActivity.forEach(async (activity) => {
          const formData = new FormData();
          formData.append("itinerary_id", this.handleString(this.state.itinenarys.itinerary_id));
          formData.append("location_id", this.handleString(activity.place_id));
          formData.append("day_rank", this.handleString(activity.day_rank));
          formData.append("activity_title", activity.title);
          formData.append("activity_desc", activity.description);
          formData.append("activity_loc", activity.location);
          activity.images.forEach((image) => {
            formData.append(
              "photos[]",
              this.dataURLtoFile(image.url, image.fileName)
            );
          });

          this.addActivityApiEndPointId = await apiCall({
            endPoint: configJSON.createActivityApiEndPoint,
            method: "POST",
            body: formData
          });
        });
      }

      if (this.state.removeActivity.length) {
        this.state.removeActivity.forEach(async (body) => {
          this.removeActivityApiEndPointId = await apiCall({
            endPoint: configJSON.removeItineraryplaceActivityApiEndPoint,
            method: "DELETE",
            contentType: configJSON.exampleApiContentType,
            body: JSON.stringify(body)
          });
        })
      }

      if (editedPlace.length) {
        editedPlace.forEach(async (activity) => {
          const body = JSON.stringify({
            "itinerary_id": this.state.itinenarys.itinerary_id,
            "location_id": activity.place_id,
            "day_rank": activity.day_rank,
            "activity_id": activity.activity_id,
            "activity_title": activity.title,
            "activity_desc": activity.description,
            "activity_loc": activity.location
          });
          this.editPlaceApiEndPointId = await apiCall({
            endPoint: configJSON.editPlaceApiEndPoint,
            method: "PUT",
            contentType: configJSON.exampleApiContentType,
            body: body
          });
        })
      }

      if (this.state.removeActivityImages.length) {
        this.state.removeActivityImages.forEach(async (body) => {
          this.removeActivityImageApiEndPointId = await apiCall({
            endPoint: configJSON.removeItineraryplaceActivityImageApiEndPoint,
            method: "DELETE",
            contentType: configJSON.exampleApiContentType,
            body: JSON.stringify(body)
          });
        })
      }

      if (newImages.length) {
        newImages.forEach(async (item) => {
          const formData = new FormData();
          formData.append("itinerary_id", this.handleString(this.state.itinenarys.itinerary_id));
          formData.append("location_id", this.handleString(item.place_id));
          formData.append("day_rank", this.handleString(item.day_rank));
          formData.append("activity_id", this.handleString(item.activity_id));
          item.image.forEach((images) => {
            formData.append(
              "photos[]",
              this.dataURLtoFile(images.url, images.fileName)
            );
          });

          this.addActivityimagesApiEndPointId = await apiCall({
            endPoint: configJSON.addActivityimagesApiEndPoint,
            method: "POST",
            body: formData
          });
        })
      }

      this.props.navigation.goBack();
    }
    else {
      this.setState({
        itinerary: { ...this.state.itinerary, addplaces: value.addplaces },
        isLoading: true,
      });
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      this.createItineraryApiId = requestMessage.messageId;
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createItineraryApiEnd
      );
      const body = JSON.stringify({
        "title": this.state.itinerary.title,
        "places": value.addplaces.map((item) => {
          return {
            "title": item.place,
            "days": item.days,
            "latitude": item.lat,
            "longitude": item.lng,
          }
        })
      })
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      );
      runEngine.sendMessage(requestMessage.id, requestMessage)
    }
  }

  handleString = (value?: number) => {
    return value?.toString() ?? "";
  }

  addTravellers = (isClicked: boolean, users: { [keys: string]: string | number | boolean; photo: string; }) => {
    const itinerary = { ...this.state.itinerary };
    if (isClicked) {
      itinerary.traveller.push(users)
      this.setState({
        itinerary, isTravellerAdded: false,
        addTraveller: [...this.state.addTraveller, users.id]
      })
      const removeTraveller = [...this.state.removeTraveller];
      if (removeTraveller.includes(users.id)) {
        removeTraveller.splice(removeTraveller.indexOf(users.id), 1);
        this.setState({
          removeTraveller
        })
      }
    } else {
      const index = itinerary.traveller.findIndex(i => i.id === users.id);
      itinerary.traveller.splice(index, 1);
      this.setState({
        itinerary,
        removeTraveller: [...this.state.removeTraveller, users.id]
      })
      const addTraveller = [...this.state.addTraveller];
      if (addTraveller.includes(users.id)) {
        addTraveller.splice(addTraveller.indexOf(users.id), 1);
        this.setState({
          addTraveller
        })
      }
    }
  }
  activityImageChange = (e: any, arrayHelper: any) => {
    for (const element of e.target.files) {
      const file = element;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        arrayHelper.push({
          fileName: file.name,
          type: file.type,
          url: reader.result
        })
      }
    }
  }

  handleRemoveImage = ({arrayHelper,imageIndex,activity_id,day_rank,place_id,imageUrl}:{arrayHelper: ArrayHelpers, imageIndex: number, activity_id?: number, day_rank?: number, place_id?: number, imageUrl?: string}) => {
    arrayHelper.remove(imageIndex);
    if (activity_id && day_rank && place_id && imageUrl) {
      const ActivityImages = [...this.state.removeActivityImages];
      const index = ActivityImages.findIndex(i => i.activity_id === activity_id);
      if (index > -1) {
        ActivityImages[index].photo_urls.push(imageUrl)
      } else {
        ActivityImages.push({
          activity_id: activity_id,
          day_rank: day_rank,
          location_id: place_id,
          photo_urls: [imageUrl],
          itinerary_id: this.state.itinenarys.itinerary_id,
        })
      }
      this.setState({
        removeActivityImages: ActivityImages
      })
    }



  }
  openItineraryLocationPage = (
    placeIndex: number,
    index: number,
    activityIndex: number,
    search: string,
  ) => {
    this.setState({
      placeIndex,
      index,
      activityIndex,
      searchText: search,
      isLocationPageOpen: true,
    });
  };

  closeItineraryLocationPage = () => {
    this.setState({
      isLocationPageOpen: false,
    });
  };
  getBookedMarkData = async (itinerary: any) => {
   if (itinerary && Object.keys(itinerary)?.length && !this.props.isUnAuth) {
      this.setState({ ...itinerary }, async () => {
        // await this.getBookedMarkData(itinerary);
        if (itinerary?.historyTab === "bookmark") {
          const doc = await document.getElementById('location_details_id');
          await doc?.click();
          const doc1 = await document.getElementById("bookmark_history_id");
          await doc1?.click();
        }

      });
      removeStorageData('ItineraryData');
    }
   
  }
}

// Customizable Area End
