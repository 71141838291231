import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

import * as Yup from "yup";
import DataContext from "../../../components/src/DataContext";
import moment from "moment";
//@ts-ignore
import { getNotficationPermission } from '../../../web/src/firebase'
import { apiCall } from "../../../components/src/utils.web";
import { dashboard, Screen2, Screen3 } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  name: string;
  email: string;
  password: string;
  mobileNumber: string;
  gender: string;
  dob: string;
  state: any[];
  city: string;
  term: boolean;
  checkPassword: boolean;
  typezz: string | null;
  errorMsg: string | null;
  error: boolean;
  accessToken: string | null;
  createOtpType: string;
  isLoading: boolean;
  fcmToken: string;
  username: string;
  isExistUser: string;
  imgData: {id:number; img: ''; data: string;}[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationControllerBlock extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getregistrationApiCallID: string = "";
  createEmailAccountId: string = "";
  otpcreateId: string = "";
  otpVerifyId: string = "";
  otpResendId: string = "";
  isUsernameExistId: string = "";
  editUserDetailApiCallId: string = "";
  timerId: NodeJS.Timeout | null = null;

  static contextType: React.Context<string> = DataContext;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      name: "",
      email: "",
      username: "",
      password: "",
      mobileNumber: "",
      gender: "",
      dob: "",
      state: [],
      city: "",
      term: true,
      checkPassword: false,
      typezz: localStorage.getItem("path") || "email_account",
      errorMsg: "",
      error: false,
      accessToken: 'token',
      createOtpType: "",
      isLoading: false,
      fcmToken: "",
      isExistUser: "",
      imgData: [
        {
          id: 1,
          img: dashboard,
          data:'head1title'
        },
        {
          id: 2,
          img: Screen2,
          data: 'head1title'
        },
        {
          id: 3,
          img: Screen3,
          data: 'head1title'
        },
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
    const accessToken = await getStorageData('token');
    this.setState({accessToken})
    getNotficationPermission().then((fcmToken: any) => this.setState({ fcmToken }))
  }

  otpcreate = () => {
    this.setState({
      isLoading: true,
    });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    let data = {};
    let typecheck = "";
    if (this.state.typezz === "email_account") {
      data = {
        data: {
          attributes: {
            email: this.state.email,
          },
        },
      };
      typecheck = configJSON.create_email_otp;
    } else {
      data = {
        data: {
          attributes: {
            full_phone_number: this.state.mobileNumber,
          },
        },
      };
      typecheck = configJSON.create_sms_otp;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpcreateId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      typecheck
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.createEmailAccountId) {
        if (responseJson.data) {
          localStorage.setItem(
            "user_details",
            JSON.stringify(responseJson.data)
          );
          this.createEmailAccountId = "";
          this.otpcreate();
        } else {
          this.setState({
            errorMsg: responseJson.errors[0].account,
            error: true,
          });
        }
      }

      if (apiRequestCallId === this.otpcreateId) {
        if (responseJson.data) {
          this.saveLoggedInUserData(responseJson);
          this.context.setData({
            email: this.state.email,
            number: this.state.mobileNumber,
          });

          this.props.navigation.navigate("OTPSignUpBlock");
        } else {
          this.setState({ error: true });
        }
      } else if (apiRequestCallId === this.isUsernameExistId) {
        if (responseJson && responseJson.message === configJSON.isUserNameValid) {
          this.setState({ isExistUser: "" })
        } else if (!responseJson) {
          this.setState({ isExistUser: "" })
        } else if (apiRequestCallId && responseJson && responseJson.errors){
          this.handleError(responseJson.errors);
        }
      }
      this.setState({
        isLoading: false,
      });
    }
  }

  saveLoggedInUserData = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      setStorageData('token', responseJson.meta.token)
      this.setState({accessToken:responseJson.meta.token})
    }
  };


  navigationFunctuon() {
    this.props.navigation.goBack();
  }

  handleClickShowPassword() {
    this.setState({ checkPassword: !this.state.checkPassword });
  }

  validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    password: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required")
      .matches(configJSON.vali, "Please enter valid password"),
    gender: Yup.string().required("Gender is required"),
    mobileNumber: Yup.string()
      .min(10, "Phone number is required minimum 10 digits")
      .max(10, "Phone number is required maximum 10 digits")
      .required("Phone number is required"),
    name: Yup.string().required("Name is required"),
    username: Yup.string().required(configJSON.userNameValidationTitle),
    term: Yup.string().required("Term is required"),
    dob: Yup.date()
      .max(
        moment()
          .add(-1, "days")
          .toDate(),
        "Date must be before of today date"
      )
      .required("Date of birth is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
  });

  createAccountByEmail() {
    this.setState({
      isLoading: true,
    });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const attrs = {
      full_name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: this.state.mobileNumber,
      gender: this.state.gender,
      username: this.state.username,
      date_of_birth: this.state.dob,
      state: this.state.state,
      city: this.state.city,
      terms_and_conditions: this.state.term,
      device_id: this.state.fcmToken
    };

    const data = {
      data: {
        type: this.state.typezz,
        attributes: attrs,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createEmailAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acoount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  SignUpByEmail(value: any) {
    if (!this.state.isExistUser) {
      this.setState({
        name: value.name,
        email: value.email,
        username: value.username,
        password: value.password,
        mobileNumber: value.mobileNumber,
        gender: value.gender,
        dob: value.dob,
        state: value.state,
        city: value.city,
        term: value.term,
      });
      if (this.state.term) {
        this.createAccountByEmail();
      }
    }
  }
  
  otpVerify = (values: string) => {
    this.setState({
      isLoading: true,
    });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.accessToken,
    };
    const data = {
      data: {
        attributes: {
          pin: values,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpVerifyId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/verify_email_otp"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // this.props.navigation.navigate("NewPasswordBlock")
  };

  // OTPSignUp(values: {one:string , two:string ,three: string , four :string}) {
  //   const data = `${values.one}${values.two} ${values.three} ${values.four}`
  //   this.otpVerify(data)
  // }


  isUserNameExist = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.setState({ username: event.target.value });
    this.timerId = setTimeout(this.handleunserNameExist, 2000) as unknown as NodeJS.Timeout;
  }

  handleunserNameExist = async () => {
    this.isUsernameExistId = await apiCall({
      endPoint: configJSON.uniqueUsernameExist,
      method: configJSON.apiMethodTypeAddDetail,
      contentType: configJSON.validationApiContentType,
      body: JSON.stringify({ "username": this.state.username })
    });
  }

  handleError = (errors: any) => {
    if (Array.isArray(errors)) {
      if (errors[0].account) {
        this.setState({ isExistUser: errors[0].account });
        return false;
      }
    }
  };

  resendOtp() {
    this.setState({
      isLoading: true,
    });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    let data = {};
    if (this.state.typezz === "email_account") {
      data = {
        data: {
          attributes: {
            email: this.state.email,
          },
        },
      };
    } else {
      data = {
        data: {
          attributes: {
            full_phone_number: this.state.mobileNumber,
          },
        },
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpResendId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/resend_otp"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
