import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ChatBubbleIcon from "@material-ui/icons/ChatBubbleOutline";
import NotificationIcon from "@material-ui/icons/NotificationsActiveOutlined";
import { Link } from "react-router-dom";
import ChatWithNotificationController, {
  Props,
} from "./ChatWithNotificationController.web";
import { NotificationStyle } from "./NotificationStyle.web";
import { dummyProfile } from "./assets";
import Notification from "./Notification.web";
import { Chat } from "./Chat.web";
import BackButton from "../../../components/src/BackButton.web";
import ChatDetails from "../../postcreation/src/ChatDetails.web";
import DataContext from "../../../components/src/DataContext";
// Customizable Area End

export class ChatWithNotification extends ChatWithNotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.fetchUnreadBadge();
  }

  resetChatId = () => {
    this.setState({ chatId: NaN }, () => this.fetchUnreadBadge());
  };
  gotToChatDetails = (chatId: number, user: any) => {
    this.setState({ chatId, currentUser: user });
  };

  renderComponent = () => {
    const { activeTab, chatId, currentUser } = this.state;
    if (activeTab && chatId) {
      return (
        <ChatDetails
          userDetails={currentUser}
          navigation={this.props.navigation}
          chatId={this.state.chatId}
          resetChatId={this.resetChatId}
          id="UserChatDetails"
        />
      );
    } else if (activeTab) {
      return (
        <Chat
          navigation={undefined}
          id={""}
          userDetail={undefined}
          gotToChatDetails={this.gotToChatDetails}
          reloadChatList={this.props.reloadChatList}
          fetchNotficationBadge={this.fetchUnreadBadge}
        />
      );
    } else {
      return (
        <Notification
          notificationData={this.props.notificationData}
          setNotificationData={this.props.setNotificationData}
          navigation={this.props.navigation}
          id={""}
          userDetail={this.props.userDetail}
          gotToChatDetails={undefined}
        />
      );
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, userDetail } = this.props;
    const { activeTab, chat_notification_badge } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={classes.mainContainer}>
        {this.state.chatId ? (
          <Box onClick={this.resetChatId} className="header-row">
            <div style={{ width: "100%" }}>
              <BackButton />
            </div>
          </Box>
        ) : (
          <Box className="header-row">
            <span
              style={{ background: activeTab ? "#25aae1" : "white" }}
              className="header-item"
              onClick={this.handleTab.bind(this,true)}
            >
              <ChatBubbleIcon
                className={activeTab ? "active-icon-img" : "icon-img"}
              />
              {chat_notification_badge.unreaded_chats && <Badge />}
            </span>
            <span
              style={{ background: !activeTab ? "#25aae1" : "white" }}
              className="header-item"
              onClick={this.handleTab.bind(this,false)}
            >
              <NotificationIcon
                className={!activeTab ? "active-icon-img" : "icon-img"}
              />
              {chat_notification_badge.unreaded_notification && <Badge />}
            </span>
            <span className="header-item">
              <Link to={`/profile/${userDetail?.id}`}>
                <img
                  src={userDetail?.attributes?.photo ?? dummyProfile}
                  className="profile-img"
                />
              </Link>
            </span>
          </Box>
        )}
        {this.renderComponent()}
      </Box>
      // Customizable Area End
    );
  }
}
//@ts-ignore
export const WrappedChatWithNotification= withStyles(NotificationStyle)(ChatWithNotification);
// Customizable Area Start
export default (props: any) => (
  <DataContext.Consumer>
      {({ notificationData, setNotificationData }: any) => {
          return (
              <WrappedChatWithNotification
                  {...props}
                  notificationData={notificationData}
                  setNotificationData={setNotificationData}
              />
          );
      }}
  </DataContext.Consumer>
);
export const Badge = () => (
  <span
    style={{
      width: "8px",
      height: "8px",
      background: "#FF3737",
      position: "absolute",
      top: -2,
      right: -2,
      borderRadius: "50%",
    }}
  />
);

// Customizable Area End
