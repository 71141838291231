// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import CarouselDisplay from '../../blocks/carouseldisplay/src/CarouselDisplay';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import BlogPostsManagement from '../../blocks/BlogPostsManagement/src/BlogPostsManagement';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import Maps from '../../blocks/maps/src/Maps';
import Testimonials from '../../blocks/Testimonials/src/Testimonials';
import AdvancedSearch from '../../blocks/advancedsearch/src/SearchUserAndPost.web';
import AdminConsole2 from '../../blocks/AdminConsole2/src/AdminConsole2';
import Customform from '../../blocks/customform/src/Customform';
import ForgotPasswordBlock from '../../blocks/forgot-password/src/ForgotPasswordBlock.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import SplashScreen from '../../blocks/email-account-login/src/SplashScreen.web';
import NewPasswordBlock from '../../blocks/forgot-password/src/NewPasswordBlock.web';
import ForgotPasswordOTPBlock from '../../blocks/forgot-password/src/ForgotPasswordOTPBlocks.web';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import PrivateChat from '../../blocks/PrivateChat/src/PrivateChat';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import Followers from '../../blocks/Followers/src/Followers';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import EmailAccountRegistrationByEmail from '../../blocks/email-account-registration/src/EmailAccountRegistrationByEmail.web';
import Hashtags from '../../blocks/Hashtags/src/Hashtags';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginNew';
import YoutubeIntegration2 from '../../blocks/YoutubeIntegration2/src/YoutubeIntegration2';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import LandingPageBlock from '../../blocks/landingpage/src/LandingPageBlock.web';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import PostList from '../../blocks/FanWall/src/FanWall';
import Feed from '../../blocks/FanWall/src/Feed.web';
import ContentFlag from '../../blocks/ContentFlag/src/ContentFlag';
import Reviews from '../../blocks/reviews/src/Reviews';
import AddReview from '../../blocks/reviews/src/AddReview';
import Videos from '../../blocks/videos/src/Videos';
import Gallery from '../../blocks/Gallery/src/Gallery';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import Notifications from '../../blocks/notifications/src/Notifications';
import ServiceSpecificSettingsAdmin from '../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin';
import ProductQuickview from '../../blocks/ProductQuickview/src/ProductQuickview';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import ProductRecommendationEngine from '../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine';
import Chat from '../../blocks/chat/src/Chat';
import Bookmark2 from '../../blocks/Bookmark2/src/Bookmark2.web';
import Upvotedownvote from '../../blocks/Upvotedownvote/src/Upvotedownvote';

import OnboardingScreenBlock from '../../blocks/email-account-login/src/OnboardingScreenBlock';
import OTPSignUpBlock from '../../blocks/email-account-registration/src/OTPSignUpBlock.web';
import MoreSteps from '../../blocks/email-account-registration/src/MoreSteps.web';
import DataContext from '../../components/src/DataContext';
import PostCreate from '../../blocks/postcreation/src/PostCreate.web';
import EditUserProfile from '../../blocks/user-profile-basic/src/EditUserProfile.web';
import Explore from '../../blocks/Bookmark2/src/explore/Explore.web';
import Places from '../../blocks/Bookmark2/src/Places.web';
import ExploreDetails from '../../blocks/Bookmark2/src/explore-details/ExploreDetails.web';
import Hotel from '../../blocks/Bookmark2/src/hotel/Hotel.web';
import HotelBookMark from '../../blocks/Bookmark2/src/hotel-bookmark/HotelBookMark.web';
import HotelRating from '../../blocks/Bookmark2/src/hotel-rating/HotelRating.web';
import HotelDetails from '../../blocks/Bookmark2/src/hotel-details/HotelDetails.web';
import { ToastContainer, toast } from 'react-toastify';
import UserProfile from '../../blocks/user-profile-basic/src/UserProfile.web';
import DiscussionForum from '../../blocks/customform/src/DiscusstionForumList.web';
import ItineraryCreate from '../../blocks/postcreation/src/ItineraryCreate.web';
import ItinerarySection from '../../blocks/postcreation/src/ItinerarySection.web';
import ItineraryView from '../../blocks/postcreation/src/ViewItinerary.web';
import LandingHomePage from '../../blocks/landingpage/src/LandingPageHome.web';
import FanWall from '../../blocks/FanWall/src/FanWallHome.web'
import LandingPagePP from '../../blocks/landingpage/src/LandingPagePP.web';
import LandingPageTC from '../../blocks/landingpage/src/LandingPageTC.web';
import LandingPageAbout from '../../blocks/landingpage/src/LandingPageAbout.web';
import 'react-toastify/dist/ReactToastify.css';
import './assets/style/style.css';
import { firebaseAPI } from './firebase';


const routeMap = {
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  PostList: {
    component: PostList,
    path: '/PostList/:id?',
    isSideMenu: true,
    isProtected: true,
  },
  Feed: {
    component: Feed,
    path: '/Feed',
  },
  PostCreate: {
    component: PostCreate,
    path: '/post-create/:id?',
    exact: true,
    isSideMenu: true,
    isProtected: true,
  },
  ItineraryCreate: {
    component: ItineraryCreate,
    path: '/itinerary-create',
    exact: true,
    isSideMenu: true,
    isProtected: true,
  },
  ItineraryView: {
    component: ItineraryView,
    path: '/iteinerary/:id',
    exact: true,
    isSideMenu: true,
    isProtected: true,
  },
  ItineraryEdit: {
    component: ItineraryCreate,
    path: '/edit-iteinerary/:id',
    exact: true,
    isSideMenu: true,
    isProtected: true,
  },
  EditUserProfile: {
    component: EditUserProfile,
    path: '/edit-profile',
    exact: true,
    isSideMenu: true,
    isProtected: true,
  },
  Explore: {
    component: Explore,
    path: '/explore',
    exact: true,
    isSideMenu: true,
    isProtected: true
  },
  Places: {
    component: Places,
    path: '/Places',
    exact: true,
  },
  ItinerarySection: {
    component: ItinerarySection,
    path: '/itinerarysection',
    exact: true,
  },
  ExploreDetails: {
    component: ExploreDetails,
    path: '/explore/:id',
    exact: true,
    isSideMenu: true,
    isProtected: true,
  },
  Hotel: {
    component: Hotel,
    path: '/explore/:id/:categoryId',
    exact: true,
    isSideMenu: true,
    isProtected: true,
  },
  HotelDetails: {
    component: HotelDetails,
    path: '/explore/:id/:name/:hotelId',
    exact: true,
    isSideMenu: true,
    isProtected: true,
  },
  HotelBookMark: {
    component: HotelBookMark,
    path: '/:hotelId/bookmark/:bookmarkId?',
    exact: true,
    isSideMenu: true,
    isProtected: true,
  },
  HotelRating: {
    component: HotelRating,
    path: '/:hotelId/rating/:ratingId?',
    exact: true,
    isSideMenu: true,
    isProtected: true,
  },
  ContentFlag: {
    component: ContentFlag,
    path: '/ContentFlag'
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews'
  },
  AddReview: {
    component: AddReview,
    path: '/AddReview'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  Gallery: {
    component: Gallery,
    path: '/Gallery'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  ServiceSpecificSettingsAdmin: {
    component: ServiceSpecificSettingsAdmin,
    path: '/ServiceSpecificSettingsAdmin'
  },
  ProductQuickview: {
    component: ProductQuickview,
    path: '/ProductQuickview'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  ProductRecommendationEngine: {
    component: ProductRecommendationEngine,
    path: '/ProductRecommendationEngine'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  Bookmark2: {
    component: Bookmark2,
    path: '/Bookmark2/:id/:bookMarkId?',
    isSideMenu: true,
    isProtected: true,
  },
  Upvotedownvote: {
    component: Upvotedownvote,
    path: '/Upvotedownvote'
  },

  CarouselDisplay: {
    component: CarouselDisplay,
    path: '/CarouselDisplay'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  BlogPostsManagement: {
    component: BlogPostsManagement,
    path: '/BlogPostsManagement'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Maps: {
    component: Maps,
    path: '/Maps'
  },
  Testimonials: {
    component: Testimonials,
    path: '/Testimonials'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch',
    isSideMenu: true,
    isProtected: true,
  },
  ForgotPasswordOTPBlock: {
    component: ForgotPasswordOTPBlock,
    path: '/ForgotPasswordOTPBlock'
  },
  OTPSignUpBlock: {
    component: OTPSignUpBlock,
    path: '/OTPSignUpBlock'
  },
  MoreSteps: {
    component: MoreSteps,
    path: '/MoreSteps'
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: '/AdminConsole2'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  ForgotPasswordBlock: {
    component: ForgotPasswordBlock,
    path: '/ForgotPasswordBlock'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPasswordBlock: {
    component: NewPasswordBlock,
    path: '/NewPasswordBlock'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5',
    isSideMenu: true,
    isProtected: true,
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  UserProfile: {
    component: UserProfile,
    path: '/Profile/:id',
    isSideMenu: true,
    isProtected: true,
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  PrivateChat: {
    component: PrivateChat,
    path: '/PrivateChat'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  Followers: {
    component: Followers,
    path: '/Followers'
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  EmailAccountRegistrationByEmail: {
    component: EmailAccountRegistrationByEmail,
    path: '/EmailAccountRegistrationByEmail'
  },
  Hashtags: {
    component: Hashtags,
    path: '/Hashtags'
  },
  YoutubeIntegration2: {
    component: YoutubeIntegration2,
    path: '/YoutubeIntegration2'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  // LandingPageBlock: {
  //   component: LandingPageBlock,
  //   path: '/LandingPageBlock',
  // },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  OnboardingScreenBlock: {
    component: OnboardingScreenBlock,
    path: '/OnboardingScreenBlock',
    exact: true
  },
  Home: {
    component: SplashScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  DiscussionForum: {
    component: DiscussionForum,
    path: '/discussion-forum',
    isSideMenu: true,
    isProtected: true
  },
  LandingHomePage: {
    component: LandingHomePage,
    path: '/LandingHomePage',
    exact: true
  },
  FanWall: {
    component: FanWall,
    path: '/FanWall',
    isSideMenu: true,
    isProtected: true,
  },

  PrivacyPolicy: {
    component: LandingPagePP,
    path: '/PrivacyPolicy',
    exact: true
  },
  TermAndCondition: {
    component: LandingPageTC,
    path: '/TermAndCondition',
    exact: true
  },
  AboutUs: {
    component: LandingPageAbout,
    path: '/AboutUs',
    exact: true
  }

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "*/AlertWeb",
  //   modal: true
  // }
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      notificationData: {
        data: [],
        meta: { unread_notifications: 0, total_pages: 1 }
      },
    };
  }

  setNewData(item) {
    this.setState({
      data: item,
    });
  }
  setNotificationList(list) {
    this.setState({ notificationData: list });
  }
  componentDidMount() {
    firebaseAPI.messaging().onMessage((payload) => {
      const response = JSON.parse(payload.data.details);
      if (response?.data?.attributes?.in_app_push_notification) {
        toast.info(payload.notification.body);
      }
      if (payload.data.notify_type === "chat_message") {
        this.setState({
          data: { ...this.state.data, chatdata: response }
        });
      }
      if (response && payload.data.notify_type !== "chat_message") {
        this.setState({
          notificationData: {
            data: [response?.data, ...this.state.notificationData.data],
            meta: { ...this.state.notificationData.meta, unread_notifications: this.state.notificationData.meta.unread_notifications + 1 }
          }
        });
      }

    });
  }
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <DataContext.Provider
        value={{
          data: this.state.data,
          setData: item => this.setNewData(item),
          notificationData: this.state.notificationData,
          setNotificationData: list => this.setNotificationList(list),
        }}
      >
        <View style={{ height: '100vh', width: '100vw' }}>
          {/* <TopNav /> */}
          <ToastContainer autoClose={3000} position="top-right" />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </DataContext.Provider>
    );
  }
}

export default App;
