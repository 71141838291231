// Customizable Area Start
import React, { useRef, useState } from "react";
import {
  Typography,
  styled,
  Dialog,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import * as Yup from "yup";
import EmojiPicker from "emoji-picker-react";

import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";

const validationSchema = Yup.object().shape({
  message: Yup.string()
    .trim()
    .required("Message is required."),
});

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (message: string) => void;
  message: string;
}

const EditChatMessage: React.FC<Props> = ({
  open,
  message,
  onClose,
  onSubmit,
}) => {
  const [isOpenEmoji, setIsOpenEmoji] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef = useRef(null);

  const pickEmoji = (emoji: any, setFieldValue: any, messageValue: any) => {
    const ref = inputRef.current;
    //@ts-ignore
    ref.focus();
    //@ts-ignore
    const start = messageValue.substring(0, ref.selectionStart);
    //@ts-ignore
    const end = messageValue.substring(ref.selectionStart);
    const text = start + emoji.emoji + end;
    setFieldValue("message", text);
    setCursorPosition(start.length + 1);
    //@ts-ignore
    inputRef.current.selectionEnd = start.length + 1;
  };
  return (
    <ChatListingContainer
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      scroll="body"
    >
      <CloseIcon
        onClick={() => onClose()}
        style={{
          color: "#25AAE1",
          position: "absolute",
          top: "40px",
          right: "35px",
          cursor: "pointer",
        }}
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          message: message,
        }}
        validationSchema={validationSchema}
        onSubmit={(value) => {
          onSubmit(value.message);
          onClose();
        }}
      >
        {({ errors, values, handleSubmit, handleChange, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Typography className="dialog-title">Edit Message</Typography>
              <div className="box-shadow-text-field">
                <TextField
                  variant="outlined"
                  className="input-field"
                  fullWidth
                  data-test-id="bookmark-description-input"
                  multiline
                  minRows={6}
                  name="message"
                  onChange={handleChange}
                  value={values.message}
                  placeholder="Enter your message"
                  inputRef={inputRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box position="relative">
                          <IconButton
                            className="icon-container"
                            onClick={() => {
                              setIsOpenEmoji(!isOpenEmoji);
                            }}
                          >
                            <SentimentSatisfiedOutlinedIcon className="emoji-icon" />
                          </IconButton>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {isOpenEmoji && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    pickEmoji(emoji, setFieldValue, values.message)
                  }
                  autoFocusSearch={false}
                />
              )}
              {errors.message && (
                <p className="error mb-20">{errors.message}</p>
              )}
              <Box
                style={{
                  display: "flex",
                  marginTop: "15px",
                }}
              >
                <Button className="no_button" onClick={onClose}>
                  Discard
                </Button>
                <Button type="submit" className="yes_button">
                  Save
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </ChatListingContainer>
  );
};

export default EditChatMessage;

const ChatListingContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    padding: "40px",
    boxSizing: "border-box",
  },
  "& .dialog-title": {
    fontSize: "32px",
    marginBottom: "20px",
    fontFamily: "Helvetica",
    color: "#000",
  },
  "& .no_button": {
    background: "#FFFFFF",
    color: "#25AAE1",
    marginRight: "30px",
    textTransform: "capitalize",
    borderRadius: "12px",
    border: "1px solid #25AAE1",
    fontSize: "20px",
    padding: "4px 30px",
    fontFamily: "Helvetica",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  "& .yes_button": {
    background: "#25AAE1",
    color: "#FFFFFF",
    boxShadow: "0px 8px 24px 0px #25AAE14D",
    textTransform: "capitalize",
    borderRadius: "12px",
    fontSize: "20px",
    padding: "4px 30px",
    "&:hover": {
      background: "#25AAE1",
    },
  },
  "& .emoji-icon": {
    width: "18px",
    height: "18px",
  },
  "& aside.EmojiPickerReact.epr-main": {
    // position: "absolute",
    // top: "35px",
    // right: "0px",
    width: "100%",
    backgroundColor: "white",
    zIndex: 1,
  },
});
// Customizable Area End
