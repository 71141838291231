// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./../config");
import { debounce } from 'lodash';

export interface Props {
    id: string;
    userDetails:  { id: string, type: string, attributes: { full_name: string, email: string, full_phone_number: string, gender: string, date_of_birth: string, state: string, city: string, activated: boolean, photo: string, is_following: boolean, interests: string[] } };
    navigation: { history: { push: (data: string) => void, replace: (data: string) => void } ,goBack:()=>void},
}
// Customizable Area End
export interface S {
    // Customizable Area Start
    accessToken: string | null,
    followingsList: any,
    serachValue: string,
    followingValue: any,
    followBackData: any,
    isLoader: boolean;
    followingListData: any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class FollowingsController extends BlockComponent<
    Props,
    S,
    SS
> {
    FollowingsGetId: string = "";
    searchFollowingsId: string = "";
    FollowingsCreateId: string = "";
    removeFollowApiCallId: string = "";
    FollowBackId: string = "";
    debouncedSearch: any

    async componentDidMount() {
        super.componentDidMount();
        this.getFollowingList()
    }

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.state = {
            // Customizable Area Start
            accessToken: localStorage.getItem("authToken"),
            followingsList: [],
            serachValue: "",
            followingValue: "",
            followBackData: "",
            isLoader: false,
            followingListData: "",
            // Customizable Area End
        };
        this.debouncedSearch = debounce(this.handleSearchfunction, 800);
    }

    // this funtion call after following list get api by using user id and store responce data in state.
    followingListIdFun = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.FollowingsGetId) {
            if (responseJson) {
                this.setState({ followingsList: responseJson.data.following, followingListData: responseJson.data.following, isLoader: false })
            }
        }
    }

    // this funtions call after following search api get responce and store data in state
    followingSearch = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.searchFollowingsId) {
            if (responseJson) {
                this.setState({ followingsList: responseJson })
            }
        }
    }

    FollowingsCreate = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.removeFollowApiCallId) {
            if (responseJson) {
                this.getFollowingList()
            }
        }
    }

    FollowBackAPICall = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.FollowBackId) {
            if (responseJson.errors) {
                alert(responseJson.errors[0].message)
            } else {
                this.getFollowingList()
            }
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            this.followingListIdFun(from, message)
            this.followingSearch(from, message)
            this.FollowingsCreate(from, message)
            this.FollowBackAPICall(from, message)
        }
    }

    // this funtion working call following user search api by using  user id
    handleSearchfunction = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.searchFollowingsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followingSeachKey}?search_by_following=${this.state.serachValue}&user_id=${this.props.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSearch = () => {
        const data = this.state.followingListData.data?.filter((item: any) => { return item.attributes.full_name.toLowerCase().includes(this.state.serachValue.toLowerCase()) })
        if (this.state.serachValue) {
            this.setState({ followingsList: { data: data } })
        } else {
            this.setState({ followingsList: this.state.followingListData })
        }
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.searchFollowingsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.following_search}=${this.state.serachValue}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getFollowingList = () => {
        this.setState({ isLoader: true })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.FollowingsGetId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followerList}?id=${this.props.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // this funtion call follow back api 
    followBack = () => {
        const headers = {
            "token": this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.FollowBackId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.followeringCreate
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    removeFollowinghandele = (value: any) => {
        const header = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.state.accessToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removeFollowApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.FollowingRemoveFollowing
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({ id: value.following_id })
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DELETE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    followerHandel = () => {
        const headers = {
            "token": this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.FollowBackId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.followeringCreate
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            this.state.followBackData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    followinghandel = (value: any, flag: boolean) => {
        if (value.is_following) {
            this.removeFollowinghandele(value)
        } else {
            const formdata = new FormData();
            formdata.append("follow[following_id]", value.following_id);
            this.setState({ followBackData: formdata }, () => this.followerHandel())
        }
    }

    // this funtions for handle search value and call api using debounced function
    searchHandel = (value: string) => {
        this.setState({ serachValue: value })
        this.debouncedSearch();
    }
}
// Customizable Area End