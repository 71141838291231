import React from 'react'

import { Box, Grid, Typography, Button } from "@material-ui/core";

interface Props {
    title: string;
    data: any;
    buttonLabel: string;
    handelClick: any;
    userDetails?: any;
    id?: any;
    goToProfile: (id: number) => void
    // active: boolean;
    // onHandle: (value: number) => void;
    // activeKey: number;
    // imageSource: any
}
export default function FollowCard({ title, data, buttonLabel, handelClick, userDetails, id, goToProfile }: Props) {

    const followeingButton = (item: any) => {
        if (userDetails.id == item.attributes.following_id) {
            return <>
            </>
        } else {
            return <>
                {item.attributes.is_following ? <Button onClick={() => handelClick(item.attributes)} variant="contained" style={{ background: '#f0f0f0', fontSize: '15px', color: '#000', boxShadow: 'none', textTransform: 'capitalize', fontWeight: 500, marginTop: '15px' }}>
                    {buttonLabel}
                </Button> : <Button onClick={() => handelClick(item.attributes)} variant="contained" style={{ background: '#25aae1', fontSize: '15px', color: '#fff', boxShadow: 'none', textTransform: 'capitalize', fontWeight: 500, marginTop: '15px' }}>
                    Follow
                </Button>}</>
        }
    }
    const handleRedirection=(type:string,attributes:{following_id:number,follower_id:number})=>{
        let id=NaN;
        if(type==="follower"){
             id=attributes.follower_id
        }else{
            id=attributes.following_id
        }
        goToProfile(id)
    }


    const followerButton = (item: any) => {
        if (userDetails.id == item.attributes.follower_id) {
            return <>
            </>
        } else {
            if (userDetails.id === id) {
                return <>
                    <Button onClick={() => handelClick(item.attributes, true)}
                        variant="contained"
                        style={{
                            background: item.attributes.is_follower ? '#f0f0f0' : '#25aae1',
                            fontSize: '15px',
                            color: item.attributes.is_follower ? '#000' : '#fff',
                            boxShadow: 'none',
                            textTransform: 'capitalize',
                            fontWeight: 500,
                            marginTop: '15px'
                        }}>
                        {buttonLabel}
                    </Button>
                    {!item.attributes.is_following && <Button onClick={() => handelClick(item.attributes.follower_id, false)}
                        variant="contained"
                        style={{
                            background: '#25aae1',
                            fontSize: '15px', color: '#fff',
                            boxShadow: 'none',
                            textTransform: 'capitalize',
                            fontWeight: 500,
                            marginTop: '15px',
                            marginLeft: "10px"
                        }}>
                        {"Follow back"}
                    </Button>}
                </>
            } else {
                { console.log("check", item.attributes) }
                return <>
                    {item.attributes.is_following ? <Button onClick={() => handelClick(item.attributes, true,)}
                        variant="contained"
                        style={{
                            background: '#f0f0f0',
                            fontSize: '15px',
                            color: '#000',
                            boxShadow: 'none',
                            textTransform: 'capitalize',
                            fontWeight: 500,
                            marginTop: '15px'
                        }}>
                        UnFollow
                    </Button> : <Button onClick={() => handelClick(item.attributes.follower_id, false)}
                        variant="contained"
                        style={{
                            background: '#25aae1',
                            fontSize: '15px',
                            color: '#fff',
                            boxShadow: 'none',
                            textTransform: 'capitalize',
                            fontWeight: 500,
                            marginTop: '15px',
                            marginLeft: "10px"
                        }}>
                        {item.attributes.is_follower ? "Follow Back" : "Follow"}
                    </Button>}
                </>
            }
        }

    }
    return (
        <>
            {data?.length == 0 ? <p style={{
                width: "100%",
                fontSize: "22px",
                color: '#938f8f',
                marginTop: "60px",
                display: 'inline-grid',
                alignContent: 'center',
                justifyContent: 'center',
                height: "70%"
            }}>{title}</p> : <Box >

                <Grid container spacing={6} style={{ alignItems: 'stretch' }}>
                    <>
                        {
                            data?.map((item: any) => (
                                <Grid item xs={4} style={{ display: 'flex' }}>
                                    <Box style={{ borderRadius: '15px', border: '1px solid #ccc', padding: '60px 20px', textAlign: 'center', background: '#fff', width: '100%' }}>
                                        <img onClick={()=>handleRedirection(item.type,item.attributes)} src={item.attributes.photo} style={{ cursor: "pointer", width: '70px', height: '70px', borderRadius: '50%', marginBottom: '10px' }}></img>
                                        <Typography onClick={()=>handleRedirection(item.type,item.attributes)} variant="h6" style={{ fontWeight: 600, cursor: "pointer" }}>
                                            {item.attributes.full_name}
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom style={{ color: '#938f8f' }}>
                                            @{item.attributes?.username}
                                        </Typography>
                                        {item.type === "following" ? followeingButton(item) : followerButton(item)}
                                    </Box>
                                </Grid>

                            ))
                        }</>
                </Grid>
            </Box>
            }
        </>
    )
}
