import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  userDetail: any;
  notificationData: {
    data: Array<any>;
    meta: { unread_notifications: number; total_pages: number };
  };
  setNotificationData: (list: any) => {};
  reloadChatList: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoader: boolean;
  notificationList: any;
  activeTab: boolean;
  chatHistory: any;
  chatId: any;
  openSearchList: boolean;
  anchorEl: any;
  notificationPage: number;
  currentUser: {
    email: string;
    id: number;
    name: string;
    offline: boolean;
    profile_photo: string;
    unread_messages: number;
  };
  itineraryItemIndex: number;
  token: string;
  chat_notification_badge: {
    unreaded_chats: boolean,
    unreaded_notification: boolean
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatWithNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetNotificationCallID: string = "";
  apiPutReadNotificationCallID: string = "";
  apiPostAcceptDeclineItineraryCallID: string = "";
  apiGetUnreadBadgeCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoader: false,
      notificationList: [],
      activeTab: false,
      chatHistory: [],
      chatId: NaN,
      openSearchList: false,
      anchorEl: null,
      notificationPage: 1,
      currentUser: {
        email: "",
        id: 0,
        name: "",
        offline: false,
        profile_photo: "",
        unread_messages: 0,
      },
      itineraryItemIndex: NaN,
      token: localStorage.getItem("authToken") || "",
      chat_notification_badge: {
        unreaded_chats: false,
        unreaded_notification: false
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.handleErrorResponse(responseJson.errors);
        this.setState({ isLoader: false });
      } else if (responseJson) {
        this.checkApiResponse(responseJson, apiRequestCallId);
      } else {
        this.handleErrorResponse(errorReponse);
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: any, prevState: any) {
    if(prevProps.notificationData?.meta?.unread_notifications !== this.props.notificationData?.meta?.unread_notifications){
      this.fetchUnreadBadge();
    }
  }
  checkApiResponse = (responseJson: any, apiRequestCallId: any) => {
    switch (apiRequestCallId) {
      case this.apiGetNotificationCallID:
        const data = responseJson?.data?.length ? responseJson.data : [];
        if (this.state.notificationPage === 1) {
          this.props.setNotificationData({ data: data, meta: responseJson?.meta });
        } else {
          this.props.setNotificationData({ data: [...this.props.notificationData.data, ...data], meta: responseJson?.meta });
        }
        this.fetchUnreadBadge();
        break;
      case this.apiPutReadNotificationCallID:
        const id = responseJson?.data.id;
        const index = this.props.notificationData.data.findIndex(
          (item: any) => item?.id == id
        );
        const tempData = this.props.notificationData.data
        tempData[index] = responseJson?.data;
        this.props.setNotificationData({ data: tempData, meta: { ...this.props.notificationData.meta, unread_notifications: this.props.notificationData.meta.unread_notifications - 1 } });
        this.fetchUnreadBadge();
        break;
      case this.apiPostAcceptDeclineItineraryCallID:
        this.props.notificationData.data.splice(this.state.itineraryItemIndex, 1)
        this.props.setNotificationData({ data: this.props.notificationData.data, meta: this.props.notificationData.meta });
        this.setState({ itineraryItemIndex: NaN });
        this.fetchUnreadBadge();
        break;
      case this.apiGetUnreadBadgeCallID:
        this.setState({ chat_notification_badge: responseJson });
        break;
      default:
        break;
    }
  };

  fetchNotification = (page: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetNotificationCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationEndpoint + `page=${page}&per_page=20`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true });
    return true;
  };
  readNotification = (id: string | number) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const body = {
      data: {
        attributes: {
          is_read: true,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPutReadNotificationCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.readNotificationEndpoint + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleTab = (active_tab:boolean) => {
    this.setState({ activeTab: active_tab });
  };
  handleNextPageNotification = () => {

    this.fetchNotification(this.state.notificationPage + 1)
    this.setState({ notificationPage: this.state.notificationPage + 1 })
  }
  onAcceptItinerary = (index: number, id: number, type: string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const attributes = {
      itinerary_id: id,
      invite: type
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostAcceptDeclineItineraryCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AcceptOrDeclineItineraryEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postDataMethod
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attributes)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ itineraryItemIndex: index })
    return true

  }

  handleErrorResponse = (errors: any) => {
    this.setState({ isLoader: false });
    this.parseApiErrorResponse(errors);
    this.handleError1(errors);
    this.parseApiCatchErrorResponse(errors);

  };
  handleError1 = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].token === configJSON.invalidToken ||
        error[0].token === configJSON.tokenExpire
      ) {
        window.localStorage.removeItem("authToken");
        toast.error(`${error[0].token}, Please login again.`);
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      } else if (error[0]) {
        toast.error(error[0]);

        this.props.navigation.goBack();
        return;
      }
    }
    toast.error("Something went to wrong please retry");
  };
  fetchUnreadBadge = () => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUnreadBadgeCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unreadBadgeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
