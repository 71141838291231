// Customizable Area Start
import React from "react";

import {
  withStyles,
  Button,
  Box,
  Typography,
  Container,
} from "@material-ui/core";

import HotelDetailsController, { Props } from "./HotelDetailsController.web";
import { HotelDetailsStyles } from "./HotelDetails.styles.web";
import Loader from "../../../../components/src/Loader.web";
import BackButton from "../../../../components/src/BackButton.web";
import StarIcon from "@material-ui/icons/Star";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { bookMarkImage } from "../assets";
import GoogleMapWeb from "../../../../components/src/GoogleMapWeb.web";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

class HotelDetails extends HotelDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.HotelDetails}>
          <Container maxWidth="lg" className="container">
            <BackButton navigation={this.props.navigation} />
            {!this.state.isLoading && (
              <div className="white-box">
                {this.state.imageUrl.length > 0 && (
                  <div
                    className="location-image-container"
                    key={`${
                      this.state.imageUrl[this.state.currentImageIndex].filename
                    } ${this.state.imageUrl[this.state.currentImageIndex].id}`}
                  >
                    <div className="carousal-button left">
                      <button onClick={this.goToPreviousImageInDetails}>
                        <ChevronLeftIcon />
                      </button>
                    </div>
                    <img
                      src={
                        this.state.imageUrl[this.state.currentImageIndex].url
                      }
                      className="hotel-images"
                      alt="location-image"
                    />
                    <div className="image-count-container">
                      <label>
                        {this.state.currentImageIndex + 1}/
                        {this.state.imageUrl.length}
                      </label>
                    </div>
                    <div className="carousal-button right">
                      <button onClick={this.goToNextImageInDetails}>
                        <ChevronRightIcon />
                      </button>
                    </div>
                  </div>
                )}
                <div>
                  <div className="name-text-container mt-10">
                    <div
                      className="name-text-container"
                      style={{ justifyContent: "unset" }}
                    >
                      <Typography
                        className="hotel-name"
                        title={this.state.name}
                      >
                        {this.state.name}
                      </Typography>
                      <Typography className="ratings">
                        <StarIcon className="rating-star" />
                        {this.state.rating}/5
                      </Typography>
                    </div>
                    <img
                      src={bookMarkImage}
                      onClick={this.redirectToBookmarkPages}
                      className="book-mark-icon"
                    />
                  </div>
                  <div className="name-text-container">
                    <div className="hotel-location">
                      <LocationOnIcon className="location-icon" />{" "}
                      <Typography
                        className="hotel-location-text"
                        title={this.state.location}
                      >
                        {this.state.location}
                      </Typography>
                    </div>
                    <Typography className="total-reviews">
                      ({this.state.reviews_count} reviews)
                    </Typography>
                  </div>
                </div>
                <div
                  className="city-description"
                  dangerouslySetInnerHTML={{
                    __html: this.state.tag_line,
                  }}
                />
                <div
                  className="city-description"
                  dangerouslySetInnerHTML={{
                    __html: this.state.description,
                  }}
                />
                <Typography className="direction-name">
                  Get Directions
                </Typography>
                <div className="google-maps">
                  {
                    <GoogleMapWeb
                      //@ts-ignore
                      lat={this.state.direction.lat}
                      lng={this.state.direction.lng}
                    />
                  }
                </div>
                <Typography className="direction-name">
                  Why book this room
                </Typography>
                <div
                  className="city-description"
                  dangerouslySetInnerHTML={{
                    __html: this.state.why_book_this,
                  }}
                />
                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    onClick={this.redirectToRatingPages}
                    className="view-rating-button"
                  >
                    View Review
                  </Button>
                </div>
              </div>
            )}
          </Container>
        </Box>
      </>
    );
  }
}
//@ts-ignore
export default withStyles(HotelDetailsStyles)(HotelDetails);
// Customizable Area End
