// Customizable Area Start
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
//@ts-ignore
import { debounce } from "lodash";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  SearchBar: {
    position: "relative",
    "& .search-image": {
      color: "#8f92a1",
      position: "absolute",
      left: "10px",
      top: "0",
      margin: "auto 0",
      bottom: "0",
    },
    "& .search-input-text": {
      color: "#8f92a1",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      padding: "20px 40px 20px 40px",
      width: "100%",
      borderRadius: "16px",
      border: "0.5px solid rgba(105,134,216,0.1)",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 2px 6px rgba(165,176,194,0.15)",
      outline: "none",
    },
    "& .cancel-image": {
      color: "#8f92a1",
      position: "absolute",
      right: "10px",
      top: "0",
      margin: "auto 0",
      cursor: "pointer",
      bottom: "0",
    },
  },
}));

interface Props {
  searchText: string;
  onApiCall: (value: string) => void;
  placeHolder?:string;
}

const SearchBar: React.FC<Props> = ({ searchText, onApiCall,placeHolder }) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(searchText);
  }, [searchText]);

  const sendRequest = useCallback((value: string) => {
    onApiCall(value.trim());
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 1000);
  }, [sendRequest]);

  const onChange = (e: any) => {
    const value = e.target.value;
    setSearch(value);
    debouncedSendRequest(value);
  };

  const cancelSearch = () => {
    setSearch("");
    sendRequest("");
  };

  return (
    <Box className={classes?.SearchBar}>
      <SearchIcon className="search-image" />
      <input className="search-input-text" placeholder={placeHolder} onChange={onChange} value={search} />
      {search && <CloseIcon className="cancel-image" onClick={cancelSearch} />}
    </Box>
  );
};

export default SearchBar;
// Customizable Area End
