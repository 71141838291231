// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import DataContext from "../../../components/src/DataContext";
import { apiCall } from "../../../components/src/utils.web";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  backToPreviousPage: () => void;
  addLocationItems: (
    placeIndex: number,
    index: number,
    activityIndex: number,
    value: string
  ) => void;
  placesIndexInformation: {
    placeIndex: number;
    index: number;
    activityIndex: number;
  };
  data: any;
  isBookmarked: boolean;
  searchText: string;
}

export interface IExploreLocation {
  id: string;
  type: string;
  attributes: {
    id: number;
    account_id: number;
    bookmarkable_id: number;
    bookmarkable_type: string;
    title: string;
    description: string;
    bookmarked_item: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          city_name: string;
          location_images: {
            id: number;
            filename: string;
            type: string;
            url: string;
          }[];
          description: string;
          latitude: number;
          longitude: number;
          categories: {
            data: {
              id: string;
              type: string;
              attributes: {
                id: number;
                name: string;
                category_cover_image: string;
              };
            }[];
          };
        };
      };
    };
  };
}

export interface IPost {
  id: string;
  type: string;
  attributes: {
    id: number;
    account_id: number;
    bookmarkable_id: number;
    bookmarkable_type: string;
    title: string;
    description: string;
    bookmarked_item: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          description: string;
          location: string;
          state: string;
          account_id: number;
          admin_user_id: string;
          created_at: string;
          uploader_name: string;
          uploader_image: string;
          comments: number;
          images_and_videos: {
            id: number;
            filename: string;
            type: string;
            url: string;
          }[];
        };
      };
    };
  };
}

export interface IExploreItems {
  id: string;
  type: string;
  attributes: {
    id: number;
    account_id: number;
    bookmarkable_id: number;
    bookmarkable_type: string;
    title: string;
    description: string;
    bookmarked_item: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          item_cover_image: string;
          name: string;
          location: string;
          tag_line: string;
          reviews_count: number;
          rating: number;
          explore_location_id: number;
          explore_category_id: number;
        };
      };
    };
  };
}

interface S {
  activeTab: "search-location" | "bookmarked";
  isLoading: boolean;
  searchLocationData: {
    name: string;
    formatted_address: string;
    icon: string;
    photos: { photo_reference: string }[];
  }[];
  searchText: string;
  page: number;
  limit: number;
  total_page: number;
  hasMore: boolean;
  searchBookmarkedData: (IExploreItems | IExploreLocation | IPost)[];
  bookMarkId: string;
  region: string;
}

interface SS {
  id: any;
}

export default class ItineraryBookmarkController extends BlockComponent<
  Props,
  S,
  SS
> {
  locationSearchApiCallId: string = "";
  locationBookMarkSearchApiCallId: string = "";
  deleteBookMarkApiCallId: string = "";
  static contextType: React.Context<string> = DataContext;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      activeTab: "search-location",
      isLoading: false,
      searchLocationData: [],
      searchText: "",
      page: 1,
      total_page: 1,
      hasMore: false,
      limit: 10,
      searchBookmarkedData: [],
      bookMarkId: "",
      region: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {    
    this.setState(
      {
        isLoading: true,
        searchText: this.props.searchText,
      },
      async () => {
        await this.searchLocationApiCall();
      }
    );
  }

  setSearchValue = async (value: string) => {
    this.setState(
      {
        searchText: value,
        hasMore: false,
        page: 1,
        isLoading: true,
      },
      async () => {
        if (this.state.activeTab === "search-location") {
          await this.searchLocationApiCall();
        } else {
          await this.searchBookmarkedLocationApiCall();
        }
      }
    );
  };

  searchLocation = async () => {
    this.locationSearchApiCallId = await apiCall({
      endPoint: `bx_block_content_management/bookmarks/search_address?query=${this.state.searchText}&region=${this.state.region}`,
      method: "GET",
      contentType: "application/json",
    });
  };

  searchLocationApiCall = async () => {
    fetch("https://ipapi.co/json/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response: any) => response.json())
      .then((result: any) => {
        this.setState(
          {
            region: result.country_code,
          },
          () => {
            this.searchLocation();
          }
        );
      })
      .catch((error: any) => {
        console.log("error", error);
        this.setState(
          {
            region: "IN",
          },
          () => {
            this.searchLocation();
          }
        );
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  searchBookmarkedLocationApiCall = async () => {
    const lat = this.props.data.itinerary.addplaces[this.props.data.placeIndex].lat
    const lng = this.props.data.itinerary.addplaces[this.props.data.placeIndex].lng
    this.locationBookMarkSearchApiCallId = await apiCall({
      endPoint: `bx_block_content_management/bookmarks/recommend_nearby_bookmarks?latitude=${lat}&longitude=${lng}&page=${this.state.page}&per_page=${this.state.limit}`,
      method: "GET",
      contentType: "application/json",
    });
  };

  deleteBookMarkedItem = async (id: string) => {
    this.setState(
      {
        bookMarkId: id,
        isLoading: true,
      },
      async () => {
        this.deleteBookMarkApiCallId = await apiCall({
          endPoint: `bx_block_content_management/bookmarks/${id}`,
          method: "DELETE",
        });
      }
    );
  };

  fetchMoreDataBookMark = async () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.searchBookmarkedLocationApiCall();
      }
    );
  };

  redirectToGoBackPage = (errors: any) => {
    toast.error(errors[0]);
    this.props.navigation.history.goBack();
    return;
  };

  handleErrorBookMark = (errors: any) => {
    if (Array.isArray(errors)) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}, Please login again.`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      } else if (errors[0]) {
        this.redirectToGoBackPage(errors);
      }
    } else toast.error("Something went to wrong please retry");
  };

  removeBookMarkedItem = () => {
    console.log("Remove bookmarked call");
    this.setState({
      searchBookmarkedData: this.state.searchBookmarkedData.filter(
        (item) => item.id != this.state.bookMarkId
      ),
    });
  };

  redirectToPostBookMarkPage = (bookmarkable_id: number, id: string) => {
    if (this.context && Object.keys(this.context).length !== 0) {
      this.context.setData({
        test: { ...this.props.data, isBookmarked: true },
      });
    }
    this.props.navigation.history.push(`/Bookmark2/${bookmarkable_id}/${id}`);
  };

  redirectToExploreLocationBookMarkPage = (
    bookmarkable_id: number,
    id: string
  ) => {
    if (this.context && Object.keys(this.context).length !== 0) {
      this.context.setData({
        test: { ...this.props.data, isBookmarked: true },
      });
    }
    this.props.navigation.history.push(`/${bookmarkable_id}/bookmark/${id}`, {
      isLocation: true,
    });
  };

  redirectToExploreItemsBookMarkPage = (
    bookmarkable_id: number,
    id: string
  ) => {
    if (this.context && Object.keys(this.context).length !== 0) {
      this.context.setData({
        test: { ...this.props.data, isBookmarked: true },
      });
    }
    this.props.navigation.history.push(`/${bookmarkable_id}/bookmark/${id}`);
  };

  addLocationItem = (cityName: string) => {
    this.props.addLocationItems(
      this.props.placesIndexInformation.placeIndex,
      this.props.placesIndexInformation.index,
      this.props.placesIndexInformation.activityIndex,
      cityName
    );
    this.props.backToPreviousPage();
  };

  handleSearchLocationResponseHandle = (responseJson: any) => {
    console.log("Response come");
    if (responseJson) {
      this.setState({
        searchLocationData: responseJson?.results ?? {},
      });
    }
  };

  handleResponseItineraryLocation = async (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.locationSearchApiCallId) {
      this.handleSearchLocationResponseHandle(responseJson);
    } else if (apiRequestCallId === this.locationBookMarkSearchApiCallId) {
      if (responseJson.data) {
        const total_pages = responseJson.meta.total_pages;
        const hasMore =
          this.state.page < responseJson.meta.total_pages ? true : false;

        const data =
          this.state.page === 1
            ? [...responseJson.data]
            : [...this.state.searchBookmarkedData, ...responseJson.data];
        this.setState({
          searchBookmarkedData: data,
          hasMore,
          total_page: total_pages,
        });
      } else {
        this.setState({
          searchBookmarkedData: [],
        });
      }
    } else if (apiRequestCallId === this.deleteBookMarkApiCallId) {
      if (responseJson.message) {
        toast.success(responseJson.message);
        this.removeBookMarkedItem();
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.errors) {
        this.handleErrorBookMark(responseJson.errors);
      }
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        await this.handleResponseItineraryLocation(
          apiRequestCallId,
          responseJson
        );
      }

      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
