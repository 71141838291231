Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Bookmark2";
exports.labelBodyText = "Bookmark2 Body";
exports.PostId = "bx_block_posts/posts"
exports.btnExampleTitle = "CLICK ME";
exports.postBookMark = "bx_block_content_management/bookmarks";
exports.videoErrorTitle = "Your browser does not support HTML video.";
exports.bookMarkPageTitle= "Bookmark";
exports.bookMarkBtnTitle= "Bookmark Now";
exports.bookMarkPageInputTitle= "Title";
exports.bookMarkPageInputDescription= "Description";
exports.ExploreTiltle="Explore";
exports.logintitlealertmodal="Login / Signup";
exports.logindesalertmodal="You need to login / signup to participate in the discussion";
exports.NoExploreData="No explore list";
exports.LoadingTilte= 'Loading...';
exports.bookMarkApiEndpoint= "bx_block_explore/explore_locations?per_page=20&page=";
exports.searchBoonmarkApiEndPoint="bx_block_explore/explore_locations/search_explore_location?per_page=20&page=";
exports.searchbtnExampleTitle="Search for destinations";
exports.ResultTiltle=' results'
exports.ofTiltle=' of ';
exports.inTiltle= ' in ';
exports.resTitle='Showing results for'
exports.getAllBookmarksEndPoint = "bx_block_content_management/bookmarks";
exports.deleteBookmarkApiEndPoint = "bx_block_content_management/bookmarks/";
exports.deleteBookmarkTitle = "Bookmark deleted succesfully!";
exports.noDataFoundError = "Bookmark not found";
exports.PostType = "BxBlockPosts::Post";
exports.ExploreLocationType = "BxBlockExplore::ExploreLocation";
exports.ExploreItemType = "BxBlockExplore::ExploreItem";
exports.loadingTitle = "Loading...";
// Customizable Area End