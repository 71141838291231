import React from "react";

import {
  Container,
  Box,
  Typography,
  Button,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";

// Customizable Area End

import BlockedUsersController, {
  configJSON,
  Props,
} from "./BlockedUsersController.web";
import ConfirmationModal from "../../../../components/src/ConfirmationModal.web";
import Loader from "../../../../components/src/Loader.web";
import InfiniteScroll from "react-infinite-scroll-component";

export default class BlockedUsers extends BlockedUsersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    //const { about_us } = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <UserContainer>
        <Box className="header-row">
          <Typography className="heading" variant="h2">
            Blocked Users
          </Typography>
          <p>Users: {this.state.blocked_users.data.length ?? 0}</p>
        </Box>

        <Loader loading={this.state.isLoading} />
        <Box style={{ overflow: "auto" }} id="scrollableDiv">
          <InfiniteScroll
            dataLength={this.state.blocked_users.data.length}
            next={this.hasMoreBlockedUsers}
            scrollableTarget="scrollableDiv"
            hasMore={
              this.state.currentPage < this.state.blocked_users.meta.total_pages
            }
            loader={<h4>{configJSON.loadingTitle}</h4>}
          >
            <UserCardWapper>
              {this.state.blocked_users.data.length
                ? this.state.blocked_users.data.map(
                    (item: any, index: number) => {
                      return (
                        <Box className="card-wapper" key={index + 1}>
                          <CardWrapper>
                            <img
                              src={item?.attributes?.photo}
                              alt="user-image"
                              className="user-image"
                            />
                            <Typography
                              style={{ cursor: "pointer" }}
                              variant="h6"
                            >
                              {item?.attributes?.full_name}
                            </Typography>
                            <Typography
                              className="user-id-title"
                              variant="body2"
                            >
                              {"@" +
                                `${
                                  item?.attributes?.username
                                    ? item?.attributes?.username
                                    : item?.attributes?.full_name
                                }`}
                            </Typography>
                            <Button
                              data-test-id="block-btn"
                              onClick={this.onOpenModal.bind(this, item?.id)}
                              className="block-btn"
                            >
                              {configJSON.blocktitle}
                            </Button>
                          </CardWrapper>
                        </Box>
                      );
                    }
                  )
                : configJSON.noBlockedUserFoundError}
            </UserCardWapper>
          </InfiniteScroll>
        </Box>
        <ConfirmationModal
          open={this.state.isOpenModal}
          handleCloseModal={this.onCloseModal}
          onHandleConfirmation={this.onHandleConfirmation}
          title="Unblock User"
          description="Are you sure want to Unblock this user?"
        />
      </UserContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const UserContainer = styled(Box)({
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  background: "#FFFFFF",
  height: "90%",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  borderRadius: "20px",
  "& .header-row": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px",
  },
  "& .heading": {
    fontSize: "22px",
    color: "#000000",
    fontFamily: "HelveticaNeue",
    fontWeight: 600,
    letterSpacing: "1px",
  },
  "& p": {
    fontSize: "16px",
    color: "#818181",
    fontFamily: "HelveticaNeue",
    lineHeight: "24px",
    paddingRight: "5px",
  },
});
const UserCardWapper = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  padding: "0",
  background: "#fafbff",
  justifyContent: "space-around",
  "& .card-wapper": {
    width: "30%",
    padding: "30px 0",
    margin: "20px 0",
    background: "#ffffff",
    border: "1px solid #c5c5c5e8",
    borderRadius: "8px",
    "@media (max-width:1100px)": {
      width: "24%",
    },
    "@media (max-width:900px)": {
      width: "32.33%",
    },
    "@media (max-width:600px)": {
      width: "48%",
    },
  },
});
const CardWrapper = styled("div")({
  width: "100%",
  textAlign: "center",
  "& .MuiButton-root": {
    textTransform: "capitalize",
  },
  "& .user-id-title": {
    color: "gray",
    overflow: "hidden",
  },
  "& .user-image": {
    width: "58px",
    height: "58px",
    objectFit: "cover",
    borderRadius: "50%",
  },
  "& .block-btn": {
    background: "#25AAE1",
    color: "#FFF",
    fontWeight: 600,
    padding: "4px 20px",
    marginTop: "10px",
  },
});
// Customizable Area End
