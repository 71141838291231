// Customizable Area Start
import React from "react";
import ItineraryDiscussionChatController, {
  Props,
} from "./ItineraryDiscussionChatController.web";
import ChatSendMessage from "../../../components/src/ChatSendMessage.web.";
import ChatListing from "../../../components/src/ChatListing.web";
import { Box, Typography } from "@material-ui/core";
import { ItineraryDiscussionChatContainer } from "./ItineraryDiscussionChat.style.web";

export class ItineraryDiscussionChat extends ItineraryDiscussionChatController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ItineraryDiscussionChatContainer>
        <Box flex="0 0 58px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gridRowGap="15px"
            className="title-text-container"
          >
            <Typography className="title-text">Discussion</Typography>
          </Box>
          <hr className="under-line itinerary-under-line" />
        </Box>
        <ChatListing
          isLoading={this.state.isLoading}
          haveMoreChat={this.state.haveMoreChat}
          scrollToBottom={this.state.scrollToBottom}
          chatData={this.state.chatListData}
          getMoreApiChatList={this.getMoreApiChatList}
          setFalseScrollToBottom={() => {
            this.setState({
              scrollToBottom: false,
            });
          }}
          deleteChatMessage={(deleteMessageId) =>
            this.deleteChatMessage(deleteMessageId)
          }
          deleteAttachmentMessage={(deleteMessageId,attachmentId) =>
            this.deleteAttachmentMessage(deleteMessageId,attachmentId)
          }
          editChatMessage={(message, messageId) =>
            this.editChatMessage(message, messageId)
          }
          
        />
        <ChatSendMessage
          message={this.state.message}
          media={this.state.media}
          submitMessageOrMedia={(message: string, media: any[]) =>
            this.submitMessageOrMedia(message, media)
          }
        />
      </ItineraryDiscussionChatContainer>
    );
  }
}

export default ItineraryDiscussionChat;
// Customizable Area End
