import React from "react";

import {
  Box,
  Typography,

  // Customizable Area Start
  Grid,
  Switch,
  alpha,
  styled,
  colors,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import NotificationController, { Props } from "./NotificationController.web";

export default class Notifications extends NotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { userDetail } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainWrapper}>
        <Typography style={webStyle.heading} variant="h6">
          Notification
        </Typography>
        <Grid container style={{ flexWrap: "nowrap", flexDirection: "column" }}>
          <Grid lg={6} style={webStyle.notif_row}>
            <Typography style={webStyle.notificaton_item} variant="h6">
              In-app Notification
            </Typography>
            <BlueSwitch
              onChange={this.onAppNotificaitonToggle}
              inputProps={{ "aria-label": "controlled" }}
              checked={
                userDetail?.attributes?.in_app_push_notification ?? false
              }
            />
          </Grid>
          <Grid lg={6} style={webStyle.notif_row}>
            <Typography style={webStyle.notificaton_item} variant="h6">
              Push Notification
            </Typography>
            <BlueSwitch
              inputProps={{ "aria-label": "controlled" }}
              checked={userDetail?.attributes?.allow_push_notification ?? false}
              onChange={this.onPushNotificaitonToggle}
            />
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    height: "90%",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "20px",
  },
  heading: {
    fontSize: "22px",
    color: "#000000",
    fontFamility: "HelveticaNeue",
    fontStyle: "italic",
    margin: "0 0 30px 0",
  },
  notificaton_item: {
    fontSize: "22px",
    color: "#818181",
    fontFamility: "HelveticaNeue",
    lineHeight: "26px",
  },
  notif_row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0 10px 0",
  },
};
export const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: colors.lightBlue[600],
    "&:hover": {
      backgroundColor: alpha(
        colors.lightBlue[600],
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: colors.lightBlue[600],
  },
}));

// Customizable Area End
