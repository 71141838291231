import React, { CSSProperties } from "react";

import {
    Box,
    // Customizable Area Start
    InputBase
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SearchIcon from '@material-ui/icons/Search';
import FollowingsController from "./FollowingsController"
import FollowCard from "../../../../components/src/FollowCard.web"
import Loader from "../../../../components/src/Loader";
// Customizable Area End
// Customizable Area Start

export default class Followings extends FollowingsController {
    goToProfile=(id:number)=>{
        this.props.navigation.history.push(`/profile/${id}`)
    }
    render() {
        return (
            // Customizable Area Start
            <>
                <Box style={{ height: '90%', }}>
                    <Loader loading={this.state.isLoader} />
                    <Box
                        style={{
                            padding: '15px',
                            alignItems: 'center',
                            display: 'flex',
                            margin: '40px 25px 30px',
                            borderRadius: '8px',
                            border: '1px solid #ccc'
                        }}>
                        <SearchIcon style={{ color: "938f8f" }} />
                        <InputBase
                            test-id="inputBaseId"
                            placeholder="Search People"
                            style={{ paddingLeft: '15px', }}
                            inputProps={{ 'aria-label': 'search google maps' }}
                            fullWidth
                            onChange={(event) => this.searchHandel(event?.target?.value)}
                        />
                    </Box>
                    <Box style={{
                        padding: '0 25px',
                        overflowY: 'auto',
                        height: '88%',
                        overflowX: 'hidden',
                    }}
                        id="scrollableDiv"
                    >
                        <FollowCard
                            title={"No followings found!"}
                            data={this.state.followingsList.data}
                            buttonLabel={"UnFollow"}
                            handelClick={this.followinghandel}
                            userDetails={this.props.userDetails}
                            goToProfile={this.goToProfile}
                        />
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area End
