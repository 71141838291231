// Customizable Area Start

export const NotificationStyle = {
  mainContainer: {
    flexWrap: "nowrap",
    backgroundColor: "rgba(228,243,255,255)",
    padding: "20px",
    position: "relative",
    borderRadius: "32px 0 0 32px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    boxSizing: "border-box",
    "& .display-flex-message": {
      flex: "1 1 auto",
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
    "& .message-view": {
      height: "100% !important",
      overflowY: "auto",
      padding: "10px 0",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(23,89,117,0.16)",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "rgb(0 4 5 / 16%)",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: " #f1f1f1",
      },
    },
    "& .header-row": {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      flex: "0 0 48px",
      height: "100%",
    },
    "& .header-item": {
      height: "48px",
      width: "48px",
      borderRadius: "10px",
      marginLeft: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      cursor: "pointer",
    },
    "& .profile-img": {
      objectFit: "cover",
      width: "48px",
      height: "48px",
      borderRadius: "10px",
      border: "none",
    },
    "& .icon-img": {
      width: "24px",
      height: "24px",
      color: "grey",
    },
    "& .active-icon-img": {
      width: "24px",
      height: "24px",
      color: "white",
    },
    "& .notification-heading": {
      display: "flex",
      position: "relative",
      alignItems: "center",
      // justifyContent: "space-between",
      margin: "15px 0",
      height: "fit-content",
      boxSizing: "border-box",
      flex: "0 0 auto",
    },
    "& .message-count": {
      height: "25px",
      background: "#FF4E4E",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "20px",
      "& .count-p": {
        fontSize: "16px",
        fontFamiliy: "HelveticaNeue",
        padding: "2px 10px",
        color: "white",
        fontWeight: "600",
      },
    },
    "& .search-container": {
      "& .MuiFormControl-root.MuiTextField-root.searchField": {
        width: "100%",
      },
      position: "relative",
      display: "flex",
      margin: "15px 0",
      padding: "5px 12px",
      borderRadius: "6px",
      backgroundColor: "white",
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .user-list": {
        marginTop: "50px",
        marginLeft: "-1%",
        minWidth: "15%",
        height: "200px",
        overflowY: "auto",
      },
    },

    "& .notification-count": {
      width: "35px",
      height: "20px",
      background: "#FF4E4E",
      color: "white",
      borderRadius: "4px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      padding: "4px",
      fontFamily: "HelveticaNeue",
    },

    "& .notification-list": {
      width: "100%",
      display: "flex",
      position: "relative",
      gap: "12px",
      alignItems: "center",
      padding: "10px 0",
      boxSizing: "border-box",
      overflow: "hidden",
    },
    "& .avatar-row-padding-0": {
      padding: "0 !important",
    },
    "& .avatar-row": {
      display: "flex",
      alignItems: "center",
      padding: "6px 0px 0 15px",
      flex: "0 0 51px",
      position: "relative",
      cursor: 'pointer',
      "& .notify": {
        width: "10px",
        height: "10px",
        background: "#FF4E4E",
        position: "absolute",
        top: "25px",
        left: "0px",
        borderRadius: "50%",
      },
    },
    "& .content-row": {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      width: "100%",
    },
    "& .content-size": {
      flex: "1 1 auto",
      wordBreak: "break-word",
    },
    "& .avatar-img": {
      width: "51px",
      height: "51px",
      borderRadius: "10px",
      objectFit: "cover",
    },
    "& .avatar-title-row": {
      display: "flex",
      justifyContent: "space-between",
      gap: "8px",
      alignItems: "center",
    },
    "& .total-read-remaining-text": {
      color: "white",
      background: "red",
      padding: "3px 6px",
      borderRadius: "6px",
      fontSize: "10px",
    },
    "& .chat-title-tex": {
      color: "#000",
      fontFamily: "Helvetica",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    "& .gallary-row": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .gallary-img": {
      height: "49px",
      width: "49px",
      objectFit: "cover",
      borderRadius: "10px",
      margin: "0 10 10px 0px",
    },
    "& .user-title": {
      color: "#1E1F20",
      fontSize: "14px",
      fontFamily: "HelveticaNeue",
    },
    "& .message": {
      color: "#8F92A1",
      fontSize: "14px",
      wordWrap: "break-word",
      fontFamily: "HelveticaNeue",
      margin: "-5px 0 10px 0",
      lineHeight: "1.2rem",
    },
    "& .time": {
      color: "#8F92A1",
      fontFamily: "Helvetica",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      margin: "0",
      textAlign: "end",
      whiteSpace: "nowrap",
      paddingRight: "5px",
      cursor: 'none'
    },
    "& .inner-msg": {
      color: "#25AAE1",
      fontSize: "14px",
      fontFamily: "HelveticaNeue",
    },
    "& .inner-msg-gr": {
      fontSize: "14px",
      fontWeight: "600",
      fontFamily: "HelveticaNeue",
    },
    "& .chat-online": {
      width: "10px",
      height: "10px",
      background: "#07bc0c",
      position: "absolute",
      top: "-2px",
      right: "-2px",
      borderRadius: "50%",
    },
    "& .chat-offline": {
      background: "#808080",
    },
    "& .user-name": {
      color: "#1E1F20",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "20px",
      margin: "0",
      wordBreak: "break-word",
      cursor: "pointer",
    },
    "& .last-message": {
      color: "#8F92A1",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "22px",
      margin: "0",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "1",
      wordBreak: "break-all",
      cursor: "pointer",
    },
    "& .main-header-chat": {
      display: "flex",
      gap: "12px",
      "& .avatar-row-chat": {
        height: "fit-content",
      },
      "& .chat-details-row": {
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        height: "100%",
        alignItems: "center",
        width: "100%",
      },
      "& .chat-user-details": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
      },
      "& .menu-image-container": {
        padding: "5px",
        cursor: "pointer",
      },
    },
    "& .horizontal-line": {
      marginTop: "20px",
      borderColor: "rgba(0, 0, 0, 0.12)",
      marginBottom: "10px",
      width: "100%",
    },
    "& .MuiList-root": {
      borderRadius: "10px",
      minWidth: "100px",
      background: "#FAFBFF",
      padding: "10px 0",
      boxShadow: "0px 0px 32px 0px rgba(14, 70, 93, 0.16)",
    },
    "& .dropdown-container": {
      zIndex: 3,
    },
    "& .MuiMenuItem-root": {
      borderBottom: "1px solid #d5d5d5",
      fontSize: "16px",
      justifyContent: "center",
      fontWeight: "500",
      fontFamily: "Helvetica",
      display: "flex",
      flexDirection: "column",
    },
    "& .MuiMenuItem-root:last-child": {
      borderBottom: "none",
    },
  },
};
// Customizable Area End
