// Customizable Area Start
import React from "react";

import { withStyles, Grid, Box, Typography } from "@material-ui/core";

import ExploreController, { Props } from "./ExploreController.web";
import { ExploreStyles } from "./Explore.styles.web";
import Loader from "../../../../components/src/Loader.web";
import ImageLabel from "../../../../components/src/ImageLabel.web";
import InfiniteScroll from "react-infinite-scroll-component";

class Explore extends ExploreController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.Explore}>
          <Typography className="main-heading">Explore</Typography>
          <Typography className="description-text">As per location</Typography>
          <div
            id="scrollableDiv"
            style={{
              height: "calc(100% - 74px)",
              overflowY: "auto",
              overflowX: "hidden",
              paddingRight: "10px",
            }}
          >
            {this.state.exploreList.length > 0 ? (
              <InfiniteScroll
                dataLength={this.state.exploreList.length}
                next={this.fetchMoreData}
                scrollableTarget="scrollableDiv"
                hasMore={this.state.hasMore}
                loader={<h4>Loading...</h4>}
                style={{
                  height: "unset !important",
                  overflow: "unset !important",
                }}
              >
                <Grid container spacing={3}>
                  {this.state.exploreList.map((item) => (
                    <Grid item md={3} sm={4} xs={12} key={item.id}>
                      <ImageLabel
                        imageClick={() =>
                          this.redirectToExploreList(item.attributes.id)
                        }
                        imageSrc={item.attributes.location_cover_image}
                        name={item.attributes.city_name}
                      />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            ) : (
              <p className="not-found-text">No explore list</p>
            )}
          </div>
        </Box>
      </>
    );
  }
}
//@ts-ignore
export default withStyles(ExploreStyles)(Explore);
// Customizable Area End
