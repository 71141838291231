
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import DataContext from "../../../components/src/DataContext";
import { dashboard, Screen2, Screen3 } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    otp: string,
    validOtp: string, // a sample OTP to validate against
    showErrorMessage: boolean,
    accessToken: string | null,
    useName: string,
    error: boolean;
    errorMsg: string;
    oneMinTimer: number;
    otps: string;
    loading:boolean;
    CarouselImg: {id:number; img: ''; data: string;}[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start
export default class OTPInputAuthControllerBlock extends BlockComponent<
    Props,
    S,
    SS
> {
    otpVerifyId: string = "";
    otpResendId: string = "";
    interval: any
    clearTimer :any
    static contextType: React.Context<string> = DataContext
    async componentDidMount() {
        super.componentDidMount();
        if(!this.context.data.email){
            this.props.navigation.navigate("ForgotPasswordBlock")
        }
        if(this.state.oneMinTimer > 0) {
            this.interval = setInterval(
                ()=> this.setState((prevState) => ({oneMinTimer : prevState.oneMinTimer -1})),1000
            );
        }
        this.setState({ useName: this.context.data.email })
    }

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.state = {
            // Customizable Area Start
            otp: '',
            validOtp: '1234', // a sample OTP to validate against
            showErrorMessage: false,
            accessToken: localStorage.getItem("authToken"),
            useName: this?.context?.data?.email,
            error: false,
            errorMsg: "",
            oneMinTimer: 59,
            otps: '',
            loading:false,
            CarouselImg: [
                {
                  data:'head1title',
                  id: 1,
                  img: dashboard,
                },
                {
                  img: Screen2,
                  id: 2,
                  data: 'head1title'
                },
                {
                  id: 3,
                  data: 'head1title',
                  img: Screen3,
                },
              ],
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.otpVerifyId) {
                if (responseJson.messages) {
                    this.props.navigation.navigate("NewPasswordBlock")
                } else {
                    this.setState({ error : true , errorMsg : responseJson.errors[0].otp} )
                }
            }
            if (apiRequestCallId === this.otpResendId) {
                this.saveLoggedInUserData(responseJson);
                if (responseJson) {
                    this.setState({oneMinTimer : 60})
                }
            }
            this.setState({
                loading:false
            });
        }
    }
    handleChangesss = (otps :any) => {
        this.setState({ otps });
      };

      saveLoggedInUserData(responseJson: any) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
          msg.addData(
            getName(MessageEnum.SessionResponseToken),responseJson.meta.token );
          this.send(msg);
        }
      }
    
    otpVerify(values: any) {
        this.setState({
            loading:true
        });
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,

        };
        const data = {
            "data": {
                "token": localStorage.getItem("authToken"),
                "otp_code": this.state.otps
            }
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.otpVerifyId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.otp_verification
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // this.props.navigation.navigate("NewPasswordBlock")
    }

    resendOtp(value : any) {
        this.setState({
            loading:true
        });
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail
        };
        let data = {}
        if (isNaN(Number(value))) {
            data = {
                "data": {
                    "attributes": {
                        "email": value
                    }
                }
            };
        } else {
            data = {
                "data": {
                    "attributes": {
                        "full_phone_number": value
                    }
                }
            };
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.otpResendId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.resend_otp
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
}
// Customizable Area End