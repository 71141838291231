import React from "react";
// Customizable Area Start

import {
    styled,
    Box
  } from "@material-ui/core";
import ItinerarySectionController, { Props, configJSON } from "./ItinerarySectionController.web";
import ItineraryCreate from "./ItineraryCreate.web";
import BackButton from "../../../components/src/BackButton.web";
import ConfirmationModal from "../../../components/src/ConfirmationModal.web";
// Customizable Area End

export default class ItinerarySection extends ItinerarySectionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    return (
        <ExploreContainer>
          <div className="ExploreContainers">
            <ConfirmationModal open={this.state.isLogin} handleCloseModal={this.handleLoginModal} onHandleCancel={()=>this.props.navigation.history.push('/OnboardingScreenBlock')} onHandleConfirmation={()=>this.props.navigation.history.push('/EmailAccountLoginBlock')} title={configJSON.logintitlealertmodal} description={configJSON.logindesalertmodal} isSignUp={true}/>
            <div className="header-section">
                <BackButton navigation={this.props.navigation} />
                <h2>{configJSON.ItineraryTitle}</h2>
            </div>
            <ItineraryCreate id="1" navigation={this.props.navigation} isUnAuth={true} handleLoginModal={this.handleLoginModal} />
          </div>
        </ExploreContainer>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const ExploreContainer = styled(Box)({
    backgroundColor: "#FAFBFF",
    '& .ExploreContainers':{
      maxWidth: "1015px",
      boxSizing: "border-box",
      height: "100%",
      padding: "40px 20px 20px",
      margin: "0 auto",
    },
    "& .header-section":{
        "& h2":{
            marginLeft: "10px",
            color: "#000000"
        },
        fontWeight: 'bold',
        display: "flex",
        fontFamily: 'Helvetica-Bold',
    }
});
// Customizable Area End