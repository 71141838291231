Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.validationApiContentType = "application/json";
exports.validationApiContentFromType = "multipart/form-data";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FanWall";
exports.labelBodyText = "FanWall Body";
exports.allPost = "bx_block_search/search_posts"
exports.btnExampleTitle = "CLICK ME";
exports.likePost= "bx_block_like/likes";
exports.getPostComments = "bx_block_comment/comments/?commentable_type=BxBlockPosts::Post&commentable_id=";
exports.postComment = "bx_block_comment/comments";
exports.activities = "bx_block_activities/user_activities/get_all_activities";
exports.applyPostFilter= "bx_block_search/search_posts?query=";
exports.applyUserFilter = "bx_block_search/search_users?query=";
exports.allUser = "bx_block_search/search_users";
exports.applyActivityFilter= "bx_block_posts/posts/filter_by_activity?query=";
exports.blockType= "BxBlockPosts::Post";
exports.noPostFoundTitle="No post found";
exports.noUserFoundTitle="No data found"
exports.filterBtn= "Apply Now";
exports.sideBarHeader="Location";
exports.sideBarSubHeader= "All Activities";
exports.searchBarPlaceholder= "Search in bhomio...";
exports.followerApiEndPoint="bx_block_followers/follows";
exports.unFollowerApiEndPoint="bx_block_followers/remove_following";
exports.loadingMassage= "Loading...";
exports.locationApiEndPoint="bx_block_search/search_posts_by_activity_and_location?";
// Customizable Area End
