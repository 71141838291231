import firebase from 'firebase'

export const firebaseAPI = firebase.initializeApp({
    apiKey: "AIzaSyBIrbQkBiRZwhW5OwQpRnee_BNxlmIRa7w",
    authDomain: "banjara-mobile.firebaseapp.com",
    projectId: "banjara-mobile",
    storageBucket: "banjara-mobile.appspot.com",
    messagingSenderId: "168529353921",
    appId: "1:168529353921:web:bbcb48cd48903792938840",
    measurementId: "G-6YWHDLSWB1",

    
});
export const getNotficationPermission = () => {
    return Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
            console.log("Notification User Permission Granted.");

            return firebaseAPI.messaging().getToken({ vapidKey: `BOg4Z1sATjWBON4KnsHc1bEn8Gl5aoxB1crGP1dfSyJpl9aCSHNW9FA82RTH7RVA97wJZaLHk8zT-op_q3PY1Qc` })
                .then((currentToken) => {
                    if (currentToken) {
                        return currentToken

                    } else {

                        console.log('Failed to generate the registration token.');
                    }
                })
                .catch((err) => {
                    console.log('An error occurred when requesting to receive the token.', err);
                });
        } else {
            console.log("User Permission Denied.");
        }
    });
}
