import React from 'react';
import {
    Box,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    Avatar,
    Modal,
    Menu,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ClickAwayListener
} from "@material-ui/core";


// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import EmojiPicker from "emoji-picker-react";
import UpWardIcon from '@material-ui/icons/ArrowUpward'
import DownWardIcon from '@material-ui/icons/ArrowDownward';

const menuIcon = require("../../../components/src/assets/menuicon.png");
const like = require("../../../components/src/assets/likeicon.png");
const disLike = require("../../../components/src/assets/unlikeicon.png")
const comment = require("../../../components/src/assets/commeticon.png")
const sendBtn = require("../../../components/src/assets/send-btn.png")
const emoji = require("../../../components/src/assets/emoji.png")
import ConfirmationModal from "../../../components/src/ConfirmationModal.web";
import ForumCardController, { Props } from './ForumCardController.web';
import InfiniteScroll from 'react-infinite-scroll-component';
import { dummyProfile } from './assets';
const configJSON = require("./config");



export default class ForumCard extends ForumCardController {
    constructor(props: Props) {
        super(props);

    }
    
    onHandelConfirmations = () => {
        this.onHandleConfirmation(this.state.commentId, this.state.myCommentId)
        this.handleCloseModal()
    }
   

    renderUserProfile() {
        const { item } = this.props;
        return (
            <Box className="user-profile">
                <Box display="flex" alignItems="center">
                    <Avatar>
                        <img src={item.admin_profile_photo ?? dummyProfile} style={{ objectFit: "cover",width:"40px",height:'40px' }} alt="avatar" />
                    </Avatar>
                    <Box sx={{ marginLeft: "8px" }}>
                        <Typography variant="subtitle2" className="user-name">
                            {item?.admin_name}

                        </Typography>
                        <Typography variant="body2" className="user-location">
                            {item?.created_at}
                        </Typography>
                    </Box>

                </Box>
            </Box>
        );
    }

    

    renderForumLikeTool = () => {
        const { item } = this.props;

        return (
            <Box className="like-tools">
                <Box>
                    <Button onClick={this.handleForumLike} data-test-id="likeForum">
                        <img src={item.liked_by_user == "true" ? like : disLike} alt="like-icon" />
                        <CountNumber>{item?.likes || 0}</CountNumber>
                    </Button>
                    <Button data-test-id="comment-icon" onClick={this.handleComment}>
                        <img src={comment} alt="comment-icon" />
                        <CountNumber>{this.state.commentCount}</CountNumber>
                    </Button>
                </Box>
            </Box>
        )
    }
    handleVote = (cmt: any, type: string) => {
        if (cmt?.current_user_upvoted == "false" && type == 'up') {
            this.onHandleUpWardAndDownWard(cmt?.id, type)
        }
        else if (cmt?.current_user_downvoted == "false" && type == 'down') {
            this.onHandleUpWardAndDownWard(cmt?.id, type)
        }
    }

    renderForumCommnetVote = (cmt: any) => {
        return (
            <Box sx={{ padding: 0 }} className="up-down-tools">
                <Box sx={{ padding: 0 }}>
                    <Button onClick={() => this.handleVote(cmt, 'up')} data-test-id="upComment">
                        <UpWardIcon className={cmt?.current_user_upvoted == "true" ? "active-ward" : 'deactive-ward'} />
                        <CountNumber>{cmt?.upvote || 0}</CountNumber>
                    </Button>
                    <Button data-test-id="downComment" onClick={() => this.handleVote(cmt, 'down')}>
                        <DownWardIcon className={cmt?.current_user_downvoted == "true" ? "active-ward" : 'deactive-ward'} />
                        <CountNumber>{cmt?.downvote || 0}</CountNumber>
                    </Button>
                </Box>
            </Box>
        )
    }

    renderComment = () => {
        const { item } = this.props;
        const open = Boolean(this.state.anchorEle)

        return (
            this.state.commentId === item.id &&
            <>
                <Box mt={2}>
                    <OutlinedInput
                        data-test-id="comment-field"
                        id="outlined-adornment-weight"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="Write a Comment"
                        style={{ width: "100%" }}
                        onKeyDown={this.handleEnter}
                        value={this.state.commentText}
                        onChange={this.handleCommentText}
                        endAdornment={
                            <><InputAdornment style={{ cursor: "pointer" }} onClick={this.handleEmoji} position="end">
                                <img src={emoji} alt="comment-icon" />
                            </InputAdornment>
                                <InputAdornment style={{ cursor: "pointer" }} onClick={this.handleForumComment}
                                    position="end">
                                    <img src={sendBtn} alt="comment-icon" />
                                </InputAdornment>
                                </>
                        }
                    />
                    {this.state.showEmojis &&
                        <div className="handle-picker">
                            <EmojiPicker onEmojiClick={this.onCommentEmojiClick} />
                        </div>
                    }
                </Box>
                <Box id="scrollableDiv" style={{ maxHeight: "250px", overflow: "auto" }}>
                    <InfiniteScroll
                        scrollableTarget="scrollableDiv"
                        next={this.handleNextCommentPage}
                        loader={<>{"Loading..."}</>}
                        hasMore={this.state.commentPage < this.state.comments?.total_page}
                        dataLength={this.state.comments?.data?.length}>
                        {this.state.comments?.data?.length ? this.state.comments?.data?.map((commentData: any, index: number) => {
                            return <Box key={index + 0}>
                                <Box className="commentBox">
                                    <Box className="user-comment-profile">
                                        <Avatar>
                                            <img src={commentData.account_image} alt="comment-img" width="40px" height="40px" />
                                        </Avatar>
                                        <Typography variant="body1" component="div" className="user-comment-name">
                                            {commentData.account_full_name}

                                        </Typography>
                                        <Typography variant="body1" component="div" className="user-comment-time">
                                            {commentData.created_at}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {commentData.comment_by_id == this.props.userDetails?.id && <><IconButton
                                            aria-controls={`simple-menu-${commentData.id}`} aria-haspopup="true" data-test-id="comment-menu"
                                            onClick={(event) => this.handleCommentMenuClick(event, commentData.id)}
                                        >
                                            <img src={menuIcon} alt="menuIcon" className="menu-icon" />
                                        </IconButton>
                                            {commentData.id === this.state.tempMenuId && <Menu
                                                id={`simple-menu-${commentData.id}`}
                                                open={commentData.id === this.state.tempMenuId ? open : false}
                                                anchorEl={this.state.anchorEle}
                                                onClose={this.handleCommentMenuClose}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: 48 * 4.5,
                                                        width: '100px',
                                                    },
                                                }}
                                            >
                                                <MenuItem data-test-id="comment-edit" onClick={() => this.handleCommentMenuEdit(commentData.id)}>
                                                    {configJSON.editName}
                                                </MenuItem>
                                                <MenuItem data-test-id="comment-remove" onClick={() => this.handleCommentMenuRemove(commentData.id)}>
                                                    {configJSON.removeName}
                                                </MenuItem>
                                            </Menu>}</>}
                                    </Box>
                                </Box>
                                <ConfirmationModal open={commentData.id === this.state.tempMenuId ? this.state.confirmModel : false}
                                    handleCloseModal={this.handleCloseModal}
                                    onHandleConfirmation={this.onHandelConfirmations} title="Remove Comment" description="Are you sure want to remove this comment?" />
                                <Box mt={1} mb={1}><Typography className='comment-content'>{commentData.content || ""}</Typography></Box>
                                {this.renderForumCommnetVote(commentData)}
                            </Box>
                        }) : <Box mt={1}>{configJSON.commentErrorMassage}</Box>}</InfiniteScroll></Box>
            </>
        )
    }

    render(): React.ReactNode {
        const { item, index } = this.props;
        return (
            <ClickAwayListener onClickAway={this.handleClickAway}>
                <ForumCardContainer>
                    <Box className="post-bcg" key={index + 1}>
                        {this.renderUserProfile()}
                        <Typography variant="subtitle1" component="h6" className="user-title">
                            {item?.question}
                        </Typography>
                        <Typography variant="subtitle2" component="p" className="user-bio">
                            {item?.description.length <= 210 || this.state.showMore === index ? item?.description : item?.description.substring(0, 210) + '...'}
                            {item?.description.length > 210 && <Typography variant="subtitle2" className="show-more" data-test-id="show-more" onClick={() => this.showMore(index)} component="span">{this.state.showMore === index ? "less" : "more"}</Typography>}
                        </Typography>
                        {this.renderForumLikeTool()}
                        {this.renderComment()}

                    </Box>
                </ForumCardContainer>
            </ClickAwayListener>

        )
    }
}

const ForumCardContainer = styled("div")({
    "& .post-bcg": {
        backgroundColor: "white",
        height: "auto",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 10px",
        borderRadius: "10px",
        padding: "20 20 10 20",
        marginBottom: "30px",
        position: "relative"
    },
    "& .comment-content": {
        fontSize: "14px",
        color: '#8F92A1',
        fontFamily: "Helvetica"
    },
    "& .user-profile": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .user-name": {
        fontWeight: "bold",
    },
    "& .user-location": {
        fontSize: "12px",
        color: "#656565"
    },
    "& .show-more": {
        color: '#25AAE1',
        fontSize: "12px",
        cursor: "pointer",
    },
    "& .menu-icon": {
        height: "17px",
        marginTop: "15px"
    },
    '& .user-title': {
        fontSize: "22px",
        marginTop: "20px",
        color: '#000000',
        fontFamily: "Helvetica"
    },
    "& .user-bio": {
        fontSize: "16px",
        marginTop: "8px",
        color: '#818181',
        fontFamily: "Helvetica"
    },
    "& .bcg-img": {
        width: "97%",
        marginTop: "20px",
        height: "380px",
        objectFit: "contain"
    },
    "& .like-tools": {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "15px"
    },
    "& .up-down-tools": {
        display: "flex",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            justifyContent: "left",
        },
        "& .MuiButton-text": {
            padding: "6px 0"
        },
        "& .active-ward": {
            color: '#25AAE1',
            fontSize: "14px"
        },
        "& .deactive-ward": {
            color: '#8F92A1',
            fontSize: "14px"
        }
    },
    "& .MuiDrawer-paper": {
        width: "450px",
        boxSizing: 'border-box',
        borderRadius: '16px 0 0 16px',
        background: '#EFBFED'
    },
    "& .iconImage": {
        height: "60px",
        width: "60px",
        backgroundColor: "red",
        borderRadius: "16px",
        marginRight: "20px",
        position: "relative",
        "&::before": {
            content: "",
            position: "absolute",
            top: "-1px",
            right: "0",
            width: "10px",
            height: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            backgroundColor: "blue",
            color: "white"
        }
    },
    "& .count": {
        position: "relative",
        "&::before": {
            content: "",
            position: "absolute",
            top: "-4px",
            right: "17px",
            width: "1em",
            height: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            backgroundColor: "orange",
            color: "white",
            zIndex: 2,
            border: "3px solid white",
        }
    },
    "& .chatsTag": {
        fontWeight: "bold !important",
        paddingLeft: "20px",
        paddingTop: "20px",
        fontSize: "25px !important"
    },
    "& .post": {
        position: "relative"
    },
    "& .indicator": {
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
        width: "42px",
        backgroundColor: "rgba(239, 243, 239, 0.7)",
        padding: "2px 0px",
        borderRadius: "8px",
        position: "absolute",
        left: "47.5%",
        top: "420px"
    },
    "& .commentBox": {
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
        "& .user-comment-profile": {
            display: "flex",
            alignItems: "center",
            "& .user-comment-name": {
                fontWeight: "bold",
                color: "black",
                fontSize: "12px",
                margin: "0 5px",
                fontFamily: "Helvetica"
            },
            "& .user-comment-time": {
                fontSize: "12px",
                marginLeft: "10px",
                color: '#8F92A1',
                fontFamily: "Helvetica"
            }

        }
    },
    "& .handle-picker": {
        "& .EmojiPickerReact": {
            marginLeft: "auto"
        }
    }
});
const CountNumber = styled("section")({
    marginLeft: "5px"
})
const MuiModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "& .paper": {
        backgroundColor: "#ffffff",
        padding: "20px 30px",
        borderRadius: "12px",
    },
    "& .modal-title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "15px"
    },
    "& .search-users": {
        width: "100%",
        borderRadius: "12px"
    },
    "& .MuiInputBase-input": {
        height: "0.5em"
    },
    "& .share-btn": {
        width: "100%",
        background: "#25AAE1",
        color: "#FFFFFF",
        fontSize: "20px",
        padding: '4px 30px',
        textTransform: "capitalize"
    },
    "& .user-name": {
        fontWeight: "bold",
    },
    "& .user-location": {
        fontSize: "12px",
        color: "#656565"
    },
    "& .MuiCheckbox-root": {
        color: "#25AAE1",
    },
    "& .users-list": {
        height: "300px",
        overflowY: "scroll"
    }
})