import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  Grid,
  // Customizable Area Start
  Chip,
  Popper
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
const configJSON = require("./config");
import InfiniteScroll from 'react-infinite-scroll-component';
// Customizable Area End

import FanWallController, {
  Props,
} from "./FanWallController.web";

import {
  searchIcon,
  filterIcon
} from "./assets";
import PostCard from "./Post.web";
import Loader from "../../../components/src/Loader.web";
import Notification from "../../notifications/src/ChatWithNotification.web";

export default class FanWall extends FanWallController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  setNotificationData = (data: any) => {
    return data;
  };
  // Customizable Area End

  render() {
    const {singlePostID,filterPopUp,filterRef,filterActivities,filterActivitiesId,isLoading,userPost,hasMoreData,userDetail,reloadChatList} = this.state;
    return (
      // Customizable Area Start
      <WrapperContainer >
        <Container disableGutters maxWidth={false} className="container">
          <Grid container component={Box} className="main-wrapper" bgcolor="#f4f5fa">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="left-side-container">
              {!singlePostID && (
                <Box
                  bgcolor="#f4f5fa"
                  sx={{
                    paddingTop: "30px",
                    position: "sticky",
                    zIndex: 11,
                    top: "0",
                    width: "91%",
                    margin: "auto",
                  }}
                >
                  <Box
                    className="search-container"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    bgcolor="#fff"
                    sx={{
                      margin: " 0px auto 10px auto",
                      padding: "5px 12px",
                      borderRadius: "6px",
                      width: "95%",
                    }}
                  >
                    <TextField
                      className="searchField"
                      placeholder={configJSON.filterInputTitle}
                      id="search-bhomio"
                      data-test-id="search-bhomio"
                      onChange={(e) => this.setInputValue(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={searchIcon} alt="searchIcon" />
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={(e) => this.handleApplyFilter(e)}
                    />
                    <Button
                      aria-describedby="simple-popover"
                      className="filter-button"
                      data-test-id="filter"
                      onClick={this.handlePostFilter}
                    >
                      <img
                        src={filterIcon}
                        alt="filterIcon"
                        className="filter-icon"
                      />
                      {configJSON.filterBtnTitle}
                    </Button>
                    <FilterWrapper
                      id="simple-popover"
                      placement="top-end"
                      open={filterPopUp}
                      anchorEl={filterRef}
                    >
                      <Box
                        className="filtermodel"
                        sx={{
                          width: {
                            xs: "280px",
                            sm: "350px",
                            md: "500px",
                            lg: "600px",
                          },
                        }}
                      >
                        <Box className="filterheader">
                          <Box>
                            <Typography className="activetitle">
                              {configJSON.activityTitle}
                            </Typography>
                            <Typography className="subtitle">
                              {configJSON.activitySubTitle}
                            </Typography>
                          </Box>
                          <CloseIcon
                            data-test-id="filte-close-icon"
                            onClick={() => this.handleCloseFilter()}
                            style={{
                              color: "#25AAE1",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                        <Box
                          style={{
                            overflow: "auto",
                            height: "100%",
                            maxHeight: "400px",
                          }}
                          className="scrollableDiv"
                        >
                          {filterActivities.length
                            ? filterActivities.map((item, index) => {
                              return (
                                <AdventurWrapper key={index + 1}>
                                  <Box className="adv-title">
                                    <Typography className="adv-title">
                                      {item.name}
                                    </Typography>
                                    <Box>
                                      {item.data.map((items) => (
                                        <Chip
                                          data-test-id="filter-activity-button"
                                          key={index}
                                          onClick={() =>
                                            this.handleActivityFilter(
                                              item.id,
                                              items.id,
                                              filterActivitiesId?.[
                                                item.id
                                              ]?.includes(items.id)
                                            )
                                          }
                                          label={items.name}
                                          variant="outlined"
                                          className={`adv-tags ${filterActivitiesId?.[
                                              item.id
                                            ]?.includes(items.id) &&
                                            "select-tag"
                                            }`}
                                        />
                                      ))}
                                    </Box>
                                  </Box>
                                </AdventurWrapper>
                              );
                            })
                            : null}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="end"
                          component="div"
                          sx={{ padding: "10px" }}
                        >
                          <Button
                            onClick={this.handleApplyActivityFilter}
                            className="apply-btn"
                            data-test-id="apply-filter-button"
                            variant="contained"
                            size="large"
                            color="primary"
                          >
                            {configJSON.filterBtn}
                          </Button>
                        </Box>
                      </Box>
                    </FilterWrapper>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    component="h5"
                    className="feed-label"
                  >
                    {configJSON.fanWallPostTitle}
                  </Typography>
                </Box>
              )}
              <Loader loading={isLoading} />
              <Box
                id="scrollableDiv"
                style={{
                  overflowY: "scroll",
                  height: singlePostID
                    ? "100%"
                    : "calc( 100% - 145px )",
                }}

                sx={{ width: "90%", margin: "auto"}}>
                {userPost?.length ? <InfiniteScroll
                  next={this.handlePost} scrollableTarget="scrollableDiv" className="scroll-item-flex" loader={<>{configJSON.loadingMassage}</>} hasMore={hasMoreData} dataLength={userPost.length}>

                  {userPost.map((post, index) => {
                    return <PostCard
                      index={index}
                      item={post?.attributes} id={index.toString()}
                      chatListAgainApiCall={this.chatListAgainApiCall}
                      navigation={this.props.navigation}
                      userDetails={userDetail}
                      handleLikePostAction={this.handleLikePostAction}
                      handleDeletePostLocal={this.handleDeletePostLocal}
                      updateShareCount={this.updateShareCount} />
                  })}</InfiniteScroll> : <div style={{textAlign: "center",fontSize: "30px",fontWeight: 'bold',paddingTop: "40px"}}>{configJSON.noPostFoundTitle}</div>}

              </Box>

            </Grid>

            {/* <Grid item xs={3} sm={3} md={3} style={{ backgroundColor: 'transparent', position: "relative", height: "100%" }}>
              <Notification
                setNotificationData={this.setNotificationData}
                navigation={this.props.navigation}
                id={""}
                userDetail={userDetail}
                notificationData={{
                  data: [],
                  meta: {
                    unread_notifications: 0,
                    total_pages: 0,
                  },
                }}
                reloadChatList={reloadChatList}
              />
            </Grid> */}

          </Grid>

        </Container>
      </WrapperContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const WrapperContainer = styled("section")({
  height: "100vh",
  "& .container": {
    width: '100%',
  },
  "& .main-wrapper": {
    display: "flex",
    height: "100%",
    position: "relative",
  },
  "& .left-side-container": {
    height: "100%",
    overflow: "hidden"
  },
  "& .scroll-item-flex": {
    display: "flex",
    flexDirection: "column",
    gap: "20px"
  },
  "& .search-container": {
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    }
  },
  "& .filter-button": {
    color: '#656565'
  },
  "& .filter-icon": {
    paddingRight: "10px"
  },
  "& .feed-label": {
    fontSize: "20px",
    fontWeight: "bold",
    color: "black",
    padding: "0 0 10 10"
  },
  "&  .MuiDrawer-paper": {
    width: "450px",
    boxSizing: 'border-box',
    borderRadius: '16px 0 0 16px',
    background: '#EFBFED'
  },
  "& .post": {
    position: "relative"
  },
});

const FilterWrapper = styled(Popper)({
  "& .filtermodel": {
    backgroundColor: "white",
    borderRadius: "12px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px",
    paddingTop: "20px",
    paddingInline: "10px",
    maxHeight: "80vh",
    display: "flex",
    flexDirection: "column",
  },
  "& .filterheader": {
    padding: "10px 15px 5px 15px",
    height: "65px",
    display: "flex",
    alignItems: 'center',
    justifyContent: "space-between"
  },
  "& .activetitle": {
    fontSize: "20px",
  },
  "& .subtitle": {
    fontSize: "12px",
    color: "#656565"
  },
  "& .apply-btn": {
    background: "#25AAE1",
    color: "#FFFFFF",
    fontSize: "20px",
    padding: '4px 30px',
    textTransform: "capitalize"
  },
});
const AdventurWrapper = styled("div")({
  "& .adv-title": {
    padding: "10px 15px 5px 15px",
  },
  "& .adv-tags": {
    margin: "5px 5px 5px 5px",
    cursor: "pointer"
  },
  "& .select-tag": {
    backgroundColor: "#25aae1 !important",
    color: "white !important",
    transition: "all 0.3s",
    "&:hover": {
      color: "#000000"
    }
  }

});
// Customizable Area End
