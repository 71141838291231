// Customizable Area Start
import React, { Component } from "react";
import { Box, InputAdornment, IconButton, TextField } from "@material-ui/core";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import { Formik } from "formik";
import EmojiPicker from "emoji-picker-react";
import { ChatSendMessageContainer } from "./ChatSendMessage.styles.web";
import * as Yup from "yup";

interface Props {
  message: string;
  media: any[];
  submitMessageOrMedia: (message: string, media: any[]) => void;
}
interface S {
  isOpenEmoji: boolean;
  cursorPosition: number;
  message: string;
  media: any[];
}

class ChatSendMessage extends Component<Props, S> {
  inputRef: any;
  inputFileRef: any;

  constructor(props: Props) {
    super(props);
    this.inputRef = React.createRef();
    this.inputFileRef = React.createRef();
    this.state = {
      cursorPosition: 0,
      isOpenEmoji: false,
      message: "",
      media: [],
    };
  }

  componentDidMount() {
    this.setState({
      media: this.props.media,
      message: this.props.message,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    _prevState: Readonly<S>
  ): void {
    if (
      prevProps.message !== this.props.message ||
      prevProps.media !== this.props.media
    ) {
      this.setState({ message: this.props.message, media: this.props.media });
    }
  }

  pickEmoji = (emoji: any, setFieldValue: any, messageValue: any) => {
    const ref = this.inputRef.current;
    ref.focus();
    const start = messageValue.substring(0, ref.selectionStart);
    const end = messageValue.substring(ref.selectionStart);
    const text = start + emoji.emoji + end;
    setFieldValue("message", text);
    
    this.setState(
      {
        cursorPosition: start.length + 1,
        isOpenEmoji: false
      },
      () => {
        this.inputRef.current.selectionEnd = start.length + 1;
      }
    );
  };

  chatValidationSchema = Yup.object().shape({
    message: Yup.string().trim(),
    media: Yup.array()
      .of(
        Yup.mixed()
          .test(
            "fileSize",
            "File size is too large",
            value => !value || value.size <= 10 * 1024 * 1024
          )
          .test(
            "fileType",
            "Unsupported file format",
            value =>
              !value ||
              ["image/jpeg", "image/png", "video/mp4"].includes(value.type)
          )
      )
      .test(
        "maxCount",
        "Maximum 5 files allowed",
        value => !value || value.length <= 5
      )
      .test(
        "fileRequired",
        "Please provide a message or an image or a video",
        function(value) {
          return this.parent.message || (!!value && value.length > 0);
        }
      ),
  });

  render() {
    return (
      <ChatSendMessageContainer>
        <Formik
          enableReinitialize
          initialValues={{
            message: this.state.message,
            media: this.state.media,
          }}
          validationSchema={this.chatValidationSchema}
          onSubmit={value => {
            this.props.submitMessageOrMedia(value.message, value.media);
          }}
        >
          {({ values, handleSubmit, handleChange, setFieldValue }) => {
            return (
              <form
                onSubmit={handleSubmit}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
              >
                <TextField
                  placeholder="Start typing…"
                  variant="outlined"
                  minRows={1}
                  maxRows={4}
                  multiline
                  name="message"
                  fullWidth
                  value={values.message}
                  inputRef={this.inputRef}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleChange}
                  className="message-input scrollableDiv"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="icon-container"
                          onClick={() => {
                            this.inputFileRef.current.click();
                          }}
                        >
                          <AddOutlinedIcon className="add-icon" />
                        </IconButton>

                        <Box position="relative">
                          {this.state.isOpenEmoji && (
                            <EmojiPicker
                              onEmojiClick={emoji =>
                                this.pickEmoji(
                                  emoji,
                                  setFieldValue,
                                  values.message
                                )
                              }
                              autoFocusSearch={false}
                            />
                          )}
                          <IconButton
                            className="icon-container"
                            onClick={() => {
                              this.setState({
                                isOpenEmoji: !this.state.isOpenEmoji,
                              });
                            }}
                          >
                            <SentimentSatisfiedOutlinedIcon className="emoji-icon" />
                          </IconButton>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  id="input-type"
                  style={{ display: "none" }}
                  type="file"
                  name="media"
                  ref={this.inputFileRef}
                  multiple
                  accept="image/png, image/jpg, image/jpeg, video/mp4,video/webm,video/ogg"
                  onChange={(event: any) => {
                    const files = Array.from(event.target.files);
                    setFieldValue("media", [...values.media, ...files]);
                    event.target.value = null;
                  }}
                />
                {values.media.length > 0 && (
                  <Box className="message-image-container" marginTop="15px">
                    {values.media.map((file: any, index) => (
                      <Box className="cancel-icon-container" key={index}>
                        <CancelIcon
                          className="cancel-icon"
                          onClick={() =>
                            setFieldValue(
                              "media",
                              values.media.filter((_, i) => i !== index)
                            )
                          }
                        />
                        {file.type.includes("image") ? (
                          <img
                            src={URL.createObjectURL(file)}
                            className="message-image"
                            alt={`Image ${index}`}
                          />
                        ) : (
                          <video
                            controls
                            className="message-image"
                            autoPlay
                            src={URL.createObjectURL(file)}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </form>
            );
          }}
        </Formik>
      </ChatSendMessageContainer>
    );
  }
}

export default ChatSendMessage;
// Customizable Area End
