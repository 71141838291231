// Customizable Area Start
import React from "react";

import {
  withStyles,
  Box,
  Typography,
  Grid,
  TextareaAutosize,
  Button,
  Container,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

import HotelRatingController, { Props } from "./HotelRatingController.web";
import { HotelRatingStyles } from "./HotelRating.styles.web";
import Loader from "../../../../components/src/Loader.web";
import BackButton from "../../../../components/src/BackButton.web";
import HotelDetailShow from "../../../../components/src/HotelDetailShow.web";
import ReviewDetail from "../../../../components/src/ReviewDetail.web";
import InfiniteScroll from "react-infinite-scroll-component";
import { Formik } from "formik";

class HotelRating extends HotelRatingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.hotelRatings}>
          <BackButton navigation={this.props.navigation} />
          <Typography className="page-title">Rating</Typography>
          <Container maxWidth="lg" className="detail-container">
            <Grid container spacing={2}>
              <Grid item md={7} sm={6}>
                {!this.state.isLoading && (
                  <HotelDetailShow
                    isBookMark
                    hotelName={this.state.name}
                    location={this.state.location}
                    rating={String(this.state.rating)}
                    reviewCount={String(this.state.reviews_count)}
                    hotelImage={this.state.itemImage}
                    description={this.state.description}
                  />
                )}
              </Grid>
              <Grid item md={5} sm={6}>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    rating: this.state.rating_number,
                    description: this.state.rating_description,
                  }}
                  validationSchema={this.formValidationSchema}
                  onSubmit={(value) => this.addReviewApiCall(value)}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="white-box-container">
                          <div
                            className="box-shadow-text-field"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: 60,
                            }}
                          >
                            <Rating
                              name="rating"
                              className="rating"
                              value={values.rating}
                              precision={0.5}
                              onChange={(e, value) => {
                                setFieldValue("rating", value ?? 0);
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.rating && touched.rating && (
                            <p className="error mb-20">{errors.rating}</p>
                          )}
                          <div className="box-shadow-text-field">
                            <TextareaAutosize
                              className="text-area-field"
                              minRows={5}
                              placeholder="Description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="description"
                            />
                          </div>
                          {errors.description && touched.description && (
                            <p className="error mb-20">{errors.description}</p>
                          )}
                          <Button
                            fullWidth
                            className="bookmark-button"
                            variant="contained"
                            type="submit"
                          >
                            Add Review
                          </Button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
            <Typography className="page-title mt-20">
              All Reviews ({this.state.reviews_count})
            </Typography>
            <div
              id="scrollableDiv"
              style={{
                maxHeight: "600px",
                height: "auto",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {this.state.reviewList.length > 0 ? (
                <InfiniteScroll
                  dataLength={this.state.reviewList.length}
                  next={this.fetchMoreData}
                  scrollableTarget="scrollableDiv"
                  hasMore={this.state.hasMore}
                  loader={<h4>Loading...</h4>}
                  style={{
                    height: "unser !important",
                    overflow: "unset !important",
                  }}
                  scrollThreshold={0.8}
                >
                  {this.state.reviewList.map((item) => (
                    <ReviewDetail
                      key={item.attributes.account_full_name}
                      name={item.attributes.account_full_name}
                      description={item.attributes.description}
                      time={item.attributes.created_at}
                      userImage={item.attributes.account_image}
                      userName={item.attributes.account_username}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                <p className="not-found-text">No review list</p>
              )}
            </div>
          </Container>
        </Box>
      </>
    );
  }
}
//@ts-ignore
export default withStyles(HotelRatingStyles)(HotelRating);
// Customizable Area End
