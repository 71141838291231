// Customizable Area Start
import React from "react";
import { makeStyles } from "@material-ui/core";

import { Grid, Typography } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const useStyles = makeStyles((theme) => ({
  HotelDetails: {
    borderRadius: "12px",
    padding: "20px",
    border: "0.5px solid rgba(105,134,216,0.1)",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
    marginBottom: "15px",
    "& .hotel-image-container": {
      height: "100%",
      width: "100%",
      maxHeight: "280px",
      position: "relative",
      borderRadius: "12px",
      overflow: "hidden",
      scrollBehavior: "smooth",
    },
    "& .left": {
      left: 10,
    },
    "& .right": {
      right: 10,
    },
    "& .hotel-images": {
      height: "100%",
      width: "100%",
      borderRadius: "12px",
      objectFit: "fill",
      aspectRatio: "10/8",
    },
    "& .name-text-container": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "15px",
      marginBottom: "10px",
    },
    "& .hotel-name": {
      color: "#1e1f20",
      fontFamily: "Helvetica-Bold",
      fontSize: "22px",
      fontWeight: "600",
      lineHeight: "27px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .hotel-location": {
      color: "#818181",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "17px",
      display: "flex",
      alignItems: "center",
    },
    "& .rating-star": {
      fill: "orange",
      height: "18px",
      width: "18px",
    },
    "& .ratings": {
      color: "#1e1f20",
      fontFamily: "Helvetica",
      fontSize: "22px",
      fontWeight: "400",
      display: "flex",
      alignItems: "center",
    },
    "& .location-icon": {
      fill: "#25aae1",
      height: "18px",
      width: "18px",
    },
    "& .total-reviews": {
      color: "#25aae1",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "400",
      whiteSpace: "nowrap",
    },
    "& .city-description": {
      color: "#818181",
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "26px",
      margin: "15px 0",
    },
    "& .hotel-location-text": {
      display: "-webkit-box",
      "-webkit-line-clamp": "1",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

interface Props {
  hotelName: string;
  location: string;
  description: string;
  reviewCount: string;
  rating: string;
  hotelImage: string;
  isBookMark?: boolean;
  onClick?: () => void;
}

const HotelDetailShow: React.FC<Props> = ({
  description,
  hotelName,
  hotelImage,
  location,
  rating,
  reviewCount,
  onClick,
  isBookMark = false,
}) => {
  const classes = useStyles();

  const reviewText = `(${reviewCount} Reviews)`;
  return (
    <div
      className={classes?.HotelDetails}
      onClick={onClick}
      style={{ cursor: isBookMark ? "default" : "pointer" }}
    >
      <Grid container spacing={2}>
        <Grid item md={isBookMark ? 12 : 4} sm={isBookMark ? 12 : 4}>
          <div className="hotel-image-container">
            <img className="hotel-images" src={hotelImage} alt="hotels" />
          </div>
        </Grid>
        <Grid item md={isBookMark ? 12 : 8} sm={isBookMark ? 12 : 8}>
          <div className="">
            <div className="name-text-container">
              <Typography className="hotel-name" title={hotelName}>
                {hotelName}
              </Typography>
              <Typography className="ratings">
                <StarIcon className="rating-star" />
                {rating}/5
              </Typography>
            </div>
            <div className="name-text-container">
              <div className="hotel-location">
                <LocationOnIcon className="location-icon" />{" "}
                <Typography className="hotel-location-text" title={location}>
                  {location}
                </Typography>
              </div>
              <Typography className="total-reviews">{reviewText}</Typography>
            </div>
          </div>
          <div
            className="city-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HotelDetailShow;
// Customizable Area End
