Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FanWall";
exports.labelBodyText = "FanWall Body";
exports.allPost = "bx_block_posts/posts"
exports.btnExampleTitle = "CLICK ME";
exports.likePost= "bx_block_like/likes";
exports.getPostComments = "bx_block_comment/comments/?commentable_type=BxBlockPosts::Post&commentable_id=";
exports.postComment = "bx_block_comment/comments";
exports.activities = "bx_block_activities/user_activities/get_all_activities";
exports.applyFilter= "bx_block_posts/posts/fanwall_search?query=";
exports.applyActivityFilter= "bx_block_posts/posts/filter_by_activity?query=";
exports.blockType= "BxBlockPosts::Post";
exports.filterBtn="Apply Now";
exports.activityTitle="All Activities";
exports.activitySubTitle="Let you know your interest";
exports.fanWallPostTitle="Today's Feed";
exports.fanWallPostTitle1="Trending Posts";
exports.noPostFoundTitle="No Post Found";
exports.filterBtnTitle="Filter";
exports.filterInputTitle="Search in bhomio...";
exports.filterInputTitle1="Search...";
exports.loadingMassage= "Loading...";
exports.editName= "Edit";
exports.deleteName= "Delete";
exports.reportName= "Report";
exports.browserSupportName="Your browser does not support HTML video.";
exports.suggestedTitle="Suggested";
exports.tempNameTitle="BG"
exports.tempuserNameTitle="Billy Green"
exports.tempNickNameTitle="@billygreen";
exports.removeName= "Remove";
exports.commentErrorMassage= "No Comment Yet"
exports.sendBtn="Send";
exports.errormassage="something went wrong";
exports.errormassage2=", Please login again.";
exports.reportEndponit = "bx_block_report/reports";
exports.lessTitle=" less";
exports.moreTitle=" more";
exports.logintitlealertmodal="Login / Signup";
exports.logindesalertmodal="You need to login / signup to participate in the discussion";
exports.homeScreenEndpoint= "bx_block_landingpage3/home_carousel_screens/updated_landing_page"
exports.searchbtnExampleTitle="Search in bhomio…";
exports.searchBoonmarkApiEndPoint="bx_block_explore/explore_locations/search_explore_location?per_page=20&page=";
exports.ResultTiltle=' results'
exports.ofTiltle=' of ';
exports.inTiltle= ' in ';
exports.resTitle='Showing results for';
exports.NoExploreData="No explore list";
// Customizable Area End