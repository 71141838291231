import { Box, Button, Typography, styled } from "@material-ui/core";
import React, { Component, Props } from "react";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
const RemoveUser = require("./assets/noun-remove-user-2636683.png");
const configJSON = require("./config.js");
type MyProps = {
  item: {
    [keys: string]: string | number | boolean;
    photo: string;
  };
  onFollowClick: (isClicked: boolean, users: {
    [keys: string]: string | number | boolean;
    photo: string;
  }) => void;
  navigation: { history: { push: (id: string) => void } };
  isInvited: boolean;
  isShowed: boolean;
  itineraryOwner: string | number | undefined;
};
type MyState = {
  isClicked: boolean;

};

export default class TravellerCard extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      isClicked: false,
    };
  }

  componentDidMount(): void {
    this.setState({
      isClicked: this.props.isInvited,
    });
  }

  componentDidUpdate(prevProps: { isInvited: boolean }): void {
    if (this.props.isInvited === prevProps.isInvited && this.state.isClicked === false && this.props.isShowed) {
      this.setState({
        isClicked: this.props.isInvited,
      });
    }
  }

  handleChangeUser = () => {
    this.setState({
      isClicked: !this.state.isClicked,
    });
    this.props.onFollowClick(!this.state.isClicked, this.props.item);
  };
  renderAddRemoveBtn = (isClicked: boolean, isOwner: boolean) => {
    if (isClicked && isOwner) {
      return null;
    } else if (isClicked) {
      return (
        <Button
          size="small"
          variant="contained"
          color="default"
          onClick={this.handleChangeUser}
        >
          {configJSON.removeName}
        </Button>
      )
    } else {
      return (
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={this.handleChangeUser}
        >
          {configJSON.addName}
        </Button>
      )
    }
  }
  render(): React.ReactNode {
    const { item, itineraryOwner } = this.props;
    const isOwner = itineraryOwner == item.id;
    return (
      <CardWrapper className={this.state.isClicked ? "isSelected" : ""}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <img src={item.photo} alt="user-image" className="user-image" />
          <div>
            <Typography
              style={{ cursor: "pointer", fontSize: "16px" }}
              variant="h6"
              onClick={() =>
                this.props.navigation.history.push(`/profile/${item.id}`)
              }
            >
              {item.full_name}
            </Typography>
            <Typography className="user-id-title" variant="body2">
              {"@" + item?.username}
            </Typography>
          </div>
        </Box>
        {this.renderAddRemoveBtn(this.state.isClicked,isOwner)}
      </CardWrapper>
    );
  }
}
const CardWrapper = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0",
  boxSizing: "border-box",
  padding: "0 30px",
  position: "relative",
  "& .MuiButton-root": {
    textTransform: "capitalize",
  },
  "& .user-id-title": {
    color: "gray",
    fontSize: "12px",
  },
  "& .user-image": {
    width: "58px",
    height: "58px",
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: "10px",
  },
  "& .MuiButton-containedPrimary": {
    background: "#25AAE1",
  },
  "&.isSelected": {
    "&:before": {
      position: "absolute",
      content: "''",
      height: "80%",
      width: "4px",
      background: "#25AAE1",
      top: "6px",
      left: "0",
      borderRadius: "16px",
    },
  },
});
