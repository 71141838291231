// Customizable Area Start
import React from "react";
import { makeStyles } from "@material-ui/core";

import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  reviewDetail: {
    "& .user-image": {
      height: 49,
      width: 49,
      borderRadius: "16px",
    },
    "& .user-details-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "15px",
    },
    "& .user-name": {
      color: "#1e1f20",
      fontFamily: "Helvetica-Bold",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
    },
    "& .username": {
      color: "#1e1f20",
      fontFamily: "Helvetica-Bold",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
    },
    "& .review-detail": {
      color: "#818181",
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "26px",
    },
    "& .time-detail": {
      color: "#8f92a1",
      fontFamily: "Helvetica",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "20px",
    },
  },
}));

interface Props {
  userImage: string;
  name: string;
  userName: string;
  time: string;
  description: string;
}

const ReviewDetail: React.FC<Props> = ({
  description,
  name,
  time,
  userImage,
  userName,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes?.reviewDetail} spacing={2}>
      <Grid item md={7} sm={6}>
        <div className="white-box-container">
          <div className="user-details-container mb-10">
            <div className="user-details-container">
              <img className="user-image" src={userImage} />
              <div>
                <Typography className="user-name">{name}</Typography>
                <Typography className="username">@{userName}</Typography>
              </div>
            </div>
            <Typography className="time-detail">{time}</Typography>
          </div>
          <Typography className="review-detail">{description}</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default ReviewDetail;
// Customizable Area End
