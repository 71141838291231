// Customizable Area Start
import React from "react";
import { makeStyles } from "@material-ui/core";

import { Box, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  Locations: {
    "& .location-detail-card": {
      borderRadius: "16px",
      border: "0.5px solid #8f92a1",
      padding: "20px",
      display: "flex",
      gap: "15px",
      "& .location-images-container .location-images": {
        width: "132px",
        height: "132px",
        borderRadius: "12px",
      },
      "& .location-details-container": {
        position: "relative",
        width: "100%",
        "& .location-name": {
          fontFamily: "Helvetica-Bold",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "22px",
          color: "#000",
          marginBottom: "15px",
          display: "-webkit-box",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "-webkit-box-orient": "vertical",
          "-webkit-line-clamp": "2",
          wordBreak: "break-all",
        },
        "& .location-details": {
          color: "#818181",
          fontFamily: "Helvetica",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "22px",
        },
        "& .plus-image-container": {
          width: "26px",
          height: "26px",
          color: "#fff",
          background: "#26A5E0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "8px",
          position: "absolute",
          top: 0,
          right: 0,
          "& .plus-image": {
            height: "20px",
            width: "20px",
          },
        },
      },
    },
  },
}));

interface Props {
  imageSrc: string;
  cityName: string;
  address: string;
  addLocationItem: (cityName: string) => void;
}

const ItineraryLocationDetails: React.FC<Props> = ({
  imageSrc,
  cityName,
  address,
  addLocationItem,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes?.Locations}>
      <Box className="location-detail-card">
        <Box className="location-images-container">
          <img className="location-images" src={imageSrc} alt="image" />
        </Box>
        <Box className="location-details-container">
          <Typography className="location-name" title={cityName}>
            {cityName}
          </Typography>
          <Typography className="location-details">{address}</Typography>
          <Box
            className="plus-image-container"
            onClick={() => addLocationItem(cityName)}
          >
            <AddIcon className="plus-image" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ItineraryLocationDetails;
// Customizable Area End
