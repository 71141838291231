Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.postDataMethod= "POST"
exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.getNotificationEndpoint = "bx_block_push_notifications/push_notifications?"
exports.readNotificationEndpoint = "bx_block_push_notifications/push_notifications/"
exports.searchUserEndpoint ="bx_block_search/search_users?";
exports.getChatsInfoApiEndPoint = "bx_block_chat/chats";
exports.createChatsApiEndPoint = "bx_block_chat/private_chats/create_private_chat";
exports.errorMassage = "somthing went wrong";
exports.AcceptOrDeclineItineraryEndPoint= "bx_block_itinerary/itinerary/accept_reject_invitation";
exports.LoadingTitle = " Loading...";
exports.invalidToken= "Invalid token";
exports.tokenExpire= "Token has Expired";
exports.dataNotFoundTitle="User Not Found";
exports.unreadBadgeEndPoint = "bx_block_push_notifications/push_notifications/unread_status"
// Customizable Area End