// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";
import { ChatListingContainer } from "./ChatListing.styles.web";
import ChatMessage from "./ChatMessage.web";
import ConfirmationModal from "./ConfirmationModal.web";
import EditChatMessage from "./EditChatMessage.web";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

interface Props {
  chatData: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        message: string;
        account_id: number;
        chat_id: number;
        created_at: string;
        updated_at: string;
        is_mark_read: false;
        message_type: string;
        attachments: {
          id: number;
          url: string;
          attachment_type: string;
        }[];
        account: {
          id: number;
          name: string;
          email: string;
          profile_photo: string;
        };
      };
    };
  }[];
  scrollToBottom: boolean;
  haveMoreChat: boolean;
  deleteChatMessage: (messageId: string) => void;
  deleteAttachmentMessage:(messageId: string,attachmentId:number) => void;
  editChatMessage: (message: string, messageId: string) => void;
  setFalseScrollToBottom: () => void;
  getMoreApiChatList: () => void;
  isLoading: boolean;
}

const ChatListing: React.FC<Props> = ({
  chatData,
  scrollToBottom,
  haveMoreChat,
  deleteChatMessage,
  deleteAttachmentMessage,
  editChatMessage,
  setFalseScrollToBottom,
  getMoreApiChatList,
  isLoading,
}) => {
  const listRef = useRef(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteAttachmentModal, setOpenDeleteAttachmentModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [messageId, setMessageId] = useState("");
  const [attachmentId, setAttachmentId] = useState(NaN);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (scrollToBottom) {
      scrollBottom();
      setFalseScrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToBottom]);

  const scrollBottom = () => {
    if (listRef.current) {
      //@ts-ignore
      const scrollHeight = listRef.current.scrollHeight;
      if (scrollHeight) {
        //@ts-ignore
        const height = listRef.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        //@ts-ignore
        listRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      }
    }
  };

  const getFormattedDate = (date: any) => {
    const currentDate = moment().startOf("day");
    const messageDate = moment(date);

    let formattedDate = "";

    if (messageDate.isSame(currentDate, "day")) {
      formattedDate = "Today";
    } else if (
      messageDate.isSame(currentDate.clone().subtract(1, "day"), "day")
    ) {
      formattedDate = "Yesterday";
    } else {
      formattedDate = messageDate.format("D MMMM YYYY");
    }
    return formattedDate;
  };

  return (
    <ChatListingContainer className="scrollableDiv">
      <div className="chat-container-absolute">
        <div
          id="scrollDiv"
          className="scrollableDiv"
          style={{
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
          ref={listRef}
        >
          <InfiniteScroll
            dataLength={chatData.length}
            next={getMoreApiChatList}
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              gap: "25px",
            }}
            inverse={true}
            hasMore={haveMoreChat}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollDiv"
          >
            {chatData.map((item, index) => (
              <>
                <ChatMessage
                  openDeleteModal={(id: string) => {
                    setMessageId(id);
                    setOpenDeleteModal(true);
                  }}
                  openEditModal={(id: string, message: string) => {
                    setMessageId(id);
                    setMessage(message);
                    setOpenEditModal(true);
                  }}
                  openDeleteAttachmentModal={(message_id: string, attachment_id: number) => {
                    setAttachmentId(attachment_id);
                    setMessageId(message_id)
                    setOpenDeleteAttachmentModal(true);
                  }}
                  chatData={item}
                  key={index}
                />
                {((index + 1 !== chatData.length &&
                  new Date(
                    item.data.attributes.created_at
                  ).toLocaleDateString() !==
                  new Date(
                    chatData[index + 1].data.attributes.created_at
                  ).toLocaleDateString()) ||
                  index + 1 === chatData.length) && (
                    <div className="created-date">
                      {getFormattedDate(item.data.attributes.created_at)}
                    </div>
                  )}
              </>
            ))}
          </InfiniteScroll>
        </div>
      </div>
      <ConfirmationModal
        open={openDeleteModal}
        handleCloseModal={() => {
          setOpenDeleteModal(false);
        }}
        onHandleConfirmation={() => {
          setOpenDeleteModal(false);
          deleteChatMessage(messageId);
        }}
        title="Delete Chat"
        description="Are you sure you want to delete chat?"
      />
       <ConfirmationModal
        open={openDeleteAttachmentModal}
        handleCloseModal={() => {
          setOpenDeleteAttachmentModal(false);
        }}
        onHandleConfirmation={() => {
          setOpenDeleteAttachmentModal(false);
          deleteAttachmentMessage(messageId,attachmentId);
        }}
        title="Delete Attachment"
        description="Are you sure you want to delete attachment?"
      />
      <EditChatMessage
        message={message}
        onClose={() => setOpenEditModal(false)}
        onSubmit={(message: string) => {
          setOpenEditModal(false);
          editChatMessage(message, messageId);
        }}
        open={openEditModal}
      />
    </ChatListingContainer>
  );
};

export default ChatListing;
// Customizable Area End
