import React from "react";

// Customizable Area Start
import {
  // Box,
  // Button,
  // Typography,
  // Checkbox,
  // Grid,
  // TextField,
  // FormControlLabel,
  // Link,
  // FormControl,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  TextField,
  FormControlLabel,
  Link,
  OutlinedInput,
  FormControl,
  withStyles,
} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ErrorIcon from "@material-ui/icons/Error";
import { Formik } from "formik";
import * as Yup from "yup";
import EmailAccountLoginControllerBlock, {
  Props, configJSON,
} from "./EmailAccountLoginControllerBlock.web";
import {Line} from './assets';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export const LoginBlockStyles = {
  Login: {
    "& .check-box-text": {
      "& .MuiFormControlLabel-label": {
        color: "#353249",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  },
};
const MuiBox = styled("div")({
  position: 'absolute',
  width: '100%',
  height: '300px',
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
  "& h3, & h1" : {
    color: '#ffffff',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '56px',
    letterSpacing: '1.8px',
    marginLeft: '70px',
    marginBottom: '0',
    fontFamily: 'Helvetica',
  },
  "& h1": {
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  "& .text-color":{
    color: "#ffffff",
    display: 'block',
    fontFamily: 'Helvetica',
  },
});

const MuiContainer = styled("div")({
  position: 'relative',
  "& .Carousel-box": { flex: 1, overflow: "hidden", height: "100%" },
  "& .main-img": {
    height: "100%",
    minHeight: "800px",
    width: "100%",
    objectFit: 'cover'
  }
});

const validationSchema = Yup.object({
  userName: Yup.string().when("isEmail", {
    is: "1",
    then: Yup.string()
      .required("Email / Number is required")
      .email("Invalid email address")
      .trim(),
    otherwise: Yup.string()
      .required("Email / Number is required")
      .min(10, "Phone number is required minimum 10 digits")
      .max(10, "Phone number is required maximum 10 digits")
      .trim(),
  }),
  password: Yup.string()
    .required("Password is required")
    .trim(),
  isRemember: Yup.boolean().default(true),
});
// Customizable Area End
// Customizable Area Start
class EmailAccountLoginBlock extends EmailAccountLoginControllerBlock {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <ThemeProvider theme={theme}>
          <Grid
            container
            style={{ overflowX: "hidden", height: "100%" }}
            className={this.props.classes.Login}
          >
            <Grid item xs={12} sm={6} md={6}>
              <MuiContainer>
              <Carousel
                animation="slide"
                swipe={true}
                index={this.state.index}
                indicators={false}
                autoPlay
              >
                {this.state.imgData.map((item: {data:'',img:''}, i: number) => {
                  return (
                    <Box
                      key={i}
                      className="Carousel-box"
                    >
                      <MuiBox>
                        <h3>{configJSON.maintitle}</h3>
                        <img src={Line} alt="line-object" width='100%' />
                        <h1>
                          {configJSON[item.data+'11']}
                          <br/>
                          <span className="text-color">
                            {configJSON[item.data+'22']}
                          </span>
                        </h1>
                      </MuiBox>
                      <img
                        className="main-img"
                        src={item.img}
                      />
                    </Box>
                  );
                })}
              </Carousel>
                <Box>
                  <Button
                    data-test-id={"btnForLogInScreen"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="explore-button"
                  >
                    Explore
                  </Button>
                </Box>
              </MuiContainer>
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ padding: "80px" }} className="background-color">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 0px",
                }}
              >
                <div
                  test-goBack="go_back"
                  className="go-back-button"
                  onClick={() => this.navigationFunctuon()}
                >
                  <ArrowBackIosIcon className="go-back-button-icon" />
                </div>
                <Typography
                  variant="h4"
                  align="left"
                  component="h2"
                  style={{ fontWeight: "bold", marginBottom: "20px" }}
                >
                  Login
                </Typography>
                <Typography variant="subtitle1" component="div" align="left">
                  Welcome back! Enter Your credentials to login back to your
                  profile
                </Typography>
                <Box sx={{ width: "100%", paddingTop: "30px" }}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      isEmail: Number(this.state.userName) ? "0" : "1",
                      userName: this.state.userName,
                      password: this.state.password,
                      isRemember: this.state.isRemember,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                      this.usserLogin(values);
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <UserLogin
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          error={this.state.error}
                          handleBlur={handleBlur}
                          check={() => this.handleClickShowPassword()}
                          checkPassword={this.state.checkPassword}
                        />
                      </form>
                    )}
                  </Formik>
                </Box>
                <Typography variant="subtitle1" component="div" align="center">
                  New to Bhomio?{" "}
                  <Link
                    href="OnboardingScreenBlock"
                    style={{ color: "rgb(33, 150, 243" }}
                  >
                    Register Now
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
    );
  }
}
export default withStyles(LoginBlockStyles)(EmailAccountLoginBlock);
// Customizable Area End
// Customizable Area Start
export const UserLogin = ({
  values,
  errors,
  touched,
  handleChange,
  error,
  check,
  checkPassword,
  handleBlur,
}: any) => {
  return (
    <>
      <div className="box-shadow-text-field">
        <TextField
          placeholder="Email Address/ Phone Number"
          variant="outlined"
          data-test-id="test_userName"
          className="input-field"
          fullWidth
          id="userName"
          autoFocus
          name="userName"
          value={values.userName}
          onChange={(event) => {
            handleChange("userName")(event);
            if (Number(values.userName)) {
              handleChange("isEmail")("0");
            } else {
              handleChange("isEmail")("1");
            }
          }}
          onBlur={handleBlur}
        />
      </div>
      {errors.userName && touched.userName && (
        <p className="error" style={{ marginBottom: 20, marginTop: 0 }}>
          {errors.userName}
        </p>
      )}
      <FormControl
        variant="outlined"
        fullWidth
        className="box-shadow-text-field"
      >
        <OutlinedInput
          id="password"
          name="password"
          placeholder="Password"
          value={values.password}
          onChange={handleChange}
          type={checkPassword ? "text" : "password"}
          className="input-field"
          onBlur={handleBlur}
          endAdornment={
            <InputAdornment test-id="test-Input-adornment" position="end">
              <IconButton
                test-id="butt_test"
                aria-label="toggle password visibility"
                onClick={check}
                edge="end"
              >
                {checkPassword ? (
                  <VisibilityOffOutlinedIcon style={{ color: "#CBCBCB" }} />
                ) : (
                  <VisibilityOutlinedIcon style={{ color: "#CBCBCB" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {errors.password && touched.password && (
        <p className="error mt-0 mb-20">{errors.password}</p>
      )}
      {error && (
        <Typography
          variant="subtitle1"
          component="div"
          align="left"
          style={{ color: "red", display: "flex", paddingBottom: "15px" }}
        >
          <ErrorIcon style={{ marginRight: "8px" }} />
          Please check your credentials again the account is not found
        </Typography>
      )}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <FormControlLabel
          className="check-box-text"
          control={
            <Checkbox
              checked={values.isRemember}
              name="isRemember"
              onChange={handleChange}
              style={{ color: "#25AAE1" }}
            />
          }
          label="Remember"
        />
        <Link
          href="ForgotPasswordBlock"
          style={{
            color: "#25aae1",
            fontFamily: "Helvetica",
            fontSize: "14px",
            textDecoration: "underline",
          }}
        >
          Forgot Password?
        </Link>
      </Box>
      <Button
        test-handleSubmit="test-handleSubmit"
        style={{
          background: "#25aae1",
          marginBottom: "30px",
          padding: "16px",
          textTransform: "unset",
          fontSize: "18px",
        }}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        Log in
      </Button>
    </>
  );
};
// Customizable Area End
