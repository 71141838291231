import React from "react";

import { Box } from "@material-ui/core";
import ChatListing from "../../../components/src/ChatListing.web";
import ChatSendMessage from "../../../components/src/ChatSendMessage.web.";
import ChatDetailsController, { Props } from "./ChatDetailsController.web";
import ConfirmationModal from "../../../components/src/ConfirmationModal.web";

export class ChatDetails extends ChatDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  closeConfirmationModal=()=>{
    this.setState({
      openDeleteConfirmationModal: false,
    })
  }
  handleYesModal=()=>{
    this.setState({
      openDeleteConfirmationModal: false,
    });
    this.deleteChat();
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className="main-header-chat">
          <Box className="avatar-row-chat">
            <img
              className="avatar-img"
              src={this.props.userDetails.profile_photo}
            />
          </Box>
          <Box className="chat-details-row">
            <Box className="chat-user-details">
              <p className="user-name" title={this.props.userDetails.name}>
                {this.props.userDetails.name}
              </p>
              {!this.props.userDetails.offline && (
                <p className="time">Online</p>
              )}
              {this.props.userDetails.offline && (
                <p className="time">Offline</p>
              )}
            </Box>
          </Box>
        </Box>
        <hr className="horizontal-line" />
        <ChatListing
          getMoreApiChatList={this.getMoreApiChatList}
          isLoading={this.state.isLoader}
          setFalseScrollToBottom={() => {
            this.setState({
              scrollToBottom: false,
            });
          }}
          chatData={this.state.chatListData}
          deleteChatMessage={(deleteMessageId) =>
            this.deleteChatMessage(deleteMessageId)
          }
          deleteAttachmentMessage={(deleteMessageId,attachmentId) =>{
            this.deleteAttachmentMessage(deleteMessageId,attachmentId)
          }}
          editChatMessage={(message, messageId) =>
            this.editChatMessage(message, messageId)
          }
          haveMoreChat={this.state.haveMoreChat}
          scrollToBottom={this.state.scrollToBottom}
        />
        <ChatSendMessage
          message={this.state.message}
          media={this.state.media}
          submitMessageOrMedia={(message: string, media: any[]) =>
            this.submitMessageOrMedia(message, media)
          }
        />
        <ConfirmationModal
          open={this.state.openDeleteConfirmationModal}
          handleCloseModal={this.closeConfirmationModal}
          onHandleConfirmation={this.handleYesModal}
          title="Delete chat"
          description="Are you sure want to delete chat?"
        />
      </>

      // Customizable Area End
    );
  }
}
export default ChatDetails;
// Customizable Area Start
export const Badge = () => (
  <span
    style={{
      width: "6px",
      height: "6px",
      background: "#FF4E4E",
      position: "absolute",
      top: 0,
      right: 0,
      borderRadius: "50%",
    }}
  />
);

// Customizable Area End
