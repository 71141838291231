import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  Image_Container: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    borderRadius: "16px",
    position: "relative",
    minHeight: "300px",
    cursor: "pointer",
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    "& .location-image": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
    "& .location-name": {
      position: "absolute",
      bottom: "10px",
      left: "5px",
      right: "5px",
      color: "#ffffff",
      fontFamily: "Helvetica-Bold",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "22px",
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
}));

interface Props {
  imageSrc: string;
  name: string;
  imageClick: () => void;
}

const ImageLabel: React.FC<Props> = ({ imageSrc, imageClick, name }) => {
  const classes = useStyles();
  return (
    <div
      className={classes?.Image_Container}
      onClick={() => imageClick()}
      style={{
        background: `linear-gradient(180deg, rgba(255,255,255,0) 80%, rgba(0,0,0,0.3) 100%),url(${imageSrc})`,
      }}
    >
      <label className="location-name" title={name}>
        {name}
      </label>
    </div>
  );
};

export default ImageLabel;
