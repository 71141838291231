// Customizable Area Start
import React from "react";

import {
  withStyles,
  Grid,
  Box,
  Typography,
  Container,
} from "@material-ui/core";

import ExploreDetailsController, {
  Props,
} from "./ExploreDetailsController.web";
import { ExploreDetailsStyles } from "./ExploreDetails.styles.web";
import Loader from "../../../../components/src/Loader.web";
import BackButton from "../../../../components/src/BackButton.web";
import ImageLabel from "../../../../components/src/ImageLabel.web";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import GoogleMapWeb from "../../../../components/src/GoogleMapWeb.web";
import { bookMarkImage } from "../assets";

class ExploreDetails extends ExploreDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Box className={classes.ExploreDetails}>
          <BackButton navigation={this.props.navigation} />
          <Container maxWidth="lg" className="details-container">
            {!this.state.isLoading && (
              <div className="white-box">
                {this.state.imageUrl.length > 0 && (
                  <div
                    key={`${
                      this.state.imageUrl[this.state.currentImageIndex].id
                    }${
                      this.state.imageUrl[this.state.currentImageIndex].filename
                    }`}
                    className="location-image-container"
                  >
                    <div className="carousal-button left">
                      <button
                        onClick={this.goToPreviousImage}
                        className="back-button-class"
                      >
                        <ChevronLeftIcon />
                      </button>
                    </div>
                    <img
                      src={
                        this.state.imageUrl[this.state.currentImageIndex].url
                      }
                      alt="location-image"
                      className="location-images"
                    />
                    <div className="image-count-container">
                      <label>
                        {this.state.currentImageIndex + 1}/
                        {this.state.imageUrl.length}
                      </label>
                    </div>
                    <div className="carousal-button right">
                      <button
                        onClick={this.goToNextImage}
                        className="next-image-click"
                      >
                        <ChevronRightIcon />
                      </button>
                    </div>
                  </div>
                )}
                <Box className="name-image-container">
                  <Typography className="city-name">
                    {this.state.cityName}
                  </Typography>
                  <img
                    src={bookMarkImage}
                    className="book-mark-icon"
                    onClick={this.redirectToBookmarkPages}
                  />
                </Box>
                <div
                  className="city-description"
                  dangerouslySetInnerHTML={{ __html: this.state.description }}
                />
                <Typography className="direction-name">
                  Get Directions
                </Typography>
                <div className="google-maps">
                  {
                    <GoogleMapWeb
                      //@ts-ignore
                      lat={this.state.direction.lat}
                      lng={this.state.direction.lng}
                    />
                  }
                </div>
                {this.state.categories.length > 0 && (
                  <>
                    <Typography className="direction-name mb-10">
                      What are you looking for?
                    </Typography>
                    <Grid container spacing={3}>
                      {this.state.categories.map((data) => (
                        <Grid item md={6} sm={6} key={data.attributes.name}>
                          <ImageLabel
                            imageClick={() =>
                              this.redirectToHotelPage(
                                data.id,
                                data.attributes.name
                              )
                            }
                            imageSrc={data.attributes.category_cover_image}
                            name={data.attributes.name}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </div>
            )}
          </Container>
        </Box>
      </>
    );
  }
}
//@ts-ignore
export default withStyles(ExploreDetailsStyles)(ExploreDetails);
// Customizable Area End
