import React from "react";

import {
    Box,
    Typography,
    // Customizable Area Start
    Button,
    withStyles,
    Grid,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import ChatWithNotificationController, {
    Props,
} from "./ChatWithNotificationController.web";
import { NotificationStyle } from "./NotificationStyle.web";
import {
    dummyProfile,
    boltIcon,
    emailUpdateIcon,
    hotelIcon,
    newFeatureIcon,
    passwordupdateIcon,
    phoneUpdateIcon,
    profileUpdateIcon,
    threadIcon,
} from "./assets";
import moment from "moment";
import DataContext from "../../../components/src/DataContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
// Customizable Area End

export class Notification extends ChatWithNotificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.fetchNotification(1);
    }
    renderMessage = (str: string) => {

        const str1 = str
            ?.split("'")
            .map(function (value, index) {
                if (index % 2 == 0) {
                    return value;
                } else {
                    return `"<span class="inner-msg">${value}</span>"`;
                }
            })
            .join("");


        const res = str1

            ?.split("#")
            .map(function (value, index) {
                if (index % 2 == 0) {
                    return value;
                } else {
                    return `<span class="inner-msg-gr">${value}</span>`;
                }
            })
            .join("");
        return `<p class="message">${res}</p>`;
    };
    goToNotification = (type: string, id: number) => {
        switch (type) {
            case "liked_post":
                return "/PostList/" + id;
            case "commented_post":
                return "/PostList/" + id;
            case "voted_comment":
                return "/discussion-forum";
            case "password_updated":
                return "/Profile/" + id;
            case "invite_rejected":
                return "/iteinerary/" + id;
            case "new_traveler_added":
                return "/iteinerary/" + id;
            case "invite_accepted":
                return "/iteinerary/" + id;
            case "itinerary_edited":
                return "/iteinerary/" + id;
            case "started_following":
                return "/Profile/" + id;
            case "new_post":
                return "/PostList/" + id;
            case "new_thread":
                return "/discussion-forum";
            case "profile_updated":
                return "/Profile/" + id;
            case "profile":
                return "/Profile/" + id;
            case "shared_post":
                return "/PostList/" + id;
            default:
                return "/FanWall";
        }
    };
    renderIcon = (type: string, profile_image: string) => {
        const typeToIcon: { [key: string]: string; } = {
            "privacy_updated": boltIcon,
            "email_updated": emailUpdateIcon,
            "password_updated": passwordupdateIcon,
            "phone_number_updated": phoneUpdateIcon,
            "profile_updated": profileUpdateIcon,
            "new_item_added": hotelIcon,
            "new_feature": newFeatureIcon,
            "new_thread": threadIcon,
        };



        const defaultIcon = profile_image ?? dummyProfile;

        const iconSrc = typeToIcon[type] || defaultIcon;

        return <img className="avatar-img" src={iconSrc} />;
    };
    navigateToNotification = (notify_type: string, profile_id: number) => {
        const type = ["invite_accepted", "invite_rejected", "new_traveler_added", "itinerary_edited", "itinerary_deleted"];
        this.props.navigation.history.push(
            this.goToNotification(notify_type, profile_id),
            {
                isItineraryCreated: type.includes(notify_type),
            }
        );
    };
    onHandleNotification = (
        is_read: boolean,
        id: string,
        notify_type: string,
        notify_item_id: number
    ) => {
        if (!is_read) {
            this.readNotification(id);
        }

        setTimeout(this.navigateToNotification.bind(this, notify_type === "voted_comment" ? "profile" : notify_type, notify_item_id), 500);
    };
    renderProfileType = (notify_type: string) => {
        switch (notify_type) {
            case 'voted_comment':
                return 'profile';
            case 'profile_updated':
                return 'profile';
            case 'started_following':
                return 'profile';
            case 'password_updated':
                return 'profile';
            case 'liked_post':
                return 'profile';
            case 'commented_post':
                return 'profile';
            case 'invite_accepted':
                return 'profile';
            case 'itinerary_edited':
                return 'profile';
            case 'new_traveler_added':
                return 'profile';
            case 'new_post':
                return 'profile';
            case 'new_invite':
                return 'profile';
            case 'shared_post':
                return 'profile';
            default:
                return 'notify_type';
        }

    }
    onHandleNotificationOnProfile = (
        is_read: boolean,
        id: string,
        notify_type: string,
        notify_item_id: number,
        profile_id: number,
    ) => {
        if (!is_read) {
            this.readNotification(id);
        }

        setTimeout(this.navigateToNotification.bind(this, this.renderProfileType(notify_type), this.renderProfileType(notify_type) === 'profile' ? profile_id : notify_item_id), 500);
    };
    getRenderId = (type: string, profile_id: number, notify_item_id: number) => {


        switch (type) {
            case "liked_post":
                return notify_item_id;
            case "commented_post":
                return notify_item_id;

            case "new_post":
                return notify_item_id;
            case "started_following":
                return profile_id;

            case "voted_comment":
                return notify_item_id;

            case "profile_updated":
                return profile_id;

            case "shared_post":
                return notify_item_id;

            default:

                break;
        }

    }
    onHandleNotificationContent = ({ is_read, id, notify_type, notify_item_id, profile_id }: {
        is_read: boolean,
        id: string,
        notify_type: string,
        notify_item_id: number,
        profile_id: number
    }) => {
        if (!is_read) {
            this.readNotification(id);
        }

        const renderId = this.getRenderId(notify_type, profile_id, notify_item_id) ?? NaN;

        if (renderId) {
            setTimeout(this.navigateToNotification.bind(this, notify_type, renderId), 500);
        }

    }

    renderTime = (created_at: string) => {
        const today = moment(new Date()).format("L");
        if (today == moment(created_at).format("L")) {
            return moment(created_at).format("LT");
        } else {
            return moment(created_at).format("L");
        }
    };
    renderNotificationButton = (
        notify_type: string,
        index1: number,
        notify_item_id: number,
        profile_id: number,
        is_read: boolean,
        id: string
    ) => {
        switch (notify_type) {
            case "new_traveler_added":
                return (
                    <Button
                        style={{
                            background: "#25AAE1",
                            color: "white",
                            fontSize: "12px",
                            padding: "2px 4px",
                            fontFamily: "HelveticaNeue",
                            marginRight: "10px",
                            borderRadius: "4px",
                            textTransform: "none",
                            width: "50%",
                        }}
                        onClick={this.onHandleNotification.bind(this, is_read, id, notify_type, notify_item_id)}
                    >
                        View Itinerary
                    </Button>
                );
            case "invite_accepted":
                return (
                    <Button
                        key="View ininerary"
                        style={{
                            background: "#25AAE1",
                            color: "white",
                            fontSize: "12px",
                            padding: "2px 4px",
                            fontFamily: "HelveticaNeue",
                            marginRight: "10px",
                            borderRadius: "4px",
                            textTransform: "none",
                            width: "50%",
                        }}
                        onClick={this.onHandleNotification.bind(this, is_read, id, notify_type, notify_item_id)}
                    >
                        View Itinerary
                    </Button>
                );

            case "new_invite":
                return (
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "4px",
                        }}
                    >
                        <Button
                            style={{
                                background: "#25AAE1",
                                color: "white",
                                fontSize: "12px",
                                padding: "2px 4px",
                                fontFamily: "HelveticaNeue",
                                marginRight: "10px",
                                borderRadius: "4px",
                                textTransform: "none",
                            }}
                            onClick={this.onAcceptItinerary.bind(
                                this,
                                index1,
                                notify_item_id,
                                "accept"
                            )}
                        >
                            Join Itinerary
                        </Button>
                        <Button
                            style={{
                                background: "white",
                                color: "#25AAE1",
                                borderRadius: "4px",
                                border: "1px solid #25AAE1",
                                fontSize: "12px",
                                padding: "2px 4px",
                                fontFamily: "HelveticaNeue",
                                marginRight: "10px",
                                textTransform: "none",
                            }}
                            onClick={this.onAcceptItinerary.bind(
                                this,
                                index1,
                                notify_item_id,
                                "decline"
                            )}
                        >
                            Reject Invite
                        </Button>
                    </Box>
                );

            default:
                break;
        }
    };
    checkBlockedUser = (blocked_by_user: boolean, blocked_by_me: boolean, is_read: boolean, id: number,) => {
        if (!is_read) {
            this.readNotification(id);
        }
        if (blocked_by_user) {
            toast.warning("You are blocked by this user")
        }
        else if (blocked_by_me) {
            toast.warning(`You have blocked this user`)
        }
    }
    onPressProfile = ({ blocked_by_user, blocked_by_me, is_read, id, title, profile_id, notify_type, notify_item_id }: { blocked_by_user: boolean, blocked_by_me: boolean, is_read: boolean, id: string, title: string, profile_id: number, notify_type: string, notify_item_id: number }) => {
        if ((blocked_by_me || blocked_by_user) && title) {
            this.checkBlockedUser(blocked_by_user, blocked_by_me, is_read, Number(id))
        } else {
            this.onHandleNotificationOnProfile(is_read, id, notify_type, notify_item_id, profile_id)
        }
    }
    renderNotificationCount = (count: number) => {
        if(count>0){
            return (
                <p className="count-p">
                    {this.props.notificationData?.meta?.unread_notifications ?? 0}
                </p>
            )
        }
       
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Grid lg={10} sm={12} className="notification-heading">

                    <Typography style={{ fontFamily: "HelveticaNeue" }}>
                        Notification
                    </Typography>





                    <span data-test-id="render-notification-count" className="message-count">
                        {this.renderNotificationCount(this.props.notificationData?.meta?.unread_notifications)}
                    </span>
                </Grid>
                <Box id="notificationScroll" className="message-view">
                    <InfiniteScroll
                        next={this.handleNextPageNotification}
                        scrollableTarget="notificationScroll"
                        dataLength={this.props.notificationData?.data?.length}
                        hasMore={this.state.notificationPage < this.props.notificationData?.meta?.total_pages}
                        loader={<>{"Loading..."}</>}
                    >


                        {this.props?.notificationData?.data?.map(
                            ({
                                id, attributes: { notify_item_id, images_and_videos, description, title, profile_image, is_read, created_at, notify_type, notify_item_type, profile_id, blocked_by_user, blocked_by_me },
                            }: any, index1: number) => (
                                <Box style={{ textDecoration: "none", color: "inherit" }} className="notification-list">
                                    <Box data-test-id={`icon-click${index1}`} onClick={this.onPressProfile.bind(this, { blocked_by_user, blocked_by_me, is_read, id, title, profile_id, notify_type, notify_item_id })} className="avatar-row">
                                        {this.renderIcon(notify_type, profile_image)}
                                        {!is_read && <span className="notify" />}
                                    </Box>
                                    <Box className="content-row content-size">
                                        <Box className="avatar-title-row">
                                            <Grid lg={6} md={6} style={{ cursor: "pointer" }}>

                                                {notify_item_type === "BxBlockPosts::Post" && (<p data-test-id="avatar-row-btn" onClick={this.onPressProfile.bind(this, { blocked_by_user, blocked_by_me, is_read, id, title, profile_id, notify_type, notify_item_id })}>{title}</p>)}

                                            </Grid>
                                            <Grid lg={6} md={6}>
                                                <p className="time">{this.renderTime(created_at)}</p>
                                            </Grid>
                                        </Box>
                                        <div
                                            test-id-data="content-row-btn"
                                            style={{ cursor: "pointer" }}

                                            onClick={(blocked_by_me || blocked_by_user) && title ? this.checkBlockedUser.bind(this, blocked_by_user, blocked_by_me, is_read, id) : this.onHandleNotificationContent.bind(this, { is_read, id, notify_type, notify_item_id, profile_id })}

                                            dangerouslySetInnerHTML={{
                                                __html: this.renderMessage(description),
                                            }}
                                        />
                                        <Box
                                            style={{ cursor: "pointer" }}

                                            onClick={(blocked_by_me || blocked_by_user) ? this.checkBlockedUser.bind(this, blocked_by_user, blocked_by_me, is_read, id) : this.onHandleNotificationContent.bind(this, { is_read, id, notify_type, notify_item_id, profile_id })}
                                        >
                                            {images_and_videos !== "" &&

                                                images_and_videos?.map(
                                                    ({ type, url }: { type: string; url: string }) =>
                                                        type === "image" && (
                                                            <img className="gallary-img" src={url} />
                                                        )
                                                )}
                                        </Box>
                                        {this.renderNotificationButton(
                                            notify_type,
                                            index1,
                                            notify_item_id,
                                            profile_id,
                                            is_read,
                                            id
                                        )}
                                    </Box>
                                </Box>
                            )
                        )}
                    </InfiniteScroll>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

//@ts-ignore
export const WrappedNotification = withStyles(NotificationStyle)(Notification);
// Customizable Area Start
export default (props: any) => (
    <DataContext.Consumer>
        {({ notificationData, setNotificationData }: any) => {
            return (
                <WrappedNotification
                    {...props}
                    notificationData={notificationData}
                    setNotificationData={setNotificationData}
                />
            );
        }}
    </DataContext.Consumer>
);
export const Badge = () => (
    <span
        style={{
            width: "6px",
            height: "6px",
            background: "#FF4E4E",
            position: "absolute",
            top: 0,
            right: 0,
            borderRadius: "50%",
        }}
    />
);

// Customizable Area End
