import React, { CSSProperties } from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Button

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../../components/src/Loader";
import { Link } from 'react-router-dom'
// Customizable Area End

import UserProfileController, { Props } from "../UserProfileController.web";

export default class Itinerary extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.fetchItinerary(this.props.id);
  }
  renderCountLoc = (location: any) => {
    let days = 0;
    let add = ''
    location?.forEach((loc: any) => {
      days += loc?.days;
      add = add + loc?.title + ", ";
    });
    add = add.substring(0, add.length - 2);
    return `${days} - Day ${add}`
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={webStyle.container} className="scrollableDiv">
        {console.log(this.props.userDetails)}
        <Loader loading={this.state.isLoader} />
        {this.state?.itineraryList?.map((itinery: any) => (
          <ItineraryCard
            id={itinery?.id}
            userId={this.props.id}
            title={itinery?.title}
            navigation={this.props.navigation}
            desc={this.renderCountLoc(itinery?.itinerary_locations)}
            btnTxt={this.props?.id == itinery?.owner_id ? "Created" : "Shared"}
          />
        ))}
        {this.state.itineraryList.length == 0 && !this.state.isLoader && <p>Not found itinerary</p>}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    margin: "20px",
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '90%',
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    boxSizing: "border-box",
    paddingBottom: "0px !important"
  } as CSSProperties,
  iterHeading: {
    fontSize: "20px",
    fontFamily: "HelveticaNeue",
    color: "#1E1F20"
  } as CSSProperties,
  iterDesc: {
    fontSize: "16px",
    fontFamily: "HelveticaNeue",
    color: "#8F92A1",
    margin: "5px 2px 0 0"
  } as CSSProperties,
  iterContainer: {
    padding: "20px",
    border: "1px solid #8F92A1",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  } as CSSProperties,
  linkStyle: {
    textDecoration: "none !important"
  },
  Btn1Style: {
    padding: "5px 10px",
    background: "#25AAE1",
    color: "white",
    textTransform: "none"
  } as CSSProperties,
  Btn2Style: {
    padding: "5px 10px",
    background: "white",
    color: "#25AAE1",
    border: "1px solid #25AAE1",
    textTransform: "none"
  } as CSSProperties
};

export const ItineraryCard = ({
  id,
  title,
  desc,
  btnTxt,
  navigation,
  userId
}: {
  id: string | number
  title: string;
  desc: string;
  btnTxt: string;
  navigation: {history: {push:(data: string,id:{})=> void}},
  userId: string,
}) => (
  <Box style={webStyle.iterContainer}>
    <Box>
      <Typography style={webStyle.iterHeading}>{title}</Typography>
      <p style={webStyle.iterDesc}>{desc}</p>
    </Box>
    <Box>
      <div onClick={()=> navigation.history.push(`/iteinerary/${id}`,{userId})} style={{ textDecoration: "none" }}>
        <Button
          style={btnTxt === "Created" ? webStyle.Btn1Style : webStyle.Btn2Style}
        >
          {btnTxt}
        </Button>
      </div>
    </Box>
  </Box>
);
// Customizable Area End
