Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.contentTypeApiAddDetail = "application/json";
exports.apiMethodTypeAddDetail = "POST";
exports.create_login = "create_login"
exports.pass ="Admin@123"
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.head1title1 = "Share your";
exports.head1title2 = "Travel";
exports.head1title3 = "Join our community!";
exports.head2title1 = "Discover";
exports.head2title2 = "Hidden gems";
exports.head2title3 = "By travelers, for travelers";
exports.head3title1 = "Plan";
exports.head3title2 = "an Adventure";
exports.head3title3 = "With your utltimate  travel companion";
exports.maintitle = 'Let’s Go!';
exports.head1title11 = "Hello,";
exports.head1title22 = "Guest";
exports.head2title11 = "Hello,";
exports.head2title22 = "Guest";
exports.head3title11 = "Hello,";
exports.head3title22 = "Guest";

// Customizable Area End