// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./../config");
import { debounce } from 'lodash';

export interface Props {
    id: string;
    userDetails: { id: string, type: string, attributes: { full_name: string, email: string, full_phone_number: string, gender: string, date_of_birth: string, state: string, city: string, activated: boolean, photo: string, is_following: boolean, interests: string[] } };
    navigation: { history: { push: (data: string) => void, replace: (data: string) => void },goBack:()=>void },
}
// Customizable Area End
export interface S {
    // Customizable Area Start
    FollowersState: boolean;
    FollowerRemoveState: boolean;
    followerBlockState: boolean;
    accessToken: string | null,
    followersList: any,
    serachValue: string,
    blockValue: any,
    followBackData: any,
    followerListById: any,
    getFollowingsList: Array<number>,
    removeEndponit: string
    isLoader: boolean,
    followersListData: any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start

    // Customizable Area End
}
// Customizable Area Start
export default class FollowersController extends BlockComponent<
    Props,
    S,
    SS
> {

    FollowersGetId: string = "";
    FollowersBLockId: string = "";
    searchFollowersId: string = "";
    FollowBackId: string = "";
    removeFollowApiCallId: string = "";
    debouncedSearch: any
    getFollowListByIDS: string = "";
    reportFollowApiCallId: string = ""
    getFollowingsList: any;
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.state = {
            // Customizable Area Start
            FollowersState: false,
            FollowerRemoveState: false,
            followerBlockState: false,
            accessToken: localStorage.getItem("authToken"),
            followersList: [],
            serachValue: "",
            blockValue: "",
            followBackData: "",
            followerListById: "",
            getFollowingsList: [],
            removeEndponit: configJSON.FollowingRemoveFollowing,
            isLoader: false,
            followersListData: []
            // Customizable Area End
        };
        this.debouncedSearch = debounce(this.handleSearchfunction, 800);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getFollowersList()
    }

    // this funtion for follower list api response store in state.
    followerListIdAPICall = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.FollowersGetId) {
            if (responseJson) {
                this.setState({ followersList: responseJson.data.followers, followersListData: responseJson.data.followers, isLoader: false })
            }
        }
    }

    // this function call  followers search api by user Id 
    handleSearchfunction = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.searchFollowersId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followerSearchFollowers}?search_by_follower=${this.state.serachValue}&user_id=${this.props.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSearch = () => {
        const data = this.state.followersListData.data?.filter((item: any) => { return item.attributes.full_name.toLowerCase().includes(this.state.serachValue.toLowerCase()) })
        if (this.state.serachValue) {
            this.setState({ followersList: { data: data } })
        } else {
            this.setState({ followersList: this.state.followersListData })
        }
    };

    // this function for user block api responce 
    followerBlockAPICall = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.FollowersBLockId) {
            if (responseJson) {
                this.getFollowersList()
            }
        }
    }

    // this funtions for follower search api responces store in state
    followerSerachAPICall = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.searchFollowersId) {
            if (responseJson) {
                this.setState({ followersList: responseJson })
            }
        }
    }

    // this api work for remove followers in list api reponse 
    removeFollowerAPICall = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.removeFollowApiCallId) {
            if (responseJson) {
                this.getFollowersList()
            }
        }
    }

    // this funtion call after followe back user get update response
    FollowBackAPICall = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.FollowBackId) {
            if (responseJson.errors) {
                alert(responseJson.errors[0].message)
            } else {
                this.getFollowersList()
            }
        }
    }

    // this funtion for get followelist by id
    getFollowListByIdAPICall = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.getFollowListByIDS) {
            if (responseJson.errors) {
                console.log("responseJson.error", responseJson.errors)
            } else {
                this.setState({ followersList: responseJson })
            }
        }
    }

    reportFollowerAPICall = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.reportFollowApiCallId) {
            if (responseJson) {
                this.getFollowersList()
            }
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            this.followerListIdAPICall(from, message)
            this.followerBlockAPICall(from, message)
            this.followerSerachAPICall(from, message)
            this.removeFollowerAPICall(from, message)
            this.FollowBackAPICall(from, message)
            this.getFollowListByIdAPICall(from, message)
            this.reportFollowerAPICall(from, message)
        }
        // Customizable Area End
    }

    // getFollowerList function call for get followers data by user id
    getFollowersList = () => {
        this.setState({ isLoader: true })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.FollowersGetId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followerList}?id=${this.props.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // followerHandel function  user two think followback and remove follower 
    followerhandel = (value: any, flag: boolean) => {
        if (this.props.id == this.props.userDetails.id) {
            this.setState({ removeEndponit: configJSON.followersRemoveFollower })
        }
        if (flag) {
            this.setState({ FollowersState: !this.state.FollowersState, blockValue: value })
        } else {
            const formdata = new FormData();
            formdata.append("follow[following_id]", value);
            this.setState({ followBackData: formdata }, () => this.followBack())
        }
    }

    // seach handel function when we user search in user debounced function run
    searchHandel = (value: string) => {
        this.setState({ serachValue: value })
        this.debouncedSearch();
    }

    //  onHandleRemoveYes funtion use for remove followers user in follower list
    onHandleRemoveYes = () => {
        this.setState({ FollowerRemoveState: false })

        const header = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removeFollowApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.removeEndponit
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({ id: this.state.blockValue.follower_id })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DELETE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // onHandlerBlockYes funtion user for block user
    onHandleBlockYes = () => {
        let data = configJSON.followingBlcok

        if (this.props.id == this.props.userDetails.id) {
            data = configJSON.followersBlockFollower
        }

        this.setState({ followerBlockState: false })

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.FollowersBLockId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({ id: this.state.blockValue.follower_id })
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiUpdateUserType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    onHandleBlock = () => {
        this.setState({ followerBlockState: !this.state.followerBlockState, FollowersState: false })
    }

    onHandleRemoveFollower = () => {
        this.setState({ FollowerRemoveState: !this.state.FollowerRemoveState, FollowersState: false })
    }

    // onHandleReport funtion call for report any user in followers list
    onHandleReport = () => {
        this.setState({ FollowersState: false })

        const header = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.state.accessToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reportFollowApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reportEndponit
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                "reportable_id": this.state.blockValue.follower_id,
                "reportable_type": "AccountBlock::Account"
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // this followBack funtion call followback api 
    followBack = () => {
        const headers = {
            "token": this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.FollowBackId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.followeringCreate
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            this.state.followBackData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }


    getFollowerListById = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.accessToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFollowListByIDS = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_followers/user_follower_profile?id=39`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // this on Handle Cloase function user cloase the box
    onHandleCLoase = () => {
        this.setState({ FollowersState: false })
    }

}

// Customizable Area End