// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../../../components/src/utils.web";
import * as Yup from "yup";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  itemImage: string;
  isLoading: boolean;
  location: string;
  reviews_count: number;
  hotelId: string;
  rating: number;
  description: string;
  name: string;
  reviewList: {
    id: string;
    attributes: {
      reviewable_type: string;
      description: string;
      created_at: string;
      account_full_name: string;
      account_image: string;
      account_username: string;
    };
  }[];
  page: number;
  limit: number;
  total_page: number;
  hasMore: boolean;
  rating_number: number;
  rating_description: string;
  rating_id: string;
}

interface SS {
  id: any;
}

export default class HotelRatingController extends BlockComponent<
  Props,
  S,
  SS
> {
  hotelDetailsApiCallId: string = "";
  allReviewDetailsApiCallId: string = "";
  addReviewApiCallId: string = "";
  singleRatingApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
      hotelId: "0",
      name: "",
      location: "",
      rating: 0,
      reviews_count: 0,
      description: "",
      itemImage: "",
      reviewList: [],
      page: 1,
      limit: 20,
      total_page: 1,
      hasMore: false,
      rating_description: "",
      rating_number: 4.5,
      rating_id: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const hotelId = this.props.navigation.getParam("hotelId");
    const ratingId = this.props.navigation.getParam("ratingId");
    this.setState(
      {
        hotelId: hotelId,
        rating_id: ratingId ?? "",
      },
      async () => {
        await this.getHotelDetails();
        await this.getAllReviewDetails();
        this.state.rating_id && (await this.getSingleRatingDetails());
      }
    );
  }

  formValidationSchema = Yup.object().shape({
    rating: Yup.number().required("Rating is required"),
    description: Yup.string().trim().required("Description is required"),
  });

  getHotelDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.hotelDetailsApiCallId = await apiCall({
      endPoint: `bx_block_explore/explore_items/${this.state.hotelId}`,
      method: "GET",
    });
  };

  getSingleRatingDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.singleRatingApiCallId = await apiCall({
      endPoint: `bx_block_explore/review_explores/${this.state.rating_id}`,
      method: "GET",
    });
  };

  fetchMoreData = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      async () => {
        await this.getAllReviewDetails();
      }
    );
  };

  getAllReviewDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.allReviewDetailsApiCallId = await apiCall({
      endPoint: `bx_block_explore/review_explores/?reviewable_type=BxBlockExplore::ExploreItem&reviewable_id=${this.state.hotelId}&page=${this.state.page}&per_page=${this.state.limit}`,
      method: "GET",
    });
  };

  addReviewApiCall = async (value: { rating: number; description: string }) => {
    this.setState({
      isLoading: true,
    });
    const data = {
      reviewable_type: "BxBlockExplore::ExploreItem",
      reviewable_id: this.state.hotelId,
      rating: value.rating,
      description: value.description,
    };
    this.addReviewApiCallId = this.state.rating_id
      ? await apiCall({
        endPoint: `bx_block_explore/review_explores/${this.state.rating_id}`,
        method: "PUT",
        contentType: "application/json",
        body: JSON.stringify(data),
      })
      : await apiCall({
        endPoint: `bx_block_explore/review_explores`,
        method: "POST",
        contentType: "application/json",
        body: JSON.stringify(data),
      });
  };

  handleErrorRating = (error: any) => {
    if (Array.isArray(error)) {
      if (
        error[0].token === "Invalid token" ||
        error[0].token === "Token has Expired"
      ) {
        window.localStorage.removeItem("authToken");
        
        toast.error(`${error[0].token}, Please login again.`);
        this.props.navigation.navigate("EmailAccountLoginBlock")
        window.localStorage.removeItem("user_details");
        return;
      } else if (error[0]) {
        toast.error(error[0]);

        this.props.navigation.goBack();
        return;
      }
    }
    toast.error("Something went to wrong please retry");
  };

  handleResponseRating = async (apiRequestCallId: any, responseJson: any) => {
    if (
      apiRequestCallId === this.hotelDetailsApiCallId &&
      responseJson.items.data
    ) {
      const data = responseJson.items.data.attributes;
      this.setState({
        rating: data.rating,
        name: data.name,
        itemImage: data.item_cover_image,
        location: data.location,
        description: data.description,
      });
    } else if (
      apiRequestCallId === this.allReviewDetailsApiCallId &&
      responseJson.data &&
      responseJson.meta
    ) {
      const total_pages = responseJson.meta.total_pages;
      const hasMore =
        this.state.page < responseJson.meta.total_pages ? true : false;
      this.setState({
        reviewList: [...this.state.reviewList, ...responseJson.data],
        reviews_count: responseJson.meta.reviews_count,
        hasMore,
        total_page: total_pages,
      });
    } else if (
      apiRequestCallId === this.addReviewApiCallId &&
      responseJson.data
    ) {
      toast.success(
        this.state.rating_id
          ? "Hotel review updated successfully"
          : "Hotel review added successfully"
      );
      this.props.navigation.history.goBack();
    } else if (
      apiRequestCallId === this.singleRatingApiCallId &&
      responseJson.data
    ) {
      this.setState({
        rating_number: responseJson.data.attributes.rating,
        rating_description: responseJson.data.attributes.description,
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.errors) {
        this.handleErrorRating(responseJson.errors);
      }
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        this.handleResponseRating(apiRequestCallId, responseJson);
      }

      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
