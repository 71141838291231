// Customizable Area Start
import React from "react";

import {
  TextField,
  Grid,
  Box,
  Typography,
  Button,
  Select,
  styled,
  InputAdornment,
  MenuItem,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  CircularProgress,
} from "@material-ui/core";
const configJSON = require("./config");
import { addIcon, mapIcon, EditIcon } from "./assets";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ItineraryCreateController, {
  Props,
} from "./ItineraryCreateController.web";
import TravellerCard from "../../../components/src/travellerCard.web";
import { ArrayHelpers, FieldArray, Formik, FormikErrors, FormikValues } from "formik";
import InfiniteScroll from "react-infinite-scroll-component";
import PostOrItineraryContainer from "../../../components/src/PostOrItineraryContainer.web";
import ItineraryLocation from "./ItineraryLocation.web";
import Loader from "../../../components/src/Loader.web";
import BackButton from "../../../components/src/BackButton.web";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete, {
  geocodeByAddress, getLatLng
} from "react-google-places-autocomplete";

const videoFormat = ["video/mp4", "video", "video/webm", "video/ogg"];


function RenderItinerary(props: { children?: React.ReactNode; isEdit: boolean; navigation: any, currentTabline: string, isUnAuth: boolean }) {
  const { isEdit, navigation, children, currentTabline, isUnAuth } = props;
  return isUnAuth ? <>{children}</> : isEdit ? <WrapperContainers container>
    <BackButton navigation={navigation} />
    {currentTabline === "form1" ? <Formone variant="h4" >{configJSON.editItineraryTitle}</Formone> : null}
    <Grid className="itinerary-container" item xs={12}>
      <Grid className="itinerary-container" item xs={7}>
        {children}
      </Grid>
    </Grid>

  </WrapperContainers> : <PostOrItineraryContainer
    navigation={navigation}
    title="Create New"
  >
    {children}
  </PostOrItineraryContainer>;
}
class ItineraryCreate extends ItineraryCreateController {
  constructor(props: Props) {
    super(props);
  }
  

  renderActivityCard = (activitys: { images: { url: string; type: string; }[]; isSaved: boolean; title: string; description: string; location: string; }, setFieldValue: any, placeIndex: number, index: number, activityIndex: number) => {
    const images = activitys.images.length > 4 ? [...activitys.images].splice(0, 3) : activitys.images
    return <ActivityCard>
      <ActivityTittle >
        <ActivityLabel variant="h4">
          {activitys.title}
        </ActivityLabel>
        



        <img src={EditIcon} style={{ cursor: "pointer",position:'absolute',right:'-20px',top:'7px' }} alt="edit" data-test-id="editIcon" onClick={this.setCustomField.bind(this, { setFieldValue, key: `addplaces[${placeIndex}].plans[${index}].activity[${activityIndex}].isSaved`, value: false })} />
      </ActivityTittle>
      <SubtitleOne variant="subtitle1" >
        <img src={mapIcon} style={{ marginRight: "5px" }} /> {activitys.location}
      </SubtitleOne>
      <UploadedImage >

        {activitys.images.length != 0 &&
          images.map((item) => {
            return (
              <UploadedImages className="uploaded-image">
                {videoFormat.includes(item.type) ? (
                  <video autoPlay={false} src={item.url} />
                ) : (
                  <img src={item.url} />
                )}
              </UploadedImages>
            );
          })}
        {activitys.images.length > 4 ?
          <UploadesdImages className="uploaded-image">
            <div className="multiple-images">+{activitys.images.length - 3}</div>
          </UploadesdImages>
          : null}
      </UploadedImage>
      <Typography variant="subtitle1">
        {activitys.description}
      </Typography>
    </ActivityCard>
  }




  setCustomField = ({ setFieldValue, key, value }: { setFieldValue: (key: string, value: any) => void, key: string, value: any }) => {
    setFieldValue(key, value)
  }


  renderAvatar = () => {
    const traveller = [...this.state.itinerary.traveller].splice(0, 4)

    return <AvterDiv style={{ display: "flex", flexWrap: "wrap" }}>{this.state.itinerary.traveller.length <= 5 ?
      <>{this.state.itinerary.traveller.map((item, index) => {
        return <Avatar alt="Traveller-img" style={{ marginLeft: `${index === 0 ? "0" : "-8px"}`, zIndex: this.state.itinerary.traveller.length - index }} src={item.photo} />
      })}</>
      :
      <>
        {this.state.isUsersExpand ? <>
          {this.state.itinerary.traveller.map((item, index) => {
            return <Avatar alt="Traveller-img" style={{ marginLeft: `${index === 0 ? "0" : "-8px"}`, zIndex: this.state.itinerary.traveller.length - index }} src={item.photo} />
          })}

          <Typography className="less-title" onClick={this.handleUsers.bind(this, false)}>{configJSON.lessTitle}</Typography>

        </> : <>
          {traveller.map((item, index) => {
            return <Avatar alt="Traveller-img" style={{ marginLeft: `${index === 0 ? "0" : "-8px"}`, zIndex: this.state.itinerary.traveller.length - index }} src={item.photo} />
          })}
          <Avatar data-test-id="expend-avatar" style={{ cursor: "pointer", marginLeft: "-8px" }} onClick={() => this.handleUsers(true)} className="addAvatar">
            +
          </Avatar>
        </>
        }
      </>
    }</AvterDiv>
  }




  
  renderActivity(activitys: { activity_id?: number; images: { url: string; type: string; }[]; isSaved: boolean; title: string; description: string; location: string; isclicked?: boolean; }, handleChange: any, allIndexs: { placeIndex: number, index: number, activityIndex: number }, arrayHelpers: any, setFieldValue: any, data: { day_rank?: number, place_id?: number },
    error: { errors: { addplaces: { plans: { activity: { title: '', location: '', description: '' }[] }[] }[] }, touched: { addplaces: { plans: { activity: { title: boolean, location: boolean, description: boolean }[] }[] }[] } }) {
    const error1 = error?.errors?.addplaces && error?.errors?.addplaces[allIndexs.placeIndex]?.plans[allIndexs.index]?.activity[allIndexs.activityIndex] || {};
    let isclicked = false;
    const clicked = activitys.isclicked;
    let setClickedValue = (value: boolean) => {
      isclicked = value
    }
    return <div style={{ padding: "0 30px" }}>
      {this.checkEditForUser() || !this.state.isEdit ? <div style={{ textAlign: "end" }}>
        <CancelIcon style={{ cursor: "pointer" }} data-test-id="cancelIcon" onClick={this.handleRemoveActivity.bind(this, { arrayHelpers, activityIndex: allIndexs.activityIndex, activity_id: activitys.activity_id, day_rank: data.day_rank, place_id: data.place_id })} />
      </div> : null}
      <AddPlaces>
        <TextField
          className="itinerary_details"
          variant="outlined"
          placeholder="Title"
          value={activitys.title}
          fullWidth
          id="title"
          onChange={handleChange}
          name={`addplaces[${allIndexs.placeIndex}].plans[${allIndexs.index}].activity[${allIndexs.activityIndex}].title`}
          type="text"
        />
      </AddPlaces>
      {clicked && (
        <p className="error">
          {error1?.title || ""}
        </p>
      )}
      <LocationDiv>
        <TextField
          id="location_details_id"
          className="itinerary_details itinerary_location"
          onChange={handleChange}
          value={activitys.location}
          placeholder="Location"
          fullWidth
          onClick={
            this.openItineraryLocationPage.bind(this,
              allIndexs.placeIndex,
              allIndexs.index,
              allIndexs.activityIndex,
              activitys.location
            )
          }
          name={`addplaces[${allIndexs.placeIndex}].plans[${allIndexs.index}].activity[${allIndexs.activityIndex}].location`}
        />
      </LocationDiv>
      {clicked && (
        <p className="error">
          {error1?.location || ""}
        </p>
      )}
      <AutoPlays>
        <FieldArray
          name={`addplaces[${allIndexs.placeIndex}].plans[${allIndexs.index}].activity[${allIndexs.activityIndex}].images`}
          render={(arrayHelper) => (<>
            {activitys.images?.length != 0 &&
              activitys.images?.map((item, imageIndex) => {
                return (
                  <div className="uploaded-image">
                    <CancelIcon
                      data-test-id="CancelIconimage"
                      onClick={this.handleRemoveImage.bind(this,{arrayHelper, imageIndex,activity_id: activitys.activity_id,day_rank: data.day_rank, place_id:data.place_id, imageUrl:item.url})}
                    />
                    {videoFormat.includes(item.type) ? (
                      <video autoPlay src={item.url} />
                    ) : (
                      <img src={item.url} />
                    )}
                  </div>
                );
              })}

            <div
              className="upload-image-container"
            >
              <img src={addIcon} className="add-image" />
              <input
                id="myInput"
                type="file"
                data-test-id="handle-activity-image"
                onChange={(e) => this.activityImageChange(e, arrayHelper)}
                name={`addplaces[${allIndexs.placeIndex}].plans[${allIndexs.index}].activity[${allIndexs.activityIndex}].images`}
                style={{ opacity: 0, position: "absolute", width: "100%", left: "0", top: "0", height: "100%", cursor: "pointer" }}
                accept="image/png, image/jpg, image/jpeg, video/mp4,video/webm,video/ogg"
                multiple
              />
            </div>
          </>)}
        />

      </AutoPlays>
      <ItineryFiledAdd>
        <TextField
          className="itinerary_details"
          placeholder="Description"
          onChange={handleChange}
          minRows={3}
          fullWidth
          value={activitys.description}
          name={`addplaces[${allIndexs.placeIndex}].plans[${allIndexs.index}].activity[${allIndexs.activityIndex}].description`}
        />
      </ItineryFiledAdd>
      <Button
        variant="contained"
        className="next-button"
        data-test-id="save-activity-btn"
        fullWidth
        type="button"
        onClick={
          this.addPlaceWithTitleLocation.bind(this, { setClickedValue, error1, setFieldValue, allIndexs })
        }
      >
        {configJSON.SaveBtnTitle}
      </Button>
      {this.isAllActivitySaved(false)}
    </div>
  }
  addPlaceWithTitleLocation = ({ setClickedValue, error1, setFieldValue, allIndexs }: { setClickedValue: (value: boolean) => void, error1: any, setFieldValue: (key: string, value: any) => void, allIndexs: { placeIndex: number, index: number, activityIndex: number } }) => {
    const isclicked = (error1.title || error1.location) ? true : false;
    setClickedValue(isclicked)
    if (!isclicked) {
      this.isAllActivitySaved(true);
      setFieldValue(`addplaces[${allIndexs.placeIndex}].plans[${allIndexs.index}].activity[${allIndexs.activityIndex}].isSaved`, true);
      setFieldValue(`addplaces[${allIndexs.placeIndex}].plans[${allIndexs.index}].activity[${allIndexs.activityIndex}].isclicked`, false);
    } else {
      setFieldValue(`addplaces[${allIndexs.placeIndex}].plans[${allIndexs.index}].activity[${allIndexs.activityIndex}].isclicked`, true);
    }
  }

  renderAddActivityHeader = () => {
    return <>{!this.state.isLocationPageOpen && (
      <>
        {!this.state.isEdit ? <Typography className="right-side-header">
          {configJSON.tabTitle}
        </Typography> : null}
        <IteneryMenuIcon variant="h4">
          {this.state.itinerary.title}



          {this.checkEditForUser() || !this.state.isEdit ? <Button
            data-test-id="more-menu-icon"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={this.handleMenuClick}
          >
            <MoreVertIcon style={{ cursor: "pointer", color: "#8c8c8c" }} />
          </Button> : null}
        </IteneryMenuIcon>
        <Menu
          id="simple-menu"
          data-test-id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
        >
          <MenuItem onClick={this.handleTabs} data-test-id="Edit-icon">{configJSON.editTitle}</MenuItem>
        </Menu>
        {this.renderAvatar()}
      </>
    )}</>
  }

  renderActivityBody = (plansdays: {
    day_rank?: number;
    Day: number;
    activity: {
      activity_id?: number;
      images: {
        url: string;
        type: string;
        fileName: string;
      }[];
      isSaved: boolean;
      title: string;
      description: string;
      location: string;
    }[];
    name: string;
  }, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, placeIndex: number, index: number, handleChange: {
    (e: React.ChangeEvent<string>): void;
    <T = string | React.ChangeEvent<string>>(field: T): T extends React.ChangeEvent<string> ? void : (e: string | React.ChangeEvent<string>) => void;
  }, arrayHelpers: ArrayHelpers, item: {
    place_id?: number;
    id?: number;
    place: string;
    days: number;
    plans: {
      day_rank?: number;
      Day: number;
      activity: {
        activity_id?: number;
        images: {
        }[];
        isSaved: boolean;
        title: string;
        description: string;
        location: string;
      }[];
      name: string;
    }[];
  }, error: { errors: { addplaces: { plans: { activity: { title: '', location: '', description: '' }[] }[] }[] }, touched: { addplaces: { plans: { activity: { title: boolean, location: boolean, description: boolean }[] }[] }[] } }) => {
    return <>{plansdays.activity?.length
      ? plansdays.activity.map(
        (activitys, activityIndex) => {
          return activitys.isSaved ? (
            <>
              {this.renderActivityCard(
                activitys,
                setFieldValue,
                placeIndex,
                index,
                activityIndex
              )}
            </>
          ) : (
            <>
              {this.renderActivity(
                activitys,
                handleChange,
                {
                  placeIndex,
                  index,
                  activityIndex,
                },
                arrayHelpers,
                setFieldValue,
                {
                  day_rank: plansdays.day_rank,
                  place_id: item.place_id
                },
                error
              )}
            </>
          );
        }
      )
      : null}</>
  }

  renderTab = () => {
    return this.state.currentTabline === "form2" ? (
      <>
        {this.renderAddActivityHeader()}
        <Formik
          enableReinitialize={true}
          initialValues={{
            addplaces: this.state.itinerary.addplaces,
          }}
          data-test-id="profileFormik1"
          validationSchema={this.validationSchema1}
          onSubmit={(value) => this.handleItinerary(value)}
        >
          {({errors, values, handleSubmit, handleChange, setFieldValue, touched }) => {
            return (
              <form data-test-id="profileForm1" onSubmit={handleSubmit}>
                {this.state.isLocationPageOpen ? (
                  <ItineraryLocation
                    isBookmarked={this.state.isBookmarked}
                    navigation={this.props.navigation}
                    id="ItineraryLocation"
                    data-test-id="ItineraryLocation"
                    placesIndexInformation={{
                      activityIndex: this.state.activityIndex,
                      index: this.state.index,
                      placeIndex: this.state.placeIndex,
                    }}
                    searchText={
                      this.handleSerachedText(values, this.state.placeIndex)
                    }
                    data={{ itinerary: { ...this.state.itinerary, addplaces: values.addplaces }, activityIndex: this.state.activityIndex, isTravellerAdded: this.state.isTravellerAdded, currentTabline: this.state.currentTabline, index: this.state.index, isLocationPageOpen: this.state.isLocationPageOpen, placeIndex: this.state.placeIndex }}
                    backToPreviousPage={this.closeItineraryLocationPage}
                    addLocationItems={(
                      placeIndex,
                      index,
                      activityIndex,
                      values
                    ) => {
                      setFieldValue(
                        `addplaces[${placeIndex}].plans[${index}].activity[${activityIndex}].location`,
                        values
                      );
                      this.setState({
                        isBookmarked: false,
                      })
                    }}
                  />
                ) : (
                  <>
                    {values.addplaces.map((item, placeIndex) => {
                      return (
                        <>
                          <ItemPlacess
                            variant="h5"
                          >
                            {item.place}
                          </ItemPlacess>
                          {item.plans.map((plansdays, index: number) => {
                            return (
                              <ItineraryAccordion
                                defaultExpanded={this.state.index === index}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  style={{ padding: "15px" }}
                                >
                                  <PlansNames
                                    variant="h6"
                                  >
                                    {plansdays.name}
                                  </PlansNames>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{
                                    justifyContent: "center",
                                    padding: "0px 0px 30px",
                                    flexDirection: "column",
                                  }}
                                >
                                  <FieldArray
                                    data-test-id="field-array"
                                    name={`addplaces[${placeIndex}].plans[${index}].activity`}
                                    render={(arrayHelpers) => (
                                      <>
                                        {this.renderActivityBody(plansdays, setFieldValue, placeIndex, index, handleChange, arrayHelpers, item, { errors: errors as { addplaces: { plans: { activity: { title: ""; location: ""; description: ""; }[]; }[]; }[] }, touched: touched as { addplaces: { plans: { activity: { title: boolean, location: boolean, description: boolean }[] }[] }[] } })}
                                        <AddplaceButtonDiv>
                                          <Button
                                            className="addplace add-places-button"
                                            type="button"
                                            data-test-id="add-places"
                                            onClick={() =>
                                              arrayHelpers.push({
                                                title: "",
                                                description: "",
                                                images: [],
                                                isSaved: false,
                                                location: "",
                                              })
                                            }
                                          >
                                            {configJSON.addActivity}
                                          </Button>
                                        </AddplaceButtonDiv>
                                      </>
                                    )}
                                  />
                                </AccordionDetails>
                              </ItineraryAccordion>
                            );
                          })}
                        </>
                      );
                    })}
                    {this.renderCreateAndEditBtn(values, errors)}
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </>
    ) : (
      <></>
    );
  };

  renderCreateAndEditBtn = (values: { addplaces: { plans: { activity: {}[] }[] }[] }, errors: {}) => {
    return values.addplaces[0].plans[0].activity.length &&
    this.state.isAllActivitySaved &&
      !Object.keys(errors).length ? (
      <Button
        variant="contained"
        className="next-button"
        fullWidth
        type="submit"
        data-test-id='submit-button'
      >
        {this.state.isEdit ? configJSON.editSaveTitle : configJSON.createBtnTitle}
      </Button>
    ) : <Button
      variant="contained"
      className="next-button"
      fullWidth
      data-test-id='errorbtn'
      onClick={this.handleItinerary.bind(this,{addplaces:this.state.itinerary.addplaces})
      }
    >
      {this.state.isEdit ? configJSON.editSaveTitle : configJSON.createBtnTitle}
    </Button>
  }

  renderActivityText = (index: number) => [
    index === 0 ?
      <ActivityText>
        {configJSON.activityTitle}
      </ActivityText>
      : null
  ]

  renderPlaces = (values: {
    title: string;
    addplaces: {
      place_id?: number;
      place: string;
      days: number;
      plans: {
        name: string;
      }[];

    }[];
  }, errors: any, touched: any, handleChange: (e: string | React.ChangeEvent<any>) => void, setFieldValue: (e: string, value: string) => void) => {
    return <FieldArray
      name="addplaces"
      render={(arrayHelpers) => (
        <div>
          {values.addplaces.map((item: {}, index: number) => {
            const Errors = errors?.addplaces?.length ? errors.addplaces[index] : { place: "", days: "" }
            const placeError = Errors?.place
            const daysError = Errors?.days
            return (
              <div
                key={index}
                className="addplace"
                style={{
                  marginBottom: 20,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >

                <GooglePlacesAutocomplete
                  apiKey="AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY"
                  data-test-id="autocomplete"
                  selectProps={{
                    isClearable: true,
                    value: values.addplaces[index].place
                      ? [
                        {
                          label: values.addplaces[index].place,
                          value: values.addplaces[index].place,
                        },
                      ]
                      : undefined,
                    classNamePrefix: "react-select",
                    className: "auto-fill-text-field itinerary_details itinerary_place",
                    placeholder: configJSON.placeTitle,
                    noOptionsMessage: () => null,
                    onChange: async (event: any) => {

                      const location =
                        event?.value?.description ?? "";
                      setFieldValue(`addplaces[${index}].place`, location);

                      let latLng: { lat: number, lng: number } = { lat: 0, lng: 0 };
                      if (location) {
                        const data = await geocodeByAddress(location);
                        latLng = await getLatLng(data[0]);
                        setFieldValue(`addplaces[${index}].lat`, latLng.lat.toString());
                        setFieldValue(`addplaces[${index}].lng`, latLng.lng.toString());
                      }
                    },
                    openMenuOnFocus: true
                  }}
                />

                <Select
                  className="itinerary_days"
                  name={`addplaces[${index}].days`}
                  onChange={handleChange}
                  defaultValue={configJSON.daysTitle}
                  data-test-id="days"
                  label-Id="select-label"
                  renderValue={() => {
                    if (values.addplaces[index].days) {
                      return values.addplaces[index].days;
                    } else {
                      return (
                        <DaysTittle>
                          {configJSON.daysTitle}
                        </DaysTittle>
                      );
                    }
                  }}
                >
                  {this.state.days.map((value) => <MenuItem value={value} data-test-id={`day${value}`}>{value}</MenuItem>)}
                </Select>
                <div className="removeplace">{index > 0 ? <CancelIcon onClick={() => this.handleremoveplace(arrayHelpers, index, values.addplaces[index].place_id)} /> : null}</div>
                {placeError && touched.addplaces?.[index]?.place ?
                  <p className="error" style={{ marginBottom: "20px", width: "50%" }}>
                    {placeError}
                  </p>
                  : <PlaceErrors />}
                {daysError && touched.addplaces?.[index]?.days ?
                  <p className="error" style={{ marginBottom: "20px", width: "40%" }}>
                    {daysError}
                  </p>
                  : null}

                {this.renderActivityText(index)}
              </div>
            );
          })}
          <PlacesAddButton >
            <Button
              className="addplace"
              type="button"
              onClick={() =>
                arrayHelpers.push({ place: "", days: 0 })
              }
            >
              {configJSON.AddplaceTitle}
            </Button>
          </PlacesAddButton>
        </div>
      )}
    />
  }
  checkEditForUser = () => {
    
    
    const userAccess = this.state.itinerary?.traveller?.filter((item: any) => item.id == this.state.userDetail.id);
    
    if (userAccess.length > 0) {
      return true
    }
    return false

  }


  render() {
    return (
      <RenderItinerary currentTabline={this.state.currentTabline} isEdit={this.state.isEdit ?? false} isUnAuth={this.props.isUnAuth ?? false} navigation={this.props.navigation}>
        <Loader loading={this.state.isLoading} />
        <WrapperContainer className="itinerary-create-container">
          <Grid item md={12} sm={12} className="itinerary-create-grid-container">
            <Box className="right-side-main-container right-side-main-container-1">
              {this.state.currentTabline === "form1" ? (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    title: this.state.itinerary.title,
                    addplaces: this.state.itinerary.addplaces,
                  }}
                  validationSchema={this.validationSchema}
                  onSubmit={(value: {}) => {
                    console.log('submit+++', value);

                    this.handleSubmitData(value);
                  }}
                  data-test-id="profileFormik"
                >
                  {(props) => {
                    const {
                      touched,
                      errors,
                      values,
                      handleSubmit,
                      handleChange,
                      setFieldValue
                    } = props;
                    return (
                      <form data-test-id="profileForm" onSubmit={handleSubmit}>
                        <Typography variant="h4" className="right-side-header">
                          {configJSON.title1}
                        </Typography>
                        <TittleTwo>
                          <TextField
                            className="itinerary_details"
                            variant="outlined"
                            placeholder={configJSON.title2}
                            fullWidth
                            id="title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            type="text"
                          />
                        </TittleTwo>
                        {errors.title && touched.title && (
                          <ErrorPTAge className="error" >
                            {errors.title}
                          </ErrorPTAge>
                        )}
                        {this.renderPlaces(
                          values,
                          errors,
                          touched,
                          handleChange,
                          setFieldValue
                        )}
                        {this.props.isUnAuth ? null :
                          <div>
                            <InviteTravells variant="h6" style={{ fontWeight: "bold" }}>
                              {configJSON.InviteTravellersTitle}
                            </InviteTravells>
                            <TextField
                              className="itinerary_details"
                              variant="outlined"
                              placeholder={configJSON.SearchTitle}
                              fullWidth
                              id="title"
                              style={{ marginBottom: "10px" }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon style={{ color: "#d6dad6" }} />
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{
                                "data-test-id": "itinerary-details",
                              }}
                              name="title"
                              type="text"
                              onChange={this.handleTravellers}
                            />
                            {this.state.isTravellerSearching ?
                              <div className="search-progress">
                                <CircularProgress />
                              </div>
                              :
                              <></>
                            }

                            {this.state.searchedTraveller?.length ? (
                              <div className="traveller-box" id="traveller">
                                <InfiniteScroll
                                  dataLength={this.state.searchedTraveller.length}
                                  next={this.fetchMoreData}
                                  data-test-id="scroll-bar"
                                  hasMore={this.state.hasMoreTravellers}
                                  loader={
                                    <h4 style={{ marginLeft: "20px" }}>
                                      {configJSON.LoadingTitle}
                                    </h4>
                                  }
                                  scrollableTarget="traveller"
                                >
                                  {this.state.searchedTraveller.filter((item) => item.id != this.state.userDetail.id).map((item) => {
                                    const isInvited = this.state.itinerary.traveller.some(
                                      (i) => i.id === item.id
                                    );
                                    return (
                                      <TravellerCard
                                        onFollowClick={this.addTravellers}
                                        navigation={this.props.navigation}
                                        item={item}
                                        isShowed={false}
                                        isInvited={isInvited}
                                        itineraryOwner={this.state.itinenarys.owner_id}
                                      />
                                    );
                                  })}
                                </InfiniteScroll>
                              </div>
                            ) : null}
                            {this.state.itinerary.traveller?.length &&
                              !this.state.searchedTraveller?.length ? (
                              <>
                                <InvitedLabel variant="h6" >{`Invited(${this.state.itinerary.traveller?.filter((item) => item.id != this.state.userDetail.id).length})`}</InvitedLabel>
                                <div className="traveller-box">
                                  {this.state.itinerary.traveller.filter((item) => item.id != this.state.userDetail.id).map((item) => {
                                    return (
                                      <AddtravellsUnfollow>
                                        <TravellerCard
                                          onFollowClick={this.addTravellers}
                                          navigation={this.props.navigation}
                                          item={item}
                                          isShowed={true}
                                          isInvited={true}
                                          itineraryOwner={this.state.itinenarys.owner_id}
                                        />
                                      </AddtravellsUnfollow>
                                    );
                                  })}
                                </div>
                              </>
                            ) : null}
                          </div>
                        }

                        <Button
                          variant="contained"
                          className="next-button"
                          fullWidth
                          data-test-id='next'
                          type="submit"
                        >
                          {this.state.isEdit ? configJSON.editSaveTitle : configJSON.nextBtn}
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              ) : null}
              {this.renderTab()}
            </Box>
          </Grid>
        </WrapperContainer>
      </RenderItinerary>
    );
  }
}

export default ItineraryCreate;

const WrapperContainer = styled("section")({
  "& .right-side-main-container": {
    borderRadius: "20px",
    border: "0.5px solid rgba(105,134,216,0.1)",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
    padding: 40,
    marginBottom: 20,
  },
  "& .less-title": {
    color: "#1E7AA1",
    cursor: "pointer",
    marginLeft: "0",
    display: "flex",
    alignItems: "center"
  },
  "& .error": {
    color: "red",
    fontWeight: "normal",
    marginBottom: "20px"
  },
  "& .right-side-header": {
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#000000",
    marginBottom: "40px",
  },
  "& .itinerary_details  input ": {
    backgroundBlendMode: "normal",
    padding: "25.32px 14px",
  },
  "& .itinerary_details  input::placeholder": {
    color: "#d5d5d5",
    opacity: 1,
  },
  "& .itinerary_details  input:-ms-input-placeholder": {
    color: "#d5d5d5",
  },
  "& .itinerary_details  input::-ms-input-placeholder": {
    color: "#d5d5d5",
  },
  "& .itinerary_details  .MuiInputBase-formControl": {
    color: "#353249",
    fontFamily: "Helvetica",
    fontSize: "18px",
    fontWeight: "400",
  },
  "& .itinerary_details .react-select__control": {
    height: '100%',
    cursor: 'text'

  },
  "&  .itinerary_details  .MuiOutlinedInput-notchedOutline": {
    border: "0.5px solid rgba(105,134,216,0.1)",
    borderRadius: "16px",
  },
  "& .itinerary_place": {
    width: "55%",
  },
  "& .react-select__indicator": {
    cursor: "pointer",
  },
  "& .next-button": {
    height: "72px",
    borderRadius: "4px",
    background: "#25aae1",
    backgroundBlendMode: "normal",
    "& .MuiButton-label": {
      color: "#ffffff",
      fontFamily: "Helvetica",
      fontSize: "24px",
      fontWeight: "400",
      textTransform: "capitalize",
    },
  },
  "& .multiple-images": {
    width: "100%",
    height: "100%",
    background: "gray",
    color: "#FFFFFF",
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px"
  },
  "& .itinerary_days": {
    width: "40%",
    borderRadius: "16px",
    border: "0.5px solid rgba(105,134,216,0.1)",
  },
  "& .MuiInput-underline::before": {
    borderBottom: "transparent !important",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "transparent !important",
  },
  "& .MuiSelect-select": {
    padding: "25.32px 14px !important",
    background: "none !important",
    fontSize: "18px",
  },
  "& .traveller-box": {
    width: "100%",
    maxHeight: "490px",
    overflow: "auto",
  },
  "& .addplace": {
    textTransform: "none",
    color: '#3498db',
    fontWeight: 'bold'
  },
  "& .removeplace": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .search-progress": {
    textAlign: "center",
    padding: "20px",
    "& div": {
      color: "#21ade7",
    }
  }

});
const ActivityText = styled("div")({
  fontSize: "16px",
  paddingLeft: "16px",
  width: "100%",
  color: "#818181",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  marginTop: "10px",
  marginBottom: "30px",
});
const ItineraryAccordion = styled(Accordion)({
  borderRadius: "16px !important",
  border: "1px solid rgba(100,100,100,0.5)",
  boxShadow: "none",
  margin: "20px 0",
  "&.MuiAccordion-root::before": {
    opacity: "0"
  },
  "& .uploaded-image": {
    width: "148px",
    height: "132px",
    borderRadius: "8px",
    position: "relative",
    display: "inline-block",
    flex: "0 0 148px",
    boxSizing: "border-box",
    "& .MuiSvgIcon-root": {
      height: "24px",
      width: "22px",
      position: "absolute",
      right: "-8px",
      top: "-10px",
      cursor: "pointer",
      zIndex: 1,
    },
    "& img": {
      objectFit: "cover",
      height: "100%",
      width: "100%",
      borderRadius: "8px",
      border: "1px solid rgba(151,151,151,0.21)",
      boxSizing: "border-box",
    },
    "& video": {
      objectFit: "cover",
      boxSizing: "border-box",
      height: "100%",
      border: "1px solid rgba(151,151,151,0.21)",
      width: "100%",
      borderRadius: "8px",
    },
  },
  "& .upload-image-container": {
    border: "1px solid rgba(151,151,151,0.21)",
    width: 148,
    position: "relative",
    height: 132,
    borderRadius: 8,
    background: "#ffffff",
    backgroundBlendMode: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    cursor: "pointer",
  },

})
const ActivityCard = styled(Box)({
  border: "1px solid #25aae1",
  padding: "30px",
  borderRadius: "16px",
  margin: "15px 30px",
})
const WrapperContainers = styled(Grid)({
  boxSizing: 'border-box',
  width: "100%",
  padding: "30px",
  "& .itinerary-container": {
    display: "flex",
    justifyContent: "center",
    "& section": {
      width: "100%",
    }
  }
});
const Formone = styled(Typography)({
  color: "#36324b",
  fontWeight: "bold",
  width: "100%"
});
const SubtitleOne = styled(Typography)({
  display: "flex",
  alignItems: 'center',
  color: "#818181"
});
const UploadedImage = styled('div')({
  display: "flex",
  flexWrap: "wrap"
});
const ActivityTittle = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position:'relative',
})
const AvterDiv = styled('div')({
  display: "flex",
  flexWrap: "wrap"
});
const AddPlaces = styled('div')({
  boxShadow: "3px 5px 11px rgba(165,176,194,0.15)",
  borderRadius: 16,
  marginBottom: 20,
});
const LocationDiv = styled('div')({
  boxShadow: "3px 5px 11px rgba(165,176,194,0.15)",
  borderRadius: 16,
  marginBottom: 20,
});
const AutoPlays = styled('div')({
  marginBottom: 20,
  display: "flex",
  flexWrap: "wrap",
  gap: 20,
});
const ItineryFiledAdd = styled('div')({
  boxShadow: "3px 5px 11px rgba(165,176,194,0.15)",
  borderRadius: 16,
  marginBottom: 20,
});
const ItemPlacess = styled(Typography)({
  color: "#1E7AA1",
  fontWeight: "bold",
  marginTop: "20px",
});
const PlansNames = styled(Typography)({
  fontWeight: "bold",
  fontSize: "18px",
});
const TittleTwo = styled('div')({
  boxShadow: "3px 5px 11px rgba(165,176,194,0.15)",
  borderRadius: 16,
  marginBottom: 20,
});
const UploadedImages = styled('div')({
  margin: "10px 0"
});
const PlacesAddButton = styled('div')({
  textAlign: "center"
});
const ErrorPTAge = styled('p')({
  marginBottom: "20px"
});
const IteneryMenuIcon = styled(Typography)({
  fontWeight: "bold",
  display: "flex",
  justifyContent: "space-between"
});
const AddplaceButtonDiv = styled('div')({
  textAlign: "center"
});
const InviteTravells = styled(Typography)({
  fontWeight: "bold"
});
const AddtravellsUnfollow = styled('div')({
  border: "1px solid #eaeaea",
  margin: "10px 0",
  borderRadius: "16px",
});
const ActivityLabel = styled(Typography)({
  fontWeight: "bold",
  color: "#353249",
  fontSize:"28px",
  width:"100%",
  paddingRight:"5px"
});
const UploadesdImages = styled('div')({
  margin: "10px 0"
});
const DaysTittle = styled(Typography)({
  color: "#d6dad6"
});
const PlaceErrors = styled('div')({
  width: "50%"
});
const InvitedLabel = styled(Typography)({
  fontWeight: "bold"
});
// Customizable Area End
