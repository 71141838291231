import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/utils.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  exploreList: any;
  userDetail: any;
  isLoading: boolean;
  carousel_screen: {
    id: string;
    type: string;
    attributes: {
      content: string;
      created_at: string;
      id: number | string;
      image: string;
      screen_type: string;
      updated_at: string;
    }[];
  }[];
  userPost: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      description: string;
      location: string;
      state: string;
      account_id: number;
      admin_user_id: any;
      created_at: string;
      images_and_videos: {
        id: number;
        filename: string;
        type: string;
        url: string;
      }[];
      uploader_name: string;
      model_name: string;
      updated_at: string;
      interests: {
        data: {
          id: string;
          type: string;
          attributes: {
            name: string;
            activities: {
              data: {
                id: string;
                type: string;
                attributes: {
                  name: string;
                };
              }[];
            };
          };
        }[];
      };
      uploader_image: string;
      is_current_user: string;
      tags: string[];
      comments: number | null;
      likes: number;
      liked_by_user: string;
      share_count: number;
    };
  }[];
  reloadChatList: boolean;
  LocationsSearch: string;
  page: number;
  subcategorys: number;
  hasMore: boolean;
  total_page: number;
  isDataLoaded: boolean;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FanWallHomeControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  HomeDataId: string = "";
  timerId: NodeJS.Timeout | null = null;
  exploreMoreListApiCallId: string = "";
  exploreListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      exploreList: [],
      userPost: [],
      userDetail: {},
      carousel_screen: [],
      isLoading: false,
      reloadChatList: false,
      LocationsSearch: "",
      page: 1,
      subcategorys: 0,
      hasMore: false,
      total_page: 1,
      isDataLoaded: false,
      token: window.localStorage.getItem("authToken") || "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    const itinerary = await getStorageData("ItineraryData", true);
    if (itinerary && Object.keys(itinerary)?.length) {
      this.props.navigation.history.push("itinerary-create");
    }
    this.getHomeScreenData();
    const data = localStorage.getItem("user_details");
    if (data) {
      this.setState({
        userDetail: JSON.parse(data),
      });
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson.errors) {
        this.handleErrorForFanwallHome(responseJson.errors);
        return;
      }
      const total_page = responseJson?.locations?.meta?.total_pages;
      const hasMore =
        this.state.page < responseJson?.locations?.meta?.total_pages
          ? true
          : false;
      switch (apiRequestCallId) {
        case this.HomeDataId:
          this.setState({
            exploreList: responseJson.top_locations.data,
            userPost: responseJson.trending_posts.data,
            carousel_screen: responseJson.carousel_screen.data,
          });
          break;
        case this.exploreMoreListApiCallId:
          const catData: {}[] = [];
          let subcategorys =
            this.state.exploreList.length <= 20 ? 0 : this.state.subcategorys;

          responseJson.locations.data.map(
            (item: {
              attributes: {
                id: number;
                categories: {
                  data: {
                    attributes: {
                      name: "";
                      category_cover_image: "";
                      id: number;
                    };
                  }[];
                };
                city_name: "";
              };
            }) => {
              catData.push(item);
              item.attributes.categories.data?.length &&
                item.attributes.categories.data.map((list) => {
                  subcategorys++;
                  catData.push({
                    attributes: {
                      id: `${item.attributes.id}/${list.attributes.id}`,
                      location_cover_image:
                        list.attributes.category_cover_image,
                      city_name:
                        list.attributes.name +
                        configJSON.inTiltle +
                        item.attributes.city_name,
                    },
                  });
                });
            }
          );

          this.setState({
            exploreList: this.state.LocationsSearch
              ? catData
              : responseJson.locations.data,
            hasMore,
            total_page,
            subcategorys,
          });
          break;
        case this.exploreListApiCallId:
          this.setState({
            exploreList: [
              ...this.state.exploreList,
              ...responseJson.locations.data,
            ],
            hasMore,
            total_page,
          });
          break;
        default:
          break;
      }

      this.setState({
        isLoading: false,
      });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  // web events

  // Customizable Area Start
  handleLikePostAction = (attr: any, index: number) => {
    const data = this.state.userPost[index];
    data.attributes = attr;
    this.state.userPost[index] = data;
    this.setState({ userPost: this.state.userPost });
  };
  handleDeletePostLocal = (index: number) => {
    const data = this.state.userPost;
    data.splice(index, 1);
    this.setState({ userPost: data });
  };
  updateShareCount = (postId: number, share_count: number) => {
    const userPost = this.state.userPost;
    const postIndex = userPost.findIndex((data) => Number(data.id) === postId);
    if (postIndex !== -1) {
      const oneData = userPost[postIndex];
      oneData.attributes.share_count =
        oneData.attributes.share_count + share_count;
      userPost.splice(postIndex, 1, oneData);
      this.setState({
        userPost,
      });
    }
  };
  fetchMoreData = async () => {
    this.setState({
      page: this.state.page + 1,
    });
    const endPoint =
      configJSON.searchBoonmarkApiEndPoint +
      this.state.page +
      "&query=" +
      this.state.LocationsSearch;
    this.exploreListApiCallId = await apiCall({
      endPoint: endPoint,
      method: configJSON.validationApiMethodType,
    });
  };
  goToExperience = (type: string) => {
    switch (type) {
      case "explore":
        this.props.navigation.navigate("Explore");
        break;
      case "itinerary_creation":
        this.props.navigation.navigate("ItineraryCreate");
        break;
      case "post_creation":
        this.props.navigation.navigate("PostCreate");
        break;
      case "post_list":
        this.props.navigation.navigate("PostList");
        break;
      default:
        break;
    }
  };
  chatListAgainApiCall = () => {
    this.setState({
      reloadChatList: !this.state.reloadChatList,
    });
  };
  setNotificationData = (data: any) => {
    return data;
  };
  redirectToExploreList = (id: number) => {
    this.props.navigation.history.push(`/explore/${id}`);
  };

  handleLocation = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.setState({
      LocationsSearch: event.target.value,
    });
    this.timerId = setTimeout(
      this.handleLocationsTraveller,
      2000
    ) as unknown as NodeJS.Timeout;
  };

  backToPost = () => {
    this.setState(
      {
        LocationsSearch: "",
      },
      () => this.handleLocationsTraveller()
    );
  };

  handleLocationsTraveller = async () => {
    if (this.state.LocationsSearch) {
      this.setState({
        page: 1,
        isLoading: true,
        isDataLoaded: true,
      });
      this.exploreMoreListApiCallId = await apiCall({
        endPoint:
          configJSON.searchBoonmarkApiEndPoint +
          "1&query=" +
          this.state.LocationsSearch,
        method: configJSON.validationApiMethodType,
        contentType: configJSON.validationApiContentType,
      });
    } else {
      this.getHomeScreenData();
      this.setState({
        isDataLoaded: false,
      });
    }
  };

  getHomeScreenData = () => {
    this.setState({
      isLoading: true,
    });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.HomeDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.homeScreenEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  renderCarouselBtnTitle = (type: string) => {
    switch (type) {
      case "itinerary_creation":
        return "Start Planning";
      case "post_creation":
        return "Share Experience";
      default:
        break;
    }
  };
  handleErrorForFanwallHome = (fanwall_errors: any) => {
    if (Array.isArray(fanwall_errors)) {
      if (
        fanwall_errors[0].token === "Invalid token" ||
        fanwall_errors[0].token === "Token has Expired"
      ) {
        toast.error(`${fanwall_errors[0].token}${configJSON.errormassage2}`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
      } else {
        toast.error(configJSON.errormassage);
      }
    }
  };

  // Customizable Area End
}
