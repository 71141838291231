import React, { CSSProperties } from "react";

import {
    Box,
    // Customizable Area Start
    InputBase
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SearchIcon from '@material-ui/icons/Search';
import FollowersController from "./FollowersController"
import FollowCard from "../../../../components/src/FollowCard.web"
import ConfimeAction from "../../../../components/src/ConfimeAction.web"
import ConfirmationModal from "../../../../components/src/ConfirmationModal.web"
import Loader from "../../../../components/src/Loader";

export default class Followers extends FollowersController {
    goToProfile=(id:number)=>{
        this.props.navigation.history.push(`/profile/${id}`)
    }
    render() {
        return (
            // Customizable Area Start
            <>
                <Box style={{ height: '90%' }}>
                    <Loader loading={this.state.isLoader} />
                    <Box style={{
                        margin: '40px 25px 30px',
                        padding: '15px',
                        borderRadius: '8px',
                        alignItems: 'center',
                        display: 'flex',
                        border: '1px solid #ccc',
                    }}>
                        <SearchIcon style={{ color: "938f8f" }} />
                        <InputBase
                            test-id="inputBaseId"
                            style={{ paddingLeft: '15px', }}
                            placeholder="Search People"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            fullWidth
                            onChange={(event) => this.searchHandel(event?.target?.value)}
                        />
                    </Box>
                    <Box
                        style={{
                            height: '88%',
                            overflowX: 'hidden',
                            padding: '0 25px',
                            overflowY: 'auto'
                        }}
                        id="scrollableDiv"
                    >
                        <FollowCard
                            title={"No followers found!"}
                            data={this.state?.followersList?.data}
                            buttonLabel={"Remove"}
                            handelClick={this.followerhandel}
                            userDetails={this.props.userDetails}
                            id={this.props.id}
                            goToProfile={this.goToProfile}
                        />
                    </Box>
                </Box>
                {this.state.FollowersState && (
                    <ConfimeAction
                        open={true}
                        followerclose={this.onHandleCLoase}
                        onHandleBlock={this.onHandleBlock}
                        onHandleRemoveFollower={this.onHandleRemoveFollower}
                        onHandleReport={this.onHandleReport}
                        title={'Confirm Action'}
                    />
                )}
                {this.state.FollowerRemoveState && (
                    <ConfirmationModal
                        open={true}
                        handleCloseModal={this.onHandleRemoveFollower}
                        onHandleConfirmation={this.onHandleRemoveYes}
                        title={'Remove Follower'}
                        description={"Are sure want to remove?"}
                    />
                )}
                {this.state.followerBlockState && (
                    <ConfirmationModal
                        open={true}
                        handleCloseModal={this.onHandleBlock}
                        onHandleConfirmation={this.onHandleBlockYes}
                        title={'Block User'}
                        description={"Are you sure want to Block this User?"}
                    />
                )}
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area End
