import React from "react";
// Customizable Area Start
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { profileAvatar } from "./assets";
import MoreStepsControllerBlock, {
  Props,
} from "./MoreStepsControllerBlock.web";
import Loader from "../../../components/src/Loader.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Formik } from "formik";
// Customizable Area End
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
export default class MoreSteps extends MoreStepsControllerBlock {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Box
        style={{ padding: "50px 80px", overflowX: "hidden", height: "100vh" }}
        className="background-color"
      >
        <Loader loading={this.state.isLoading} />
        <ThemeProvider theme={theme}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "50px",
            }}
          >
            <div
              test-goBack="go_back"
              className="go-back-button"
              onClick={this.navigationFunctuon}
              style={{ marginBottom: "0px", marginRight: 25 }}
            >
              <ArrowBackIosIcon className="go-back-button-icon" />
            </div>
            <Typography
              variant="h4"
              style={{
                color: "#0a083a",
                fontFamily: "Helvetica-Bold",
                fontSize: "32px",
                fontWeight: "normal",
                lineHeight: "40px",
                letterSpacing: "-1.28px",
              }}
            >
              <strong>Few more steps</strong>
            </Typography>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={{
              tags: [],
            }}
            validationSchema={this.validationTagsFormSchema}
            onSubmit={(value) => {
              this.handleSubmit(value);
            }}
          >
            {({ touched, errors, values, handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4} style={{ marginBottom: "50px" }}>
                    <Grid item xs={6}>
                      <Box
                        style={{
                          boxShadow: "0px 0px 39px -16px rgba(0,0,0,0.3)",
                          padding: "35px",
                          height: "100%",
                          borderRadius: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h6"
                          style={{ marginBottom: "30px" }}
                        >
                          Upload your photo
                        </Typography>
                        <Box
                          onClick={this.handleImageClick}
                          style={{ position: "relative", display: "inline" }}
                        >
                          {!this.state.data ? (
                            <img
                              src={profileAvatar}
                              style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                                border: "1px solid rgb(33, 150, 243)",
                                padding: "6px",
                              }}
                            ></img>
                          ) : (
                            <img
                              src={URL.createObjectURL(this?.state?.image)}
                              style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                                border: "1px solid rgb(33, 150, 243)",
                                padding: "6px",
                              }}
                            ></img>
                          )}
                          <Box
                            style={{
                              borderRadius: "50%",
                              border: "5px solid #fff",
                              padding: "6px",
                              width: "25px",
                              height: "25px",
                              textAlign: "center",
                              position: "absolute",
                              bottom: " 0",
                              background: "rgb(33, 150, 243)",
                              right: "0",
                            }}
                          >
                            <input
                              data-test-id="FileInput"
                              accept="image/*"
                              className={""}
                              ref={this.inputRef}
                              id="file-input"
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e: any) => this.handleImageChange(e)}
                            />
                            <svg
                              fill="none"
                              height="25"
                              viewBox="0 0 20 20"
                              width="25"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                clip-rule="evenodd"
                                d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z"
                                fill="#fff"
                                fill-rule="evenodd"
                              />
                            </svg>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        style={{
                          boxShadow: "0px 0px 39px -16px rgba(0,0,0,0.3)",
                          padding: "35px",
                          height: "100%",
                          borderRadius: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <Typography variant="h6">All activities</Typography>
                        <Typography
                          gutterBottom
                          style={{ color: "#a2a0a0", marginBottom: "30px" }}
                        >
                          {values.tags.length >= 3
                            ? "Let us know you interest"
                            : "Let us know you interest, select at least 3"}
                        </Typography>
                        <div className="tag-items">
                          {this.state.arr.data.length > 0 ? (
                            this.state.arr.data.map((data: any) => (
                              <Box
                                style={{ marginBottom: "20px" }}
                                key={data.id}
                              >
                                <Typography gutterBottom variant="h6">
                                  {data.attributes.name}
                                </Typography>
                                <Box
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {data.attributes.activities.data.map(
                                    (item: any) => (
                                      <span
                                        id={item.id}
                                        key={item.attributes.name}
                                        className={
                                          values.tags.findIndex(
                                            (tag: any) => tag.id === item.id
                                          ) != -1
                                            ? "selected-chip-button"
                                            : "un-selected-chip-button"
                                        }
                                        test-id="handleClick"
                                        onClick={(e) => {
                                          if (
                                            values.tags.some(
                                              (tag: any) => tag.id === item.id
                                            )
                                          ) {
                                            setFieldValue(
                                              "tags",
                                              values.tags.filter(
                                                (c: any) => c.id !== item.id
                                              )
                                            );
                                          } else {
                                            const tag = {
                                              ...item,
                                              parentId: data.id,
                                            };
                                            setFieldValue("tags", [
                                              ...values.tags,
                                              tag,
                                            ]);
                                          }
                                        }}
                                      >
                                        {item.attributes.name}
                                      </span>
                                    )
                                  )}
                                </Box>
                              </Box>
                            ))
                          ) : (
                            <p
                              style={{
                                color: "#a2a0a0",
                                margin: "10px 0",
                                fontSize: 14,
                              }}
                            >
                              No activity found
                            </p>
                          )}
                        </div>
                        {errors.tags && touched.tags && (
                          <p
                            className="error"
                            style={{
                              marginTop: "20px",
                              marginBottom: "15px",
                            }}
                          >
                            {errors.tags}
                          </p>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  {this.state.errorFlag && this.state.errorMsg}
                  <Button
                    data-test-id={"btnForLogInScreen"}
                    variant="contained"
                    color="primary"
                    style={{
                      background: "rgb(33, 150, 243)",
                      padding: "15px",
                      margin: "0px auto",
                      display: "block",
                      textTransform: "capitalize",
                      minWidth: "255px",
                      color: "#ffffff",
                    }}
                    type="submit"
                  >
                    Save & Continue
                  </Button>
                </form>
              );
            }}
          </Formik>
        </ThemeProvider>
      </Box>
    );
  }
}

// Customizable Area End
