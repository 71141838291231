import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Grid, TextField } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordControllerBlock, {
  Props, configJSON,
} from "./ForgotPasswordControllerBlock.web";
import ErrorIcon from "@material-ui/icons/Error";
import Loader from "../../../components/src/Loader.web";
import Carousel from "react-material-ui-carousel";
import {Line} from './assets';
// Customizable Area End
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const validationSchema = Yup.object({
  userName: Yup.string().when("isEmail", {
    is: "1",
    then: Yup.string()
      .email("Invalid email address")
      .required("Email / Number is required"),
    otherwise: Yup.string()
      .required("Email / Number is required")
      .min(10, "Phone number is required 10 minimum digits")
      .max(10, "Phone number is required 10 maximum digits"),
  }),
});

const MuiDiv = styled("div")({
  position: 'absolute',
  width: '100%',
  height: '300px',
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
  "& h3, & h1" : {
    marginBottom: '0',
    color: '#ffffff',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    lineHeight: '56px',
    letterSpacing: '1.8px',
    marginLeft: '70px',
    fontSize: '18px',
  },
  "& h1": {
    fontWeight: 'normal',
    fontSize: '60px',
    fontStyle: 'normal',
  },
  "& .text-color":{
    display: 'block',
    color: "#ffffff",
  },
});

const MuiMainContainers = styled("div")({
  position: 'relative',
  "& .main-img": {
    width: "100%",
    height: "100%",
    objectFit: 'cover'
  },
  "& .Carousel-box": { flex: 1, overflow: "hidden", height: "100%" },
});

// Customizable Area End
export default class ForgotPasswordBlock extends ForgotPasswordControllerBlock {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.isLoading} />
        <Grid container style={{ overflowX: "hidden", height: "100%" }}>
          <Grid item xs={6}>
          <MuiMainContainers>
              <Carousel
                animation="slide"
                swipe={true}
                indicators={false}
                autoPlay
              >
                {this.state.CarouselImages.map((item ,i) => {
                  return (
                    <Box
                      className="Carousel-box"
                      key={i}
                    >
                      <MuiDiv>
                        <h3>{configJSON.maintitle}</h3>
                        <img src={Line} alt="line-object" width='100%' />
                        <h1>
                          {configJSON[item.data+'11']}
                          <br/>
                          <span className="text-color">
                            {configJSON[item.data+'22']}
                          </span>
                        </h1>
                      </MuiDiv>
                      <img
                        className="main-img"
                        src={item.img}
                      />
                    </Box>
                  );
                })}
              </Carousel>
                <Box>
                  <Button
                    data-test-id={"btnForLogInScreen"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="explore-button"
                  >
                    Explore
                  </Button>
                </Box>
              </MuiMainContainers>
          </Grid>
          <Grid item xs={6} style={{ padding: "80px" }}  className="background-color">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 0px",
              }}
            >
              <div
                className="go-back-button"
                test-goBack="go_back"
                onClick={() => this.navigationFunctuon()}
              >
                <ArrowBackIosIcon className="go-back-button-icon" />
              </div>
              <Typography
                variant="h4"
                component="h2"
                align="left"
                style={{ fontWeight: "bold", marginBottom: "20px" }}
              >
                Forgot Password
              </Typography>
              <Typography variant="subtitle1" component="div" align="left">
                Provide your either mobile number or email address of your
                account to reset your password
              </Typography>
              <Box sx={{ width: "100%", paddingTop: "30px" }}>
                <Formik
                  enableReinitialize
                  initialValues={{ isEmail: "0", userName: "" }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    this.forgotPassowrdFunction(values);
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    values,
                    handleBlur,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <ForgotPasswordForm
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        error={this.state.error}
                        errorMsg={this.state.errorMsg}
                      />
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const ForgotPasswordForm = ({
  values,
  errors,
  touched,
  handleChange,
  error,
  errorMsg,
  handleBlur,
}: any) => {
  return (
    <>
      <div className="box-shadow-text-field">
        <TextField
          placeholder="Email Address/ Phone Number"
          data-test-id="test_userName"
          variant="outlined"
          className="input-field"
          fullWidth
          id="userName"
          name="userName"
          value={values.userName}
          onBlur={handleBlur}
          onChange={(event) => {
            handleChange("userName")(event);
            if (Number(values.userName)) {
              handleChange("isEmail")("0");
            } else {
              handleChange("isEmail")("1");
            }
          }}
        />
      </div>
      {errors.userName && touched.userName && (
        <p className="error mt-0 mb-20">{errors.userName}</p>
      )}
      {error && (
        <Typography
          component="div"
          align="left"
          variant="subtitle1"
          style={{ color: "red", display: "flex", paddingBottom: "15px" }}
        >
          <ErrorIcon style={{ marginRight: "8px" }} /> {errorMsg}
        </Typography>
      )}
      <Button
        test-handleSubmit="test-handleSubmit"
        style={{
          background: "#25aae1",
          marginTop: "20px",
          marginBottom: "30px",
          padding: "16px",
          textTransform: "unset",
          fontSize: "18px",
        }}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        Submit
      </Button>
    </>
  );
};
// Customizable Area End
