// Customizable Area Start
import React from "react";

import ViewItineraryController, { Props } from "./ViewItineraryController.web";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Typography,
  styled,
  IconButton,
} from "@material-ui/core";
import BackButton from "../../../components/src/BackButton.web";
import Loader from "../../../components/src/Loader.web";
const configJSON = require("./config");
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { mapIcon } from "./assets";
import ConfirmationModal from "../../../components/src/ConfirmationModal.web";
import ItineraryDiscussionChat from "./ItineraryDiscussionChat.web";

const videoFormat = ["mp4", "webm", "avi", "mkv", "mov"];

class ItineraryView extends ViewItineraryController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const traveller = [...this.state.itinenary.travelers].splice(0, 4);
    const history = this.props.navigation.history
    return (
      <WrapperContainer container spacing={0}>
        <Loader loading={this.state.isLoading} />
        <Grid className="itinerary-view itinerary-view-container" id="scrollableDiv" item sm={8}>
          <div
            data-test-id="back-btn"
            onClick={()=>{this.handleBackButton(!!history.location.state.userId)}
          }
          >
            <BackButton />
          </div>
          <div className="itinerary-container itinerary-view-container">
            <div className="itinerary-header itinerary-view-header">
              <div>
                <Typography style={{ fontWeight: "bold" }} variant="h4">
                  {this.state.itinenary.title}
                </Typography>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.itinenary.travelers.length <= 5 ? (
                    <>
                      {this.state.itinenary.travelers.map((item, index) => {
                        return (
                          <Avatar
                            alt="Traveller-img"
                            title={item.full_name}
                            style={{
                              marginLeft: `${index === 0 ? "0" : "-8px"}`,
                              zIndex:
                                this.state.itinenary.travelers.length - index,
                            }}
                            src={item.profile_photo}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {this.state.isUsersExpand ? (
                        <>
                          {this.state.itinenary.travelers.map((item, index) => {
                            return (
                              <Avatar
                                alt="Traveller-img"
                                title={item.full_name}
                                style={{
                                  marginLeft: `${index === 0 ? "0" : "-8px"}`,
                                  zIndex:
                                    this.state.itinenary.travelers.length -
                                    index,
                                }}
                                src={item.profile_photo}
                              />
                            );
                          })}
                          <Typography
                            data-test-id="less-title"
                            className="less-title"
                            onClick={() => this.handleUsers(false)}
                          >
                            {configJSON.lessTitle}
                          </Typography>
                        </>
                      ) : (
                        <>
                          {traveller.map((item, index) => {
                            return (
                              <Avatar
                                alt="Traveller-img"
                                title={item.full_name}
                                style={{
                                  marginLeft: `${index === 0 ? "0" : "-8px"}`,
                                  zIndex:
                                    this.state.itinenary.travelers.length -
                                    index,
                                }}
                                src={item.profile_photo}
                              />
                            );
                          })}
                          <Avatar
                            data-test-id="expend-avatar"
                            style={{ cursor: "pointer", marginLeft: "-8px" }}
                            onClick={() => this.handleUsers(true)}
                            className="addAvatar"
                          >
                            +
                          </Avatar>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div>
                {this.state.EditableUsers.includes(
                  this.state.userDetail?.id ?? "0"
                ) ? (
                  <Button
                    data-test-id="more-menu-icon"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={this.handleMenuClick}
                  >
                    <MoreVertIcon
                      style={{ cursor: "pointer", color: "#8c8c8c" }}
                    />
                  </Button>
                ) : null}

                <Menu
                  id="simple-menu"
                  data-test-id="closed-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleMenuClose}
                >
                  <MenuItem
                    onClick={() =>
                      this.props.navigation.history.push(
                        `/edit-iteinerary/${this.state.itinenary.id}`
                      )
                    }
                    data-test-id="Edit-icon"
                  >
                    {configJSON.editTitle}
                  </MenuItem>
                  {Number(this.state.userDetail.id) ==
                    this.state.itinenary.owner_id ? (
                    <MenuItem
                      onClick={this.handleDeleteItinerary}
                      data-test-id="delete-itinerary-btn"
                    >
                      {configJSON.deleteTitle}
                    </MenuItem>
                  ) : null}
                </Menu>

                <ConfirmationModal
                  open={this.state.deleteModal}
                  handleCloseModal={this.handleModalClose}
                  onHandleConfirmation={this.handleDelete}
                  title={configJSON.deleteItineraryTitle}
                  description={configJSON.deleteItineraryTitle2}
                />
              </div>
            </div>

            {this.state.itinenary.itinerary_locations.map((item) => {
              return (
                <>
                  <Typography
                    variant="h5"
                    style={{
                      color: "#1E7AA1",
                      fontWeight: "bold",
                      marginTop: "20px",
                    }}
                  >
                    {item.title}
                  </Typography>
                  {this.handleDays(item.days).map((days, index) => {
                    return (
                      <div
                        className="day-box"
                        data-test-id="day-box"
                        onClick={() =>
                          this.handleActivity(item.id, days.name, index)
                        }
                      >
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          {days.name}
                        </Typography>
                      </div>
                    );
                  })}
                </>
              );
            })}

            <MuiModal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={this.state.ModelOpen}
              onClose={this.modalclose}
            >
              <Box className="paper">
                <Box className="modal-title">
                  <Typography variant="h4">{this.state.ModelTitle}</Typography>
                  <CloseIcon
                    onClick={this.modalclose}
                    data-test-id="close-modal-icon"
                    style={{
                      color: "#25AAE1",
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <Box className="modal-description">
                  
                  
                  
                  {this.state.Activitys?.length>0 ? this.state.Activitys?.map((item) => {
                    return (
                      <ActivityCard>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{ fontWeight: "bold", color: "#353249" }}
                            variant="h4"
                          >
                            {item.name}
                          </Typography>
                        </div>
                        <Typography
                          variant="subtitle1"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#818181",
                          }}
                        >
                          <img src={mapIcon} style={{ marginRight: "5px" }} />{" "}
                          {item.location}
                        </Typography>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {item.photos.length != 0 &&
                            item.photos.map((items) => {
                              const url =
                                items
                                  .split(".")
                                  .pop()
                                  ?.toLowerCase() ?? "";
                              return (
                                <div
                                  className="uploaded-image"
                                  style={{ margin: "10px 0" }}
                                >
                                  {videoFormat.includes(url) ? (
                                    <video autoPlay={false} src={items} />
                                  ) : (
                                    <img src={items} />
                                  )}
                                </div>
                              );
                            })}
                        </div>
                        <Typography variant="subtitle1">
                          {item.description}
                        </Typography>
                      </ActivityCard>
                    );
                  }):<p>Activity not found</p>}
                </Box>
              </Box>
            </MuiModal>
          </div>
        </Grid>
        {this.state.itinenary.travelers
          .map((item) => item.account_id)
          .includes(Number(this.state.userDetail.id)) && (
            <Grid item sm={4} className="itinerary-chat-container">
              <ItineraryDiscussionChat
                itineraryData={{
                  id: this.state.itinenary.id,
                  owner_id: this.state.itinenary.owner_id,
                  title: this.state.itinenary.title,
                  travelers: this.state.itinenary.travelers,
                }}
                navigation={this.props.navigation}
              />
            </Grid>
          )}
      </WrapperContainer>
    );
  }
}

export default ItineraryView;

const WrapperContainer = styled(Grid)({
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  background: "#FAFBFF",
  "& .itinerary-view": {
    padding: "30px 12px 12px 30px",
    maxHeight: "100%",
    overflow: "auto",
  },
  "& .itinerary-container": {
    borderRadius: "20px",
    border: "0.5px solid rgba(105,134,216,0.1)",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 10px 16px rgba(165,176,194,0.15)",
    padding: 40,
    marginBottom: 20,
  },
  "& .day-box": {
    padding: "30px",
    border: "1px solid #92d5f0",
    borderRadius: "16px",
    background: "#ffffff",
    margin: "15px 0",
    cursor: "pointer",
  },
  "& .itinerary-header": {
    display: "flex",
    alignItems: "top",
    justifyContent: "space-between",
  },
  "& .less-title": {
    color: "#1E7AA1",
    cursor: "pointer",
    marginLeft: "0",
    display: "flex",
    alignItems: "center",
  },
  "& .itinerary-chat-container": {
    background: "rgba(37,170,225,0.08)",
    borderRadius: "32px 0px 0px 0px",
    border: "1px solid rgba(151, 151, 151, 0.16)",
    padding: "56px 32px 20px 32px",
    position: "relative",
    boxSizing: "border-box",
    height: "100%",
  },
});
const MuiModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .paper": {
    backgroundColor: "#ffffff",
    padding: "20px 30px",
    borderRadius: "12px",
    width: "800px",
    maxHeight: "80vh",
  },
  "& .modal-title": {
    display: "flex",
    maxHeight: "7vh",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  },
  "& .modal-description": {
    maxHeight: "70vh",
    overflow: "auto",
    "& p":{
      fontSize:"14px",
      fontFamily: "HelveticaNeue",
      color:'grey'
    }
  },
});
const ActivityCard = styled(Box)({
  border: "1px solid #25aae1",
  padding: "30px",
  borderRadius: "16px",
  margin: "15px 0",
  "& .uploaded-image": {
    width: "148px",
    height: "132px",
    borderRadius: "8px",
    position: "relative",
    display: "inline-block",
    flex: "0 0 148px",
    boxSizing: "border-box",
    "& .MuiSvgIcon-root": {
      height: "24px",
      width: "22px",
      position: "absolute",
      right: "-8px",
      top: "-10px",
      cursor: "pointer",
      zIndex: 1,
    },
    "& img": {
      objectFit: "cover",
      height: "100%",
      width: "100%",
      borderRadius: "8px",
      border: "1px solid rgba(151,151,151,0.21)",
      boxSizing: "border-box",
    },
    "& video": {
      objectFit: "cover",
      boxSizing: "border-box",
      height: "100%",
      border: "1px solid rgba(151,151,151,0.21)",
      width: "100%",
      borderRadius: "8px",
    },
  },
});

// Customizable Area End
