// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../../../components/src/utils.web";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  exploreList: {
    id: string;
    type: string;
    attributes: {
      id: number;
      city_name: string;
      location_cover_image: string;
    };
  }[];
  isLoading: boolean;
  page: number;
  limit: number;
  total_page: number;
  hasMore: boolean;
}

interface SS {
  id: any;
}

export default class ExploreController extends BlockComponent<Props, S, SS> {
  exploreListApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      exploreList: [],
      isLoading: false,
      page: 1,
      total_page: 1,
      hasMore: false,
      limit: 20,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    await this.getExploreList();
  }

  getExploreList = async () => {
    this.exploreListApiCallId = await apiCall({
      endPoint: `bx_block_explore/explore_locations/?page=${this.state.page}&per_page=${this.state.limit}`,
      method: "GET",
    });
  };

  fetchMoreData = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getExploreList();
      }
    );
  };

  handleError1 = (errors: any) => {
    if (Array.isArray(errors)) {
      if (
        errors[0].token === "Invalid token" ||
        errors[0].token === "Token has Expired"
      ) {
        toast.error(`${errors[0].token}, Please login again.`);
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("user_details");
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return false;
      }
    }
    toast.error("Something went to wrong please retry");
  };

  handleResponse = async (apiRequestCallId: any, responseJson: any) => {
    if (
      apiRequestCallId === this.exploreListApiCallId &&
      responseJson.locations
    ) {
      const total_pages = responseJson.locations.meta.total_pages;
      const hasMore =
        this.state.page < responseJson.locations.meta.total_pages
          ? true
          : false;
      this.setState({
        exploreList: [
          ...this.state.exploreList,
          ...responseJson.locations.data,
        ],
        hasMore,
        total_page: total_pages,
      });
    }
  };

  redirectToExploreList = (id: number) => {
    this.props.navigation.history.push(`/explore/${id}`);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson.errors) {
        this.handleError1(responseJson.errors);
      }
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        await this.handleResponse(apiRequestCallId, responseJson);
      }

      this.setState({
        isLoading: false,
      });
    }
  }
}

// Customizable Area End
